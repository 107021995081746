<template>
    <Base>
        <div class="container-content">
            <div class="application" v-if="form">
                <FormAside :sections="form.sections"/>
                <form class="form check">
                    <h3 class="form__title">
                        Check your information before send application
                    </h3>
                    <div class="form__substrate"></div>
                    <div class="form__triangles">
                        <div></div>
                        <div></div>
                    </div>
                    <div class="form__line"></div>
                    <div class="form__card-body" id="fieldsContainer">
                        <table class="table" v-for="section in form.sections">
                            <tbody class="check__tbody">
                            <tr>
                                <td colspan="2">
                                    <div style="display: flex; align-items: center; justify-content: space-between">
                                        <h3>{{ section.label }}</h3>
                                        <router-link
                                            class="btn-edit"
                                            :to="{
                                            name: 'filling_page',
                                            params: {
                                                questionnaireId: this.$route.params.questionnaireId,
                                            },
                                            query: {
                                                section: section.name,
                                            },
                                        }"
                                            style="display: block">Edit
                                        </router-link>
                                    </div>
                                </td>
                            </tr>
                            <tr v-for="(value, field_name) in answers[section.name]">
                                <td v-if="getLabel(section.name, field_name)">
                                    {{ getLabel(section.name, field_name) }}
                                </td>
                                <td
                                    v-if="getPrettyValue(section.name, field_name)"
                                    v-html="getPrettyValue(section.name, field_name)"
                                >
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <button style="margin-top: 40px;" class="--btn-next" type="button" @click="finishFilling">Send</button>
                    </div>
                </form>
            </div>
        </div>
    </Base>
</template>

<script>
import Base from "@/layouts/base.vue";
import FormAside from "@/components/elements/FormAside.vue";
import Checkbox from "@/components/fields/Checkbox.vue";

export default {
    name: "check",
    components: {Checkbox, FormAside, Base},
    data: () => ({
        form: null,
        answers: null,
    }),
    methods: {
        getField(sectionName, fieldName) {
            return (this.form.sections
                .find(s => s.name === sectionName).fields
                .find(f => f.name === fieldName));
        },
        getLabel(sectionName, fieldName) {
            const field = this.getField(sectionName, fieldName);
            return field ? field.label : '';
        },
        getPrettyValue(sectionName, fieldName) {
            const field = this.getField(sectionName, fieldName);
            if (!field) return '';
            const value = this.answers[sectionName][fieldName];
            if (field.type === 'Address' && typeof value === 'object') {
                return `${value.postcode}, ${value.country}, ${value.state}, ${value.city}, ${value.street}, ${value.house}`;
            }
            if (field.type === 'StringArray' && value instanceof Array) {
                return value.join(', ');
            }
            if (field.type === 'SelectArray' && value instanceof Array) {
                return value.join(', ');
            }
            if (field.type === 'CountryArray' && value instanceof Array) {
                return value.join(', ');
            }
            if (field.type === 'Nested' && value instanceof Array) {
                let v = [];
                for (const nestedValues of value) {
                    let nestedV = '';
                    for (const nestedField of field.nested_fields) {
                        nestedV += `<div class="check__td-nested"><span>${nestedField.label} :</span> <span>${nestedValues[nestedField.name]}</span></div>`;
                    }
                    v.push(nestedV);
                }
                return v.join('------<br />');
            }
            return value;
        },
        async finishFilling() {
            const questionnaire = await this.$services.questionnaires.finishFilling(
                this.$route.params.questionnaireId,
            )
            this.$router.push({
                name: 'questionnaires',
            })
        },
    },
    async mounted() {
        const questionnaireId = this.$route.params.questionnaireId;
        const questionnaire = await this.$services.questionnaires.getQuestionnaire({
            questionnaire_id: questionnaireId
        });
        this.answers = questionnaire.answers;
        this.form = questionnaire.form;
    },
}
</script>

<style lang="sass">

.check
    table
        border-collapse: collapse
    table, button, .form__title, .form__line
        z-index: 10
        position: relative
    &__tbody
        tr
            td
                width: 50%
                padding: 8px 0
                border-bottom: 1px solid var(--description-text)
                vertical-align: baseline
                &:first-child
                    font-weight: 500

            td + td
                text-align: end
    &__td-nested
        display: flex
        justify-content: space-between
        margin-left: 32px
        border-bottom: 1px solid var(--description-text)
        padding: 4px 0
        &:last-child
            border-bottom: none

</style>