<template>
    <div class="address-field">
        <div class="address-field__input address-field__enter-field">
            <AddressIcon/>
            <input
                class="input"
                ref="autocompleteInput"
                @input="_replaceGermanySymbols('autocompleteInput')"
            >
        </div>
        <div class="address-field__result-input">
            <div class="address-field__row">
                <div v-if="country !== false" class="address-field__input">
                    <input style="display: none" type="text" ref="country" @input="_replaceGermanySymbols('country')">
                    <v-select
                        class="address-field__vSelect"
                        :options="options"
                        :reduce="option => option.value"
                        placeholder="Country"
                        v-model="vCountry"
                        :disabled="Boolean(countryOnly)"
                    >
                        <template #option="{ label, value }">
                            <div class="country-field__option">
                                <img
                                    :src="require(`@/libs/flag-icons/${_transformInIsoCode3(label, 'name').toLowerCase()}.gif`)"
                                    alt="">
                                {{ label }}
                            </div>
                        </template>
                        <template #selected-option="{label, value}">
                            <div class="country-field__selected">
                                <img
                                    :src="require(`@/libs/flag-icons/${_transformInIsoCode3(label, 'name').toLowerCase() || label}.gif`)"
                                    alt="">
                                {{ label }}
                            </div>
                        </template>
                    </v-select>
                </div>
                <div v-if="state" class="address-field__input">
                    <v-select
                        class="address-field__vSelect"
                        :class="{'display-none': !vCountry || (vCountry !== 'CHN' && vCountry !== 'IND')}"
                        :options="vCountry === 'CHN' ? CHINESE_PROVINCES : INDIAN_STATES"
                        placeholder="State"
                        v-model="vState"
                    >
                    </v-select>
                    <input @input="_replaceGermanySymbols('state')"
                           @change="updateValue" :class="{'display-none': vCountry && (vCountry === 'CHN' || vCountry === 'IND')}" class="input"
                           ref="state" placeholder="State" type="text">
                </div>
            </div>
            <div class="address-field__row">
                <div
                    class="address-field__input"
                    :class="{'address-field__hide-border': !postcode && !street && !house}"
                >
                    <v-select
                        :class="{'display-none': !vCountry || vCountry !== 'CHN'}"
                        class="address-field__vSelect"
                        :options="vState ? CHINESE_PROVINCES_CITIES[vState]: CHINESE_CITIES"
                        placeholder="City"
                        ref="city"
                        v-model="vCity"
                    >
                    </v-select>
                    <input @input="_replaceGermanySymbols('city')"
                        :class="{'display-none': vCountry && vCountry === 'CHN'}" @change="updateValue" class="input"
                           ref="city" placeholder="City" type="text">
                </div>
                <div
                    v-if="district && vCity !== 'Suzhou'"
                    class="address-field__input"
                    :class="{'address-field__hide-border': !postcode && !street && !house}"
                >
                    <v-select
                        :class="{'display-none': !vCountry || (vCountry !== 'CHN' && vCountry !== 'IND')}"
                        class="address-field__vSelect"
                        :options="vCity && vCountry === 'CHN' ? CHINESE_COUNTIES[vCity] : INDIAN_DISTRICTS[vState]"
                        placeholder="District"
                        ref="district"
                        v-model="vDistrict"
                        :disabled="!vCity && vCountry === 'CHN'"
                    >
                    </v-select>
                    <input @input="_replaceGermanySymbols('district')"
                        :class="{'display-none': vCountry && (vCountry === 'CHN' || vCountry === 'IND')}" @change="updateValue" class="input"
                           ref="district" placeholder="District" type="text">
                </div>
            </div>
            <div v-if="postcode" :class="{'address-field__hide-border': !street && !house}"
                 class="address-field__input">
                <input
                    @input="_replaceGermanySymbols('postcode')"
                    @change="updateValue"
                    class="input"
                    ref="postcode"
                    placeholder="Postcode"
                    type="text"
                >
            </div>
            <div class="address-field__row">
                <div v-if="street" class="address-field__input address-field__hide-border">
                    <input @input="_replaceGermanySymbols('street')"
                        @change="updateValue" class="input" ref="street" placeholder="Street" type="text">
                </div>
                <div v-if="house" class="address-field__input address-field__hide-border">
                    <input
                        @input="_replaceGermanySymbols('house')"
                        @change="updateValue"
                        class="input"
                        ref="house"
                        placeholder="House Nr."
                        type="text"
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select'
import COUNTRIES from '@/dictionaries/countries'
import AddressIcon from "@/components/icons/AddressIcon.vue";
import Input from "@/components/fields/fillingPage/IntegerField.vue";
import {
    CHINESE_COUNTIES,
    CHINESE_PROVINCES_CITIES,
    CHINESE_PROVINCES,
    CHINESE_CITIES,
} from '@/dictionaries/china'

import {
    INDIAN_STATES,
    INDIAN_DISTRICTS
} from "@/dictionaries/india";


export default {
    components: {Input, AddressIcon, vSelect},
    props: {
        modelValue: {
            default: {}
        },
        validators: {
            type: Array,
            default: []
        },
        clearable: {
            type: Boolean,
            default: true
        },
        country: {
            type: Boolean,
            default: true
        },
        postcode: {
            type: Boolean,
            default: true
        },
        city: {
            type: Boolean,
            default: true
        },
        state: {
            type: Boolean,
            default: true
        },
        district: {
            type: Boolean,
            default: true
        },
        street: {
            type: Boolean,
            default: true
        },
        house: {
            type: Boolean,
            default: true
        },
        countryOnly: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            addressPlacesKeys: {
                country: 'country',
                state: 'administrative_area_level_1',
                city: 'locality',
                postcode: 'postal_code',
                street: 'route',
                house: 'street_number',
            },
            isWatchModelValue: true,
            vCountry: null,
            vCity: null,
            vDistrict: null,
            vState: null,
            CHINESE_PROVINCES_CITIES, CHINESE_COUNTIES, CHINESE_PROVINCES, CHINESE_CITIES,
            INDIAN_DISTRICTS, INDIAN_STATES,
            init: true,
            inits: {
                country: true,
                state: true,
                city: true,
                district: true
            }
        }
    },
    mounted() {
        if (this.countryOnly) {
            this.vCountry = this.countryOnly;
        } else {
            this.vCountry = this.modelValue.country
        }

        if (this.district) {
            this.addressPlacesKeys.district = 'neighborhood'
        }
        this.updateFields(this.modelValue)
        const input = this.$refs.autocompleteInput;
        const autocomplete = new google.maps.places.Autocomplete(input);
        autocomplete.addListener("place_changed", () => {
            const object = this.modelValue
            for (const key in object) {
                if (object.hasOwnProperty(key)) {
                    if (key === 'district' || key === 'city' || key === 'state') {
                        object[key] = null;
                    } else {
                        object[key] = ''
                    }
                }
            }
            this.updateFields(object)
            const selectedPlace = autocomplete.getPlace();
            if (selectedPlace.geometry && selectedPlace.formatted_address) {
                selectedPlace.address_components.reverse().forEach(item => {
                    for (const data in this.addressPlacesKeys) {
                        if (this.addressPlacesKeys[data] === item.types[0]) {
                            if (data === 'country' && this.country) {
                                this.vCountry = this._transformInIsoCode3(item.short_name, 'iso_code')
                                this.$refs.country.value = this._transformInIsoCode3(item.short_name, 'iso_code')
                            } else {
                                if (this.$refs[data]) {
                                    if (data === 'state' && this.vCountry === 'CHN') {
                                        const provinces = CHINESE_PROVINCES
                                        for (const province in provinces) {
                                            if (item.long_name.toLowerCase() === province.toLowerCase()) {
                                                this.$refs[data].value = provinces[province]
                                                this.vState = provinces[province]
                                            }
                                        }
                                    } else if (this.vCountry && (this.vCountry !== 'CHN' || (this.vCountry === 'CHN' && data !== 'city' && data !== 'district'))) {
                                        this.$refs[data].value = item.long_name
                                        this[`v${data.charAt(0).toUpperCase() + data.slice(1)}`] = item.long_name
                                    } else if (data === 'city' && this.vCountry === 'CHN') {
                                        const cities = CHINESE_PROVINCES_CITIES[this.vState]
                                        for (const city in cities) {
                                            this.$refs[data].value = cities[city]
                                            this.vCity = cities[city]
                                        }
                                    } else if (data === 'district' && this.vCountry === 'CHN') {
                                        const districts = CHINESE_COUNTIES[this.vCity]
                                        for (const district in districts) {
                                            this.$refs[data].value = districts[district]
                                            this.vCity = districts[district]
                                        }
                                    } else {
                                        this.$refs[data].value = item.long_name
                                    }
                                }
                            }
                        }
                    }
                })
                this.$emit('update:modelValue', this._transformAddress())
            }
        });

    },
    computed: {
        options() {
            const array = [];
            for (const country in COUNTRIES) {
                array.push({
                    label: COUNTRIES[country].name,
                    value: COUNTRIES[country].iso3_code,
                });
            }
            return array;
        }
    },
    methods: {
        _replaceGermanySymbols(field) {
            this.$refs[field].value = this.$refs[field].value
                .replace(/Ä/g, 'Ae')
                .replace(/Ö/g, 'Oe')
                .replace(/Ü/g, 'Ue')
                .replace(/ß/g, 'ss')
                .replace(/ä/g, 'ae')
                .replace(/ö/g, 'oe')
                .replace(/ü/g, 'ue');

            this.updateValue()
        },
        _resetVData(array = []) {
            array.forEach(item => {
                this[item] = null
            })
        },
        _transformInIsoCode3(name, type) {
            for (const country in COUNTRIES) {
                if (COUNTRIES[country][type] === name) {
                    return COUNTRIES[country].iso3_code
                }
            }
        },
        _transformInName(name, type) {
            return Object.values(COUNTRIES).find(c => c[type] === name.toUpperCase()).name
        },
        _transformAddress() {
            const updateArray = {}
            for (const ref in this.$refs) {
                if (this.$refs[ref] && ref !== 'autocompleteInput' && this.$refs[ref].value !== '') {
                    updateArray[ref] = this.$refs[ref].value
                }
            }
            if (this.vState && this.vCountry === 'CHN') {
                updateArray.state = this.vState
            }
            if (this.vCity && this.vCountry === 'CHN') {
                updateArray.city = this.vCity
            }
            if (this.vDistrict && this.vCountry === 'CHN') {
                updateArray.district = this.vDistrict
            }
            return updateArray
        },
        updateValue() {
            this.$emit('update:modelValue', this._transformAddress())
        },
        updateFields(object) {
            for (const key in object) {
                if (object.hasOwnProperty(key) && this.$refs[key]) {
                    this.$refs[key].value = object[key];
                    const vData = `v${key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()}`
                    if (this.$data.hasOwnProperty(vData)) {
                        this[vData] = object[key]
                    }
                } else if (key === 'country') {
                    if (object[key].length === 3) {
                        this.vCountry = {
                            label: this._transformInName(object[key], 'iso3_code'),
                            value: object[key],
                        };
                    } else {
                        console.error('[ERROR]: The country value contains a non-iso_code3');
                        this.vCountry = null
                    }
                }
            }
        }
    }
    ,
    watch: {
        'vCountry'(to) {
            this.$refs.country.value = to
            if (to === null) {
                this._resetVData(['vCity', 'vDistrict', 'vState'])
                for (const ref in this.$refs) {
                    if (this.$refs[ref]) {
                        this.$refs[ref].value = ''
                    }
                }
            }
            if (!this.inits.country) this.updateValue()
            this.inits.country = false
        }
        ,
        'vCity'(to) {
            this.$refs.city.value = to
            if (to === null) {
                this._resetVData(['vCity', 'vDistrict'])
                if (this.$refs.city) delete this.$refs.city.value
                if (this.$refs.district) delete this.$refs.district.value
            }
            if (!this.inits.country) this.updateValue()
        }
        ,
        'vState'(to) {
            this.$refs.state.value = to
            if (to === null) {
                this._resetVData(['vCity', 'vDistrict', 'vState'])
                if (this.$refs.district) delete this.$refs.district.value
                if (this.$refs.city) delete this.$refs.city.value
                if (this.$refs.state) delete this.$refs.state.value
            }
            if (!this.inits.country) this.updateValue()
        }
        ,
        'vDistrict'(to) {
            this.$refs.district.value = to
            if (!to) {
                this._resetVData(['vDistrict'])
                if (this.$refs.district) delete this.$refs.district.value
            }
            if (!this.inits.country) this.updateValue()
        }
    }
}
</script>

<style lang="sass">
.display-none
    display: none

.address-field
    border: 1px solid var(--border-form-input)
    border-radius: var(--border-radius-input)
    background: var(--background-input)
    width: 100%

    &:hover
        transition: 0.2s
        border: 1px solid var(--description-text)

    &__hide-border
        border-bottom: none !important

    &__enter-field
        position: relative
        border-radius: var(--border-radius-input) var(--border-radius-input) 0 0
        overflow: hidden

        input
            padding-left: 36px !important

        svg
            position: absolute
            top: 50%
            left: 12px
            transform: translateY(-50%)

    &__row
        display: flex

        & > .address-field__input + .address-field__input
            border-left: 1px solid var(--border-form-input)

    &__input
        border-bottom: 1px solid var(--border-form-input)
        width: 100%

    .input
        width: 100%
        height: var(--input-height)
        background: none
        border: 1px solid
        border-color: var(--border-form-input)
        padding-left: 12px
        padding-right: 8px
        outline: none
        font-size: 16px
        color: inherit
        transition: 0.2s

    &__vSelect
        height: 40px

        & .vs
            &__dropdown-toggle
                height: 100% !important
                padding: 0
                margin: 0
                border: none

            &__selected-options
                padding-left: 6px !important

                input::placeholder
                    color: var(--description-text)

            &__dropdown-menu
                background: var(--background-input)
                border: 1px solid var(--description-text-darken)
                border-top: none

    &-valid
        background: var(--primary-lighten) !important
        border-color: var(--primary) !important

        & .address-field__vSelect
            & .vs
                &__dropdown-menu
                    background: var(--primary-lighten) !important
                    border: 1px solid var(--primary) !important
                    border-top: none

                &__actions
                    svg
                        path
                            fill: var(--primary) !important

        & .address-field__row
            & > .address-field__input + .address-field__input
                border-left: 1px solid var(--primary) !important

        & .address-field__input
            border-bottom: 1px solid var(--primary) !important

    &-invalid
        background: var(--error) !important
        border-color: var(--error_red) !important
        border-radius: 3px 3px 0 0

        & .address-field__vSelect
            & .vs
                &__dropdown-menu
                    background: var(--error) !important
                    border: 1px solid var(--error_red) !important
                    border-top: none

                &__actions
                    svg
                        path
                            fill: var(--error_red) !important

        & .address-field__row
            & > .address-field__input + .address-field__input
                border-left: 1px solid var(--error_red) !important

        & .address-field__input
            border-bottom: 1px solid var(--error_red) !important
</style>