<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            style="stroke: none"
            d="M1.85714 10.4815H4.42857V11.6667C4.42857 11.994 4.68441 12.2593 5 12.2593H6.14286C6.45845 12.2593 6.71429 11.994 6.71429 11.6667V10.4815H11.2857V11.6667C11.2857 11.994 11.5416 12.2593 11.8571 12.2593H13C13.3156 12.2593 13.5714 11.994 13.5714 11.6667V10.4815H16.4286V15.8148C16.4286 16.4694 15.9169 17 15.2857 17H3C2.36882 17 1.85714 16.4694 1.85714 15.8148V10.4815Z"
            fill="#000000"/>
        <path
            style="stroke: none"
            d="M1 2.77779V8.11112C1 8.76568 1.51167 9.2963 2.14286 9.2963H4.42857V8.70371C4.42857 8.37643 4.68441 8.11112 5 8.11112H6.14286C6.45845 8.11112 6.71429 8.37643 6.71429 8.70371V9.2963H11.2857V8.70371C11.2857 8.37643 11.5416 8.11112 11.8571 8.11112H13C13.3156 8.11112 13.5714 8.37643 13.5714 8.70371V9.2963H15.8571C16.4883 9.2963 17 8.76568 17 8.11112V2.77779H11.8488H11.5714H6.42857H6.19931H1Z"
            fill="#000000"/>
        <path
            style="fill: none"
            d="M6.42857 2.77779H11.5714M6.42857 2.77779V1.29607C6.42857 1.13243 6.55649 1.00001 6.71429 1.00001H11.2857C11.4435 1.00001 11.5714 1.13266 11.5714 1.2963V2.77779M6.42857 2.77779H6.19931M11.5714 2.77779H11.8488M5.57143 9.2963V11.0741M12.4286 9.2963V11.0741M11.8488 2.77779H17V8.11112C17 8.76568 16.4883 9.2963 15.8571 9.2963H13.5714V8.70371C13.5714 8.37643 13.3156 8.11112 13 8.11112H11.8571C11.5416 8.11112 11.2857 8.37643 11.2857 8.70371V9.2963H6.71429V8.70371C6.71429 8.37643 6.45845 8.11112 6.14286 8.11112H5C4.68441 8.11112 4.42857 8.37643 4.42857 8.70371V9.2963H2.14286C1.51167 9.2963 1 8.76568 1 8.11112V2.77779H6.19931M11.8488 2.77779V1.72021C11.8488 1.16793 11.4011 0.720215 10.8488 0.720215H7.1993C6.64702 0.720215 6.19931 1.16793 6.19931 1.72021V2.77779M1.85714 10.4815H4.42857V11.6667C4.42857 11.994 4.68441 12.2593 5 12.2593H6.14286C6.45845 12.2593 6.71429 11.994 6.71429 11.6667V10.4815H11.2857V11.6667C11.2857 11.994 11.5416 12.2593 11.8571 12.2593H13C13.3156 12.2593 13.5714 11.994 13.5714 11.6667V10.4815H16.4286V15.8148C16.4286 16.4694 15.9169 17 15.2857 17H3C2.36882 17 1.85714 16.4694 1.85714 15.8148V10.4815Z"
            stroke="#000000" stroke-width="0.5"/>
    </svg>
</template>

<script>
export default {
    name: "Suitcase"
}
</script>
