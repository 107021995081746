<template>
    <svg class="search-input-icon" :width="width" :height="height" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11 11L8.58333 8.58333M9.88889 5.44444C9.88889 7.89904 7.89904 9.88889 5.44444 9.88889C2.98985 9.88889 1 7.89904 1 5.44444C1 2.98985 2.98985 1 5.44444 1C7.89904 1 9.88889 2.98985 9.88889 5.44444Z"
            :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: "SearchInputIcon",
    props: {
        stroke: {
            default: '#000000'
        },
        width: {
            default: 12
        },
        height: {
            default: 12
        }
    }
}
</script>

<style scoped lang="sass">

</style>