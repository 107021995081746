<template>
    <div class="country-select__wrap">
        <div class="country-select" :tabindex="tabindex" @blur="handleBlur">
            <div class="select" :class="{open}">
                <div @click="open = !open" class="select__inner">
                    <img
                        :src="require(`@/assets/images/form-select_page/${selected ? selected.toLowerCase() : 'form-select'}.svg`)"
                        alt="">
                    {{ selected || placeholder }}
                </div>
            </div>
            <div class="dropdown items" :class="{ selectHide: !open }">
                <!--            <input @input.prevent="handleSearch" class="search" placeholder="Search" type="text">-->
                <div v-if="options.length" class="dropdown-list">
                    <div
                        class="dropdown_item"
                        v-for="(option, i) in options"
                        :key="i"
                        @click.prevent="selectOption(option.value)"
                    >
                        <img :src="require(`@/assets/images/form-select_page/${option.value.toLowerCase()}.svg`)" alt="">
                        {{ option.label }}
                    </div>
                </div>
                <div v-else class="dropdown_item">Field not found</div>
            </div>
        </div>
        <button class="country-select__button-start --btn-next" @click.prevent="$emit('JumpToQuestionnaire')"
                :class="{'show-button': selected && !open}">
            Start order
        </button>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: String,
            required: false
        },
        options: {
            type: Array,
            required: true,
        },
        tabindex: {
            type: Number,
            required: false,
            default: 0,
        },
        placeholder: {
            type: String,
            required: false,
            default: "Select country of destination",
        },
    },
    data() {
        return {
            open: false,
            selected: this.modelValue || ""
        };
    },
    mounted() {
        this.selected = this.modelValue;
    },
    watch: {
        value(newValue) {
            this.selected = newValue;
        },
    },
    methods: {
        handleSearch(event) {
            const searchValue = event.target.value;
            this.$emit('search', searchValue);
        },
        handleBlur(event) {
            let isSearchInput = event.relatedTarget && event.relatedTarget.classList.contains('search');
            if (!isSearchInput) {
                this.open = false;
                isSearchInput = null;
            }
        },
        selectOption(option) {
            this.selected = option;
            this.open = false;
            this.$emit('update:modelValue', option);
        }
    }
};
</script>

<style scoped lang="sass">

.selectHide
    opacity: 0 !important
    z-index: -1 !important

.show-button
    display: block !important

.open
    border-radius: 4px 4px 0 0 !important


.country-select
    height: 60px
    position: relative
    &__button-start
        display: none
        margin-top: 32px

    &__wrap
        max-width: 360px
        width: 100%


    .select
        display: flex
        align-items: center
        width: 100%
        height: 100%
        color: #5A5A5A
        font-size: 16px
        border-radius: 4px
        border: 3px solid var(--primary-middle)
        outline: none
        cursor: pointer
        transition: 0.2s
        position: relative

        &__inner
            width: 100%
            height: 100%
            background: var(--white)
            border-radius: 4px
            transition: 0.2s
            display: flex
            align-items: center
            padding-left: 16px
            font-size: 18px
            font-weight: 400
            position: relative

            img
                width: 24px
                height: 24px
                margin-right: 18px

    .dropdown
        width: 100%
        display: flex
        flex-direction: column
        border-radius: 0 0 4px 4px
        position: absolute
        top: 100%
        left: 0
        margin: 0 !important
        background: #FCFCFD
        z-index: 10
        overflow: hidden
        opacity: 1
        transition: 0.2s
        padding: 12px 0
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25)

        &-list
            max-height: 176px
            height: auto
            overflow-y: auto
            margin-top: 16px

        .search
            background: white
            border: 1px solid var(--description-text-darken) !important
            outline: none
            border-radius: 40px
            padding-left: 12px
            height: 30px
            transition: 0.2s

            &:focus
                border-color: var(--primary) !important

        &_item
            margin: 0 !important
            padding: 12px 20px
            cursor: pointer
            transition: 0.2s
            font-weight: 400
            display: flex
            align-items: center

            img
                width: 24px
                height: 24px
                margin-right: 18px

            &:hover
                background: var(--primary-lighten)
</style>




