<template>
    <div class="policies__content form shadow">
        <span class="bold">1. Betreiber, Anwendungsbereich</span>
        <br><br>
        1.1. Betreiber ist die Visum.IO UG (haftungsbeschränkt), Christian-Pleß-Straße 14, 63069 Offenbach
        am
        Main (nachstehend „Visum.IO UG“ oder „wir“ bzw. „uns“).
        <br>
        1.2. Gegenstand dieser AGB ist die Erbringung von Visums-bezogenen Dienstleistungen durch die
        Visum.IO
        UG, insbesondere über die unter visum.io bereitgestellte Plattform zur automatisierten Verarbeitung
        der
        Daten von Visums-Antragsstellern (nachstehend als „Plattform“ bezeichnet).
        <br><br>
        <span class="bold">2. Vertragsschluss, Speicherung der Vertragsbestimmungen und Vertragssprache</span>
        <br><br>
        2.1. Sofern der Vertragsschluss nicht im Wege individueller Kommunikation, also in persönlicher
        Anwesenheit, per E-Mail, Fax, Post, Telefon o.ä., sondern online auf unserer Website erfolgt, kommt
        der
        Vertrag wie folgt zustande:
        <br>
        2.1.1. Erst die Bestellung der Dienstleistung durch Sie ist ein bindendes Angebot zum Abschluss
        eines
        entsprechenden Vertrages. Um die Bestellung vorzunehmen, durchlaufen Sie den Bestellprozess auf der
        Website und tragen die dort abgefragten Angaben ein. Vor Absendung der Bestellung haben Sie die
        Möglichkeit, sämtliche Bestelldaten noch einmal zu überprüfen und ggf. zu korrigieren. Erst mit der
        Absendung der Bestellung geben Sie an uns ein verbindliches Angebot zum Abschluss eines Vertrages
        ab.
        <br>
        2.1.2. Wir können Ihr Angebot innerhalb von fünf Tagen durch - Zusendung einer Auftragsbestätigung
        per
        Post, Fax oder E-Mail, oder - Aufforderung zur Zahlung annehmen; maßgeblich für die Einhaltung der
        Frist
        ist jeweils der Zeitpunkt des Zugangs unserer Auftragsbestätigung oder Zahlungsaufforderung bei
        Ihnen.
        <br>
        2.1.3. Wir speichern die Vertragsbestimmungen, also die Bestelldaten / Registrierungsdaten und die
        vorliegenden AGB. Sie können die Vertragsbestimmungen Ihrerseits ausdrucken oder speichern, indem
        Sie
        jeweils die übliche Funktionalität Ihres Browsers nutzen (dort meist „Drucken“ bzw. "Datei" >
        "Speichern
        unter"). Die Bestelldaten/Registrierungsdaten sind in der Übersicht enthalten, die im letzten
        Schritt
        der Bestellung/Registrierung angezeigt wird.
        <br>
        2.1.4. Vertragssprache ist Deutsch.
        <br><br>
        <span class="bold">3. Nutzerkonto</span>
        <br><br>
        3.1. Bei der Registrierung des Nutzerkontos sind richtige und vollständige Angaben zu machen. Daten
        Dritter dürfen ohne deren Einwilligung nicht verwendet werden.
        <br>
        3.2. Sie sind verpflichtet, Ihre Zugangsdaten wie bspw. Ihr Passwort vertraulich zu behandeln,
        Dritten
        nicht zugänglich zu machen, und uns im Falle des Verlusts oder einer unbefugten Nutzung Ihrer
        Zugangsdaten unverzüglich zu unterrichten.
        <br><br>
        <span class="bold">4. Leistungen</span>
        <br><br>
        4.1. Beschreibung der wesentlichen Leistungen: Unsere wesentlichen Leistungen sind: <br>
        4.1.1. Automatisierte Ausfüllung von Visumsanträgen, ggf. auch für E-Visen, in elektronischer Form
        (insbesondere automatisierte Ausfüllung auf Websites von Behörden der jeweiligen Zielländer zur
        elektronischen Ausfüllung bereitgestellter Antragsformulare) unter Verwendung zuvor vom Nutzer auf
        der
        Plattform eingegebener Daten und anschließende Übermittlung der vorausgefüllten Unterlagen in
        Dateiform,
        bspw. PDF, an den Kunden, damit der Kunde diese Unterlagen ausdrucken, unterzeichnen und bei den
        zuständigen Behörden einreichen kann.
        <br>
        4.1.2. Bei entsprechender Beauftragung übernehmen wir auch die Einreichung des unterschriebenen
        Visumsantrages bei den Behörden und/oder die Abholung und Weiterleitung des ausgestellten Visums an
        den
        Kunden.
        <br>
        4.2. Nur Beantragung geschuldet, kein Erfolg: Leistungen der Visum.IO UG im Zusammenhang mit der
        Beantragung von Visen bestehen immer nur darin, Ihnen die Beantragung des Visums, insbesondere durch
        Automatisierung, und ggf. die Einreichung und Abholung von Visen zu erleichtern. Nicht geschuldet
        ist
        die tatsächliche Ausstellung des Visums. Auch auf die Bearbeitungsdauer hat die Visum.IO UG keinen
        Einfluss.
        <br><br>
        <span class="bold">5. Bereitstellung von Daten durch den Nutzer</span>
        <br><br>
        5.1. Zur Beantragung eines Visums-Antrags auf der Plattform gespeicherte Daten des Nutzers werden
        dort
        für spätere Visumsanträge gespeichert, um die künftige Beantragung von Visen für den Nutzer zu
        vereinfachen. Da sich die länderspezifischen Anforderungen an die Ausstellung von Visen von Zeit zu
        Zeit
        ändern und für unterschiedliche Länder unterschiedliche Angaben erforderlich sind, werden Sie von
        uns im
        Zusammenhang mit weiteren Visumsanträgen ggf. dazu aufgefordert, die jeweils erforderlichen Daten zu
        ergänzen.
        <br><br>
        <span class="bold">6. Sonstige Mitwirkungspflichten und -obliegenheiten des Nutzers</span>
        <br><br>
        6.1. Sie sind dazu verpflichtet, alle erforderlichen Angaben wahrheitsgemäß und vollständig zu
        machen.
        <br>
        6.2. Sollten es zu Änderungen Ihrer auf der Plattform eingepflegten oder in sonstiger Weise an uns
        mitgeteilten Daten kommen, sind Sie dazu verpflichtet, uns rechtzeitig zu informieren.
        <br>
        6.3. Je nach Art der beauftragten Dienstleistungen, bspw. für die Abholung von Visen, kann es
        erforderlich sein, uns bei den Behörden vorzulegende Dokumente (bspw. Personalausweis) und/oder
        Kopien
        davon zu überlassen und/oder eine Vollmacht zu erteilen.
        <br><br>
        <span class="bold">7. Vergütung, Abrechnung</span>
        <br><br>
        7.1. Der Kunde zahlt für die von ihm gewählten Leistungen die Entgelte gemäß unserer bei
        Vertragsschluss
        gültigen Preisliste oder sonstigen Preisaufstellung.
        <br>
        7.2. Die Abrechnung erfolgt per E-Mail.
        <br><br>
        <span class="bold">8. Haftungsausschlüsse und -beschränkungen</span>
        <br><br>
        Für eine Haftung von uns auf Schadensersatz gilt:
        <br>
        8.1. Bei Vorsatz und grober Fahrlässigkeit, auch unserer Erfüllungsgehilfen, haften wir nach den
        gesetzlichen Bestimmungen. Das gleiche gilt bei fahrlässig verursachten Schäden aus der Verletzung
        des
        Lebens, des Körpers oder der Gesundheit.
        <br>
        8.2. Bei fahrlässig verursachten Sach- und Vermögensschäden haften wir nur bei der Verletzung einer
        wesentlichen Vertragspflicht, jedoch der Höhe nach beschränkt auf die bei Vertragsschluss
        vorhersehbaren
        und vertragstypischen Schäden; wesentliche Vertragspflichten sind solche, deren Erfüllung die
        ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht und auf deren Einhaltung der
        Vertragspartner regelmäßig vertrauen darf.
        <br>
        8.3. Im Übrigen ist eine Haftung von uns, unabhängig von deren Rechtsgrund, ausgeschlossen.
        <br>
        8.4. Die Haftungsausschlüsse und -beschränkungen der vorstehenden Absätze (1) bis (3) gelten
        sinngemäß
        auch zugunsten unserer Erfüllungsgehilfen.
        <br>
        8.5. Eine Haftung wegen Übernahme einer Garantie oder nach dem Produkthaftungsgesetz bleibt von den
        Haftungsausschlüssen und -beschränkungen der vorstehenden Absätze (1) bis (4) unberührt.
        <br><br>
        <span class="bold">9. Anwendbares Recht, Gerichtsstand</span>
        <br><br>
        9.1. Es gilt deutsches Recht unter Ausschluss der Bestimmungen des UN-Kaufrechts. Gegenüber einem
        Verbraucher gilt diese Rechtswahl nur insoweit, als dadurch keine zwingenden gesetzlichen
        Bestimmungen
        des Staates, in dem er seinen Wohnsitz oder gewöhnlichen Aufenthalt hat, eingeschränkt werden.
        <br>
        9.2. Gerichtsstand im Verkehr mit Kaufleuten, juristischen Personen des öffentlichen Rechts oder
        öffentlich-rechtlichen Sondervermögen ist der Sitz unseres Unternehmens. Wir sind jedoch nach
        unserer
        Wahl berechtigt, am Sitz des Kunden zu klagen.
    </div>
</template>

<script>
import Base from "@/layouts/base.vue";

export default {
    components: {Base},
}
</script>

<style lang="sass">

</style>