import {createStore} from 'vuex'

export default createStore({
    state: {
        showInPartnerDesign: false,
        user: null,
    },
    mutations: {
        SHOW_IN_PARTNER_DESIGN(state, value) { state.showInPartnerDesign = value },
        USER(state, value) { state.user = value },
    },
    actions: {},
    modules: {}
})
