<template>
    <svg :width="width" :height="height" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 10.1988H3.44881e-06C-0.000902865 9.43897 0.176829 8.68761 0.521524 7.99403C0.866219 7.30045 1.37008 6.68034 2 6.17444V3.59956C2 2.6449 2.42143 1.72933 3.17157 1.05429C3.92172 0.379238 4.93913 0 6 0C7.06086 0 8.07828 0.379238 8.82843 1.05429C9.57857 1.72933 10 2.6449 10 3.59956V6.17444C10.6299 6.68034 11.1338 7.30045 11.4785 7.99403C11.8232 8.68761 12.0009 9.43897 12 10.1988ZM4.11333 10.7987H7.88666C7.74893 11.15 7.49351 11.4541 7.1556 11.6693C6.81769 11.8845 6.41394 12 6 12C5.58606 12 5.18231 11.8845 4.8444 11.6693C4.50649 11.4541 4.25107 11.15 4.11333 10.7987Z"
            :fill="fill"/>
    </svg>
</template>

<script>
export default {
    name: "NotificationIcon",
    props: {
        fill: {
            default: '#000000'
        },
        width: {
            default: 12
        },
        height: {
            default: 12
        }
    }
}
</script>