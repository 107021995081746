<template>
    <div class="checkbox-custom">
        <div class="checkbox-custom__input">
            <input
                type="checkbox"
                :id="name"
                class="checkbox"
                :checked="modelValue === true"
                :disabled="disabled"
                @change="$emit('update:modelValue', modelValue = !modelValue)"
            >
            <label :for="name"></label>
        </div>
        <label v-if="label" class="label checkbox-custom__label">{{ label }}</label>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true,
            default: ''
        },
        modelValue: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: String
    }
}
</script>

<style lang="sass">
.checkbox-custom
    display: flex
    justify-content: center
    align-items: center
    &__label
        margin-left: 16px
    &__input
        height: 24px
        & input[type=checkbox]:checked,
        & input[type=checkbox]:not(:checked)
            position: absolute
            left: -99999px
            & + label
                display: block
                position: relative
                line-height: 20px
                cursor: pointer
                width: 24px
                height: 24px
                &:before
                    content: ""
                    position: absolute
                    left: 0
                    top: 0
                    width: 24px
                    height: 24px
                    border-radius: var(--border-radius-input)
                &:after
                    content: ""
                    position: absolute
                    -webkit-transition: all 0.2s ease
                    -moz-transition: all 0.2s ease
                    -o-transition: all 0.2s ease
                    transition: all 0.2s ease
                    left: 5px
                    top: 5px
                    width: 15px
                    height: 9px
                    border-left: 3px solid var(--white)
                    border-bottom: 3px solid var(--white)
                    -webkit-transform: rotate(-50deg)
                    -moz-transform: rotate(-50deg)
                    -o-transform: rotate(-50deg)
                    -ms-transform: rotate(-50deg)
                    transform: rotate(-50deg)

        & input[type=checkbox]:checked
            & + label
                &:before
                    border: 2px var(--primary) solid
                    background: var(--primary)

                &:after
                    opacity: 1

            & + .checkbox-name
                color: var(--primary)
                font-weight: 600

            & + .--color-font
                color: var(--main_black) !important
                font-weight: 400 !important

        & input[type=checkbox]:checked:disabled
            & + label
                &:before
                    border: 2px gray solid
                    background: gray

        & input[type=checkbox]:not(:checked)
            & + label
                &:before
                    border: 2px var(--description-text) solid
                    background: none

                &:after
                    opacity: 0
</style>