<template>
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V13C0 13.2652 0.105357 13.5196 0.292893 13.7071C0.48043 13.8946 0.734784 14 1 14H15C15.2652 14 15.5196 13.8946 15.7071 13.7071C15.8946 13.5196 16 13.2652 16 13V1C16 0.734784 15.8946 0.48043 15.7071 0.292893C15.5196 0.105357 15.2652 0 15 0ZM3.46 2C3.75667 2 4.04668 2.08797 4.29336 2.2528C4.54003 2.41762 4.73229 2.65189 4.84582 2.92597C4.95935 3.20006 4.98906 3.50166 4.93118 3.79264C4.8733 4.08361 4.73044 4.35088 4.52066 4.56066C4.31088 4.77044 4.04361 4.9133 3.75264 4.97118C3.46166 5.02906 3.16006 4.99935 2.88597 4.88582C2.61189 4.77229 2.37762 4.58003 2.2128 4.33336C2.04797 4.08668 1.96 3.79667 1.96 3.5C1.96 3.10218 2.11804 2.72064 2.39934 2.43934C2.68064 2.15804 3.06218 2 3.46 2ZM2 11.5V9.45L5 6.41C5.09368 6.31687 5.22041 6.2646 5.3525 6.2646C5.48459 6.2646 5.61132 6.31687 5.705 6.41L7 7.675L3.16 11.5H2ZM14 11.5H4.575L10.39 5.685C10.4837 5.59187 10.6104 5.5396 10.7425 5.5396C10.8746 5.5396 11.0013 5.59187 11.095 5.685L14 8.59V11.5Z"
            fill="#000000"/>
    </svg>
</template>

<script>
export default {
    name: "Picture"
}
</script>

<style lang="sass" scoped>
path
    stroke: none !important
</style>
