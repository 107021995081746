<template>
    <Base>
        <div class="application">
            <div class="form card shadow pa-2">
                <h3 class="form__title">
                    Success
                </h3>
                <div class="form__line"></div>
                <div class="form__card-body">
                    Your data has been processed. An agency employee will contact you.
                </div>
            </div>
        </div>
    </Base>
</template>

<script>
import Base from "@/layouts/base.vue";

export default {
    name: "form_success",
    components: {Base}
}
</script>

<style lang="sass">

</style>