const COUNTRIES = {
    "AIA": {
        "iso_code": "AI",
        "iso3_code": "AIA",
        "name": "Anguilla"
    },
    "ALB": {
        "iso_code": "AL",
        "iso3_code": "ALB",
        "name": "Albania"
    },
    "ARG": {
        "iso_code": "AR",
        "iso3_code": "ARG",
        "name": "Argentina"
    },
    "ARM": {
        "iso_code": "AM",
        "iso3_code": "ARM",
        "name": "Armenia"
    },
    "ATG": {
        "iso_code": "AG",
        "iso3_code": "ATG",
        "name": "Antigua and Barbuda"
    },
    "AUS": {
        "iso_code": "AU",
        "iso3_code": "AUS",
        "name": "Australia"
    },
    "AZE": {
        "iso_code": "AZ",
        "iso3_code": "AZE",
        "name": "Azerbaijan"
    },
    "BEL": {
        "iso_code": "BE",
        "iso3_code": "BEL",
        "name": "Belgium"
    },
    "BEN": {
        "iso_code": "BJ",
        "iso3_code": "BEN",
        "name": "Benin"
    },
    "BGD": {
        "iso_code": "BD",
        "iso3_code": "BGD",
        "name": "Bangladesh"
    },
    "BHR": {
        "iso_code": "BH",
        "iso3_code": "BHR",
        "name": "Bahrain"
    },
    "BLR": {
        "iso_code": "BY",
        "iso3_code": "BLR",
        "name": "Belarus"
    },
    "BLZ": {
        "iso_code": "BZ",
        "iso3_code": "BLZ",
        "name": "Belize"
    },
    "BMU": {
        "iso_code": "BM",
        "iso3_code": "BMU",
        "name": "Bermuda"
    },
    "BRB": {
        "iso_code": "BB",
        "iso3_code": "BRB",
        "name": "Barbados"
    },
    "BTN": {
        "iso_code": "BT",
        "iso3_code": "BTN",
        "name": "Bhutan"
    },
    "BWA": {
        "iso_code": "BW",
        "iso3_code": "BWA",
        "name": "Botswana"
    },
    "CAN": {
        "iso_code": "CA",
        "iso3_code": "CAN",
        "name": "Canada"
    },
    "CHL": {
        "iso_code": "CL",
        "iso3_code": "CHL",
        "name": "Chile"
    },
    "CHN": {
        "iso_code": "CN",
        "iso3_code": "CHN",
        "name": "China"
    },
    "CMR": {
        "iso_code": "CM",
        "iso3_code": "CMR",
        "name": "Cameroon"
    },
    "COK": {
        "iso_code": "CK",
        "iso3_code": "COK",
        "name": "Cook Islands"
    },
    "COL": {
        "iso_code": "CO",
        "iso3_code": "COL",
        "name": "Colombia"
    },
    "CUB": {
        "iso_code": "CU",
        "iso3_code": "CUB",
        "name": "Cuba"
    },
    "CXR": {
        "iso_code": "CX",
        "iso3_code": "CXR",
        "name": "Christmas Island"
    },
    "CYP": {
        "iso_code": "CY",
        "iso3_code": "CYP",
        "name": "Cyprus"
    },
    "DEU": {
        "iso_code": "DE",
        "iso3_code": "DEU",
        "name": "Germany"
    },
    "DNK": {
        "iso_code": "DK",
        "iso3_code": "DNK",
        "name": "Denmark"
    },
    "DZA": {
        "iso_code": "DZ",
        "iso3_code": "DZA",
        "name": "Algeria"
    },
    "ECU": {
        "iso_code": "EC",
        "iso3_code": "ECU",
        "name": "Ecuador"
    },
    "EGY": {
        "iso_code": "EG",
        "iso3_code": "EGY",
        "name": "Egypt"
    },
    "EST": {
        "iso_code": "EE",
        "iso3_code": "EST",
        "name": "Estonia"
    },
    "ETH": {
        "iso_code": "ET",
        "iso3_code": "ETH",
        "name": "Ethiopia"
    },
    "FJI": {
        "iso_code": "FJ",
        "iso3_code": "FJI",
        "name": "Fiji"
    },
    "FRA": {
        "iso_code": "FR",
        "iso3_code": "FRA",
        "name": "France"
    },
    "GAB": {
        "iso_code": "GA",
        "iso3_code": "GAB",
        "name": "Gabon"
    },
    "GEO": {
        "iso_code": "GE",
        "iso3_code": "GEO",
        "name": "Georgia"
    },
    // "GGY": {
    //     "iso_code": "GG",
    //     "iso3_code": "GGY",
    //     "name": "Guernsey"
    // },
    "GIB": {
        "iso_code": "GI",
        "iso3_code": "GIB",
        "name": "Gibraltar"
    },
    "GIN": {
        "iso_code": "GN",
        "iso3_code": "GIN",
        "name": "Guinea"
    },
    "GRC": {
        "iso_code": "GR",
        "iso3_code": "GRC",
        "name": "Greece"
    },
    "GRD": {
        "iso_code": "GD",
        "iso3_code": "GRD",
        "name": "Grenada"
    },
    "GRL": {
        "iso_code": "GL",
        "iso3_code": "GRL",
        "name": "Greenland"
    },
    // "GUM": {
    //     "iso_code": "GU",
    //     "iso3_code": "GUM",
    //     "name": "Guam"
    // },
    "HKG": {
        "iso_code": "HK",
        "iso3_code": "HKG",
        "name": "Hong Kong"
    },
    "HND": {
        "iso_code": "HN",
        "iso3_code": "HND",
        "name": "Honduras"
    },
    "HRV": {
        "iso_code": "HR",
        "iso3_code": "HRV",
        "name": "Croatia"
    },
    "HTI": {
        "iso_code": "HT",
        "iso3_code": "HTI",
        "name": "Haiti"
    },
    // "IMN": {
    //     "iso_code": "IM",
    //     "iso3_code": "IMN",
    //     "name": "Isle of Man"
    // },
    "IND": {
        "iso_code": "IN",
        "iso3_code": "IND",
        "name": "India"
    },
    "IRL": {
        "iso_code": "IE",
        "iso3_code": "IRL",
        "name": "Ireland"
    },
    "IRQ": {
        "iso_code": "IQ",
        "iso3_code": "IRQ",
        "name": "Iraq"
    },
    "ITA": {
        "iso_code": "IT",
        "iso3_code": "ITA",
        "name": "Italy"
    },
    // "JEY": {
    //     "iso_code": "JE",
    //     "iso3_code": "JEY",
    //     "name": "Jersey"
    // },
    "JOR": {
        "iso_code": "JO",
        "iso3_code": "JOR",
        "name": "Jordan"
    },
    "KEN": {
        "iso_code": "KE",
        "iso3_code": "KEN",
        "name": "Kenya"
    },
    "KHM": {
        "iso_code": "KH",
        "iso3_code": "KHM",
        "name": "Cambodia"
    },
    "KIR": {
        "iso_code": "KI",
        "iso3_code": "KIR",
        "name": "Kiribati"
    },
    "KWT": {
        "iso_code": "KW",
        "iso3_code": "KWT",
        "name": "Kuwait"
    },
    "LBR": {
        "iso_code": "LR",
        "iso3_code": "LBR",
        "name": "Liberia"
    },
    "LCA": {
        "iso_code": "LC",
        "iso3_code": "LCA",
        "name": "Saint Lucia"
    },
    "LSO": {
        "iso_code": "LS",
        "iso3_code": "LSO",
        "name": "Lesotho"
    },
    "LTU": {
        "iso_code": "LT",
        "iso3_code": "LTU",
        "name": "Lithuania"
    },
    "LVA": {
        "iso_code": "LV",
        "iso3_code": "LVA",
        "name": "Latvia"
    },
    "MAC": {
        "iso_code": "MO",
        "iso3_code": "MAC",
        "name": "Macao"
    },
    "MCO": {
        "iso_code": "MC",
        "iso3_code": "MCO",
        "name": "Monaco"
    },
    "MDG": {
        "iso_code": "MG",
        "iso3_code": "MDG",
        "name": "Madagascar"
    },
    "MEX": {
        "iso_code": "MX",
        "iso3_code": "MEX",
        "name": "Mexico"
    },
    "MLI": {
        "iso_code": "ML",
        "iso3_code": "MLI",
        "name": "Mali"
    },
    "MMR": {
        "iso_code": "MM",
        "iso3_code": "MMR",
        "name": "Myanmar"
    },
    "MOZ": {
        "iso_code": "MZ",
        "iso3_code": "MOZ",
        "name": "Mozambique"
    },
    "MRT": {
        "iso_code": "MR",
        "iso3_code": "MRT",
        "name": "Mauritania"
    },
    "MSR": {
        "iso_code": "MS",
        "iso3_code": "MSR",
        "name": "Montserrat"
    },
    "MWI": {
        "iso_code": "MW",
        "iso3_code": "MWI",
        "name": "Malawi"
    },
    "MYS": {
        "iso_code": "MY",
        "iso3_code": "MYS",
        "name": "Malaysia"
    },
    "MYT": {
        "iso_code": "YT",
        "iso3_code": "MYT",
        "name": "Mayotte"
    },
    "NFK": {
        "iso_code": "NF",
        "iso3_code": "NFK",
        "name": "Norfolk Island"
    },
    "NGA": {
        "iso_code": "NG",
        "iso3_code": "NGA",
        "name": "Nigeria"
    },
    "CPV": {
        "iso_code": "CV",
        "iso3_code": "CPV",
        "name": "Cabo Verde"
    },
    "GMB": {
        "iso_code": "GM",
        "iso3_code": "GMB",
        "name": "Gambia"
    },
    "CZE": {
        "iso_code": "CZ",
        "iso3_code": "CZE",
        "name": "Czechia"
    },
    // "COG": {
    //     "iso_code": "CG",
    //     "iso3_code": "COG",
    //     "name": "Congo"
    // },
    "ISR": {
        "iso_code": "IL",
        "iso3_code": "ISR",
        "name": "Israel"
    },
    "ABW": {
        "iso_code": "AW",
        "iso3_code": "ABW",
        "name": "Aruba"
    },
    "AGO": {
        "iso_code": "AO",
        "iso3_code": "AGO",
        "name": "Angola"
    },
    "ARE": {
        "iso_code": "AE",
        "iso3_code": "ARE",
        "name": "United Arab Emirates"
    },
    "ATF": {
        "iso_code": "TF",
        "iso3_code": "ATF",
        "name": "French Southern Territories"
    },
    "BDI": {
        "iso_code": "BI",
        "iso3_code": "BDI",
        "name": "Burundi"
    },
    "BGR": {
        "iso_code": "BG",
        "iso3_code": "BGR",
        "name": "Bulgaria"
    },
    "BIH": {
        "iso_code": "BA",
        "iso3_code": "BIH",
        "name": "Bosnia and Herzegovina"
    },
    "BRA": {
        "iso_code": "BR",
        "iso3_code": "BRA",
        "name": "Brazil"
    },
    "BVT": {
        "iso_code": "BV",
        "iso3_code": "BVT",
        "name": "Bouvet Island"
    },
    // "KOS": {
    //     "iso_code": "XK",
    //     "iso3_code": "KOS",
    //     "name": "Kosovo"
    // },
    // "ABH": {
    //     "iso_code": "AB",
    //     "iso3_code": "ABH",
    //     "name": "Abkhazia"
    // },
    "CHE": {
        "iso_code": "CH",
        "iso3_code": "CHE",
        "name": "Switzerland"
    },
    "CIV": {
        "iso_code": "CI",
        "iso3_code": "CIV",
        "name": "Côte d'Ivoire"
    },
    "COM": {
        "iso_code": "KM",
        "iso3_code": "COM",
        "name": "Comoros"
    },
    "CYM": {
        "iso_code": "KY",
        "iso3_code": "CYM",
        "name": "Cayman Islands"
    },
    "DJI": {
        "iso_code": "DJ",
        "iso3_code": "DJI",
        "name": "Djibouti"
    },
    "ERI": {
        "iso_code": "ER",
        "iso3_code": "ERI",
        "name": "Eritrea"
    },
    "ESP": {
        "iso_code": "ES",
        "iso3_code": "ESP",
        "name": "Spain"
    },
    "FIN": {
        "iso_code": "FI",
        "iso3_code": "FIN",
        "name": "Finland"
    },
    "GBR": {
        "iso_code": "GB",
        "iso3_code": "GBR",
        "name": "United Kingdom"
    },
    "GHA": {
        "iso_code": "GH",
        "iso3_code": "GHA",
        "name": "Ghana"
    },
    "GLP": {
        "iso_code": "GP",
        "iso3_code": "GLP",
        "name": "Guadeloupe"
    },
    "GTM": {
        "iso_code": "GT",
        "iso3_code": "GTM",
        "name": "Guatemala"
    },
    "GUY": {
        "iso_code": "GY",
        "iso3_code": "GUY",
        "name": "Guyana"
    },
    "IDN": {
        "iso_code": "ID",
        "iso3_code": "IDN",
        "name": "Indonesia"
    },
    "IOT": {
        "iso_code": "IO",
        "iso3_code": "IOT",
        "name": "British Indian Ocean Territory"
    },
    "JAM": {
        "iso_code": "JM",
        "iso3_code": "JAM",
        "name": "Jamaica"
    },
    "JPN": {
        "iso_code": "JP",
        "iso3_code": "JPN",
        "name": "Japan"
    },
    "KGZ": {
        "iso_code": "KG",
        "iso3_code": "KGZ",
        "name": "Kyrgyzstan"
    },
    "KNA": {
        "iso_code": "KN",
        "iso3_code": "KNA",
        "name": "Saint Kitts and Nevis"
    },
    "LBY": {
        "iso_code": "LY",
        "iso3_code": "LBY",
        "name": "Libya"
    },
    "LUX": {
        "iso_code": "LU",
        "iso3_code": "LUX",
        "name": "Luxembourg"
    },
    "MDV": {
        "iso_code": "MV",
        "iso3_code": "MDV",
        "name": "Maldives"
    },
    "MHL": {
        "iso_code": "MH",
        "iso3_code": "MHL",
        "name": "Marshall Islands"
    },
    "NIU": {
        "iso_code": "NU",
        "iso3_code": "NIU",
        "name": "Niue"
    },
    "NOR": {
        "iso_code": "NO",
        "iso3_code": "NOR",
        "name": "Norway"
    },
    "NPL": {
        "iso_code": "NP",
        "iso3_code": "NPL",
        "name": "Nepal"
    },
    "NRU": {
        "iso_code": "NR",
        "iso3_code": "NRU",
        "name": "Nauru"
    },
    "OMN": {
        "iso_code": "OM",
        "iso3_code": "OMN",
        "name": "Oman"
    },
    "PAK": {
        "iso_code": "PK",
        "iso3_code": "PAK",
        "name": "Pakistan"
    },
    "PAN": {
        "iso_code": "PA",
        "iso3_code": "PAN",
        "name": "Panama"
    },
    "PER": {
        "iso_code": "PE",
        "iso3_code": "PER",
        "name": "Peru"
    },
    "PNG": {
        "iso_code": "PG",
        "iso3_code": "PNG",
        "name": "Papua New Guinea"
    },
    "POL": {
        "iso_code": "PL",
        "iso3_code": "POL",
        "name": "Poland"
    },
    "PRT": {
        "iso_code": "PT",
        "iso3_code": "PRT",
        "name": "Portugal"
    },
    "QAT": {
        "iso_code": "QA",
        "iso3_code": "QAT",
        "name": "Qatar"
    },
    "REU": {
        "iso_code": "RE",
        "iso3_code": "REU",
        "name": "Réunion"
    },
    "RWA": {
        "iso_code": "RW",
        "iso3_code": "RWA",
        "name": "Rwanda"
    },
    "SEN": {
        "iso_code": "SN",
        "iso3_code": "SEN",
        "name": "Senegal"
    },
    "SGP": {
        "iso_code": "SG",
        "iso3_code": "SGP",
        "name": "Singapore"
    },
    "SLB": {
        "iso_code": "SB",
        "iso3_code": "SLB",
        "name": "Solomon Islands"
    },
    "SLE": {
        "iso_code": "SL",
        "iso3_code": "SLE",
        "name": "Sierra Leone"
    },
    "SLV": {
        "iso_code": "SV",
        "iso3_code": "SLV",
        "name": "El Salvador"
    },
    "SOM": {
        "iso_code": "SO",
        "iso3_code": "SOM",
        "name": "Somalia"
    },
    // "SSD": {
    //     "iso_code": "SS",
    //     "iso3_code": "SSD",
    //     "name": "South Sudan"
    // },
    "SUR": {
        "iso_code": "SR",
        "iso3_code": "SUR",
        "name": "Suriname"
    },
    "SVN": {
        "iso_code": "SI",
        "iso3_code": "SVN",
        "name": "Slovenia"
    },
    "SWE": {
        "iso_code": "SE",
        "iso3_code": "SWE",
        "name": "Sweden"
    },
    "TCA": {
        "iso_code": "TC",
        "iso3_code": "TCA",
        "name": "Turks and Caicos Islands"
    },
    "TCD": {
        "iso_code": "TD",
        "iso3_code": "TCD",
        "name": "Chad"
    },
    "TGO": {
        "iso_code": "TG",
        "iso3_code": "TGO",
        "name": "Togo"
    },
    "TKL": {
        "iso_code": "TK",
        "iso3_code": "TKL",
        "name": "Tokelau"
    },
    "TON": {
        "iso_code": "TO",
        "iso3_code": "TON",
        "name": "Tonga"
    },
    "TUN": {
        "iso_code": "TN",
        "iso3_code": "TUN",
        "name": "Tunisia"
    },
    "TUR": {
        "iso_code": "TR",
        "iso3_code": "TUR",
        "name": "Turkey"
    },
    "TUV": {
        "iso_code": "TV",
        "iso3_code": "TUV",
        "name": "Tuvalu"
    },
    "UGA": {
        "iso_code": "UG",
        "iso3_code": "UGA",
        "name": "Uganda"
    },
    "UZB": {
        "iso_code": "UZ",
        "iso3_code": "UZB",
        "name": "Uzbekistan"
    },
    "VIR": {
        "iso_code": "VI",
        "iso3_code": "VIR",
        "name": "Virgin Islands, U.S."
    },
    "VUT": {
        "iso_code": "VU",
        "iso3_code": "VUT",
        "name": "Vanuatu"
    },
    "WSM": {
        "iso_code": "WS",
        "iso3_code": "WSM",
        "name": "Samoa"
    },
    "YEM": {
        "iso_code": "YE",
        "iso3_code": "YEM",
        "name": "Yemen"
    },
    "ZMB": {
        "iso_code": "ZM",
        "iso3_code": "ZMB",
        "name": "Zambia"
    },
    "BRN": {
        "iso_code": "BN",
        "iso3_code": "BRN",
        "name": "Brunei Darussalam"
    },
    "VNM": {
        "iso_code": "VN",
        "iso3_code": "VNM",
        "name": "Viet Nam"
    },
    "SRB": {
        "iso_code": "RS",
        "iso3_code": "SRB",
        "name": "Serbia"
    },
    "TLS": {
        "iso_code": "TL",
        "iso3_code": "TLS",
        "name": "Timor-Leste"
    },
    "USA": {
        "iso_code": "US",
        "iso3_code": "USA",
        "name": "United States"
    },
    "MNG": {
        "iso_code": "MN",
        "iso3_code": "MNG",
        "name": "Mongolia"
    },
    "MUS": {
        "iso_code": "MU",
        "iso3_code": "MUS",
        "name": "Mauritius"
    },
    "NAM": {
        "iso_code": "NA",
        "iso3_code": "NAM",
        "name": "Namibia"
    },
    "NER": {
        "iso_code": "NE",
        "iso3_code": "NER",
        "name": "Niger"
    },
    "NIC": {
        "iso_code": "NI",
        "iso3_code": "NIC",
        "name": "Nicaragua"
    },
    "MKD": {
        "iso_code": "MK",
        "iso3_code": "MKD",
        "name": "Macedonia, Republic of"
    },
    "LAO": {
        "iso_code": "LA",
        "iso3_code": "LAO",
        "name": "Lao People's Democratic Republic"
    },
    "BHS": {
        "iso_code": "BS",
        "iso3_code": "BHS",
        "name": "Bahamas"
    },
    "NLD": {
        "iso_code": "NL",
        "iso3_code": "NLD",
        "name": "Netherlands"
    },
    "NZL": {
        "iso_code": "NZ",
        "iso3_code": "NZL",
        "name": "New Zealand"
    },
    "SYR": {
        "iso_code": "SY",
        "iso3_code": "SYR",
        "name": "Syria"
    },
    "BOL": {
        "iso_code": "BO",
        "iso3_code": "BOL",
        "name": "Bolivia"
    },
    "PLW": {
        "iso_code": "PW",
        "iso3_code": "PLW",
        "name": "Palau"
    },
    "PRI": {
        "iso_code": "PR",
        "iso3_code": "PRI",
        "name": "Puerto Rico"
    },
    "ROU": {
        "iso_code": "RO",
        "iso3_code": "ROU",
        "name": "Romania"
    },
    "ANT": {
        "iso_code": "AQ",
        "iso3_code": "ANT",
        "name": "Antarctica"
    },
    "AFG": {
        "iso_code": "AF",
        "iso3_code": "AFG",
        "name": "Afghanistan"
    },
    "AND": {
        "iso_code": "AD",
        "iso3_code": "AND",
        "name": "Andorra"
    },
    // "ATA": {
    //     "iso_code": "AQ",
    //     "iso3_code": "ATA",
    //     "name": "Antarctica"
    // },
    "AUT": {
        "iso_code": "AT",
        "iso3_code": "AUT",
        "name": "Austria"
    },
    "BFA": {
        "iso_code": "BF",
        "iso3_code": "BFA",
        "name": "Burkina Faso"
    },
    // "BLM": {
    //     "iso_code": "BL",
    //     "iso3_code": "BLM",
    //     "name": "Saint Barthélemy"
    // },
    "CCK": {
        "iso_code": "CC",
        "iso3_code": "CCK",
        "name": "Cocos (Keeling) Islands"
    },
    "CRI": {
        "iso_code": "CR",
        "iso3_code": "CRI",
        "name": "Costa Rica"
    },
    "DMA": {
        "iso_code": "DM",
        "iso3_code": "DMA",
        "name": "Dominica"
    },
    "ESH": {
        "iso_code": "EH",
        "iso3_code": "ESH",
        "name": "Western Sahara"
    },
    "FRO": {
        "iso_code": "FO",
        "iso3_code": "FRO",
        "name": "Faroe Islands"
    },
    "GNB": {
        "iso_code": "GW",
        "iso3_code": "GNB",
        "name": "Guinea-Bissau"
    },
    "GUF": {
        "iso_code": "GF",
        "iso3_code": "GUF",
        "name": "French Guiana"
    },
    "HUN": {
        "iso_code": "HU",
        "iso3_code": "HUN",
        "name": "Hungary"
    },
    "ISL": {
        "iso_code": "IS",
        "iso3_code": "ISL",
        "name": "Iceland"
    },
    "KAZ": {
        "iso_code": "KZ",
        "iso3_code": "KAZ",
        "name": "Kazakhstan"
    },
    "LBN": {
        "iso_code": "LB",
        "iso3_code": "LBN",
        "name": "Lebanon"
    },
    "LKA": {
        "iso_code": "LK",
        "iso3_code": "LKA",
        "name": "Sri Lanka"
    },
    "MAR": {
        "iso_code": "MA",
        "iso3_code": "MAR",
        "name": "Morocco"
    },
    "MLT": {
        "iso_code": "MT",
        "iso3_code": "MLT",
        "name": "Malta"
    },
    "MNE": {
        "iso_code": "ME",
        "iso3_code": "MNE",
        "name": "Montenegro"
    },
    "MTQ": {
        "iso_code": "MQ",
        "iso3_code": "MTQ",
        "name": "Martinique"
    },
    "NCL": {
        "iso_code": "NC",
        "iso3_code": "NCL",
        "name": "New Caledonia"
    },
    "KOR": {
        "iso_code": "KR",
        "iso3_code": "KOR",
        "name": "Korea, Republic of"
    },
    // "ALA": {
    //     "iso_code": "AX",
    //     "iso3_code": "ALA",
    //     "name": "Åland Islands"
    // },
    "ASM": {
        "iso_code": "AS",
        "iso3_code": "ASM",
        "name": "American Samoa"
    },
    // "BES": {
    //     "iso_code": "BQ",
    //     "iso3_code": "BES",
    //     "name": "Bonaire, Sint Eustatius and Saba"
    // },
    "CAF": {
        "iso_code": "CF",
        "iso3_code": "CAF",
        "name": "Central African Republic"
    },
    // "CUW": {
    //     "iso_code": "CW",
    //     "iso3_code": "CUW",
    //     "name": "Curaçao"
    // },
    "DOM": {
        "iso_code": "DO",
        "iso3_code": "DOM",
        "name": "Dominican Republic"
    },
    "FLK": {
        "iso_code": "FK",
        "iso3_code": "FLK",
        "name": "Falkland Islands (Malvinas)"
    },
    "GNQ": {
        "iso_code": "GQ",
        "iso3_code": "GNQ",
        "name": "Equatorial Guinea"
    },
    "HMD": {
        "iso_code": "HM",
        "iso3_code": "HMD",
        "name": "Heard Island and McDonald Islands"
    },
    "LIE": {
        "iso_code": "LI",
        "iso3_code": "LIE",
        "name": "Liechtenstein"
    },
    // "MAF": {
    //     "iso_code": "MF",
    //     "iso3_code": "MAF",
    //     "name": "Saint Martin (French part)"
    // },
    "PHL": {
        "iso_code": "PH",
        "iso3_code": "PHL",
        "name": "Philippines"
    },
    "PRY": {
        "iso_code": "PY",
        "iso3_code": "PRY",
        "name": "Paraguay"
    },
    "SAU": {
        "iso_code": "SA",
        "iso3_code": "SAU",
        "name": "Saudi Arabia"
    },
    "SJM": {
        "iso_code": "SJ",
        "iso3_code": "SJM",
        "name": "Svalbard and Jan Mayen"
    },
    "SVK": {
        "iso_code": "SK",
        "iso3_code": "SVK",
        "name": "Slovakia"
    },
    "SYC": {
        "iso_code": "SC",
        "iso3_code": "SYC",
        "name": "Seychelles"
    },
    "TJK": {
        "iso_code": "TJ",
        "iso3_code": "TJK",
        "name": "Tajikistan"
    },
    "URY": {
        "iso_code": "UY",
        "iso3_code": "URY",
        "name": "Uruguay"
    },
    "VGB": {
        "iso_code": "VG",
        "iso3_code": "VGB",
        "name": "Virgin Islands, British"
    },
    "ZWE": {
        "iso_code": "ZW",
        "iso3_code": "ZWE",
        "name": "Zimbabwe"
    },
    "RUS": {
        "iso_code": "RU",
        "iso3_code": "RUS",
        "name": "Russia"
    },
    "MNP": {
        "iso_code": "MP",
        "iso3_code": "MNP",
        "name": "Northern Mariana Islands"
    },
    "MDA": {
        "iso_code": "MD",
        "iso3_code": "MDA",
        "name": "Moldova, Republic of"
    },
    "SDN": {
        "iso_code": "SD",
        "iso3_code": "SDN",
        "name": "Sudan"
    },
    "SHN": {
        "iso_code": "SH",
        "iso3_code": "SHN",
        "name": "Saint Helena, Ascension and Tristan da Cunha"
    },
    "SMR": {
        "iso_code": "SM",
        "iso3_code": "SMR",
        "name": "San Marino"
    },
    "SPM": {
        "iso_code": "PM",
        "iso3_code": "SPM",
        "name": "Saint Pierre and Miquelon"
    },
    "SWZ": {
        "iso_code": "SZ",
        "iso3_code": "SWZ",
        "name": "Swaziland"
    },
    "THA": {
        "iso_code": "TH",
        "iso3_code": "THA",
        "name": "Thailand"
    },
    "TKM": {
        "iso_code": "TM",
        "iso3_code": "TKM",
        "name": "Turkmenistan"
    },
    "TTO": {
        "iso_code": "TT",
        "iso3_code": "TTO",
        "name": "Trinidad and Tobago"
    },
    "UKR": {
        "iso_code": "UA",
        "iso3_code": "UKR",
        "name": "Ukraine"
    },
    "VCT": {
        "iso_code": "VC",
        "iso3_code": "VCT",
        "name": "Saint Vincent and the Grenadines"
    },
    "WLF": {
        "iso_code": "WF",
        "iso3_code": "WLF",
        "name": "Wallis and Futuna"
    },
    "ZAF": {
        "iso_code": "ZA",
        "iso3_code": "ZAF",
        "name": "South Africa"
    },
    "TWN": {
        "iso_code": "TW",
        "iso3_code": "TWN",
        "name": "Taiwan, Province of China"
    },
    "VAT": {
        "iso_code": "VA",
        "iso3_code": "VAT",
        "name": "Holy See (Vatican City State)"
    },
    "STP": {
        "iso_code": "ST",
        "iso3_code": "STP",
        "name": "Sao Tome and Principe"
    },
    "PSE": {
        "iso_code": "PS",
        "iso3_code": "PSE",
        "name": "Palestine, State of"
    },
    "VEN": {
        "iso_code": "VE",
        "iso3_code": "VEN",
        "name": "Venezuela"
    },
    "COD": {
        "iso_code": "CD",
        "iso3_code": "COD",
        "name": "Congo"
    },
    "IRN": {
        "iso_code": "IR",
        "iso3_code": "IRN",
        "name": "Iran"
    },
    "FSM": {
        "iso_code": "FM",
        "iso3_code": "FSM",
        "name": "Micronesia, Federated States of"
    },
    "PCN": {
        "iso_code": "PN",
        "iso3_code": "PCN",
        "name": "Pitcairn"
    },
    "PYF": {
        "iso_code": "PF",
        "iso3_code": "PYF",
        "name": "French Polynesia"
    },
    "SGS": {
        "iso_code": "GS",
        "iso3_code": "SGS",
        "name": "South Georgia and the South Sandwich Islands"
    },
    "SXM": {
        "iso_code": "SX",
        "iso3_code": "SXM",
        "name": "Sint Maarten (Dutch part)"
    },
    "UMI": {
        "iso_code": "UM",
        "iso3_code": "UMI",
        "name": "United States Minor Outlying Islands"
    },
    "PRK": {
        "iso_code": "KP",
        "iso3_code": "PRK",
        "name": "Korea, Democratic People's Republic of"
    },
    "TZA": {
        "iso_code": "TZ",
        "iso3_code": "TZA",
        "name": "Tanzania"
    }
}

export default COUNTRIES