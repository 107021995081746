<template>
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.1822 10.0002C13.7208 9.01689 14.0017 7.91963 14 6.80559C14 3.0469 10.8661 0 7 0C3.13395 0 1.02684e-05 3.0469 1.02684e-05 6.80559C-0.00282334 8.41102 0.580884 9.96525 1.64707 11.1912L1.6553 11.2012C1.65818 11.2036 1.66066 11.2068 1.66271 11.2092H1.64707L5.80094 15.4967C5.95493 15.6556 6.14071 15.7823 6.34688 15.8688C6.55304 15.9554 6.77523 16 6.9998 16C7.22436 16 7.44655 15.9554 7.65271 15.8688C7.85888 15.7823 8.04466 15.6556 8.19865 15.4967L12.3529 11.2092H12.3373L12.3439 11.2016L12.3447 11.2008C12.3743 11.1664 12.404 11.1319 12.4332 11.0967C12.7188 10.7554 12.9694 10.3885 13.1822 10.0002ZM7.00165 9.40772C6.34641 9.40772 5.71801 9.15466 5.25468 8.7042C4.79136 8.25375 4.53106 7.64279 4.53106 7.00575C4.53106 6.36871 4.79136 5.75776 5.25468 5.3073C5.71801 4.85684 6.34641 4.60378 7.00165 4.60378C7.65689 4.60378 8.28529 4.85684 8.74862 5.3073C9.21194 5.75776 9.47223 6.36871 9.47223 7.00575C9.47223 7.64279 9.21194 8.25375 8.74862 8.7042C8.28529 9.15466 7.65689 9.40772 7.00165 9.40772Z"
            fill="#B9B9B9"/>
    </svg>
</template>

<style scoped lang="sass">

</style>