<template>
    <svg :class="{'direction-arrow-left': direction === 'left'}" width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 13L7 7L1 1" :stroke="stroke" stroke-width="2"/>
    </svg>
</template>

<script>
export default {
    name: "PaginatorArrow",
    props: {
        stroke: {
            default: '#000000'
        },
        direction: {
            default: 'right'
        }
    }
}
</script>

<style scoped lang="sass">
.direction-arrow-left
    transform: rotate(180deg)
</style>