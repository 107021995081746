<template>
    <div class="settings__content personal-information">
        <h2>Personal Information</h2>
        <form @submit.prevent="editPersonalInformation">
            <div class="settings-input">
                <label>Name</label>
                <input type="text"
                       v-model="user.first_name"
                >
            </div>
            <div class="settings-input" style="margin-top: 16px">
                <label>Surname</label>
                <input type="text"
                       v-model="user.last_name"
                >
            </div>
            <div class="personal-information__line"></div>
            <div class="settings-input">
                <label>Email</label>
                <input type="text"
                       v-model="user.email"
                >
            </div>
            <button class="--btn-next">Save Changes</button>
        </form>
    </div>
</template>

<script>
export default {
    name: "personal_information",
    data: () => ({
        user: {
            first_name: null,
            last_name: null,
            email: null
        }
    }),
    methods: {
        async editPersonalInformation() {
            let info
            info = await this.$services.settings.editPersonalInformation(this.user.first_name, this.user.last_name, this.user.email)
            console.log(info)
        }
    },
    mounted() {
        this.user = this.$store.state.user
    }
}
</script>

<style scoped lang="sass">
.personal-information
    form
        max-width: 486px
    .--btn-next
        margin-top: 32px
        width: 200px
    &__line
        width: 100%
        height: 1px
        background: var(--settings-sidebar-border-color--default)
        margin: 30px 0

</style>