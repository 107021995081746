const Centrifuge = require("centrifuge");



class BaseEvents {
    connect() {
        const location = window.location;
        let host = '';
        if (location.protocol === 'https:') {
            host += 'wss:';
        } else {
            host += 'ws:';
        }
        host += '//' + location.host;
        this.centrifuge = new Centrifuge(`${host}/connection/websocket`);
        this.centrifuge.on('connect', function(ctx){
            console.log('Centrifugo connected: ', ctx);
        });
        this.centrifuge.on('disconnect', function(ctx){
            console.log('Centrifugo disconnected: ' + ctx.reason);
        });
        this.centrifuge.connect();
    }

    subscribe(channelName, callback) {
        if (!this.centrifuge || !this.centrifuge.isConnected()) {
            this.connect();
        }
        return this.centrifuge.subscribe(channelName, message => callback(message.data));
    }

    describeAll() {
        this.centrifuge.disconnect();
    }
}


class QuestionnairesEvents extends BaseEvents {
    generationStatusChanged(questionnaireId) {
        return (callback) => {
            const channelName = `questionnaire-${questionnaireId}__generation-status-changed`;
            return this.subscribe(channelName, data => callback(data));
        }
    }
}


export const events = {
    install: (app, options) => {
        app.config.globalProperties.$events = {
            questionnaires: new QuestionnairesEvents(),
        }
    },
};
