class BaseService {
    _getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== "") {
            const cookies = document.cookie.split(";");
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === name + "=") {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    async _callMethod(app, method, kwargs) {
        let url = `/frontend_api/${app}/`
        if (method) {
            url += `${method}/`
        }
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(kwargs),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFTOKEN': this._getCookie('csrftoken'),
            },
            credentials: 'same-origin'
        });
        return await response.json();
    }

    async _get(app, method) {
        let url = `/frontend_api/${app}/`
        if (method) {
            url += `${method}/`
        }
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'same-origin'
        });
        return await response.json();
    }

    _objectToQueryString(obj) {
        return Object.entries(obj)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&');
    }
}


class QuestionnairesService extends BaseService {
    async getQuestionnaires(filters = null) {
        const response = await fetch(`/frontend_api/questionnaires?${this._objectToQueryString(filters)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return await response.json();
    }

    async authorize(email, password) {
        const response = await fetch('/frontend_api/questionnaires/authorize/authorize/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email, password,
            }),
        })
        return await response.json();
    }

    async authenticate() {
        return this._callMethod('questionnaires', 'authenticate')
    }

    async setShowInPartnerDesign(value) {
        return this._callMethod('questionnaires', 'set_show_in_partner_design', {value})
    }

    async createQuestionnaire(document_type) {
        return this._callMethod('questionnaires', 'create_questionnaire', {
            document_type,
        })
    }

    async cloneQuestionnaire(questionnaire_id) {
        return this._callMethod('questionnaires', 'clone_questionnaire',
            questionnaire_id
        )
    }

    async getQuestionnaire({questionnaire_id, access_token}) {
        return this._callMethod('questionnaires', 'get_questionnaire', {
            questionnaire_id, access_token,
        })
    }

    async startGeneration(
        questionnaire_id, pay_for_evisa, bank_card_number, bank_card_month, bank_card_year, bank_card_cvv, bank_card_holder,
    ) {
        return this._callMethod('questionnaires', 'start_generation', {
            questionnaire_id, pay_for_evisa, bank_card_number,
            bank_card_month, bank_card_year, bank_card_cvv, bank_card_holder,
        })
    }

    async finishFilling(questionnaire_id) {
        return this._callMethod('questionnaires', 'finish_filling', {
            questionnaire_id,
        })
    }

    async healthCheckCentrifugo(questionnaire_id) {
        return this._callMethod('questionnaires', 'healthcheck_centrifugo', {
            questionnaire_id,
        })
    }

    async setFieldValue(questionnaire_id, section_name, field_name, value) {
        const serializedValue = this._serializeValue(value);
        return this._callMethod('questionnaires', 'set_field_value', {
            questionnaire_id,
            section_name,
            field_name,
            ...serializedValue,
        })
    }

    _serializeValue(value) {
        const serializedValue = {};
        if (value instanceof Array) {
            serializedValue.value_list = value
        } else if (typeof value === 'number') {
            serializedValue.value_int = value
        } else if (typeof value === 'object') {
            serializedValue.value_dict = value
        } else if (typeof value === 'string') {
            serializedValue.value_str = value
        } else {
            serializedValue.value_str = null;
        }
        return serializedValue
    }

    async setFieldsValues(questionnaire_id, values) {
        const requestData = {};
        for (const key in values) {
            const value = this._serializeValue(values[key]);
            requestData[key] = value;
        }
        return this._callMethod('questionnaires', 'set_field_value', {
            questionnaire_id,
            requestData,
        })
    }
}

class SettingsService extends BaseService {
    async editPersonalInformation(first_name, last_name, email) {
        return this._callMethod('questionnaires', 'edit_personal_information', {
            first_name,
            last_name,
            email
        })
    }
    async apiStructure() {
        return this._get('questionnaires', 'api_structure')
    }

    async webhooks() {
        return this._get('webhooks')
    }

    async addWebhook(name, url) {
        return this._callMethod('webhooks', 'add_webhook', {
            name, url,
        })
    }

    async changePassword(old_password, new_password) {
        return this._callMethod('questionnaires', 'change_password', {
            old_password,
            new_password
        })
    }
}

class AccountService extends BaseService {

}

export const services = {
    install: (app, options) => {
        app.config.globalProperties.$services = {
            questionnaires: new QuestionnairesService(),
            settings: new SettingsService()
        };
    },
}
