<template>
    <div class="boolean-field">
        <div class="boolean-field__container-inner">
            <div class="boolean-field__radio">
                <div class="boolean-field__radio-button">
                    <input
                        class="boolean-field__radio-input radio"
                        type="radio"
                        :name="name"
                        value="Yes"
                        :id="`Yes${name}`"
                        :checked="modelValue === 'Yes'"
                        @change="$emit('update:modelValue', 'Yes')"
                    />
                    <div class="boolean-field__radio-border --br-red"
                         style="border-right:1px solid #c7c7c7; border-radius: 3px 0 0 3px">
                        <div class="boolean-field__radio-check" style="border-radius: 3px 0 0 3px;"></div>
                        <label class="white" :for="`Yes${name}`">Yes</label>
                    </div>
                </div>
            </div>
            <div class="boolean-field__radio">
                <div class="boolean-field__radio-button">
                    <input
                        class="boolean-field__radio-input radio"
                        type="radio"
                        :name="name"
                        value="No"
                        :id="`No${name}`"
                        :checked="modelValue === 'No'"
                        @change="$emit('update:modelValue', 'No')"
                    />
                    <div class="boolean-field__radio-border" style="border-radius: 0 3px 3px 0;">
                        <div class="boolean-field__radio-check" style="border-radius: 0 3px 3px 0;"></div>
                        <label class="white" :for="`No${name}`">No</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
let idCounter = 0;
export default {
    props: {
        modelValue: String,
        validators: {
            type: Array,
            default: []
        },
    },
    data() {
        return {
            name: `boolean-field-${idCounter++}`
        };
    }
}
</script>

<style scoped lang="sass">

.boolean-field
    width: 100%
    &__radio
        display: flex
        cursor: pointer
        width: 100%
        height: var(--input-height)
        position: relative

        label
            margin: 0
            line-height: 1
            position: absolute
            left: 50%
            top: 33%
            color: var(--description-text)

    &__radio-input
        width: 100%
        height: var(--input-height)
        opacity: 0
        position: absolute
        cursor: pointer

    &__radio-input:checked + .boolean-field__radio-border > .boolean-field__radio-check
        display: block !important

    &__radio-input:checked + .boolean-field__radio-border > .white
        color: var(--white)

    &__radio-check
        background: var(--primary)
        width: 100%
        height: var(--input-height)
        display: none


    &__container,
    &__container-inner
        display: flex
        width: 100%
        position: relative

    &__radio-button
        width: 100%
        height: var(--input-height)
        position: relative
        display: inline-block

    &__radio-border
        width: 100%
        height: var(--input-height)
        display: flex
        justify-content: center
        align-items: center
        background: var(--background-input)
        border: 1px solid var(--border-form-input)

</style>