<template>
    <div style="padding-top: 70px;" class="policies__content form shadow">
        <div class="policies__row">
            <div class="policies__row-label">
                Allgemeines
            </div>
            <div class="policies__row-value">
                Angaben gemäß § 5 TMG
                <br><br>
                Visum.IO UG (haftungsbeschränkt)<br>
                Christian-Pleß-Straße 14 <br>
                63069 Offenbach am Main <br>
                +49 (0) 69 46 90 81 63 <br>
                <a style="text-decoration: none" href="mailto:info@visum.io">info@visum.io</a>
            </div>
        </div>
        <div class="policies__row">
            <div class="policies__row-label">
                Vertretungsberechtigter
                Geschäftsführer
            </div>
            <div class="policies__row-value">
                Sergej Pocivalov
            </div>
        </div>
        <div class="policies__row">
            <div class="policies__row-label">
                Handelsregister Nr
            </div>
            <div class="policies__row-value">
                HRB 51365 <br>
                Handelsregister B des Amtsgerichts Offenbach am Main
            </div>
        </div>
        <div class="policies__row">
            <div class="policies__row-label">
                Umsatzsteuer-Identifikationsnummer
            </div>
            <div class="policies__row-value">
                DE321102104 <br>
                gemäß §27 a Umsatzsteuergesetz
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "imprint",
    provide() {
        return {
            mainTitle: 'Impressum'
        }
    }
}
</script>