import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dateFilter from '@/filters/date.filter'

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import {services} from "@/plugins/services";
import {events} from "@/plugins/events";

const app = createApp(App)

app.config.globalProperties.$filters = {dateFilter}

app
    .component('VueDatePicker', VueDatePicker)
    .use(store)
    .use(router)
    .use(services)
    .use(events)
    .mount('#app')
