<template>
    <svg :width="width" :height="height" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.61538 13H2.53846C2.13044 13 1.73912 12.8595 1.4506 12.6095C1.16209 12.3594 1 12.0203 1 11.6667V2.33333C1 1.97971 1.16209 1.64057 1.4506 1.39052C1.73912 1.14048 2.13044 1 2.53846 1H5.61538M11 7L8.20844 4.58065M11 7L8.20844 9.41935M11 7H3.84555"
            :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: "LogoutIcon",
    props: {
        stroke: {
            default: '#000000'
        },
        width: {
            default: 12
        },
        height: {
            default: 14
        }
    }
}
</script>
