export const INDIAN_STATES = [
    'Chandigarh',
    'Andaman and Nicobar Islands',
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chhattisgarh',
    'Delhi',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jammu and Kashmir',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Madhya Pradesh',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Orissa',
    'Pondicherry',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Tripura',
    'Uttar Pradesh',
    // 'West Pradesh',
    'West Bengal',
    // 'Dadra Nagar Haveli',
    'Lakshadweep',
    'Telangana',
    'Uttarakhand',
    'Ladakh',
    'Dadra Nagar Haveli and Daman and Diu'
]

export const INDIAN_DISTRICTS = {
    'Chandigarh': ['Chandigarh', 'Not Known'],
    'Andaman and Nicobar Islands': ["Nicobar", "North and Middle Andaman", "South Andaman", "Not Known"],
    'Andhra Pradesh': [
        "Alluri Seetha Rama Raju",
        "Anakapalli",
        "Ananthapuramu",
        "Annamayya",
        "Bapatla",
        "Chitoor",
        "East Godavari",
        "Eluru",
        "Guntur",
        "Konaseema",
        "Krishna",
        "Kurnool",
        "Nandyal",
        "Not known",
        "NTR",
        "Palnadu",
        "Parvathipuram Manyam",
        "Prakasam",
        "SPS Nellore",
        "Srikakulam",
        "Sri Satya Sal",
        "Thirupathi",
        "Visakhaparnam",
        "Vizianagaram",
        "West Godavari",
        "YSR District Kadapa"
    ],
    'Arunachal Pradesh': [
        "Anjaw",
        "Changlang",
        "Dibang Valley",
        "East Kameng",
        "East Siang",
        "Kamle",
        "Kra Daadi",
        "Kurung Kumey",
        "Lepa-Rada",
        "Lohit",
        "Longding",
        "Lower Dibang Valley",
        "Lower Siang",
        "Lower Subansiri",
        "Namsai",
        "Not Known",
        "Pakke Kesang",
        "Papum Pare",
        "Shi-Yomi",
        "Siang",
        "Tawang",
        "Tirap",
        "Upper Siang",
        "Upper Subansiri",
        "West Kameng",
        "West Siang"
    ],
    'Assam': [
        "Baksa",
        "Barpeta",
        "Biswanath",
        "Bongaigaon",
        "Cachar",
        "Charaideo",
        "Chirang",
        "Darrang",
        "Dhemaji",
        "Dhubri",
        "Dibugarh",
        "Dima Hasao",
        "Goalpara",
        "Golaghat",
        "Hailadandi",
        "Hojai",
        "Jorhat",
        "Kampur Rural",
        "Karbi Anglong",
        "Karimganj",
        "Kokrajhar",
        "Lakhimpur",
        "Majui",
        "Marigaon",
        "Morigaon",
        "Nagaon",
        "Nalbari",
        "Not Known",
        "Sivasagar",
        "Sonitpur",
        "South Salmara",
        "Tamulpur",
        "Tinsukia",
        "Udalguri",
        "West Karbi Anglong"
    ],
    'Bihar': [
        "Araria",
        "Arwal",
        "Aurangabad",
        "Banka",
        "Begusarai",
        "Bhagalpur",
        "Bhojpur",
        "Darbhanga",
        "East Champaran",
        "Gaya",
        "Gopalganj",
        "Jamui",
        "Jehanabad",
        "Kaimur",
        "Katihar",
        "Khagaria",
        "Kishanganj",
        "Lakhisarai",
        "Madhepura",
        "Madhubani",
        "Munger",
        "Muzaffarpur",
        "Nalanda",
        "Nawada",
        "Not Known",
        "Patna",
        "Purnia",
        "Rohtas",
        "Saharsa",
        "Samastipur",
        "Saran",
        "Sheikhpura",
        "Sheohar",
        "Sitamarhi",
        "Siwan",
        "Supaul",
        "Vaishali",
        "West Champaran"
    ],
    'Chhattisgarh': [
        "Balod",
        "Balodabazar",
        "Balrampur",
        "Bastar",
        "Bemetara",
        "Bijapur",
        "Bilaspur",
        "Dantewada",
        "Dhamtari",
        "Durg",
        "Gariaband",
        "Gaurella Pendra Marwahi",
        "Janjgir-Champa",
        "Jashpur",
        "Kabirdham",
        "Kanker",
        "Kondagaon",
        "Korba",
        "Koriya",
        "Mahasamund",
        "Mungeli",
        "Narayanpur",
        "Not Known",
        "Raigarh",
        "Raipur",
        "Rajnandgaon",
        "Sukma",
        "Surajpur",
        "Surguja"
    ],
    'Delhi': [
        "Delhi",
        "New Delhi",
        "Not Known"
    ],
    'Goa': [
        "North Goa",
        "Not Known",
        "South Goa"
    ],
    'Gujarat': [
        "Ahmedabad",
        "Amreli",
        "Anand",
        "Aravali",
        "Banaskantha",
        "Bharuch",
        "Bhavnagar",
        "Botad",
        "Chhota Udepur",
        "Dahod",
        "Devbhumi Dwarka",
        "Gandhinagar",
        "Gir Somnath",
        "Jamnagar",
        "Junagadh",
        "Kheda",
        "Kutch",
        "Mahesana",
        "Mahisagar",
        "Morbi",
        "Narmada",
        "Navsari",
        "Not Known",
        "Panch Mahals",
        "Patan",
        "Porbandar",
        "Rajkot",
        "Sabar Kantha",
        "Surat",
        "Surendranagar",
        "Tapi",
        "The Dangs",
        "Vadodara",
        "Valsad"
    ],
    'Haryana': [
        "Ambala",
        "Bhiwani",
        "Charkhi Dadri",
        "Faridabad",
        "Fatehabad",
        "Gurgaon",
        "Hisar",
        "Jhajjar",
        "Jind",
        "Kaithal",
        "Karnal",
        "Kurukshetra",
        "Mahendragarh",
        "Mewat",
        "Not Known",
        "Palwal",
        "Panchkula",
        "Panipat",
        "Rewari",
        "Rohtak",
        "Sirsa",
        "Sonipat",
        "Yamunanagar"
    ],
    'Himachal Pradesh': [
        "Bilaspur",
        "Chamba",
        "Hamirpur",
        "Kangra",
        "Kinnaur",
        "Kullu",
        "Lahaul and Spiti AND SPITI",
        "Mandi",
        "Not Known",
        "Shimla",
        "Sirmaur",
        "Solan",
        "Una"
    ],
    'Jammu and Kashmir': [
        "Anantnag",
        "Bandipora",
        "Baramulla",
        "Budgam",
        "Doda",
        "Ganderbal",
        "Jammu",
        "Kathua",
        "Kishtwar",
        "Kulgam",
        "Kupwada",
        "Not Known",
        "Poonch",
        "Pulwama",
        "Rajauri",
        "Ramban",
        "Reasi",
        "Samba",
        "Shopian",
        "Srinagar",
        "Udhampur"
    ],
    'Jharkhand': [
        "Bokaro",
        "Chatra",
        "Deoghar",
        "Dhanbad",
        "Dumka",
        "East Singhbhum",
        "Garhwa",
        "Giridih",
        "Godda",
        "Gumla",
        "Hazaribag",
        "Jamshedpur",
        "Jamtara",
        "Khunti",
        "Koderma",
        "Latehar",
        "Lohardaga",
        "Not Known",
        "Pakur",
        "Palamu",
        "Ramgarh",
        "Ranchi",
        "Sahibganj",
        "Seraikela",
        "Simdega",
        "West Singhbhum"
    ],
    'Karnataka': [
        "Bagalkot",
        "Bangalore",
        "Bangalore Rural",
        "Belgaum",
        "Bellary",
        "Bidar",
        "Bijapur",
        "Chamrajnagar",
        "Chickmagalur",
        "Chikkaballapura",
        "Chitradurga",
        "Davangere",
        "Dharwad",
        "Gadag",
        "Gulbarga",
        "Hassan",
        "Haveri",
        "Kolar",
        "Koppal",
        "Mandya",
        "Mysore",
        "North Kannada",
        "Not Known",
        "Raichur",
        "Ramanagara",
        "Shimoga",
        "South Kannada",
        "Tumkur",
        "Udupi",
        "Vijayanagara",
        "Yadgiri"
    ],
    'Kerala': [
        "Alappuzha",
        "Ernakulam",
        "Idukki",
        "Kannur",
        "Kasargod",
        "Kollam",
        "Kottayam",
        "Kozhikode",
        "Malappuram",
        "Not Known",
        "Palakkad",
        "Pathanamthitta",
        "Thiruvananthapuram",
        "Thrissur",
        "Wayanad"
    ],
    'Madhya Pradesh': [
        "Agar Malwa",
        "Alirajpur",
        "Anuppur",
        "Ashoknagar",
        "Balaghat",
        "Barwani",
        "Betul",
        "Bhind",
        "Bhopal",
        "Burhanpur",
        "Chhatarpur",
        "Chhindwara",
        "Damoh",
        "Datia",
        "Dewas",
        "Dhar",
        "Dindori",
        "Guna",
        "Gwalior",
        "Harda",
        "Hoshangabad",
        "Indore",
        "Jabalpur",
        "Jhabua",
        "Katni",
        "Khandwa",
        "Khargone",
        "Mandla",
        "Mandsaur",
        "Morena",
        "Narsinghpur",
        "Neemuch",
        "Niwari",
        "Not Known",
        "Panna",
        "Raisen",
        "Rajgarh",
        "Ratlam",
        "Rewa",
        "Sagar",
        "Satna",
        "Sehore",
        "Seoni",
        "Shahdol",
        "Shajapur",
        "Sheopur",
        "Shivpuri",
        "Sidhi",
        "Singrauli",
        "Tikamgarh",
        "Ujjain",
        "Umaria",
        "Vidisha"
    ],
    'Maharashtra': [
        "Ahmednagar",
        "Akola",
        "Amravati",
        "Aurangabad",
        "Beed",
        "Bhandara",
        "Buldhana",
        "Chandrapur",
        "Dhule",
        "Gadchiroli",
        "Gondia",
        "Hingoli",
        "Jalgaon",
        "Jalna",
        "Kolhapur",
        "Latur",
        "Mumbai",
        "Nagpur",
        "Nanded",
        "Nandurbar",
        "Nashik",
        "Not Known",
        "Osmanabad",
        "Palghar",
        "Parbhani",
        "Pune",
        "Raigarh",
        "Ratnagiri",
        "Sangli",
        "Satara",
        "Sindhudurg",
        "Solapur",
        "Thane",
        "Wardha",
        "Washim",
        "Yavatmal"
    ],
    'Manipur': [
        "Bishnupur",
        "Chandel",
        "Churachandpur",
        "Imphal East",
        "Imphal West",
        "Jiribam",
        "Kakching",
        "Kamjong",
        "Kangpokpi",
        "Noney",
        "Not Known",
        "Pherzawl",
        "Senapati",
        "Tamenglong",
        "Tengnoupal",
        "Thoubal",
        "Ukhrul"
    ],
    'Meghalaya': [
        "Eastern West Khasi Hills District",
        "East Garo Hills",
        "East Jaintia Hills",
        "East Khasi Hills",
        "North Garo Hills",
        "Not Known",
        "Ribhoi",
        "South Garo Hills",
        "South West Garo Hills",
        "South West Khasi Hills",
        "West Garo Hills",
        "West Jaintia Hills",
        "West Khasi Hills"
    ],
    'Mizoram': [
        "Aizawl",
        "Champhai",
        "Kolasib",
        "Lawngtlai",
        "Lunglei",
        "Mamit",
        "Not Known",
        "Saiha",
        "Serchhip"
    ],
    'Nagaland': [
        "Dimapur",
        "Kiphire",
        "Kohima",
        "Longleng",
        "Mokokchung",
        "Mon",
        "Noklak",
        "Not Known",
        "Peren",
        "Phek",
        "Shamator",
        "Tseminyu",
        "Tuensang",
        "Wokha",
        "Zunheboto"
    ],
    'Orissa': [
        "Angul",
        "Balasore",
        "Bargarh",
        "Bhadrak",
        "Bolangir",
        "Boudh",
        "Cuttack",
        "Deogarh",
        "Dhenkanal",
        "Gajapati",
        "Ganjam",
        "Jagatsinghapur",
        "Jajapur",
        "Jharsuguda",
        "Kalahandi",
        "Kandhamal",
        "Kendrapara",
        "Keonjhar",
        "Khordha",
        "Koraput",
        "Malkangiri",
        "Mayurbhanj",
        "Nabarangapur",
        "Nayagarh",
        "Not Known",
        "Nuapada",
        "Puri",
        "Rayagada",
        "Sambalpur",
        "Sonapur",
        "Sundergarh"
    ],
    'Pondicherry': [
        "Karaikal",
        "Mahe",
        "Not Known",
        "Pondicherry",
        "Yanam"
    ],
    'Punjab': [
        "Amritsar",
        "Barnala",
        "Bathinda",
        "Faridkot",
        "Fatehgarh Sahib",
        "Fazilka",
        "Firozpur",
        "Gurdaspur",
        "Hoshiarpur",
        "Jalandhar",
        "Kapurthala",
        "Ludhiana",
        "Malerkotla",
        "Mansa",
        "Moga",
        "Muktsar",
        "Not Known",
        "Pathankot",
        "Patiala",
        "Rupnagar",
        "Sangrur",
        "SAS Nagar Mohali",
        "SBS Nagar Nawanshar",
        "Sri Muktsar Sahib",
        "Tarantaran"
    ],
    'Rajasthan': [
        "Ajmer",
        "Alwar",
        "Banswara",
        "Baran",
        "Barmer",
        "Bharatpur",
        "Bhilwara",
        "Bikaner",
        "Bundi",
        "Chittorgarh",
        "Churu",
        "Dausa",
        "Dholpur",
        "Dungarpur",
        "Ganganagar",
        "Hanumangarh",
        "Jaipur",
        "Jaisalmer",
        "Jalor",
        "Jhalawar",
        "Jhunjhunu",
        "Jodhpur",
        "Karauli",
        "Kota",
        "Nagaur",
        "Not Known",
        "Pali",
        "Pratapgarh",
        "Rajsamand",
        "Sawai Madhopur",
        "Sikar",
        "Sirohi",
        "Tonk",
        "Udaipur"
    ],
    'Sikkim': [
        "East Sikkim",
        "North Sikkim",
        "Not Known",
        "Pakyong",
        "Soreng",
        "South Sikkim",
        "West Sikkim"
    ],
    'Tamil Nadu': [
        "Ariyalur",
        "Chengalpattu",
        "Chennai",
        "Coimbatore",
        "Cuddalore",
        "Dharmapuri",
        "Dindigul",
        "Erode",
        "Kallakurichi",
        "Kancheepuram",
        "Kanniyakumari",
        "Karur",
        "Krishnagiri",
        "Madurai",
        "Myladuthurai",
        "Nagapattinam",
        "Namakkal",
        "Not Known",
        "Perambalur",
        "Pudukkottai",
        "Ramanathapuram",
        "Ranipet",
        "Salem",
        "Sivaganga",
        "Tenkasi",
        "Thanjavur",
        "Theni",
        "The Nilgiris",
        "Thiruvallur",
        "Thiruvarur",
        "Thoothukkudi",
        "Tiruchirappalli",
        "Tirunelveli",
        "Tirupathur",
        "Tiruppur",
        "Tiruvannamalai",
        "Vellore",
        "Viluppuram",
        "Virudhunagar"
    ],
    'Tripura': [
        "Dhalai",
        "Gomati",
        "Khowai",
        "North Tripura",
        "Not Known",
        "Sepahijala",
        "South Tripura",
        "Unakoti",
        "West Tripura"
    ],
    'Uttar Pradesh': [
        "Agra",
        "Aligarh",
        "Allahabad",
        "Ambedkar Nagar",
        "Amethi",
        "Auraiya",
        "Azamgarh",
        "Badaun",
        "Baghpat",
        "Bahraich",
        "Ballia",
        "Balrampur",
        "Banda",
        "Barabanki",
        "Bareilly",
        "Basti",
        "Bijnor",
        "Bulandshahr",
        "Chandauli",
        "Chitrakoot",
        "Deoria",
        "Etah",
        "Etawah",
        "Faizabad",
        "Farrukhabad",
        "Fatehpur",
        "Firozabad",
        "Gautam Buddha Nagar",
        "Ghaziabad",
        "Ghazipur",
        "Gonda",
        "Gorakhpur",
        "Hamirpur",
        "Hardoi",
        "Jalaun",
        "Jaunpur",
        "Jhansi",
        "Jyotiba Phule Nagar",
        "Kannauj",
        "Kanpur",
        "Kanshiram Nagar",
        "Kaushambi",
        "Kushinagar",
        "Lakhimpur Kheri",
        "Lalitpur",
        "Lucknow",
        "Mahamya Nagar",
        "Maharajganj",
        "Mahoba",
        "Mainpuri",
        "Mathura",
        "Mau",
        "Meerut",
        "Mirzapur",
        "Moradabad",
        "Muzaffarnagar",
        "Not Known",
        "Panchsheel Nagar",
        "Pilibhit",
        "Pratapgarh",
        "Raebareli",
        "Ramabai Nagar",
        "Rampur",
        "Saharanpur",
        "Sambhal",
        "Sant Kabir Nagar",
        "Sant Ravidas Nagar",
        "Shahjahanpur",
        "Shamli",
        "Siddharthnagar",
        "Sitapur",
        "Sonbhadra",
        "Srawasti",
        "Sultanpur",
        "Unnao",
        "Varanasi"
    ],
    // 'West Pradesh': [],
    'West Bengal': [
        "Alipurduar",
        "Bankura",
        "Birbhum",
        "Darjiling",
        "East Burdwan",
        "East Midnapore",
        "Howrah",
        "Hooghly",
        "Jalpaiguri",
        "Jhargram",
        "Kalimpong",
        "Koch Bihar",
        "Kolkata",
        "Malda",
        "Murshidabad",
        "Nadia",
        "North 24 Parganas",
        "North Dinajpur",
        "Not Known",
        "Purba Bardhaman",
        "Puruliya",
        "South 24 Parganas",
        "South Dinajpur",
        "Sundarban",
        "West Burdwan",
        "West Midnapore"
    ],
    // 'Dadra Nagar Haveli': [],
    'Lakshadweep': [
        "Kavaratti",
        "Not Known"
    ],
    'Telangana': [
        "Adilabad",
        "Bhadradri Kothagudem",
        "Hyderabad",
        "Jagitial",
        "Jayashankar Bhupalapally",
        "Jogulamba Gadwal",
        "Kamareddy",
        "Karim Nagar",
        "Khammam",
        "Kumurambheem Asifabad",
        "Mahabubabad",
        "Mahbubnagar",
        "Medak",
        "Mulugu",
        "Nagarkurnool",
        "Nalgonda",
        "Narayanpet",
        "Nirmal",
        "Nizamabad",
        "Not Known",
        "Peddapalli",
        "Rajanna Sircilla",
        "Rangareddi",
        "Sangareddy",
        "Siddipet",
        "Suryapet",
        "Vikarabad",
        "Wanaparthy",
        "Warangal"
    ],
    'Uttarakhand': [
        "Almora",
        "Bageshwar",
        "Chamoli",
        "Champawat",
        "Dehradun",
        "Haridwar",
        "Nainital",
        "Not Known",
        "Pauri Garhwal",
        "Pithoragarh",
        "Rudraprayag",
        "Tehri Garhwal",
        "Udham Singh Nagar",
        "Uttarkashi"
    ],
    'Ladakh': [
        "Kargil",
        "Leh"
    ],
    'Dadra Nagar Haveli and Daman and Diu': [
        "Daman",
        "Diu",
        "Not Known",
        "Silvassa"
    ]
}

export default { INDIAN_STATES, INDIAN_DISTRICTS }