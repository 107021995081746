<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.2 24C14.7284 24 14.3579 23.8546 14.0884 23.5637C13.8189 23.2728 13.6842 22.8756 13.6842 22.3722C13.6842 21.8575 13.8133 21.1639 14.0716 20.2912C14.3411 19.4074 14.6049 18.6074 14.8632 17.8914C15.1326 17.1754 15.2674 16.6999 15.2674 16.465C15.2674 16.2188 15.1888 16.0958 15.0316 16.0958C14.6386 16.0958 14.0042 16.8677 13.1284 18.4116L12 17.7572C13.6505 14.9826 15.1663 13.5953 16.5474 13.5953C17.4905 13.5953 17.9621 14.1882 17.9621 15.3741C17.9621 15.721 17.8498 16.2748 17.6253 17.0355L16.4126 20.4926C16.2891 20.817 16.2274 21.1135 16.2274 21.382C16.2274 21.6394 16.3284 21.768 16.5305 21.768C16.9123 21.768 17.614 21.0632 18.6358 19.6535L19.3432 20.308C18.7368 21.4268 18.0126 22.3218 17.1705 22.9931C16.3396 23.6644 15.6828 24 15.2 24ZM20 8.64462C20 9.09214 19.8484 9.50049 19.5453 9.86969C19.2421 10.2389 18.8323 10.4235 18.3158 10.4235C17.7993 10.4235 17.4119 10.2725 17.1537 9.97038C16.8954 9.65712 16.7663 9.26555 16.7663 8.79566C16.7663 8.32577 16.9235 7.91181 17.2379 7.5538C17.5635 7.1846 17.9789 7 18.4842 7C18.9895 7 19.3656 7.16222 19.6126 7.48667C19.8709 7.79993 20 8.18592 20 8.64462Z"
            fill="#0057FF"/>
        <circle cx="16" cy="16" r="15" stroke="#0057FF" stroke-width="2"/>
    </svg>
</template>

<script>
export default {
    name: "VisardMessage"
}
</script>

<style scoped lang="sass">

</style>