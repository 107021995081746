<template>
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.2 2.4H12.8V13.6H3.2V2.4H4.8V1.6C4.8 1.17565 4.96857 0.768687 5.26863 0.468629C5.56869 0.168571 5.97565 0 6.4 0H9.6C10.0243 0 10.4313 0.168571 10.7314 0.468629C11.0314 0.768687 11.2 1.17565 11.2 1.6V2.4ZM13.6 2.4H14.4C14.8243 2.4 15.2313 2.56857 15.5314 2.86863C15.8314 3.16869 16 3.57565 16 4V12C16 12.4243 15.8314 12.8313 15.5314 13.1314C15.2313 13.4314 14.8243 13.6 14.4 13.6H13.6V2.4ZM2.4 2.4V13.6H1.6C1.17565 13.6 0.768687 13.4314 0.468629 13.1314C0.168571 12.8313 0 12.4243 0 12V4C0 3.12 0.72 2.4 1.6 2.4H2.4ZM6.4 1.6V2.4H9.6V1.6H6.4Z"
            fill="#000000"/>
    </svg>
</template>

<script>
export default {
    name: "Case"
}
</script>

<style scoped lang="sass">
    path
        stroke: none !important
</style>