<template>
    <div class="input-search">
        <input
            id="searching"
            type="text"
            placeholder="Seach in applications"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
        >
        <label for="searching">
            <SearchInputIcon
                stroke="var(--primary)"
            />
        </label>
    </div>
</template>

<script>

import SearchInputIcon from "@/components/icons/SearchInputIcon.vue";

export default {
    components: {SearchInputIcon},
    props: {
        modelValue: {
            type: String,
            required: false,
            default: ''
        }
    }
}
</script>

<style scoped lang="sass">

.input-search
    position: relative

    input
        padding-right: 30px
        font-size: 14px
        outline: none
        width: 233px
        height: 34px
        padding-left: 16px
        transition: 0.2s
        font-family: var(--fonts)
        color: var(--main_black)
        font-weight: 400
        background: white
        border: 1px solid
        border-color: var(--border-form-input)
        border-radius: var(--border-radius-input)
        &:focus
            border-color: var(--primary) !important
        &:hover
            border-color: var(--description-text-darken)

    & .search-input-icon
        position: absolute
        top: 50%
        right: 14px
        transform: translateY(-50%)
</style>