<template>
    <Base>
        <section class="settings__container">
            <SettingsSidebar/>
            <router-view></router-view>
        </section>
    </Base>
</template>

<script>
import Base from "@/layouts/base.vue";
import SettingsSidebar from "@/components/elements/SettingsSidebar.vue";

export default {
    name: "settings",
    components: {SettingsSidebar, Base},
    created() {
        document.body.style.background = 'var(--white)'
    }
}
</script>

<style lang="sass">
.settings
    &__container
        margin: 0 auto 0
        max-width: var(--container-width)
        padding: 0 30px 30px
        display: flex
        min-height: calc(100vh - 80px)

    &__content
        width: 100%
        margin-left: 16px
        padding: 16px
        border-radius: 0

    &__item
        display: flex
        flex-direction: column

    &__label
        font-size: 17px

    &__input
        outline: none
        border: 1px solid var(--border-form-input)
        transition: 0.2s
        border-radius: 5px
        padding-left: 16px
        height: 35px
        display: block

        &:focus
            border: 1px solid var(--primary)

    &__btn
        background: var(--primary)
        border: 1px solid var(--primary)
        border-radius: 5px
        padding: 4px 16px
        cursor: pointer
        color: var(--white)
        margin-top: 16px

    &__select-container
        display: flex
        flex-direction: column
        padding-bottom: 16px
        border-bottom: 1px solid var(--main_black)
        margin-bottom: 16px

    &__select
        width: 100%
        margin-bottom: 16px
        padding-left: 10px
        border: 1px solid var(--border-form-input)
        height: 35px
        border-radius: 5px

.--link-active
    color: var(--primary) !important

</style>