import moment from "moment";


export class Validator {
    error_message = 'Not a valid'

    /**
     *  @abstract
     *  @returns {boolean}
     */
    validate(value) {
        throw new Error('Not implemented!');
    }

    check(value) {
        if (this.validate(value)) return [];
        return [this.error_message];
    }
}


export class NotEmptyString extends Validator {
    error_message = 'Cannot be empty string'

    validate(value) {
        return typeof value === 'string' && Boolean(value);
    }
}


export class BooleanString extends Validator {
    validate(value) {
        return ['Yes', 'No'].includes(value);
    }
}


export class ValidMomentDate extends Validator {
    error_message = 'Not a valid date'
    validate(value) {
        return typeof value === 'string' && moment(value, 'DD.MM.YYYY').isValid();
    }
}

export class NotEmptyArray extends Validator {
    error_message = 'Cannot be empty'
    validate(value) {
        return value instanceof Array && value.length;
    }
}


export class ValidAddress extends Validator {
    error_message = 'Not a valid address'

    validate(value) {
        return typeof value === 'object';
    }
}
