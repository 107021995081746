<template>
    <div class="form-fields-ui">
        String Field
        <div class="fields-row">
            <StringField v-model="stringField"/>
        </div>
        Boolean Field
        <div class="fields-row">
            <BooleanField v-model="booleanField"/>
        </div>
        Date Field
        <div class="fields-row">
            <DateField v-model="dateField"/>
        </div>
        Select Field
        <div class="fields-row">
            <SelectField v-model="selectField" :options="['Canada', 'United States', 'Russia', 'China', 'Georgia']" />
        </div>
        Multi Select Field
        <div class="fields-row">
            <MultiSelectField v-model="multiSelectField" :options="['Canada', 'United States', 'Russia', 'China', 'Georgia']" />
        </div>
        Country Field
        <div class="fields-row">
            <CountryField v-model="countryField" />
        </div>
        Multi Country Field
        <div class="fields-row">
            <MultiCountryField v-model="multiCountryField" />
        </div>
        Integer Field
        <div class="fields-row">
            <IntegerField v-model="integerField" />
        </div>
        Phone Field
        <div class="fields-row">
            <PhoneField v-model="phoneField" />
        </div>
        Multi String Field
        <div class="fields-row">
            <MultiStringField v-model="multiStringField" />
        </div>
        Address String Field
        <div class="fields-row">
            <AddressStringField v-model="addressStringField" />
        </div>
        Address Field
        <div class="fields-row">
            <AddressField v-model="addressField" />
        </div>
        {{ addressField }}

        Address Field 2
        <div class="fields-row">
            <AddressField v-model="addressField2" />
        </div>
        {{ addressField2 }}
        <hr>

        Photo field
        <div class="fields-row">
            <PhotoField v-model="photoField" />
        </div>

        <span style="word-break: break-word">{{ photoField }}</span>

        <div class="fields-row" v-for="field in fields">
            <FormField
                :key="field.name"
                :label="field.label"
                :type="field.type"
                :fields="field.fields"
                v-model="answers[field.name]"
            />
        </div>

        <pre>{{ answers }}</pre>


        <button class="form-fields-ui__check" @click.prevent="checkValueFields">Click Check Field (watch logs)</button>
    </div>
</template>

<script>
import StringField from "@/components/fields/fillingPage/StringField.vue";
import BooleanField from "@/components/fields/fillingPage/BooleanField.vue";
import DateField from "@/components/fields/fillingPage/DateField.vue";
import SelectField from "@/components/fields/fillingPage/SelectField.vue";
import MultiSelectField from "@/components/fields/fillingPage/MultiSelectField.vue";
import CountryField from "@/components/fields/fillingPage/CountryField.vue";
import MultiCountryField from "@/components/fields/fillingPage/MultiCountryField.vue";
import IntegerField from "@/components/fields/fillingPage/IntegerField.vue";
import PhoneField from "@/components/fields/fillingPage/PhoneField.vue";
import MultiStringField from "@/components/fields/fillingPage/MultiStringField.vue";
import AddressStringField from "@/components/fields/fillingPage/AddressStringField.vue";
import AddressField from "@/components/fields/fillingPage/AddressField.vue";
import FormField from "@/components/fields/FormField.vue";
import PhotoField from "@/components/fields/fillingPage/PhotoField.vue";


const MOCK_FIELDS = [
    {
        name: 'surname',
        label: 'Surname',
        type: 'String',
        validators: [
            {
                name: 'Only8Symbols',
            }
        ],
    },
    {
        name: 'first_name',
        label: 'First Name',
        type: 'String',
        validators: [
            {
                name: 'Only8Symbols',
            }
        ],
    },
    {
        name: 'some_nested',
        label: 'Some nested field',
        type: 'Nested',
        fields: [
            {
                name: 'surname',
                label: 'Surname',
                type: 'String',
                validators: [
                    {
                        name: 'Only8Symbols',
                    }
                ],
            },
            {
                name: 'first_name',
                label: 'First Name',
                type: 'String',
                validators: [
                    {
                        name: 'Only8Symbols',
                    }
                ],
            },
            {
                name: 'is_a_human',
                label: 'Is a human?',
                type: 'Boolean',
            },
        ],
        validators: [],
    },
    // {
    //     name: 'work_address',
    //     label: 'Work address',
    //     type: 'Address',
    //     validators: [],
    // },
    // {
    //     name: 'home_address',
    //     label: 'Home address',
    //     type: 'Address',
    //     validators: [],
    // },
    {
        name: 'country',
        label: 'Country',
        type: 'Country',
        validators: [],
    },
    {
        name: 'multi_country',
        label: 'Multi Country',
        type: 'MultiCountry',
        validators: [],
    },
    {
        name: 'select',
        label: 'Select',
        type: 'Select',
        validators: [],
    },
    {
        name: 'photo',
        label: 'Photo',
        type: 'Photo',
        validators: [],
    },
]

export default {
    components: {
        PhotoField,
        FormField,
        AddressField,
        MultiStringField,
        PhoneField,
        MultiCountryField,
        CountryField,
        MultiSelectField,
        SelectField,
        DateField,
        BooleanField,
        StringField,
        IntegerField,
        AddressStringField,
    },
    data() {
        return {
            photoField: null,
            stringField: null,
            booleanField: null,
            dateField: null,
            selectField: null,
            multiSelectField: null,
            countryField: null,
            multiCountryField: null,
            integerField: null,
            phoneField: null,
            multiStringField: null,
            addressStringField: null,
            addressField: {},
            addressField2: {},

            fields: MOCK_FIELDS,
            answers: {},
        }
    },
    methods: {
        checkValueFields() {
            for (const d in this.$data) {
                console.log(`${d}: ${this.$data[d]}`)
            }
            console.log(this.addressField)
            this.addressField = {
                postcode: 'postcode',
                country: 'ind',
                city: 'city 22',
                state: 'state',
                street: 'street',
                house: 'house',
                district: 'dsc'
            }
        }
    },
}
</script>

<style lang="sass">

.form-fields-ui
    margin: 0 auto
    max-width: 900px
    width: 100%
    padding: 32px
    background: white
    min-height: 100vh

    &__check
        position: absolute
        right: 16px
        top: 16px
        border: none
        background: var(--primary)
        color: var(--main_black)
        padding: 16px

</style>