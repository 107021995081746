<template>
  <div class="form-field"
       :class="{
             '--error': allErrors.length,
         }">
    <div class="form-field__label">
      {{ label }}
    </div>
    <div class="form-field__input-and-errors">
      <div class="form-field__input">
        <AddressField
          v-if="type === 'Address'"
          v-bind="$props"
          v-model="selectedValue"
          :country="addressFields.country"
          :postcode="addressFields.postcode"
          :city="addressFields.city"
          :state="addressFields.state"
          :district="addressFields.district"
          :street="addressFields.street"
          :house="addressFields.house"
          :country-only="addressCountryOnly"
        />
        <AddressStringField
          v-if="type === 'AddressString'"
          v-bind="$props"
          v-model="selectedValue"/>
        <BooleanField
          v-if="type === 'BooleanString'"
          v-bind="$props"
          v-model="selectedValue"/>
        <CountryField
          v-if="type === 'Country'"
          :choices="choices"
          v-bind="$props"
          v-model="selectedValue"/>
        <DateField
          v-if="type === 'Date'"
          v-bind="$props"
          v-model="selectedValue"/>
        <IntegerField
          v-if="type === 'Integer'"
          v-bind="$props"
          v-model="selectedValue"/>
        <MultiCountryField
          v-if="type === 'MultiCountry'"
          v-bind="$props"
          v-model="selectedValue"/>
        <MultiSelectField
          v-if="type === 'MultiSelect'"
          v-bind="$props"
          v-model="selectedValue"/>
        <MultiStringField
          v-if="type === 'StringArray'"
          v-bind="$props"
          v-model="selectedValue"/>
        <PhoneField
          v-if="type === 'Phone'"
          v-bind="$props"
          v-model="selectedValue"/>
        <SelectField
          v-if="type === 'Select'"
          v-bind="$props"
          v-model="selectedValue"/>
        <StringField
          v-if="type === 'String'"
          v-bind="$props"
          v-model="selectedValue"/>
        <NestedField
          :show-required-errors="showRequiredErrors"
          v-if="type === 'Nested'"
          v-bind="$props"
          :fields="fields"
          v-model="selectedValue"/>
        <PhotoField
          v-if="type === 'Photo'"
          :field-name="fieldName"
          :section-name="sectionName"
          :questionnaire-id="questionnaireId"
          @updatePhoto="$emit('updatePhoto', $event)"
          v-bind="$props"
          v-model="selectedValue"/>
        <FileField
          v-if="type === 'File'"
          :field-name="fieldName"
          :section-name="sectionName"
          :questionnaire-id="questionnaireId"
          @updatePhoto="$emit('updateFile', $event)"
          v-bind="$props"
          v-model="selectedValue"/>
        <MultiCountryField
          v-if="type === 'CountryArray'"
          v-bind="$props"
          v-model="selectedValue"
        />
      </div>
      <div class="form-field__errors" v-if="allErrors.length && type !== 'Nested'">
        <div class="form-field__error" v-for="error in allErrors">{{ _transformErrorMessage(error) }}</div>
      </div>
    </div>
  </div>
</template>

<script>

import AddressField from "@/components/fields/fillingPage/AddressField.vue";
import AddressStringField from "@/components/fields/fillingPage/AddressStringField.vue";
import BooleanField from "@/components/fields/fillingPage/BooleanField.vue";
import CountryField from "@/components/fields/fillingPage/CountryField.vue";
import DateField from "@/components/fields/fillingPage/DateField.vue";
import IntegerField from "@/components/fields/fillingPage/IntegerField.vue";
import MultiCountryField from "@/components/fields/fillingPage/MultiCountryField.vue";
import MultiSelectField from "@/components/fields/fillingPage/MultiSelectField.vue";
import MultiStringField from "@/components/fields/fillingPage/MultiStringField.vue";
import PhoneField from "@/components/fields/fillingPage/PhoneField.vue";
import SelectField from "@/components/fields/fillingPage/SelectField.vue";
import StringField from "@/components/fields/fillingPage/StringField.vue";
import {defineAsyncComponent} from "vue";
import {NotEmptyArray, NotEmptyString, ValidAddress, ValidMomentDate} from "@/validators";
import PhotoField from "@/components/fields/fillingPage/PhotoField.vue";
import ERRORS_MESSAGES from "@/errors";
import FileField from "@/components/fields/fillingPage/FileField.vue";


const VALIDATORS = {
  Address: [ValidAddress],
  AddressString: [NotEmptyString],
  Country: [NotEmptyString],
  Date: [ValidMomentDate],
  Integer: [],
  MultiCounty: [NotEmptyArray],
  MultiSelect: [NotEmptyArray],
  MultiString: [NotEmptyArray],
  Nested: [NotEmptyArray],
  Phone: [],
  Select: [],
  String: [NotEmptyString],
}


export default {
  props: {
    fieldName: String,
    sectionName: String,
    questionnaireId: Number,
    showRequiredErrors: Boolean,
    type: String,
    fields: {
      type: Array,
      required: false,
    },
    label: String,
    choices: {
      type: Array,
      required: false,
    },
    modelValue: {
      required: true,
    },
    errors: {
      type: [Array, Object],
      default: [],
    },
    addressFields: Object,
    addressCountryOnly: String,
  },
  data: () => ({
    self_errors: [],
    validators: [],
  }),
  methods: {
    _transformErrorMessage(error) {
      for (const message in ERRORS_MESSAGES) {
        if (message === error) {
          return ERRORS_MESSAGES[message]
        }
      }
      return error
    },
    validate(value) {
      let errors = [];
      for (const validator of this.validators) {
        const validatorErrors = validator.check(value);
        errors = [...errors, ...validatorErrors];
      }
      this.self_errors = errors;
    }
  },
  mounted() {
    const fieldTypeValidators = VALIDATORS[this.type] || [];
    for (const validator of fieldTypeValidators) {
      this.validators.push(new validator());
    }
  },
  computed: {
      selectedValue: {
          get() {
              return this.modelValue
          },
          set(newValue) {
              this.$emit('update:modelValue', newValue)
              this.validate(newValue);
              if (!this.self_errors.length) {
                  this.$emit('validValue', newValue);
              }
          }
      },
      allErrors() {
          const result = [...this.self_errors];
          for (const errorKey in this.errors) {
              const error = this.errors[errorKey];
              if (error === 'REQUIRED') {
                  if (this.showRequiredErrors) result.push(error);
              } else {
                  result.push(error);
              }
          }
          return result;
      },
  },
    components: {
      FileField,
        PhotoField,
        AddressField,
        AddressStringField,
        BooleanField,
        CountryField,
        DateField,
        IntegerField,
        MultiCountryField,
        MultiSelectField,
        MultiStringField,
        PhoneField,
        SelectField,
        StringField,
        NestedField: defineAsyncComponent(() => import("@/components/fields/fillingPage/NestedField.vue")),
    },
}

</script>

<style lang="sass">
.form-field
    width: 100%
    margin-top: var(--margin-row-field)
    display: var(--form-field-display)

    &:first-child
        margin-top: 0

    &__label
        color: var(--label-color)
        width: var(--width-label)
        margin-right: var(--label-margin-r)

    &__input-and-errors
        width: var(--width-input)

    &__errors
        border: 1px solid var(--error_red)
        border-top: 0
        border-bottom: 0
        width: 100%
        border-radius: 0 0 var(--border-radius-input) var(--border-radius-input)
        overflow: hidden

    &__error
        width: 100%
        background: var(--error)
        padding: 2px 8px
        border-bottom: 1px solid var(--error_red)

        &:last-child
            border-radius: 0 0 var(--border-radius-input) var(--border-radius-input)

    &.--error
        border-color: var(--error_red) !important
        color: var(--error_red) !important

        svg
            border-color: var(--error_red) !important

        .phone-field > .v-select > .vs__dropdown-toggle
            border-radius: var(--border-radius-input) 0 0 0 !important

        .phone-field > .phone-input
            border-radius: 0 var(--border-radius-input) 0 0 !important


        & .vs
            &__search
                background: none !important
                border: none !important

            &__dropdown-toggle
                background: var(--error)
                border-radius: var(--border-radius-input) var(--border-radius-input) 0 0 !important
                border: 1px solid var(--error_red) !important

            &__open-indicator
                background: none !important

            &__selected
                color: var(--error_red) !important

        & .address-field
            border-color: var(--error_red) !important
            border-radius: 3px 3px 0 0 !important

            &__input
                border: none

        & .nested-field
            & .address-field
                border: none !important
                &__input
                    input
                        border-radius: 0 !important
                & .vs__dropdown-toggle
                    border-radius: 0 !important
                & .vs__clear
                    background-color: var(--error)

        input
            background: var(--error) !important
            border-color: var(--error_red) !important
            border-radius: var(--border-radius-input) var(--border-radius-input) 0 0 !important

</style>
