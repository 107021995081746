import {createRouter, createWebHistory} from 'vue-router'

import FormSelect from "@/pages/form/form_select.vue";
import Questionnaires from "@/pages/personal_area/questionnaires/questionnaires.vue";
import Questionnaire from "@/pages/personal_area/questionnaires/questionnaire.vue";
import Check from "@/pages/form/check.vue";
import FillingPage from "@/pages/form/filling_page.vue";
import FormSuccess from "@/pages/form/form_success.vue";
import Auth from "@/pages/auth/auth.vue";
import FormFieldsUi from "@/pages/form-fields-ui.vue";
import IconPack from "@/pages/icon-pack.vue";
import Notifications from "@/pages/personal_area/notifications.vue";

// Policies
import Imprint from "@/pages/policies/imprint.vue";
import Agb from "@/pages/policies/agb.vue";
import DataProtection from "@/pages/policies/data_protection.vue";
import CancellationPolicy from "@/pages/policies/cancellation_policy.vue";

// Settings
import ApiSettings from "@/pages/personal_area/settings/api_settings.vue";
import Webhooks from "@/pages/personal_area/settings/webhooks.vue";
import PersonalInformation from "@/pages/personal_area/settings/personal_information.vue";
import Credentials from "@/pages/personal_area/settings/credentials.vue";

// Layouts
import SettingsLayout from "@/layouts/settings_layout.vue";
import PoliciesLayout from "@/layouts/policies_layout.vue";

const routes = [
    {
        path: '/',
        name: 'questionnaires',
        component: Questionnaires,
        meta: {
            title: 'Questionnaires - VisaEase'
        }
    },
    {
        path: '/form-select/',
        name: 'formSelect',
        component: FormSelect,
        meta: {title: 'Country selection - VisaEase'}
    },
    {
        path: '/policies/',
        name: 'policies',
        meta: {title: 'Confidentiality policy - VisaEase'},
        component: PoliciesLayout,
        children: [
            {path: 'agb', name: 'agb', component: Agb, meta: {title: 'AGB - VisaEase'}},
            {path: 'impressum', name: 'impressum', component: Imprint, meta: {title: 'Imprint - VisaEase'}},
            {path: 'datenschutz', name: 'datenschutz', component: DataProtection, meta: {title: 'Data Protection - VisaEase'}},
            {path: 'widerrufsbelehrung', name: 'widerrufsbelehrung', component: CancellationPolicy, meta: {title: 'Cancellation Policy - VisaEase'}},
        ]
    },
    {
        path: '/settings/',
        name: 'settings',
        component: SettingsLayout,
        meta: {title: 'Settings - VisaEase'},
        children: [
            {path: 'api_key_settings', name: 'api_key_settings', component: ApiSettings, meta: {title: 'API - VisaEase'}},
            {path: 'webhooks', name: 'webhooks', component: Webhooks, meta: {title: 'Webhooks - VisaEase'}},
            {path: 'personal_information', name: 'personal_information', component: PersonalInformation, meta: {title: 'Personal Information - VisaEase'}},
            {path: 'credentials', name: 'credentials', component: Credentials, meta: {title: 'Credentials - VisaEase'}},
        ]
    },
    {
        path: '/check/:questionnaireId',
        name: 'check',
        component: Check,
        meta: {title: 'Data Verification - VisaEase'}
    },
    {
        path: '/filling_page/:questionnaireId',
        name: 'filling_page',
        component: FillingPage,
        meta: {title: 'Filling page #:questionnaireId - VisaEase'}
    },
    {
        path: '/filling_page_iframe/:accessToken',
        name: 'filling_page_iframe',
        component: FillingPage,
        meta: {title: 'Iframe filling page #:questionnaireId - VisaEase'}
    },
    {
        path: '/form_success/',
        name: 'form_success',
        component: FormSuccess,
        meta: {title: 'Form success - VisaEase'}
    },
    {
        path: '/auth/',
        name: 'auth',
        component: Auth,
        meta: {title: 'Authorization - VisaEase'}
    },
    {
        path: '/questionnaire/:questionnaireId',
        name: 'questionnaire',
        component: Questionnaire,
        meta: {title: 'Questionnaire #:questionnaireId - VisaEase'}
    },
    {
        path: '/form-fields-ui/',
        name: 'form-fields-ui',
        component: FormFieldsUi,
        meta: {title: 'Form field UI - VisaEase'}
    },
    {
        path: '/icon-pack/',
        name: 'icon-pack',
        component: IconPack,
        meta: {title: 'Icon Pack - VisaEase'}
    },
    {
        path: '/notifications/',
        name: 'notifications',
        component: Notifications,
        meta: {title: 'Notifications - VisaEase'}
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {

    if (to.name === 'filling_page' || to.name === 'questionnaire') {
        document.title = to.meta.title.replace(':questionnaireId', to.params.questionnaireId);
    } else {
        document.title = to.meta.title || 'Visa Ease';
    }
    next();
});

export default router
