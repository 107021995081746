<template>
    <VueDatePicker
        placeholder="DD.MM.YYYY"
        text-input
        auto-apply
        format="dd.MM.yyyy"
        class="input-date"
        v-model="selectedValue"
    ></VueDatePicker>
</template>

<script>

import moment from "moment";

export default {
    props: {
        validators: {
            type: Array,
            default: []
        },
        modelValue: String
    },
    computed: {
        selectedValue: {
            get() {
                return moment(this.modelValue, 'DD.MM.YYYY').toDate();
            },
            set(newValue) {
                console.log('NEW VALUE', newValue)
                this.$emit(
                    'update:modelValue',
                    moment(newValue).format('DD.MM.YYYY'),
                )
            }
        }
    }
};
</script>

<style lang="sass">
.input-date
    svg
        transition: 0.2s
    &:hover
        svg
            border-color: var(--description-text)
    &:focus-within
        svg
            border-color: var(--primary)
    .dp__input_icon
        height: 100%
        padding-top: 0
        padding-bottom: 0
        border-right: 1px solid var(--border-form-input)

    .dp__button_bottom
        display: none

    .dp__range_end, .dp__range_start, .dp__active_date, .dp__overlay_cell_active
        background: var(--primary)

    .dp__today
        border-color: var(--primary)

    .dp__calendar_header_item
        color: var(--primary)
        font-weight: 400

    .dp--menu-wrapper
        left: 0 !important

    input
        width: 100%
        height: var(--input-height)
        background: var(--background-input)
        border: 1px solid
        border-color: var(--border-form-input)
        padding-right: 8px
        outline: none
        font-size: 16px
        color: inherit
        transition: 0.2s
        border-radius: var(--border-radius-input)
        padding-left: 52px

        &:hover
            border-color: var(--description-text)

        &:focus
            border-color: var(--primary)

    &-valid
        input
            background: var(--primary-lighten) !important
            border-color: var(--primary) !important

        svg
            path
                fill: var(--primary) !important

    &-invalid
        input
            background: var(--error) !important
            border-color: var(--error_red) !important
            border-radius: 3px 3px 0 0
            color: var(--error_red)

        svg
            path
                fill: var(--error_red) !important
</style>
