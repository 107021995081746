<template>
    <header class="header" id="header">
        <div class="header__inner">
            <div class="header__logo">
                <router-link tag="a" to="/">
                    <img src="../../assets/images/logo.svg" alt="">
                </router-link>
            </div>
            <div class="header__actions">
                <span class="header__switch-label">Toggle theme</span>
                <Switcher class="header__switch" v-model="showInPartnerDesign" name="toggleDesign" />
                <router-link tag="a" to="/form-select/" class="btn-add-small"><span></span></router-link>
                <div class="header__dropdown">
                    <div class="header__dropdown-icon">
                        <NotificationIcon fill="var(--header-icons)"/>
                    </div>
                    <div class="header__dropdown-notification">
                        <h4>Notifications</h4>
                        <ul class="header__dropdown-notification-list">
                            <li class="header__dropdown-notification-item">
                                <div class="header__dropdown-notification-item-content">
                                    <div class="header__dropdown-notification-item-country">
                                        <img src="@/assets/images/questionnaires/chn_selected.svg" alt="chn">
                                    </div>
                                    <div class="header__dropdown-notification-item-data">
                                        <div class="notification-data">
                                            <span style="color: var(--main_black)">Electronic visa</span>
                                            <span style="color: var(--primary)"> 1233 </span>
                                            <span style="color: var(--description-text)">(Mittermayer, Bettin) has been granted. </span>
                                        </div>
                                        <div class="header__dropdown-notification-item-time">Now</div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <router-link to="/notifications" class="header__dropdown-notification-link" >See all notifications</router-link>
                    </div>
                </div>
                <div class="header__dropdown">
                    <div class="header__dropdown-icon">
                        <ProfileIcon fill="var(--header-icons)"/>
                    </div>
                    <div class="header__dropdown-option">
                        <router-link class="header__dropdown-option-link" to="/notifications">Notifications</router-link>
                        <router-link class="header__dropdown-option-link" to="/settings/personal_information">Settings
                        </router-link>
                        <button class="header__dropdown-option-link">
                            <LogoutIcon
                                stroke="var(--header-icons)"
                            />
                            Logout
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </header>

</template>

<script>
import NotificationIcon from "@/components/icons/header/NotificationIcon.vue";
import ProfileIcon from "@/components/icons/header/ProfileIcon.vue";
import LogoutIcon from "@/components/icons/LogoutIcon.vue";
import Switcher from "@/components/fields/Switcher.vue";

export default {
    components: {
        LogoutIcon,
        NotificationIcon,
        ProfileIcon,
        Switcher
    },
    data: () => ({
        isShowProfile: false,
        isShowNotification: false
    }),
    computed: {
        showInPartnerDesign: {
            get() { return this.$store.state.showInPartnerDesign },
            set(v) {
                this.$store.commit('SHOW_IN_PARTNER_DESIGN', v)
                this.$services.questionnaires.setShowInPartnerDesign(v)
            }
        },
    },
}
</script>

<style lang="sass">
.header
    padding: 10px 0
    background: var(--white)
    width: 100%
    position: sticky
    top: 0
    z-index: 100
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08)

    &__logo
        height: 24px

    &__inner
        width: 100%
        max-width: var(--container-width)
        padding: 0 30px
        margin: 0 auto
        display: flex
        justify-content: space-between
        align-items: center

    &__actions
        display: flex
        align-items: center
    &__switch
        margin-right: 32px
        &-label
            color: var(--description-text)
            margin-right: 8px

    &__dropdown
        padding: 18px 0 18px 24px
        position: relative
        overflow: hidden

        &:hover
            overflow: initial
            .header__dropdown-option,
            .header__dropdown-notification
              z-index: 10
              opacity: 1

        &-icon
            width: 24px
            height: 24px
            display: flex
            align-items: center
            justify-content: center
            border: 1px solid
            border-color: var(--header-icons)
            border-radius: 100%
            cursor: pointer
            transition: 0.2s
            &:hover
                border-color: var(--description-text-darken)
                svg
                    transition: 0.2s
                    path
                        fill: var(--description-text-darken)

        &-option
            position: absolute
            top: 100%
            right: 0
            background: var(--white)
            border: 1px solid var(--paginator-active)
            width: 120px
            padding: 12px 0
            z-index: -1
            opacity: 0
            transition: 0.2s

            &-link
                padding-left: 12px
                border: none
                background: none
                font-size: 14px
                display: flex
                align-items: center
                margin-top: 12px
                font-family: var(--fonts)
                color: var(--main_black)

                &:first-child
                    margin-top: 0

                svg
                    margin-right: 8px

        &-notification
            background: var(--white)
            border: 1px solid var(--paginator-active)
            padding-top: 16px
            padding-bottom: 20px
            position: absolute
            top: 100%
            right: 0
            width: 400px
            z-index: -1
            opacity: 0
            transition: 0.2s
            h4
                font-size: 16px
                font-weight: 500
                padding-left: 24px
            &-list
                list-style-type: none
                padding: 0
                margin-top: 24px
                margin-bottom: 18px
            &-item
                padding: 16px 24px
                position: relative
                &:after
                    content: ''
                    display: block
                    width: 88%
                    height: 1px
                    background: var(--paginator-active)
                    position: absolute
                    left: 50%
                    transform: translateX(-50%)
                    bottom: 0
                &-content
                    display: flex
                    align-items: flex-start
                    justify-content: space-between
                &-country
                    margin-top: 6px
                    border: 6px solid var(--primary)
                    border-radius: 100%
                    width: 24px
                    height: 24px
                    margin-right: 16px
                    display: flex
                    align-items: center
                    justify-content: center
                    img
                        width: 20px
                        height: 20px
                &-data
                    & .notification-data
                        font-size: 14px
                        font-weight: 500
                &-time
                    color: var(--description-text)
                    margin-top: 8px
            &-link
                margin-left: 24px
                color: var(--description-text)


</style>