<template>
  <Base>
    <div class="container questionnaires">
      <h2>Applications</h2>
      <form action="" method="post">
        <table cellspacing="1" rules="rows" class="questionnaires__table">
          <caption class="questionnaires__caption">
            <div class="questionnaires__caption-container">
              <div class="questionnaires__caption-type-filter">
                <a
                  @click.prevent="type_visa = 'all'"
                  :class="{'questionnaires__caption-type-filter-active': type_visa === 'all'}"
                  href=""
                >All Applications</a>
                <a
                  @click.prevent="type_visa = 'evisa'"
                  href=""
                  :class="{'questionnaires__caption-type-filter-active': type_visa === 'evisa'}"
                >eVisas</a>
                <a
                  @click.prevent="type_visa = 'applications'"
                  href=""
                  :class="{'questionnaires__caption-type-filter-active': type_visa === 'applications'}"
                >Applications</a>
                <a
                  @click.prevent="type_visa = 'NOT_FILLED'"
                  href=""
                  :class="{'questionnaires__caption-type-filter-active': type_visa === 'NOT_FILLED'}"
                >Drafts</a>
              </div>
              <div class="questionnaires__filters">
                <InputSearch
                  style="margin-right: 32px;"
                  v-model="search"
                />
                <div class="questionnaires__select-filter">
                  <v-select
                    class="select-filter"
                    :options="destinationFilterOptions"
                    :reduce="(option) => option.value"
                    placeholder="Destination"
                    v-model="destination"
                  >
                    <template #search="{ attributes, events }">
                      <div
                        class="select-filter__search"
                        :class="{'select-filter__search-hide': destination}"
                      >
                        <Map/>
                        <input
                          maxlength="1"
                          class="vs__search"
                          v-bind="attributes"
                          v-on="events"
                        />
                      </div>
                    </template>
                    <template #selected-option="value">
                      <div class="select-filter__selected">
                        <img
                          :src="require(`@/assets/images/questionnaires/${value.value}_selected.svg`)"
                          alt="">
                        {{ value.label }}
                      </div>
                    </template>
                    <template #option="option">
                      <div class="select-filter__option">
                        <img
                          :src="require(`@/assets/images/questionnaires/${option.value}.svg`)"
                          alt="">
                        {{ option.label }}
                      </div>
                    </template>
                  </v-select>
                </div>
                <div style="width: 210px;" class="questionnaires__select-filter">
                  <v-select
                    class="select-filter"
                    :options="statusFilterOptions"
                    placeholder="Status"
                    v-model="status"
                  >
                    <template #search="{ attributes, events }">
                      <div
                        class="select-filter__search"
                        :class="{'select-filter__search-hide': status}"
                      >
                        <StatusIcon/>
                        <input
                          maxlength="1"
                          class="vs__search"
                          v-bind="attributes"
                          v-on="events"
                        />
                      </div>
                    </template>
                    <template #selected-option="value">
                      <div
                        class="select-filter__selected"
                      >
                                                <span style="margin-right: 7px"
                                                      :class="documentStatusColor(value.label, false, false)"
                                                      class="select-filter__status-circle"></span>
                        {{ documentStatusDictionary[value.label] }}
                      </div>
                    </template>
                    <template #option="option">
                      <div
                        class="select-filter__option"
                      >
                                                <span style="margin-right: 7px;"
                                                      :class="documentStatusColor(option.label, false, false)"
                                                      class="select-filter__status-circle"></span>
                        {{ documentStatusDictionary[option.label] }}
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
          </caption>
          <thead>
          <tr>
            <!--                        <th style="padding-left: 24px; width: 60px;" class="questionnaires__th">-->
            <!--                            <Checkbox name="checkboxAll"/>-->
            <!--                        </th>-->
            <th style="width: 90px;" class="questionnaires__th">ID</th>
            <th style="width: 120px;" class="questionnaires__th">Created</th>
            <th style="width: 120px;" class="questionnaires__th">Destination</th>
            <th style="width: 120px;" class="questionnaires__th">Type</th>
            <th style="width: 270px;" class="questionnaires__th">Applicant</th>
            <th style="width: 100px;" class="questionnaires__th">Dob</th>
            <th style="width: 120px;" class="questionnaires__th">PasspOrt No.</th>
            <th style="width: 120px;" class="questionnaires__th">Status</th>
            <!--                        <th style="padding-right: 24px; max-width: 15px;" class="questionnaires__th"></th>-->
          </tr>
          </thead>
          <template v-if="questionnaires && questionnaires.length">
            <tbody>
            <tr @click="clickFieldRow(questionnaire.id)" v-for="questionnaire in questionnaires"
                :key="`quetionnaire_${questionnaire.id}`"
                class="questionnaires__tr">
              <!--                            <td style="padding-left: 24px;">-->
              <!--                                <Checkbox :name="`checkbox_${questionnaire.id}`"/>-->
              <!--                            </td>-->
              <td style="width: 90px; padding-right: 8px;" class="questionnaires__td">
                {{ questionnaire.id }}
                <span></span>
              </td>
              <td style="width: 120px;" class="questionnaires__td">{{
                  $filters.dateFilter(questionnaire.created, 'DD.MM.YYYY')
                }}
              </td>
              <td style="width: 120px;" class="questionnaires__td">
                <img
                  :src="require(`@/assets/images/questionnaires/${typeDocuments[questionnaire.document_type].iso3_code}.svg`)"
                  alt="">
                {{ typeDocuments[questionnaire.document_type].value }}
              </td>
              <td style="width: 120px;" class="questionnaires__td">
                {{ typeVisa(questionnaire.document_type) }}
              </td>
              <td style="width: 270px;" class="questionnaires__td">
                                <span v-if="questionnaire.answers">
                                    {{ fullName(questionnaire) }}
                                </span>
              </td>
              <td style="width: 100px;" class="questionnaires__td">
                                <span v-if="questionnaire.answers">
                                      {{ dateOfBirth(questionnaire) }}
                                </span>
              </td>
              <td style="width: 120px;" class="questionnaires__td">
                                <span v-if="questionnaire.answers">
                                      {{ passportNumber(questionnaire) }}
                                </span>
              </td>
              <td style="white-space: nowrap; width: 120px;" class="questionnaires__td">
                <div
                  class="questionnaires__td-status"
                >
                                    <span
                                      :class="documentStatusColor(questionnaire.filling_status, questionnaire.generation_status)"
                                      class="questionnaires__td-status-circle"></span>
                  {{ documentStatus(questionnaire.filling_status, questionnaire.generation_status) }}
                </div>
              </td>
              <!--                            <td class="questionnaires__td-links-container">-->
              <!--                                <a class="questionnaires__td-links" href="#">-->
              <!--                                    <div></div>-->
              <!--                                </a>-->
              <!--                                <div class="questionnaires__show-link">-->
              <!--                                    <router-link target="_blank"-->
              <!--                                                 :to="{-->
              <!--                                                    name: 'questionnaire',-->
              <!--                                                    params: {-->
              <!--                                                        questionnaireId: questionnaire.id,-->
              <!--                                                    }-->
              <!--                                                   }">-->
              <!--                                        Open questionnaire-->
              <!--                                    </router-link>-->
              <!--                                </div>-->
              <!--                            </td>-->
            </tr>
            </tbody>
          </template>
          <template v-else>
            <tr class="questionnaires__table-empty">
              <td>No questionnaires</td>
            </tr>
          </template>
        </table>
        <Paginator
          :pagination="{offset, total, limit}"
          @clickNextPage="offset = Number(offset) + limit < total ? (Number(offset) + limit) : offset"
          @clickPerPage="offset = offset > 0 ? (Number(offset) - Number(limit)) : 0"
          @clickStartPage="offset = 0"
          @clickEndPage="offset = Math.floor(total / limit) * limit"
          @clickPage="(page) => {offset = (page - 1) * limit}"
        />
      </form>
    </div>
  </Base>
</template>

<script>
import Base from "@/layouts/base.vue";
import Checkbox from "@/components/fields/Checkbox.vue";
import InputSearch from "@/components/fields/InputSearch.vue";
import typeDocuments from "@/dictionaries/type_documents";
import documentStatusDictionary from "../../../dictionaries/document_status";
import 'vue-select/dist/vue-select.css';
import vSelect from "vue-select";
import Map from "@/components/icons/Map.vue";
import StatusIcon from "@/components/icons/StatusIcon.vue";
import Paginator from "@/components/elements/Paginator.vue";

export default {
  name: "questionnaires",
  computed: {
    typeDocuments() {
      return typeDocuments
    },
    status: {
      get() {
        return this.$route.query.status || null
      },
      set(status) {
        if (status) {
          this.$router.push({query: {...this.$route.query, status: status}})
        } else {
          let query = {...this.$route.query};
          delete query['status'];
          this.$router.push({query});
        }
      }
    },
    offset: {
      get() {
        return this.$route.query.offset || 0
      },
      set(offset) {
        this.$router.push({query: {...this.$route.query, offset: offset}})
      }
    },
    limit: {
      get() {
        return this.$route.query.limit || 15
      },
      set(limit) {
        this.$router.push({query: {...this.$route.query, limit: limit}})
      }
    },
    search: {
      get() {
        return this.$route.query.search || ''
      },
      set(search) {
        if (search && search !== '') {
          this.$router.push({
            query: {
              ...this.$route.query,
              search: search
            }
          })
        } else {
          let query = {...this.$route.query};
          delete query['search'];
          this.$router.push({query});
        }
      }
    },
    destination: {
      get() {
        return this.$route.query.destination || null
      },
      set(destination) {
        if (destination) {
          this.$router.push({
            query: {
              ...this.$route.query,
              destination: destination
            }
          })
        } else {
          let query = {...this.$route.query};
          delete query['destination'];
          this.$router.push({query});
        }
      }
    },
    type_visa: {
      get() {
        return this.$route.query.type_visa || 'all'
      },
      set(type_visa) {
        this.$router.push({
          query: {
            ...this.$route.query,
            type_visa: type_visa
          }
        })
      }
    },
  },
  components: {Paginator, StatusIcon, Map, vSelect, InputSearch, Checkbox, Base},
  data: () => ({
    documentStatusDictionary,
    questionnaires: null,
    destinationFilterOptions: [
      {value: 'chn', label: 'China'},
      {value: 'ind', label: 'Indian'},
      {value: 'lka', label: 'Sri Lanka'},
      {value: 'sau', label: 'Saudi Arabia'},
      {value: 'uae', label: 'UAE'},
    ],
    statusFilterOptions: ['NOT_FULLY_FILLED', 'FILLED', 'IN_PROGRESS', 'ERROR', 'DONE', 'FAILED_TO_PAY'],
    total: null,

  }),
  methods: {
    documentStatus(fStatus, gStatus) {
      if (!gStatus) {
        return documentStatusDictionary[fStatus] || 'Not status'
      } else {
        return documentStatusDictionary[gStatus] || 'Not status'
      }
    },
    documentStatusColor(fStatus, gStatus = false) {
      let status
      if (gStatus) status = gStatus
      else status = fStatus
      return {
        'questionnaires__td-status-YELLOW': status === 'FILLED',
        'questionnaires__td-status-RED': status === 'ERROR' || status === 'FAILED_TO_PAY',
        'questionnaires__td-status-GREEN': status === 'DONE',
        'questionnaires__td-status-RED-LIGHT': status === 'IN_PROGRESS' || status === 'STARTED',
        'questionnaires__td-status-PRIMARY': status === 'GRANTED',
      }
    },
    clickFieldRow(questionnaire) {
      this.$router.push({
        name: 'questionnaire',
        params: {
          questionnaireId: questionnaire,
        }
      })
    },
    fullName(questionnaire) {
      if (questionnaire.document_type === 'CHINESE_VISA') {
        return `${questionnaire.answers.passport_data.surname || ''} ${questionnaire.answers.passport_data.name || ''}`
      } else if (questionnaire.document_type === 'INDIAN_E_VISA') {
        return `${questionnaire.answers.passport_data.surname || ''} ${questionnaire.answers.passport_data.name || ''}`
      } else if (questionnaire.document_type === 'SRI_LANKA_E_VISA' || questionnaire.document_type === 'UAE_E_VISA' || questionnaire.document_type === 'SAUDI_E_VISA') {
        return `${questionnaire.answers.applicant_data.surname || ''} ${questionnaire.answers.applicant_data.name || ''}`
      } else {
        return '[Warn] - Added new country in methods'
      }
    },
    dateOfBirth(questionnaire) {
      if (questionnaire.document_type === 'CHINESE_VISA') {
        return `${questionnaire.answers.passport_data.date_of_birth || ''}`
      } else if (questionnaire.document_type === 'INDIAN_E_VISA') {
        if (questionnaire.answers.hasOwnProperty('passport_data')) {
          return `${questionnaire.answers.passport_data.date_of_birth || ''}`
        } else {
          return ''
        }
      } else if (questionnaire.document_type === 'SRI_LANKA_E_VISA' || questionnaire.document_type === 'UAE_E_VISA' || questionnaire.document_type === 'SAUDI_E_VISA') {
        return `${questionnaire.answers.applicant_data.date_of_birth || ''}`
      } else {
        return '[Warn] - Added new country in methods'
      }
    },
    passportNumber(questionnaire) {
      if (questionnaire.document_type === 'CHINESE_VISA') {
        return `${questionnaire.answers.passport_data.passport_number || ''}`
      } else if (questionnaire.document_type === 'INDIAN_E_VISA') {
        if (questionnaire.answers.hasOwnProperty('passport_data')) {
          return `${questionnaire.answers.passport_data.passport_number || ''}`
        } else {
          return ''
        }
      } else if (questionnaire.document_type === 'SRI_LANKA_E_VISA' || questionnaire.document_type === 'UAE_E_VISA' || questionnaire.document_type === 'SAUDI_E_VISA') {
        return `${questionnaire.answers.applicant_data.passport_number || ''}`
      } else {
        return '[Warn] - Added new country in methods'
      }
    },
    async loadQuestionnaires() {
      const response = await this.$services.questionnaires.getQuestionnaires(
        {
          status: this.status,
          destination: this.destination,
          search: this.search,
          type_visa: this.type_visa,
          offset: this.offset,
          limit: this.limit
        }
      );
      this.questionnaires = response.results
      this.total = response.total_count
    },
    typeVisa(type) {
      if (type.includes('_E_VISA')) {
        return 'eVisa'
      } else {
        return 'Application'
      }
    }
  },
  async mounted() {
    this.$router.push({query: {status: 'FILLED'}})
    await this.loadQuestionnaires();
  },
  created() {
    document.body.style.background = 'var(--background)'
  },
  watch: {
    offset: async function () {
      await this.loadQuestionnaires()
    },
    limit: async function () {
      await this.loadQuestionnaires()
    },
    status: async function () {
      this.offset = 0
      await this.loadQuestionnaires()
    },
    type_visa: async function () {
      this.offset = 0
      await this.loadQuestionnaires()
    },
    destination: async function () {
      this.offset = 0
      await this.loadQuestionnaires()
    },
    search: async function () {
      this.offset = 0
      await this.loadQuestionnaires()
    },
  }
}
</script>

<style lang="sass">
.questionnaires
  &.container
    padding: 0 32px

  h2
    font-weight: 500
    font-size: 20px
    padding-left: 24px
    margin: 29px 0

  &__filters
    display: flex
    align-items: center

  &__select-filter
    width: 150px

    & + .questionnaires__select-filter
      margin-left: 16px
      width: 130px

      .vs__dropdown-menu
        width: 210px !important
        min-width: 210px !important
        max-width: 210px !important

    .vs
      &__dropdown-toggle
        border: none

      &__selected
        margin-top: 0 !important
        margin-left: 0 !important
        padding-left: 0 !important
        position: initial !important
        opacity: 1 !important

      &__actions
        padding-top: 0 !important

      &__search
        margin-top: 0 !important

      &__open-indicator,
      &__clear
        fill: var(--primary)

      &__dropdown-menu
        border: none !important

  &__table
    border-radius: 0 0 5px 5px
    border: 1px solid var(--background)
    width: 100%
    background: var(--white)
    border-collapse: collapse

    tr
      border-bottom: 1px solid var(--background)
      border-top: 1px solid white

    tbody
      tr
        transition: 0.1s
        cursor: pointer

        &:hover
          background: var(--settings-json-bg)

    &-empty
      position: relative
      height: 50px

      td
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        color: var(--description-text)
        font-weight: 500

  &__caption
    border: 1px solid var(--background)
    border-bottom: none
    border-radius: 5px 5px 0 0
    background: var(--white)

    &-type-filter
      display: flex
      align-items: center

      &-active
        color: var(--main_black) !important
        border-bottom: 4px solid var(--primary)

      a
        color: var(--description-text)
        margin-left: 32px
        padding-bottom: 14px
        transform: translateY(3px)

        &:first-child
          margin-left: 0

    &-container
      display: flex
      justify-content: space-between
      align-items: flex-start
      margin: 0 24px
      border-bottom: 2px solid #EBEBEB
      padding-top: 21px

      p
        color: var(--main_black)
        font-size: 20px
        font-weight: 700
        margin: 0

  &__th
    color: var(--description-text)
    font-size: 14px
    font-weight: 400
    padding-top: 22px
    padding-bottom: 14px
    text-align: start
    text-transform: uppercase

    &:first-child
      padding-left: 24px

    &:last-child
      padding-right: 24px

  &__td
    font-size: 16px
    font-weight: 400
    color: var(--description-text)
    text-align: start
    padding: 14px 0

    &:first-child
      padding-left: 24px
      padding-right: 20px !important
      color: var(--primary)

    &:last-child
      padding-right: 24px

    &-status
      display: flex
      align-items: center

      &-circle
        width: 8px
        height: 8px
        border-radius: 100%
        background: var(--description-text)
        display: block
        margin-right: 7px

      &-YELLOW
        background: #FFCF23

      &-RED-LIGHT
        background: #FF8C69

      &-RED
        background: #E32727

      &-GREEN
        background: #23C173

      &-PRIMARY
        background: var(--primary)

    &-links
      display: flex
      align-items: center
      justify-content: center
      width: 24px
      height: 28px
      border-radius: 4px

      div
        width: 4px
        height: 4px
        background: var(--primary)
        border-radius: 100%
        position: relative

        &:after, &:before
          content: ''
          width: 4px
          height: 4px
          background: var(--primary)
          border-radius: 100%
          position: absolute
          left: 0

        &:after
          top: -6px

        &:before
          top: 6px

  &__td-links-container
    padding: 0 16px
    text-align: end
    max-width: 15px
    position: relative

    a
      svg
        fill: var(--primary)

    &:hover
      .questionnaires
        &__show-link
          z-index: 10
          opacity: 1

        &__td-links
          background: var(--primary-lighten)

  &__show-link
    z-index: 0
    opacity: 0
    transition: 0.2s
    font-size: 16px
    position: absolute
    top: 100%
    right: 0
    box-shadow: 0 2px 10px 0 #0000000D
    background: var(--white)
    border: 1px solid var(--border-form-input)
    padding: 0

    a
      color: var(--main_black)
      padding: 10px 20px
      background: var(--white)
      display: block
      text-align: left
      white-space: nowrap

      &:hover
        background: var(--primary-lighten)

  &__id
    color: var(--primary)

@media (max-width: 1200px)
  .questionnaires
    &__caption-container
      flex-direction: column

    &__filters
      width: 100%
      justify-content: end
      margin-top: 6px
      margin-bottom: 6px

</style>