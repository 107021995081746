<template>
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.6 0.599976C1.17565 0.599976 0.768687 0.768546 0.468629 1.0686C0.168571 1.36866 0 1.77563 0 2.19998V6.19998H4.904C5.272 4.79198 6.544 3.79998 8 3.79998C8.70867 3.80187 9.39676 4.03837 9.95686 4.47255C10.5169 4.90673 10.9175 5.51415 11.096 6.19998H16V2.19998C16 1.77563 15.8314 1.36866 15.5314 1.0686C15.2313 0.768546 14.8243 0.599976 14.4 0.599976H1.6ZM8 5.39998C7.57565 5.39998 7.16869 5.56855 6.86863 5.8686C6.56857 6.16866 6.4 6.57563 6.4 6.99998C6.4 7.42432 6.56857 7.83129 6.86863 8.13135C7.16869 8.4314 7.57565 8.59998 8 8.59998C8.42435 8.59998 8.83131 8.4314 9.13137 8.13135C9.43143 7.83129 9.6 7.42432 9.6 6.99998C9.6 6.57563 9.43143 6.16866 9.13137 5.8686C8.83131 5.56855 8.42435 5.39998 8 5.39998ZM0 7.79998V11.8C0 12.2243 0.168571 12.6313 0.468629 12.9313C0.768687 13.2314 1.17565 13.4 1.6 13.4H14.4C14.8243 13.4 15.2313 13.2314 15.5314 12.9313C15.8314 12.6313 16 12.2243 16 11.8V7.79998H11.096C10.9175 8.4858 10.5169 9.09322 9.95686 9.5274C9.39676 9.96158 8.70867 10.1981 8 10.2C7.29133 10.1981 6.60323 9.96158 6.04314 9.5274C5.48305 9.09322 5.08249 8.4858 4.904 7.79998H0Z"
            fill="#000000"/>
    </svg>
</template>

<script>
export default {
    name: "Passport"
}
</script>

<style lang="sass" scoped>
path
    stroke: none !important
</style>