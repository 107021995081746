<template>
  <Base>
    <!--Firefox autocomplete off-->
    <input type="text" style="display:none">
    <input type="password" style="display:none">
    <!--Firefox autocomplete off end-->

    <div class="container-content">
      <div class="application">
        <div class="form-aside__container" style="min-width: 352px; max-width: 352px">
          <div class="questionnaire__search">
            <input type="text" @input="search" v-model="searchInput" id="search">
            <label for="search"><img src="@/assets/images/search.svg" alt="Search"></label>
            <button @click.prevent="clearSearch" class="questionnaire__search-delete-btn"
                    v-if="searchInput && searchInput !== ''"></button>
            <div v-if="resultSearch.length" class="questionnaire__search-dropdown">
              <div
                v-for="r in resultSearch"
                :key="r.label"
                class="questionnaire__search-dropdown-item-wrap"
              >
                <copy-button
                  :is-background="true"
                  :text="copyText(r.value)"
                  class="questionnaire__search-dropdown-item-copy"
                />
                <a
                  :class="['questionnaire__search-dropdown-item', {'questionnaire__search-dropdown-item-obj': typeof r.value === 'object'}]"
                  @click.prevent="scrollToElement($event, r.label)"
                >
                  <span>{{ r.label }}</span>
                  <recursive-component :item="r.value"></recursive-component>
                </a>
              </div>
            </div>
          </div>
          <aside v-if="form" style="position: relative" class="form-aside">
            <div class="profile-data">
              <div style="margin-bottom: 16px;" class="profile-data__label">Questionnaire
                №{{ $route.params.questionnaireId }}
              </div>
              <div class="profile-data__country-and-name">
                <img width="28"
                     :src="require(`@/assets/images/questionnaires/${typeDocuments[form.document_type].iso3_code}_selected.svg`)"
                     alt="">
                <div>{{ fullName() }}</div>
              </div>
              <div class="profile-data__status">{{ typeVisa() }}</div>
              <div class="profile-data__status">
                Application ID: {{ consulateApplicationId || 'NONE' }}
              </div>
              <div class="profile-data__status">
                Status:
                <span :class="documentStatusColor(fillingStatus, status)">
                  {{ transformStatus() }}
                </span>
              </div>
            </div>
            <div class="questionnaire__group-btn">
              <button @click.prevent="cloneQuestionnaire" class="btn-save">USE DATA</button>
              <router-link
                class="btn-save"
                :to="{
                          name: 'filling_page',
                          params: {
                              questionnaireId: this.$route.params.questionnaireId,
                          },
                          query: {
                              section: typeDocuments[form.document_type].first_section,
                          },
                      }"
              >EDIT
              </router-link>
              <button @click.prevent="payForVisa = !payForVisa"
                      :class="['--btn-next', {'questionnaire__pay-btn': payForVisa}]">
                <span v-if="!payForVisa">Payment</span>
                <span v-else>cancel payment</span>
              </button>
            </div>
            <div v-show="payForVisa"
                 style="position: relative; display: flex; flex-direction: column; margin-top: 56px;">
              <div class="bank-card">
                <div class="floating-input" style="width: 100%;">
                  <input
                    id="card_number"
                    type="text"
                    placeholder="&nbsp;"
                    v-model="bankCardNumber"
                    :disabled="!canGenerate"
                  />
                  <label for="card_number">Card number</label>
                </div>
                <div class="bank-card__row" style="justify-content: space-between">
                  <div style="display: flex;">
                    <div class="floating-select floating-select__small">
                      <select
                        id="month"
                        @change="checkSelectValue"
                        v-model="bankCardMonth"
                        :disabled="!canGenerate"
                      >
                        <option disabled value="" selected>&nbsp;</option>
                        <option
                          v-for="(month, index) in Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'))"
                          :key="`month_${index}`"
                          :value="month"
                        >
                          {{ month }}
                        </option>
                      </select>
                      <label for="month">MM</label>
                    </div>
                    <div class="floating-select floating-select__small" style="margin-left: 12px;">
                      <select
                        id="year"
                        @change="checkSelectValue"
                        v-model="bankCardYear"
                        :disabled="!canGenerate"
                      >
                        <option disabled value="" selected>&nbsp;</option>
                        <option
                          v-for="(year, index) in Array.from({length: 16}, (_, i) => new Date().getFullYear() + i)"
                          :key="`year_${index}`"
                          :value="year"
                        >
                          {{ year.toString().slice(-2) }}
                        </option>
                      </select>
                      <label for="year">YY</label>
                    </div>
                  </div>
                  <div class="floating-input" style="width: 64px;">
                    <input
                      id="cvv"
                      type="password"
                      placeholder="&nbsp;"
                      autocomplete="off"
                      v-model="bankCardCVV"
                      :disabled="!canGenerate"
                    />
                    <label for="cvv">CVV</label>
                  </div>
                </div>
                <div class="floating-input" style="width: 100%;">
                  <input
                    type="text"
                    id="enter_name"
                    placeholder="&nbsp;"
                    v-model="bankCardHolder"
                    :disabled="!canGenerate"
                  />
                  <label for="enter_name">Card Holder</label>
                </div>
                <button
                  :disabled="!canGenerate"
                  style="height: 44px; margin-top: 24px; padding: initial; background: var(--yellow); text-transform: uppercase"
                  class="--btn-next"
                  @click.prevent="generateQuestionnaire"
                >send payment
                </button>
              </div>
            </div>
          </aside>
        </div>

        <div class="questionnaire__content">
          <div class="questionnaire__documents">
            <pdf-doc :src-url="documentUrl" :filling-status="fillingStatus" :status="status"/>
          </div>
          <div class="form check" style="width: 100%">
            <div class="form__substrate"></div>
            <div class="form__triangles">
              <div></div>
              <div></div>
            </div>
            <div style="margin-top: 0 !important;" class="form__card-body">
              <table class="table" v-for="section in form.sections" v-if="form">
                <tbody class="check__tbody">
                <tr>
                  <td colspan="2">
                    <div style="display: flex; align-items: center; justify-content: space-between">
                      <h3>{{ section.label }}</h3>

                      <router-link
                        class="btn-edit"
                        :to="{
                          name: 'filling_page',
                          params: {
                              questionnaireId: this.$route.params.questionnaireId,
                          },
                          query: {
                              section: section.name,
                          },
                      }"
                        style="display: block">Edit
                      </router-link>
                    </div>
                  </td>
                </tr>
                <tr
                  v-for="(value, field_name) in answers[section.name]"
                  :id="getLabel(section.name, field_name)"
                  :ref="getLabel(section.name, field_name)"
                  v-if="fieldInForm(section.name, field_name)"
                >
                  <td
                    v-if="getLabel(section.name, field_name)"
                    style="padding-left: 6px;"
                  >
                    <p style="margin: 0; display: inline-block">{{ getLabel(section.name, field_name) }}</p>
                  </td>
                  <td
                    v-if="getPrettyValue(section.name, field_name)"
                    v-html="`<p style='margin: 0; display: inline-block'>${getPrettyValue(section.name, field_name)}</p>`"
                    style="padding-right: 6px;"
                  >
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Base>
</template>

<script>
import Base from "@/layouts/base.vue";
import Switcher from "@/components/fields/Switcher.vue";
import Input from "@/components/fields/fillingPage/IntegerField.vue";
import typeDocuments from "@/dictionaries/type_documents";
import documentStatus from "@/dictionaries/document_status";
import pdfDoc from "@/components/pdfDoc.vue";
import RecursiveComponent from "@/components/elements/RecursiveComponent.vue";
import CopyButton from "@/components/elements/CopyButton.vue";

const findInObject = (obj, query) => {
  const results = [];

  const searchFields = (fields, basePath) => {
    fields.forEach((field, index) => {
      const currentPath = basePath.concat([index]);
      searchField(field, currentPath);
    });
  };

  const searchField = (field, path) => {
    const labelMatch = field.label && typeof field.label === 'string' && field.label.toLowerCase().includes(query.toLowerCase());
    const valueMatch = field.value && typeof field.value === 'string' && field.value.toLowerCase().includes(query.toLowerCase());

    if ((labelMatch || valueMatch) && field.value) {
      results.push({
        value: field.value,
        label: field.label,
        path: `form.sections[${path[0]}].fields[${path[1]}]` + (path.length > 2 ? `.nested_fields[${path[3]}]` : '')
      });
    }

    if (field.type === 'Nested' && field.nested_fields && Array.isArray(field.nested_fields)) {
      searchFields(field.nested_fields, path.concat(['nested_fields']));
    }
  };

  if (obj && obj.sections && Array.isArray(obj.sections)) {
    obj.sections.forEach((section, secIndex) => {
      if (section.fields && Array.isArray(section.fields)) {
        searchFields(section.fields, [secIndex]);
      }
    });
  }

  return results;
};


export default {
  name: "questionnaire",
  components: {RecursiveComponent, Input, Switcher, Base, pdfDoc, CopyButton},
  data: () => ({
    typeDocuments,
    documentStatus,
    form: null,
    answers: null,
    documentUrl: null,
    generate: false,
    fillingStatus: null,
    status: 'NOT_STARTED',
    statusMessage: {
      show: false,
      value: ''
    },
    consulateApplicationId: '',
    payForVisa: false,
    bankCardNumber: '',
    bankCardMonth: '',
    bankCardYear: '',
    bankCardCVV: '',
    bankCardHolder: '',
    resultSearch: [],
    searchInput: ''
  }),
  computed: {
    canGenerate() {
      return this.status !== 'IN_PROGRESS'
    }
  },
  methods: {
    clearSearch() {
      this.searchInput = ''
      this.resultSearch = []
    },
    copyText(data) {
      if (Array.isArray(data)) {
        return data.map(item => this.copyText(item)).join(", ");
      } else if (data && typeof data === 'object') {
        return Object.keys(data)
          .map(key => `${key}: ${this.copyText(data[key])}`)
          .join(", ");
      } else {
        return String(data);
      }
    },
    scrollToElement(event, refName) {
      event.preventDefault();
      this.$nextTick(() => {
        const element = this.$refs[refName][0];
        if (element) {
          const top = element.getBoundingClientRect().top + window.pageYOffset;
          const offset = window.innerHeight * 0.20;
          const scrollToPosition = top - offset;

          window.scrollTo({
            top: scrollToPosition,
            behavior: 'smooth'
          });
        }
      });
    },
    async cloneQuestionnaire() {
      const response = await this.$services.questionnaires.cloneQuestionnaire({questionnaire_id: Number(this.$route.params.questionnaireId)})
      this.$router.push({
        name: 'questionnaire',
        params: {
          questionnaireId: response.questionnaire_id,
        }
      })
    },
    checkSelectValue(event) {
      if (event.target.value) {
        event.target.classList.add('floating-select__focus');
      } else {
        event.target.classList.remove('floating-select__focus');
      }
    },
    documentStatusColor(fStatus, gStatus = false) {
      const status = gStatus || fStatus;
      return {
        'questionnaire__status-YELLOW': status === 'FILLED',
        'questionnaire__status-RED': status === 'ERROR' || status === 'FAILED_TO_PAY',
        'questionnaire__status-GREEN': status === 'DONE',
        'questionnaire__status-RED-LIGHT': status === 'IN_PROGRESS' || status === 'STARTED',
        'questionnaire__status-PRIMARY': status === 'GRANTED',
      }
    },
    transformStatus() {
      return documentStatus[this.status || this.fillingStatus] || this.status || this.fillingStatus;
    },
    fullName() {
      if (!this.form || !this.answers) return 'No data in form or answers.';
      switch (this.form.document_type) {
        case 'INDIAN_E_VISA':
          return this.answers.passport_data.surname + ' ' + this.answers.passport_data.name;
        case 'CHINESE_VISA':
          return this.answers.passport_data.surname + ' ' + this.answers.passport_data.name;
        case 'SRI_LANKA_E_VISA':
        case 'SAUDI_E_VISA':
        case 'UAE_E_VISA':
          return this.answers.applicant_data.surname + ' ' + this.answers.applicant_data.name;
        default:
          return 'Document type not supported.';
      }
    },
    typeVisa() {
      if (!this.form || !this.answers) return 'No data in form or answers.';
      switch (this.form.document_type) {
        case 'INDIAN_E_VISA':
          return this.answers['registration_data' || 'details_of_visa_sought'].visa_service;
        case 'CHINESE_VISA':
          return `${this.answers.travel_data.visit_purpose} - ${this.answers.travel_data.visa_validity_maximum_days} Days - ${this.answers.travel_data.entries}`;
        case 'SRI_LANKA_E_VISA':
          return `${this.answers.applicant_data.visa_type} - ${this.answers.applicant_data.visa_requirement_days} Days`;
        case 'SAUDI_E_VISA':
          return `${this.answers.applicant_data.purpose_of_visit} visa`;
        case 'UAE_E_VISA':
          return this.answers.applicant_data.visa_type;
        default:
          return 'Document type not supported.';
      }
    },
    getField(sectionName, fieldName) {
      return (this.form.sections
        .find(s => s.name === sectionName).fields
        .find(f => f.name === fieldName));
    },
    fieldInForm(sectionName, fieldName) {
      return this.getField(sectionName, fieldName) === undefined;
    },
    getLabel(sectionName, fieldName) {
      const field = this.getField(sectionName, fieldName);
      return field ? field.label : null;
    },
    getPrettyValue(sectionName, fieldName) {
      const field = this.getField(sectionName, fieldName);
      if (!field) return null;
      const value = this.answers[sectionName][fieldName];
      const isCheckValue = (value, obj) => {
        if (!obj.hasOwnProperty(value)) {
          return ''
        } else {
          return `${obj[value]}, `
        }
      }
      if (field.type === 'Address' && typeof value === 'object') {
        return `${isCheckValue('postcode', value)} ${isCheckValue('country', value)} ${isCheckValue('state', value)} ${isCheckValue('city', value)} ${isCheckValue('street', value)} ${isCheckValue('house', value)}`;
      }
      if (field.type === 'StringArray' && value instanceof Array) {
        return value.join(', ');
      }
      if (field.type === 'SelectArray' && value instanceof Array) {
        return value.join(', ');
      }
      if (field.type === 'CountryArray' && value instanceof Array) {
        return value.join(', ');
      }
      if (field.type === 'Nested' && value instanceof Array) {
        let v = [];
        for (const nestedValues of value) {
          let nestedV = '';
          for (const nestedField of field.nested_fields) {
            nestedV += `<div class="check__td-nested"><span style="white-space: nowrap">${nestedField.label} :</span>
              <span>${typeof nestedValues[nestedField.name] === 'string' ? nestedValues[nestedField.name] : Object.values(nestedValues[nestedField.name]).join(', ')}</span>
              </div>`;
          }
          v.push(nestedV);
        }
        return v.join('------<br />');
      }
      return value;
    },
    async finishFilling() {
      const questionnaire = await this.$services.questionnaires.finishFilling(
        this.$route.params.questionnaireId,
      )
      this.$router.push({
        name: 'questionnaires',
      })
    },
    async generateQuestionnaire() {
      try {
        await this.$services.questionnaires.startGeneration(
          this.$route.params.questionnaireId,
          this.payForVisa,
          this.bankCardNumber || "NONE",
          this.bankCardMonth || "NONE",
          this.bankCardYear || "NONE",
          this.bankCardCVV || "NONE",
          this.bankCardHolder || "NONE",
        )
      } catch (e) {
        console.error('[ERROR]' + e)
      }
    },
    openDocument() {
      window.open(this.documentUrl, '_blank').focus();
    },
    actualizeStatus() {
      switch (this.status) {
        case 'NOT_STARTED':
          this.generate = false
          break;
        case 'STARTED':
          this.generate = true
          this.statusMessage.show = true
          this.statusMessage.value = 'Questionnaire generation started.'
          break;
        case 'IN_PROGRESS':
          this.generate = true
          this.statusMessage.show = true
          this.statusMessage.value = 'Questionnaire in the process of generation.'
          break;
        case 'DONE':
          this.generate = false
          this.statusMessage.show = true
          this.statusMessage.value = 'Questionnaire successfully generated.'
          break;
        case 'ERROR':
          this.generate = false
          this.statusMessage.show = true
          this.statusMessage.value = 'Failed to generate the questionnaire.'
      }
    },
    search(event) {
      const $event = event.target.value
      if ($event.length < 1) {
        this.resultSearch = []
      }
      if ($event.length >= 1) {
        this.resultSearch = findInObject(this.form, $event)
      }

    }
  },
  async mounted() {
    const questionnaireId = this.$route.params.questionnaireId;
    const questionnaire = await this.$services.questionnaires.getQuestionnaire({
      questionnaire_id: questionnaireId,
    });
    this.form = questionnaire.form;
    this.answers = questionnaire.answers;
    this.documentUrl = questionnaire.document_url;
    this.status = questionnaire.status;
    this.fillingStatus = questionnaire.filling_status
    this.consulateApplicationId = questionnaire.consulate_application_id;
    this.$events.questionnaires.generationStatusChanged(questionnaireId)(data => {
      this.status = data.status;
      this.actualizeStatus();
    })


  },
  async unmounted() {
    this.$events.questionnaires.describeAll();
  },
  watch: {
    resultSearch: {
      deep: true,
      handler(newVal, oldVal) {
        oldVal.forEach(item => {
          this.$refs[item.label][0].classList.remove('questionnaire__search-label')
          this.$refs[item.label][0].classList.remove('questionnaire__search-value')
        })

        newVal.forEach(item => {
          const labelMatches = item.label.toLowerCase().includes(this.searchInput.toLowerCase());
          const valueMatches = typeof item.value === 'string' ? item.value.toLowerCase().includes(this.searchInput.toLowerCase()) : item.value;

          const element = this.$refs[item.label][0];

          if (labelMatches) {
            element.classList.add('questionnaire__search-label');
          } else {
            element.classList.remove('questionnaire__search-label');
          }

          if (valueMatches) {
            element.classList.add('questionnaire__search-value');
          } else {
            element.classList.remove('questionnaire__search-value');
          }
        })
      }
    }
  }

}
</script>

<style lang="sass">
.questionnaire
  display: flex
  background: white
  padding: 32px 0 144px
  min-height: calc(100vh - 104px)
  height: 100%

  &__content
    width: 100%

  &__documents
    margin-bottom: 32px
    display: flex

    .pdf-doc + .pdf-doc
      margin-left: 32px

  &__pay-btn
    background: none !important
    border: 1px solid var(--primary) !important
    color: var(--primary) !important

  &__search
    position: relative
    width: 100%
    margin-bottom: 16px

    &-delete-btn
      position: absolute
      top: 50%
      transform: translateY(-50%)
      right: 14px
      width: 14px
      height: 14px
      background: white
      border: 1px solid var(--primary)
      border-radius: 100%

      &:after, &:before
        content: ''
        width: 8px
        height: 1px
        background: var(--primary)
        display: block
        position: absolute
        left: 2px
        top: 5.5px

      &:after
        transform: rotate(-45deg)

      &:before
        transform: rotate(45deg)

    &-label > td:first-child > p, &-value > td:last-child > p
      background: yellow

    &-dropdown
      display: flex
      flex-direction: column
      position: absolute
      padding: 16px 12px 20px
      top: calc(100% - 1px)
      width: 100%
      left: 0
      max-height: 400px
      overflow-y: auto
      overflow-x: hidden
      z-index: 100
      background: white
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05)
      border: 1px solid var(--border-form-input)

      &-item
        display: flex
        justify-content: space-between
        cursor: pointer

        &-copy
          position: absolute
          right: 0
          top: 0
          display: none

        &-wrap
          margin-top: 12px
          position: relative
          &:hover
            .questionnaire__search-dropdown-item-copy
              display: flex

          &:first-child
            margin-top: 0

        &-obj
          display: block

        span
          display: inline-block
          max-width: 326px
          white-space: nowrap
          text-overflow: ellipsis
          overflow: hidden
          line-height: 1

          &:first-child
            color: var(--description-text-darken)

          &:last-child
            color: var(--main_black)


    input
      width: 100%
      height: 34px
      border-radius: var(--border-radius-input)
      outline: none
      border: 1px solid var(--border-form-input)
      padding-right: 32px
      padding-left: 16px

    img
      position: absolute
      top: 50%
      right: 16px
      transform: translateY(-50%)

  &__status
    &-YELLOW
      color: #d2aa1d

    &-RED-LIGHT
      color: #FF8C69

    &-RED
      color: #E32727

    &-GREEN
      color: #23C173

    &-PRIMARY
      background: var(--primary)

  &-enter-active, &-leave-active
    transform: scale(0.4)
    opacity: 0


  &-enter, &-leave-to
    transform: scale(1)
    opacity: 1


  &__message-generate
    margin-top: 16px
    line-height: 1
    background: var(--primary-lighten)
    color: var(--primary)
    border: 1px solid var(--primary)
    border-radius: 4px
    padding: 8px 14px
    font-size: 15px

    &-error
      background: var(--error) !important
      color: var(--error_red) !important
      border-color: var(--error_red) !important


  &__group-btn
    margin-top: 24px

    button, a
      display: flex
      align-items: center
      justify-content: center
      height: 44px !important
      padding: initial
      text-transform: uppercase !important
      font-size: 16px

    & .btn-save
      width: 100%
      margin-bottom: 16px

  &__loader
    display: flex
    justify-content: center
    align-items: center
    margin-right: 8px

    &::before
      content: ''
      display: block
      width: 20px
      height: 20px
      border-radius: 50%
      border: 3px solid
      border-color: white transparent white transparent
      animation: loader-rotate 2s linear infinite

  @keyframes loader-rotate
    0%
      transform: rotate(0deg)
    100%
      transform: rotate(360deg)

  &__section
    background: var(--white)
    width: 100%
    border: 0
    margin: 0
    padding: 28px 90px
    box-shadow: 2px 0 20px 0 rgba(156, 156, 156, 0.3)
    border-radius: 5px

    & + &
      margin-top: 16px

    &.--closed
      background: var(--background)
      box-shadow: none

      .form__title
        background: transparent

    h3
      cursor: pointer


.bank-card
  &__row
    display: flex
    padding: 8px 0

    input, select
      height: 44px

      option[disabled]
        color: gray

    input + select, select + input, select + select, input + input
      margin-left: 8px
</style>