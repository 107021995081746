<template>
    <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.84961 1V17M9.59961 3.90909H3.97461C3.27842 3.90909 2.61074 4.17727 2.11845 4.65464C1.62617 5.132 1.34961 5.77945 1.34961 6.45455C1.34961 7.12964 1.62617 7.77709 2.11845 8.25445C2.61074 8.73182 3.27842 9 3.97461 9H7.72461C8.4208 9 9.08848 9.26818 9.58077 9.74555C10.073 10.2229 10.3496 10.8704 10.3496 11.5455C10.3496 12.2206 10.073 12.868 9.58077 13.3454C9.08848 13.8227 8.4208 14.0909 7.72461 14.0909H1.34961"
            stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: "Dollars"
}
</script>

<style lang="sass" scoped>
    path
        fill: none !important
</style>