export const CHINESE_COUNTIES = {
    "Chaozhou": [
        "Raoping Xian", "Chao'an Qu", "Xiangqiao Qu"
    ],
    "Rizhao": [
        "Lanshan Qu", "Donggang Qu", "Ju Xian", "Wulian Xian"
    ],
    "Alxa Meng": [
        "Ejina Qi", "Alashan Zuoqi", "Alashan Youqi"
    ],
    "Hengyang": [
        "Hengnan Xian", "Qidong Xian", "Changning Shi", "Zhengxiang Qu",
        "Hengshan Xian", "Shigu Qu", "Hengyang Xian", "Hengdong Xian",
        "Nanyue Qu", "Leiyang Shi", "Yanfeng Qu", "Zhuhui Qu"
    ],
    "Dali": [
        "Heqing Xian", "Yangbi Yizu Zizhixian", "Yunlong Xian", "Xiangyun Xian",
        "Eryuan Xian", "Binchuan Xian", "Weishan Yizu Huizu Zizhixian",
        "Jianchuan Xian", "Midu Xian", "Nanjian Yizu Zizhixian",
        "Yongping Xian", "Dali Shi"
    ],
    "Fuzhou": [
        "Cangshan Qu", "Yongtai Xian", "Minhou Xian", "Lianjiang Xian",
        "Pingtan Xian", "Changle Qu", "Luoyuan Xian", "Jin'an Qu",
        "Taijiang Qu", "Fuqing Shi", "Minqing Xian", "Mawei Qu", "Gulou Qu"
    ],
    "Haixi": [
        "Dulan Xian", "Tianjun Xian", "Delhi Shi", "Ulan Xian", "Golmud Shi"
    ],
    "Meizhou": [
        "Pingyuan Xian", "Fengshun Xian", "Xingning Shi", "Wuhua Xian",
        "Meijiang Qu", "Meixian Qu", "Jiaoling Xian", "Dabu Xian"
    ],
    "Foshan": [
        "Shunde Qu", "Gaoming Qu", "Chancheng Qu", "Sanshui Qu", "Nanhai Qu"
    ],
    "Jilin": [
        "Changyi Qu", "Panshi Shi", "Yongji Xian", "Jiaohe Shi", "Huadian Shi",
        "Longtan Qu", "Shulan Shi", "Chuanying Qu", "Fengman Qu"
    ],
    "Lingao Xian": [],
    "Changchun": [
        "Kuancheng Qu", "Yushu Shi", "Nanguan Qu", "Shuangyang Qu",
        "Jiutai Qu", "Chaoyang Qu", "Dehui Shi", "Nong'an Xian", "Erdao Qu",
        "Lüyuan Qu"
    ],
    "Beihai": [
        "Haicheng Qu", "Hepu Xian", "Tieshangang Qu", "Yinhai Qu"
    ],
    "Luohe": [
        "Yancheng Qu", "Zhaoling Qu", "Linying Xian", "Yuanhui Qu",
        "Wuyang Xian"
    ],
    "Hengshui": [
        "Gucheng Xian", "Jizhou Qu", "Raoyang Xian", "Zaoqiang Xian",
        "Anping Xian", "Fucheng Xian", "Jing Xian", "Wuyi Xian",
        "Wuqiang Xian", "Taocheng Qu", "Shenzhou Shi"
    ],
    "Taizhong": [],
    "Changde": [
        "Li Xian", "Dingcheng Qu", "Hanshou Xian", "Shimen Xian", "Linli Xian",
        "Wuling Qu", "Jinshi Shi", "Taoyuan Xian", "Anxiang Xian"
    ],
    "Jiamusi": [
        "Xiangyang Qu", "Dongfeng Qu", "Tongjiang Shi", "Huachuan Xian",
        "Huanan Xian", "Fuyuan Shi", "Fujin Shi", "Qianjin Qu", "Jiaoqu",
        "Tangyuan Xian"
    ],
    "Pingdingshan": [
        "Xinhua Qu", "Ruzhou Shi", "Wugang Shi", "Jia Xian", "Zhanhe Qu",
        "Weidong Qu", "Shilong Qu", "Lushan Xian", "Baofeng Xian", "Ye Xian"
    ],
    "Huangshi": [
        "Huangshigang Qu", "Xialu Qu", "Yangxin Xian", "Daye Shi",
        "Tieshan Qu", "Xisaishan Qu"
    ],
    "Yingtan": ["Yuehu Qu", "yujiang Xian", "Guixi Shi"],
    "Yilan": [],
    "Huangnan": [
        "Jianzha Xian", "Henan Mongolzu Zizhixian", "Tongren Xian", "Zeku Xian"
    ],
    "Jiaozuo": [
        "Bo'ai Xian", "Qinyang Shi", "Macun Qu", "Wen Xian", "Wuzhi Xian",
        "Zhongzhan Qu", "Xiuwu Xian", "Jiefang Qu", "Shanyang Qu",
        "Mengzhou Shi"
    ],
    "Beijing": [
        "Haidian Qu", "Xicheng Qu", "Dongcheng Qu", "Yanqing  Qu",
        "Mentougou Qu", "Changping Qu", "Pinggu Qu", "Chaoyang Qu",
        "Huairou Qu", "Fengtai Qu", "Fangshan Qu", "Shunyi Qu",
        "Miyun Qu", "Tongzhou Qu", "Daxing Qu", "Shijingshan Qu"
    ],
    "Shenyang": [
        "Heping Qu", "Shenbeixin Qu", "Sujiatun Qu", "Liaozhong Qu",
        "Shenhe Qu", "Kangping Xian", "Faku Xian", "Huanggu Qu",
        "Hun'nan Qu", "Tiexi Qu", "Xinmin Shi", "Yuhong Qu", "Dadong Qu"
    ],
    "Xinxiang": ["Yuanyang Xian", "Muye Qu", "Changyuan Xian", "Fengqiu Xian",
                 "Huixian Shi", "Hongqi Qu", "Xinxiang Xian", "Huojia Xian",
                 "Yanjin Xian", "Fengquan Qu", "Weihui Shi", "Weibin Qu"],
    "Qianxinan": ["Qinglong Xian", "Anlong Xian", "Wangmo Xian",
                  "Zhenfeng Xian", "Pu'an Xian", "Xingyi Shi", "Ceheng Xian",
                  "Xingren Xian"],
    "Xilin Gol Meng": ["Xi Wuzhumuqin Qi", "Dong Wuzhumuqin Qi",
                       "Xianghuang Qi", "Abaga Qi", "Zhenglan Qi",
                       "Sunite Youqi", "Taipusi Qi", "Xilinhaote Shi",
                       "Sunite Zuoqi", "Zhengxiangbai Qi", "Duolun  Xian",
                       "Er'lianhaote Shi"],
    "Xishuangbanna": ["Jinghong Shi", "Menghai Xian", "Menghla Xian"],
    "Jiuquan": ["Yumen Shi", "Suzhou Qu", "Dunhuang Shi",
                "Subei Mengguzu Zizhixian", "Jinta Xian",
                "Akesai Hashakezu Zizhixian", "Guazhou Xian"],
    "Baisha Lizu Zizhixian": [],
    "Qiongzhong Lizu Miaozu Zizhixian": [],
    "Garzê": ["Daocheng（Dabba）Xian", "Xiangcheng（Qagchêng）Xian",
              "Luding（Jagsamka）Xian", "Dêrong Xian", "Shiqu Xian", "Dêgê Xian",
              "Yajiang（Nyagquka）Xian", "Batang Xian", "Danba（Rongzhag）Xian",
              "Garzê Xian", "Seda Xian", "Xinlong（Nyagrong）Xian",
              "Luhuo（Zhaggo）Xian", "Litang Xian", "Baiyu Xian", "Kangding Shi",
              "Dawu Xian", "Jiulong（Gyaisi）Xian"],
    "Qujing": ["Shizong Xian", "ZhanyiQu", "Malong Xian", "Fuyuan Xian",
               "Xuanwei Shi", "Luoping Xian", "Luliang Xian", "Huize Xian",
               "Qilin Qu"],
    "Zhuhai": ["Jinwan Qu", "Doumen Qu", "Xiangzhou Qu"],
    "Wenshan": ["Malipo Xian", "Yanshan Xian", "Maguan Xian", "Qiubei Xian",
                "Wenshan Shi", "Xichou Xian", "Funing Xian", "Guangnan Xian"],
    "Weifang": ["Changle Xian", "Gaomi Shi", "Fangzi Qu", "Linqu Xian",
                "Anqiu Shi", "Kuiwen Qu", "Changyi Shi", "Qingzhou Shi",
                "Zhucheng Shi", "Hanting Qu", "Weicheng Qu", "Shouguang Shi"],
    "Liaocheng": ["Gaotang Xian", "Shen Xian", "Linqing Shi", "Yanggu Xian",
                  "Guan Xian", "Dongchangfu Qu", "Dong'e Xian", "Chiping Xian"],
    "Hefei": ["Chaohu Shi", "Lujiang Xian", "Feixi Xian", "Baohe Qu",
              "Shushan Qu", "Changfeng Xian", "luyang Qu", "Feidong Xian",
              "Yaohai Qu"],
    "Tai'an": ["Taishan Qu", "Ningyang Xian", "Xintai Shi", "Jiaoqu Qu",
               "Dongping Xian", "Feicheng Shi"],
    "Ezhou": ["Liangzihu Qu", "Huarong Qu", "Echeng Qu"],
    "Lanzhou": ["Xigu Qu", "Anning Qu", "Honggu Qu", "Yongdeng Xian",
                "Chengguan Qu", "Yuzhong Xian", "Qilihe Qu", "Gaolan Xian"],
    "MudanJiang": ["Linkou Xian", "Dongning Shi", "Muling Shi", "Xi'an Qu",
                   "Dongan Qu", "Suifenhe Shi", "Ning'an Shi", "Aimin Qu",
                   "Hailin Shi", "Yangming Qu"],
    "Jixi": ["Chengzihe Qu", "Didao Qu", "Lishu Qu", "Hengshan Qu", "Mashan Qu",
             "Jiguan Qu", "Mishan Shi", "Hulin Shi", "Jidong Xian"],
    "Yangzhou": ["Gaoyou Shi", "Baoying Xian", "Jiangdu Qu", "Yizheng Shi",
                 "Hanjiang Qu", "Guangling Qu"],
    "Tainan": [],
    "Changsha": ["Kaifu Qu", "Ningxiang Shi", "Furong Qu", "Yuhua Qu",
                 "Changsha Xian", "Yuelu Qu", "Tianxin Qu", "Liuyang Shi",
                 "Wangcheng Qu"],
    "Tunchang Xian": [],
    "Linyi": ["Cangshan Xian", "Yinan Xian", "Junan Xian", "Linshu Xian",
              "Mengyin Xian", "Hedong Qu", "Lanshan Qu", "Yishui Xian",
              "Luozhuang Qu", "Pingyi Xian", "Tancheng Xian", "Fei Xian"],
    "Xinzhou": ["Dingxiang Xian", "Dai Xian", "Baode Xian", "Shenchi Xian",
                "Hequ Xian", "Wutai Xian", "Ningwu Xian", "Pianguan Xian",
                "Wuzhai Xian", "Xinfu Qu", "Jingle Xian", "Yuanping Shi",
                "Kelan Xian", "Fanzhi Xian"],
    "Quanzhou": ["Fengze Qu", "Hui'an Xian", "Luojiang Qu", "Jinjiang Shi",
                 "Nan'an Shi", "Yongchun Xian", "Jinmen Xian", "Shishi Shi",
                 "Anxi Xian", "Licheng Qu", "Dehua Xian", "QuanGang Qu"],
    "Jiayuguan": [],
    "Zhoukou": ["Taikang Xian", "Shenqiu Xian", "Xiangcheng Shi", "Luyi Xian",
                "Dancheng Xian", "Shangshui Xian", "Huaiyang Xian",
                "Fugou Xian", "Chuanhui Qu", "Xihua Xian"],
    "Jinzhou": ["Beizhen Shi", "Linghai Shi", "Yi Xian", "Linghe Qu",
                "Taihe Qu", "Guta Qu", "Heishan Xian"],
    "Huizhou": ["Huiyang Qu", "Boluo Xian", "Huidong Xian", "Longmen Xian",
                "Huicheng Qu"],
    "Nanyang": ["Tongbai Xian", "Fangcheng Xian", "Dengzhou Shi",
                "Xichuan Xian", "Sheqi Xian", "Wancheng Qu", "Xinye Xian",
                "Xixia Xian", "Wolong Qu", "Neixiang Xian", "Tanghe Xian",
                "Zhenping Xian", "Nanzhao Xian"],
    "Chenzhou": ["Guiyang Xian", "Anren Xian", "Linwu Xian", "Guidong Xian",
                 "Rucheng Xian", "Beihu Qu", "Zixing Shi", "Yongxing Xian",
                 "Jiahe Xian", "Yizhang Xian", "Suxian Qu"],
    "Zhoushan": ["Dinghai Qu", "Shengsi Xian", "Putuo Qu", "Daishan Xian"],
    "Yuncheng": ["Jishan Xian", "Pinglu Xian", "Linyi Xian", "Ruicheng Xian",
                 "Yancheng Qu", "Yuanqu Xian", "Wanrong Xian", "Hejin Shi",
                 "Wenxi Xian", "Yongji Shi", "Xinjiang Xian", "Jiang Xian",
                 "Xia Xian"],
    "Nanchong": ["Jialing Qu", "Yingshan Xian", "Xichong Xian", "Gaoping Qu",
                 "Peng'an Xian", "Yilong Xian", "Nanbu Xian", "Shunqing Qu",
                 "Langzhong Shi"],
    "Xianning": ["Jiayu Xian", "Tongshan Xian", "Chibi Shi", "Chongyang Xian",
                 "Xian'an Qu", "Tongcheng Xian"],
    "Qiandongnan": ["Tianzhu Xian", "Taijiang Xian", "Sansui Xian",
                    "Jinping Xian", "Rongjiang Xian", "Cengong Xian",
                    "Leishan Xian", "Majiang Xian", "Shibing Xian",
                    "Jianhe Xian", "Congjiang Xian", "Huangping Xian",
                    "Zhenyuan Xian", "Danzhai Xian", "Liping Xian",
                    "Kaili Shi"],
    "Shijiazhuang": ["Pingshan Xian", "Jingxing Kuangqu", "Xingtang Xian",
                     "Qiaoxi Qu", "Jinzhou Shi", "Zanhuang Xian", "Chang'an Qu",
                     "Yuhua Qu", "Gaoyi Xian", "Xinji Shi", "Jingxing Xian",
                     "Luquan Qu", "Luancheng Qu", "Wuji Xian", "Yuanshi Xian",
                     "Xinhua Qu", "Zhengding Xian", "Gaocheng Qu",
                     "Shenze Xian", "Xinle Shi", "Zhao Xian", "Lingshou Xian"],
    "Aba": ["Zoigê Xian", "Ma'erkangshi", "Jinchuan（Quqên）Xian",
            "Songpan（Sungqu）Xian", "Jiuzhaigou Xian", "Li Xian",
            "Wenchuan Xian", "Aba（Ngawa）Xian", "Zamtang Xian", "Mao Xian",
            "Heishui Xian", "Hongyuan Xian", "Xiaojin Xian"],
    "Xining": ["Datong Huizu Tuzu Zizhixian", "Chengbei Qu", "Chengzhong Qu",
               "Huangyuan Xian", "Chengxi Qu", "Chengdong Qu",
               "Huangzhong Xian"],
    "Wuzhong": ["Litong Qu", "hongsipu qu", "Qingtongxia Shi", "Yanchi Xian",
                "Tongxin Xian"],
    "Chifeng": ["Kalaqin Qi", "Songshan Qu", "Aluke'erqin Qi", "Linxi Xian",
                "Balin Zuoqi", "Aohan Qi", "Keshiketeng Qi", "Ningcheng Xian",
                "Wengniute Qi", "Yuanbaoshan Qu", "Balin Youqi", "Hongshan Qu"],
    "Qinzhou": ["Pubei Xian", "Lingshan Xian", "Qinnan Qu", "Qinbei Qu"],
    "Yancheng": ["Binhai Xian", "Tinghu Qu", "Dafeng Qu", "Yandu Qu",
                 "Jianhu Xian", "Funing Xian", "Dongtai Shi", "Sheyang Xian",
                 "Xiangshui Xian"],
    "Kaifeng": ["Shunhe Huizu Qu", "Longting Qu", "Xiangfu Qu", "Weishi Xian",
                "Qi Xian", "Lankao Xian", "Gulou Qu", "Tongxu Xian",
                "Yuwangtai Qu"],
    "Zhangzhou": ["Changtai Xian", "Pinghe Xian", "Hua'an Xian", "Nanjing Xian",
                  "Longwen Qu", "Xiangcheng Qu", "Longhai Shi", "Yunxiao Xian",
                  "Dongshan Xian", "Zhangpu Xian", "Zhao'an Xian"],
    "Liaoyang": ["Hongwei Qu", "Dengta Shi", "Taizihe Qu", "Liaoyang Xian",
                 "Wensheng Qu", "Baita Qu", "Gongchangling Qu"],
    "Shanghai": ["Qingpu Qu", "Jing'an Qu", "Yangpu Qu", "Jiading Qu",
                 "Changning Qu", "Chongming Qu", "Xuhui Qu", "Putuo Qu",
                 "Huangpu Qu", "Minhang Qu", "Songjiang Qu", "Jinshan Qu",
                 "Hongkou Qu", "Baoshan Qu", "Fengxian Qu", "Pudong Xinqu"],
    "Tongliao": ["Kailu Xian", "Zhalute Qi", "Kulun Qi", "Ke'erqin Qu",
                 "Ke'erqin Zuoyi Houqi", "Huolinguole Shi",
                 "Ke'erqin Zuoyi Zhongqi", "Naiman Qi"],
    "Jiulong": [],
    "Dalian": ["Shahekou Qu", "Wafangdian Shi", "Changhai Xian", "Zhongshan Qu",
               "Xigang Qu", "Lüshunkou Qu", "PuLanDian Qu", "Ganjingzi Qu",
               "Jinzhou Qu", "Zhuanghe Shi"],
    "Haibei": ["Gangca Xian", "Haiyan Xian", "Menyuan Huizu Zizhixian",
               "Qilian Xian"],
    "Puyang": ["Taiqian Xian", "Puyang Xian", "Nanle Xian", "Hualong Qu",
               "Fan Xian", "Qingfeng Xian"],
    "Tongchuan": ["Yijun Xian", "Yintai Qu", "Wangyi Qu", "Yaozhou Qu"],
    "Anyang": ["Beiguan Qu", "Yindou Qu", "Tangyin Xian", "Neihuang Xian",
               "Anyang Xian", "Hua Xian", "Wenfeng Qu", "Linzhou Shi",
               "Long'an Qu"],
    "Zhaotong": ["Yanjin Xian", "Zhenxiong Xian", "Ludian Xian", "Weixin Xian",
                 "Qiaojia Xian", "yongshanxian", "Daguan Xian", "Suijiang Xian",
                 "Zhaoyang Shi", "Yiliang Xian", "Shuifu Xian"],
    "Lijiang": ["Yulong Naxizu Zizhixian", "Huaping Xian", "Gucheng Qu",
                "Yongsheng Xian", "Ninglang Yizu Zizhixian"],
    "Huai'an": ["Hongze Qu", "Huaiyin Qu", "Lianshui Xian", "Jinhu Xian",
                "Xuyi Xian", "Huaian Qu", "Qingjiangpu Qu"],
    "QitaiHe": ["Boli Xian", "Qiezihe Qu", "Xinxing Qu", "Taoshan Qu"],
    "Zibo": ["Gaoqing Xian", "Linzi Qu", "Zichuan Qu", "Zhoucun Qu",
             "Zhangdian Qu", "Huantai Xian", "Boshan Qu", "Yiyuan Xian"],
    "Xianyang": ["Qian Xian", "Yangling Qu", "Xingping Shi", "Bin Xian",
                 "Wugong Xian", "Yongshou Xian", "Xunyi Xian", "Liquan Xian",
                 "Weicheng Qu", "Jingyang Xian", "Sanyuan Xian", "Changwu Xian",
                 "Qindu Qu", "Chunhua Xian"],
    "Yangjiang": ["Jiangcheng Qu", "Yangdong Qu", "Yangchun Shi",
                  "Yangxi Xian"],
    "Qiannan": ["Libo Xian", "Sandu Suizu Zizhixian", "Duyun Shi",
                "Luodian Xian", "Dushan Xian", "Changshun Xian", "Fuquan Shi",
                "Pingtang Xian", "Huishui Xian", "Guiding Xian", "Weng'an Xian",
                "Longli Xian"],
    "Linzhi Shi": ["Lang Xian", "Bayi Qu", "Bomi Xian", "Gongbujiangda Xian",
                   "Chayu Xian", "Motuo Xian", "Milin Xian"],
    "Ulanqab": ["Chaha'er Youyihouqi", "Siziwang Qi", "Xinghe Xian",
                "Zhuozi Xian", "Shangdou Xian", "Huade Xian", "Ji'ning Qu",
                "Fengzhen Shi ", "Chaha'er Youyizhongqi",
                "Chaha'er Youyiqianqi", "Liangcheng Xian"],
    "Yichang": ["Xiling Qu", "Zhijiang Shi", "Dianjun Qu",
                "Wufeng Tujiazu Zizhixian", "Yiling Qu",
                "Changyang Tujiazu Zizhixian", "Dangyang Shi", "Xingshan Xian",
                "Yuan'an Xian", "Yidu Shi", "Zigui Xian", "Wujiagang Qu",
                "Xiaoting Qu"],
    "Jingmen": ["Jingshan Xian", "Shayang Xian", "Dongbao Qu", "Zhongxiang Shi",
                "Duodao Qu"],
    "Jinzhong": ["Qi Xian", "Heshun Xian", "Taigu Xian", "Xiyang Xian",
                 "Shouyang Xian", "Yushe Xian", "Lingshi Xian", "Jiexiu Shi",
                 "Yuci Qu", "Pingyao Xian", "Zuoquan Xian"],
    "Qamdo": ["Luolong Xian", "Zuogong Xian", "Chaya Xian", "Gongjue Xian",
              "Mangkang Xian", "Karuo Qu", "Leiwuqi Xian", "Bianba Xian",
              "Dingqing Xian", "Jiangda Xian", "Basu Xian"],
    "Suining": ["Pengxi Xian", "Anju Qu", "Shehong Xian", "Chuanshan Qu",
                "Daying Xian"],
    "Tianjin": ["Jinghai Qu", "Hongqiao Qu", "Beichen Qu", "Dongli Qu",
                "Jizhou Qu", "Wuqing Qu", "Jinnan Qu", "Baodi Qu", "Ninghe Qu",
                "Hebei Qu", "Nankai Qu", "Binhai Xinqu", "Hexi Qu", "Hedong Qu",
                "Heping Qu", "Xiqing Qu"],
    "Chengmai Qu": [],
    "Benxi": ["Nanfen Qu", "Pingshan Qu", "Mingshan Qu",
              "Huanren Manzu Zizhixian", "Benxi Manzu Zizhixian", "Xihu Qu"],
    "Wanning Qu": [],
    "Nansha": [],
    "Liupanshui": ["Luzhi Tequ", "Shuicheng Xian", "Zhongshan Qu",
                   "Panzhou Shi"],
    "Meishan": ["Hongya Xian", "Renshou Xian", "Pengshan Qu", "Dongpo Qu",
                "Danling Xian", "Qingshen Xian"],
    "Leshan": ["Wutongqiao Qu", "Ebian Yizu Zizhixian", "Qianwei Xian",
               "Shizhong Qu", "Jiajiang Xian", "Mabian Yizu Zizhixian",
               "Jinkouhe Qu", "Shawan Qu", "Emeishan Shi", "Muchuan Xian",
               "Jingyan Xian"],
    "Karamay": ["Karamay Qu", "Dushanzi Qu", "Orku Qu", "Baijiantan Qu"],
    "Yan'an": ["Ganquan Xian", "Zichang Xian", "Yanchang Xian", "Wuqi Xian",
               "Yanchuan Xian", "Huangling Xian", "Fu Xian", "Yichuan Xian",
               "Zhidan Xian", "Ansai Qu", "Baota Qu", "Huanglong Xian",
               "Luochuan Xian"],
    "Guangzhou": ["Liwan Qu", "Zengcheng Qu", "Nansha QU", "Yuexiu Qu",
                  "Huangpu Qu", "Fanyu Qu", "Haizhu Qu", "Tianhe Qu",
                  "Conghua Qu", "Baiyun Qu", "Huadou Qu"],
    "Yantai": ["Haiyang Shi", "Muping Qu", "Qixia Shi", "Laiyang Shi",
               "Laizhou Shi", "Laishan Qu", "Zhifu Qu", "Penglai Shi",
               "Fushan Qu", "Zhaoyuan Shi", "Longkou Shi", "Changdao Xian"],
    "Guangan": ["Wusheng Xian", "Huaying Shi", "Yuechi Xian", "Linshui Xian",
                "Guang'an Qu", "Qianfeng Qu"],
    "Shannan": ["Cuona Xian", "Qiongjie Xian", "Luozha Xian", "Gongga Xian",
                "Qusong Xian", "Sangri Xian", "Jiacha Xian", "Naidong Qu",
                "Zhanang Xian", "Cuomei Xian", "Longzi Xian", "Langkazi Xian"],
    "Lhasa": ["Dangxiong Xian", "Nimu Xian", "Linzhou Xian", "Duilongdeqing Qu",
              "Mozhugongka Xian", "Damu Qu", "Chengguan Qu", "Qushui Xian"],
    "Shizuishan": ["Pingluo Xian", "Huinong Qu", "Dawukou Qu"],
    "Jining": ["Yutai Xian", "Weishan Xian", "Jiaxiang Xian", "Wenshang Xian",
               "Zoucheng Shi", "Qufu Shi", "Yanzhou Qu", "Jinxiang Xian",
               "Sishui Xian", "Rencheng Qu", "Liangshan Xian"],
    "Ya'an": ["Shimian Xian", "Yucheng Qu", "Baoxing Xian", "Lushan Xian",
              "Yingjing Xian", "Hanyuan Xian", "Mingshan Qu", "Tianquan Xian"],
    "Chengde": ["Kuancheng Manzu Zizhixian", "Xinglong Xian", "Longhua Xian",
                "Pingquan Shi", "Luanping Xian",
                "Weichang Manzu Mongolzu Zizhixian", "Yingshouyingzi Kuangqu",
                "Shuangqiao Qu", "Fengning Manzu Zizhixian", "Shuangluan Qu",
                "Chengde Xian"],
    "Binzhou": ["Wudi Xian", "Zouping Xian", "Huimin Xian", "Yangxin Xian",
                "Boxing Xian", "Bincheng Xian", "Zhanhua Xian"],
    "Altay": ["beitun Shi", "Habahe（Kaba）Xian", "alaer Shi",
              "Fuyun（Koktokay）Xian", "Altay Shi", "Tumushuke Shi", "kunyu Shi",
              "Qinghe（Qinggil）Xian", "Shihezi Shi", "Fuhai（Burultokay）Xian",
              "Jeminay Xian", "Burqin Xian", "shuanghe Shi", "wujiaqu Shi",
              "tiemenguan Shi", "kekedala Shi"],
    "Nanjing": ["Jianye Qu", "Yuhuatai Qu", "Gaochun Qu", "Qixia Qu",
                "Liuhe Qu", "Gulou Qu", "Xuanwu Qu", "Lishui Qu", "Pukou Qu",
                "jiangning Qu", "Qinhuai Qu"],
    "Hebi": ["Heshan Qu", "Xun Xian", "Qi Xian", "Shancheng Qu", "Qibin Qu"],
    "Shaoxing": ["Xinchang Xian", "Shengzhou Shi", "Zhuji Shi", "Shangyu Qu",
                 "Yuecheng Qu", "Keqiao Qu"],
    "Jingdezhen": ["Zhushan Qu", "Leping Shi", "Fuliang Xian", "Changjiang Qu"],
    "Enshi": ["Xianfeng Xian", "Hefeng Xian", "Jianshi Xian", "Lichuan Shi",
              "Laifeng Xian", "Enshi Shi", "Xuan'en Xian", "Badong Xian"],
    "Qionghai Shi": [],
    "Qian'an Shi": [],
    "Tangshan": ["Fengrun Qu", "Fengnan Qu", "Luan Xian", "Caofeidian Qu",
                 "Guye Qu", "Luannan Xian", "Leting Xian", "Lubei Qu",
                 "Zunhua Shi", "Yutian Xian", "Lunan Qu", "Kaiping Qu",
                 "Qianxi Xian"],
    "Shenzhen": ["Longgang Qu", "Bao'an Qu", "Yantian Qu", "Luohu Qu",
                 "Longhua Qu", "Futian Qu", "Nanshan Qu", "Pingshan Qu"],
    "Changjiang Lizu Zizhixian": [],
    "Dongying": ["Dongying Qu", "Kenli Qu", "Lijin Xian", "Guangrao Xian",
                 "Hekou Qu"],
    "Datong": ["Zuoyun Xian", "Tianzhen Xian", "Kuangqu", "Lingqiu Xian",
               "Guangling Xian", "Datong Xian", "Xinrong Qu", "Hunyuan Xian",
               "Chengqu", "Nanjiao Qu", "Yanggao Xian"],
    "Hechi": ["huanjiang Maonanzu Zizhixian", "Nandan Xian", "Tian'e Xian",
              "Jinchengjiang Qu", "Luocheng Mulaozu Zizhixian",
              "Du'an Yaozu Zizhixian", "Bama Yaozu Zizhixian", "Yizhou Qu",
              "Dahua Yaozu Zizhixian", "Donglan Xian", "Fengshan Xian"],
    "Golog": ["Jigzhi Xian", "Maqên Xian", "Baima Xian", "Gadê Xian",
              "Madoi Xian", "Tarlag Xian"],
    "Wuzhou": ["Wanxiu Qu", "Cenxi Shi", "Mengshan Xian", "Teng Xian",
               "Changzhou Qu", "Cangwu Xian", "Longxu Qu"],
    "Panjin": ["Shuangtaizi Qu", "Xinglongtai Qu", "Dawa Qu", "Panshan Xian"],
    "Quzhou": ["Changshan Xian", "Jiangshan Shi", "Kecheng Qu", "Qujiang Qu",
               "Longyou Xian", "Kaihua Xian"],
    "Lu'an": ["Jinzhai Xian", "Huoshan Xian", "Yuan Qu", "Jinan QU", "Yeji Qu",
              "Huoqiu Xian", "Shucheng Xian"],
    "Honghe": ["Yuanyang Xian", "Mile Shi", "Shiping Xian",
               "Pingbian Miaozu Zizhixian", "Kaiyuan Shi", "Jianshui Xian",
               "Jinping Miaozu Yaozu Daizu Zizhixian", "Gejiu Shi", "Luxi Xian",
               "Honghe Xian", "Lüchun Xian", "Mengzi Shi",
               "Hekou Yaozu Zizhixian"],
    "Gaoxiong": [],
    "Baoshan": ["Changning Xian", "Shidian Xian", "Longling Xian",
                "Tengchong Shi", "Longyang Qu"],
    "ürümqi": ["DaBanCheng Qu", "Saybag Qu", "Xinshi Qu", "Tianshan Qu",
               "Midong Qu", "ürümqi Xian", "Toutunhe Qu", "Shuimogou Qu"],
    "Tieling": ["Kaiyuan Shi", "Xifeng Xian", "Tieling Xian", "Qinghe Qu",
                "Tiefa Shi", "Yinzhou Qu", "Changtu Xian"],
    "Shangrao": ["Yushan Xian", "Yiyang Xian", "Yugan Xian", "Xinzhou Qu",
                 "Wannian Xian", "Hengfeng Xian", "Shangrao Xian", "Dexing Shi",
                 "Poyang Xian", "Guangfeng Qu", "Qianshan Xian", "Wuyuan Xian"],
    "Baoji": ["Fengxiang Xian", "Long Xian", "Jintai Qu", "Qishan Xian",
              "Qianyang Xian", "Fufeng Xian", "Linyou Xian", "Chencang Qu",
              "Weibin Qu", "Feng Xian", "Taibai Xian", "Mei Xian"],
    "Guigang": ["Gangnan Qu", "Pingnan Xian", "Guiping Shi", "Tantang Qu",
                "Gangbei Qu"],
    "Huangshan": ["Yi Xian", "Tunxi Qu", "Xiuning Xian", "Huangshan Qu",
                  "Qimen Xian", "Huizhou Qu", "She Xian"],
    "Lishui": ["Yunhe Xian", "songyang Xian", "Longquan Shi", "Jinyun Xian",
               "Qingtian Xian", "Jingning Shezu Zizhixian", "Suichang Xian",
               "Qingyuan Xian", "Liandu Qu"],
    "Panzhihua": ["Dong Qu", "Xi Qu", "Yanbian Xian", "Miyi Xian", "Renhe Qu"],
    "Zunyi": ["Wuchuan Gelaozu Miaozu Zizhixian", "Meitan Xian",
              "Honghuagang Qu", "Bozhou Qu", "Yuqing Xian", "Suiyang Xian",
              "Huichuan Qu", "Fenggang Xian",
              "Daozhen Gelaozu Miaozu Zizhixian", "Zheng'an Xian",
              "Chishui Shi", "Xishui Xian", "Renhuai Shi", "Tongzi Xian"],
    "Pingxiang": ["Anyuan Qu", "Xiangdong Qu", "Lianhua Xian", "Shangli Xian",
                  "Luxi Xian"],
    "Yunfu": ["Yun'an Qu", "Luoding Shi", "Xinxing Xian", "Yuncheng Qu",
              "Yunan Xian"],
    "Dazhou": ["Dazhu Xian", "Xuanhan xian", "Wanyuan Xian", "Tongchuan Shi",
               "Dachuan shi", "Qu xian", "Kaijiang Xian"],
    "Guangyuan": ["Jiange Xian", "Yuanba Qu", "Shizhong Qu", "Cangxi Xian",
                  "Qingchuan Xian", "Wangcang Xian", "Chaotian Qu"],
    "Jieyang": ["Jiedong Qu", "Jiexi Xian", "Rongcheng Qu", "Huilai Xian",
                "Puning Shi"],
    "Sanming": ["Jiangle Xian", "Mingxi Xian", "Yong'an Shi", "Sanyuan Qu",
                "Jianning Xian", "Datian Xian", "Youxi Xian", "Meilie Qu",
                "Sha Xian", "Ninghua Xian", "Taining Xian", "Qingliu Xian"],
    "Bose": ["Youjiang Qu", "Xilin Xian", "Longlin Gezu Zizi Xian",
             "Pingguo Xian", "Dedeping Xian", "Tiandong Xian", "Tianlin Xian",
             "Napo Xian", "Tianyang Xian", "Leye Xian", "Jingxi Shi",
             "Lingyun Xian"],
    "Daqing": ["Saertu Qu", "Datong Qu", "Lindian Xian", "Ranghulu Qu",
               "Zhaozhou Xian", "Honggang Qu", "Longfeng Qu",
               "Duerbote Mengguzu Zizhixian", "Zhaoyuan Xian"],
    "Huludao": ["Lianshan Qu", "Nanpiao Qu", "Suizhong Xian", "Jianchang Xian",
                "Xingcheng Shi", "Longgang Qu"],
    "Chongzuo": ["Ningming Xian", "Longzhou Xian", "Pingxiang Shi",
                 "Tiandeng Xian", "Jiangzhou Qu", "Fusui Xian", "Daxin Xian"],
    "Xuancheng": ["Jixi Xian", "Xuanzhou Qu", "Langxi Xian", "Jingde Xian",
                  "Jing Xian", "Ningguo Shi", "Guangde Xian"],
    "Yichun": ["Tieli Shi", "Yichun Qu", "Xinqing Qu", "Jiayin Xian",
               "Wumahe Qu", "Dailing Qu", "Meixi Qu", "Xilin Qu",
               "Tangwanghe Qu", "Jinshantun Qu", "Wuying Qu", "Wuyiling Qu",
               "Nancha Qu", "Hongxing Qu", "Shangganling Qu", "Cuiluan Qu",
               "Youhao Qu"],
    "Yulin": ["Yuzhou Qu", "Xingye Xian", "Rong Xian", "Beiliu Shi",
              "Luchuan Xian", "Fumian Qu", "Bobai Xian"],
    "Taizhou": ["Gaogang Qu", "Xinghua Shi", "Jiangyan Qu", "Jingjiang Shi",
                "Taixing Shi", "Hailing Qu"],
    "Luoyang": ["Luolong Qu", "Luoning Xian", "Mengjin Xian", "Yanshi Shi",
                "Luanchuan Xian", "Chanhe Huizu Qu", "Jili Qu", "Jianxi Qu",
                "Yiyang Xian", "Xigong Qu", "Song Xian", "Ruyang Xian",
                "Xin'an Xian", "Laocheng Qu", "Yichuan Xian"],
    "Qingyang": ["Huachi Xian", "Ning Xian", "Heshui Xian", "Xifen Qu",
                 "Zhengning Xian", "Qingcheng Xian", "Zhenyuan Xian",
                 "Huan Xian"],
    "Dezhou": ["Decheng Qu", "Qingyun Xian", "Pingyuan Xian", "Leling Shi",
               "Yucheng Shi", "Ningjin Xian", "Wucheng Xian", "Linyi Xian",
               "Qihe Xian", "Xiajin Xian", "Lingcheng Qu"],
    "Xuchang": ["Yanling Xian", "Yuzhou Shi", "Xiangcheng Xian", "Changge Shi",
                "Jian'an Qu", "Weidu Qu"],
    "Dingan Xian": [],
    "Xiangtan": ["Xiangxiang Shi", "Xiangtan Xian", "Shaoshan Shi",
                 "Yuetang Qu", "Yuhu Qu"],
    "Huaibei": ["Suixi Xian", "Duji Qu", "Xiangshan Qu", "Lieshan Qu"],
    "Gannan": ["Hezuo Shi", "Zhuoni Xian", "Xiahe Xian", "Luqu Xian",
               "Zhouqu Xian", "diebu Xian", "Maqu Xian", "Lintan Xian"],
    "Shanwei": ["Luhe Xian", "Chengqu", "Lufeng Shi", "Haifeng Xian"],
    "Da Hinggan Ling": ["Mohe Xian", "Tahe Xian", "Huma Xian"],
    "Xinzhu": [],
    "Wenchang Qu": [],
    "Xigazê": ["Yadong Xian", "Saga Xian", "Sangzhuzi Qu", "Bailang Xian",
               "Dingjie Xian", "Jiangzi Xian", "Nanmulin Xian", "Jilong Xian",
               "Renbu Xian", "Kangma Xian", "Nielamu Xian", "Sajia Xian",
               "Dingri Xian", "Xietongmen Xian", "Zhongba Xian", "Lazi Xian",
               "Angren Xian", "Gangba Xian"],
    "Hanzhong": ["Chenggu Qu", "Foping Xian", "Xixiang Xian", "Lǜeyang Xian",
                 "Ningqiang Xian", "Yang Xian", "Hantai Qu", "Zhenba Xian",
                 "Liuba Xian", "Mian Xian", "Chenggu Xian"],
    "Dongfang Shi": [],
    "Xiaogan": ["Dawu Xian", "Yingcheng Shi", "Xiaonan Qu", "Yunmeng Xian",
                "Hanchuan Shi", "Xiaochang Xian", "Anlu Shi"],
    "Shiyan": ["Danjiangkou Shi", "Maojian Qu", "Yunxi Xian", "Zhuxi Xian",
               "Yunyang Qu", "Fang Xian", "Zhangwan Qu", "Zhushan Xian"],
    "Hongkong Island": [],
    "Jingzhou": ["Jingzhou Qu", "Shishou Shi", "Jiangling Xian", "Honghu Shi",
                 "Songzi Shi", "Shashi Qu", "Jianli Xian", "Gong'an Xian"],
    "Nujiang": ["Lushui Shi", "Gongshan Derungzu Nuzu Zizhixian", "Fugong Xian",
                "Lanping Baizu Pumizu Zizhixian"],
    "Zhenjiang": ["Jingkou Qu", "Runzhou Qu", "Yangzhong Shi", "Danyang Shi",
                  "Jurong Shi", "dantu Qu"],
    "Deyang": ["Luojiang Xian", "Guanghan Shi", "Shifang Shi", "Jingyang Qu",
               "Mianzhu Shi", "Zhongjiang Xian"],
    "Mianyang": ["Pingwu Xian", "Fucheng Qu", "Zitong Xian", "Yanting Xian",
                 "Santai Xian", "Beichuan Qiangzu Zizhixian", "Jiangyou Shi",
                 "Youxian Qu", "Anzhou Qu"],
    "Macao": [],
    "Linfen": ["Huozhou Shi", "Raodu Qu", "Hongdong Xian", "Anze Xian",
               "Yonghe Xian", "Gu Xian", "Xi Xian", "Xiangfen Xian",
               "Fushan Xian", "Ji Xian", "Quwo Xian", "Pu Xian", "Daning Xian",
               "Houma Shi", "Yicheng Xian", "Fenxi Xian", "Xiangning Xian"],
    "Qiqihar": ["Tailai Xian", "Gannan Xian", "Jianhua Qu", "Keshan Xian",
                "Longsha Qu", "Meilisi Dahanerzu Qu", "Fulaerqu Qu",
                "Angangxi Qu", "Kedong Xian", "Nehe Shi", "Tiefeng Qu",
                "Yian Xian", "Baiquan Xian", "Longjiang Xian", "Nianzishan Qu",
                "Fuyu Xian"],
    "Heze": ["Cao Xian", "Mudan Qu", "YunCheng Xian", "JuanCheng Xian",
             "JuYe Xian", "Shan Xian", "Dingtao Qu", "Dongming Xian",
             "ChengWu Xian"],
    "Pingliang": ["Lingtai Xian", "Chongxin Xian", "Kongtong Qu",
                  "Jingning Xian", "Huating Xian", "Jingchuan Xian",
                  "Shuanglang Xian"],
    "Zhuzhou": ["Tianyuan Qu", "Yanling Xian", "Shifeng Qu", "Lusong Qu",
                "Hetang Qu", "Liling Shi", "You Xian", "Chaling Xian",
                "Zhuzhou Xian"],
    "Bengbu": ["Bengshan Qu", "Huaishang Qu", "Huaiyuan Xian", "Longzihu Qu",
               "Wuhe Xian", "Guzhen Xian", "Yuhui Qu"],
    "Kashi": ["Yecheng（Kargilik）Xian", "Zepu（Poskam）Xian",
              "Taxkorgan Tajik Zizhixian", "Shufu Xian", "Shache（Yarkant）Xian",
              "Yopurga Xian", "Yengisar Xian", "Kashi（Kaxgar）Shi",
              "Markit Xian", "Shule Xian", "Jiashi（Payzawat）Xian",
              "Bachu（Maralbexi）Xian"],
    "Nantong": ["Haimen Shi", "Chongchuan Qu", "Hai'an Xian", "Rudong Xian",
                "Qidong Shi", "Tongzhou QU", "Gangzha Qu", "Rugao Shi"],
    "Yangquan": ["Yu Xian", "Jiaoqu", "Chengqu", "Kuangqu", "Pingding Xian"],
    "Hualian": [],
    "Huainan": ["Bagongshan Qu", "Datong Qu", "Fengtai Xian", "Panji Qu",
                "Xiejiaji Qu", "Shou Xian", "Tianjia'an Qu"],
    "Xinyang": ["Xi Xian", "Xin Xian", "Gushi Xian", "Huaibin Xian",
                "Shangcheng Xian", "Shihe Qu", "Luoshan Xian", "Guangshan Xian",
                "Pingqiao Qu", "Huangchuan Xian"],
    "Dandong": ["Fengcheng Shi", "Kuandian Manzu Zizhixian", "Zhenan Qu",
                "Zhenxing Qu", "Donggang Shi", "Yuanbao Qu"],
    "Lincang": ["Linxiang Qu", "Gengma Daizu Wazu Zizhixian", "Fengqing Xian",
                "Yun Xian", "Zhenkang Xian", "Yongde Xian",
                "Cangyuan Wazu Zizhixian",
                "Shuangjiang Lahuzu Bulangzu Daizu Zizhixian"],
    "Zhangye": ["Shandan Xian", "Gaotai Xian", "Su'nanyu Guzu Zizhixian",
                "Minle Xian", "Ganzhou Qu", "Lanze Xian"],
    "Dehong": ["Ruili Shi", "Luxi Shi", "Yingjiang Xian", "Lianghe Xian",
               "Longchuan Xian"],
    "Xi'an": ["Zhouzhi Xian", "Baqiao Qu", "Chang'an Qu", "Yanliang Qu",
              "Lantian Xian", "Gaoling Qu", "Lintong Qu", "Weiyang Qu",
              "Lianhu Qu", "Huyi Qu", "Xincheng Qu", "Beilin Qu", "Yanta Qu"],
    "Dingxi": ["Min Xian", "Weiyuan Xian", "Longxi Xian", "Lintao Xian",
               "Zhang Xian", "Anding Xian", "Tongwei xian"],
    "Shennongjia": [],
    "Suihua": ["Qinggang Xian", "Qingan Xian", "Anda Shi", "Hailun Shi",
               "Wangkui Xian", "Lanxi Xian", "Suileng Xian", "Mingshui Xian",
               "Zhaodong Shi", "Beilin Qu"],
    "Qingdao": ["Laoshan Qu", "Licang Qu", "Jiaozhou Shi", "Jimo Qu",
                "Chengyang Qu", "Laixi Shi", "Shinan Qu", "Pingdu Shi",
                "Huangdao Qu", "Shibei Qu"],
    "Jincheng": ["Lingchuan Xian", "Chengqu", "Qinshui Xian", "Zezhou Xian",
                 "Yangcheng Xian", "Gaoping Shi"],
    "Bayannur": ["Hangjin Houqi", "Wulate Houqi", "Wulate Zhongqi",
                 "Wuyuan Xian", "Linhe Qu", "Wulate Qianqi", "Dengkou Xian"],
    "Haikou": ["Xiuying Qu", "Qiongshan Qu", "Longhua Qu", "Meilan Qu"],
    "Yiyang": ["Yuanjiang Shi", "Nan Xian", "Taojiang Xian", "Ziyang Qu",
               "Heshan Qu", "Anhua Xian"],
    "Penghu": [],
    "Liangshan": ["Meigu Xian", "Leibo Xian", "Puge Xian", "Yuexi Xian",
                  "Huidong Xian", "Jinyang Xian", "Huili Xian", "Dechang Xian",
                  "Butuo Xian", "Muli Zangzu Zizhixian", "Xichang Shi",
                  "Mianning Xian", "Ningnan Xian", "Zhaojue Xian",
                  "Ganluo Xian", "Yanyuan Xian", "Xide Xian"],
    "Yushu": ["Zhidoi Xian", "Chindu Xian", "Yushu Xian", "Zadoi Xian",
              "Nangqên Xian", "Qumarlêb Xian"],
    "Bozhou": ["Guyang Xian", "Lixin Xian", "Qiaocheng Qu", "Mengcheng Xian"],
    "Ili Kazak": ["Chabuchaerxibozizhi Xian", "Gongliu Xian", "Yining Shi",
                  "Nileke Xian", "yining Xian", "Xinyuan Xian", "Kuitun Shi",
                  "Tekesi Xian", "Zhaosu Xian", "Huocheng Xian",
                  "Huoerguoshi Shi"],
    "Tacheng": ["Shawan Xian", "Usu Shi", "Yumin（Qagantokay）Xian", "Toli Xian",
                "Tacheng（Qoqek）Shi", "Emin（Dorbiljin）Xian",
                "Hoboksar Mongol Zizhixian"],
    "Baicheng": ["Zhenlai Xian", "Da'an Shi", "Taonan Shi", "Tongyu Xian",
                 "Taobei Qu"],
    "Changzhi": ["Chengqu", "Zhangzi Xian", "Huguan Xian", "Pingshun Xian",
                 "Qinyuan Xian", "Lucheng Shi", "Wuxiang Xian",
                 "Xiangyuan Xian", "Jiaoqu", "Licheng Xian", "Tunliu Xian",
                 "Changzhi Xian", "Qin Xian"],
    "Qinhuangdao": ["Haigang Qu", "Lulong Xian", "Shanhaiguan Qu", "Funing Qu",
                    "Changli Xian", "Beidaihe Qu", "Qinglong Manzu Zizhixian"],
    "Bayingolin Mongol": ["Hoxud Xian", "Hejing Xian", "Ruoqiang（Qakilik）Xian",
                          "Luntai（Bügür）Xian", "Yanqi Huizu Zizhixian",
                          "Korla Shi", "Yuli（Lopnur）Xian", "Bohu（Bagrax）Xian",
                          "Qiemo（Qarqan）Xian"],
    "Qianjiang": [],
    "Maoming": ["Huazhou Shi", "Maonan Qu", "Gaozhou Shi", "Dianbai Qu",
                "Xinyi Shi"],
    "Chuxiong": ["Wuding Xian", "Mouding Xian", "Nanhua Xian", "Dayao Xian",
                 "Yao'an Xian", "Yongren Xian", "Shuangbai Xian",
                 "Yuanmou Xian", "Chuxiong Shi", "Lufeng Xian"],
    "Nanping": ["Zhenghe Xian", "Songxi Xian", "Shunchang Xian", "Yanping Qu",
                "Shaowu Shi", "Pucheng Xian", "Wuyishan Shi", "Jian'ou Shi",
                "Guangze Xian", "Jianyang Qu"],
    "Tianmen": [],
    "Chengdu": ["Dayi Xian", "Shuangliu Qu", "Chenghua Qu", "Jinjiang Qu",
                "Pidou Qu", "Wenjiang Qu", "Pengzhou Shi", "Dujiangyan Shi",
                "Xinjin Xian", "Chongzhou Shi", "Qingyang Qu", "Qionglai Shi",
                "Longquanyi Qu", "Jianyang Qu", "Jinniu Qu", "Pujiang Xian",
                "Qingbaijiang Qu", "Xindu Qu", "Wuhou Qu", "Jintang Xian"],
    "Zhanjiang": ["Lianjiang Shi", "Leizhou Shi", "Chikan Qu", "Mazhang Qu",
                  "Suixi Xian", "Xiashan Qu", "Wuchuan Shi", "Xuwen Xian",
                  "Potou Qu"],
    "Nagqu": ["Bange Xian", "Nierong Xian", "Shuanghu Xian", "Baqing Xian",
              "Seni Qu", "Suo Xian", "Nima Xian", "Shenzha Xian", "Biru Xian",
              "Anduo Xian", "Jiali Xian"],
    "Cangzhou": ["Wuqiao Xian", "Dongguang Xian", "Xinhua Qu", "Huanghua Shi",
                 "Hejian Shi", "Yanshan Xian", "Botou Shi",
                 "Mengcun Huizu Zizhixian", "Nanpi Xian", "Renqiu Shi",
                 "Haixing Xian", "Cang Xian", "Suning Xian", "Xian Xian",
                 "Qing Xian", "Yunhe Qu"],
    "Wuhan": ["Hongshan Qu", "Huangpi Qu", "Caidian Qu", "Jiang'an Qu",
              "Wuchang Qu", "Hannan Qu", "Dongxihu Qu", "Jianghan Qu",
              "Qiaokou Qu", "Jiangxia Qu", "Hanyang Qu", "Xinzhou Qu",
              "Qingshan Qu"],
    "Pingdong": [],
    "Nantou": [],
    "Yanbian": ["Helong Shi", "Dunhua Shi", "Antu Xian", "Tumen Shi",
                "Longjing Shi", "Hunchun Shi", "Yanji Shi", "Wangqing Xian"],
    "Qingyuan": ["Qingcheng Qu", "Lianshan Zhuangzu Yaozu Zizhixian",
                 "Yangshan Xian", "Yingde Shi", "Liannan Yaozu Zizhixian",
                 "Lianzhou Shi", "Fogang Xian", "Qingxin Qu"],
    "Lingshui Lizu Zizhixian": [],
    "Ngari": [],
    "Xiamen": ["Haicang Qu", "Xiangan Qu", "Siming Qu", "Jimei Qu", "Huli Qu",
               "Tong'an Qu"],
    "Ningbo": ["Yuyao Shi", "Jiangbei Qu", "Zhenhai Qu", "Yinzhou Qu",
               "Fenghua Qu", "Haishu Qu", "Cixi Shi", "Ninghai Xian",
               "Beilun Qu", "Xiangshan Xian"],
    "Wuzhishan Shi": [],
    "Xiangfan": ["Nanzhang Xian", "Yicheng Shi", "Zaoyang Shi", "Baokang Xian",
                 "Xiangcheng Qu", "Fancheng Qu", "Laohekou Shi", "Xiangzhou Qu",
                 "Gucheng Xian"],
    "Baotou": ["Donghe Qu", "Baiyun Ebo Kuangqu", "Tumote Youqi", "Hondlon Qu",
               "Guyang Xian", "Daerhanmaomingan Lianheqi", "Qingshan Qu",
               "Jiuyuan Qu", "Shiguai Kuangqu"],
    "Fuxin": ["Xinqiu Qu", "Haizhou Qu", "Xihe Qu", "Fuxin Mengguzu Zizhixian",
              "Taiping Qu", "Zhangwu Xian", "Qinghemen Qu"],
    "Heyuan": ["Dongyuan Xian", "Longchuan Xian", "Lianping Xian",
               "Heping Xian", "Zijin Xian", "Yuancheng Qu"],
    "Jinhua": ["Dongyang Shi", "Jindong Qu", "Wucheng Qu", "Lanxi Shi",
               "Pujiang Xian", "Pan'an Xian", "Yongkang Shi", "Yiwu Shi",
               "Wuyi Xian"],
    "Baoting Lizu Miaozu Zizhixian": [],
    "Xingan Meng": ["Ke'erqin Youyi Qianqi", "A'ershan Shi", "Wulanhaote Shi",
                    "Zhalaite Qi", "Ke'erqin Youyi Zhongqi", "Tuquan Xian"],
    "Yunlin": [],
    "Fangchenggang": ["Dongxing Shi", "Gangkou Qu", "Fangcheng Qu",
                      "Shangsi Xian"],
    "Hangzhou": ["Fuyang Qu", "Jiande Shi", "Xiaoshan Qu", "Tonglu Xian",
                 "Xiacheng Qu", "Xihu Qu", "Lingan Qu", "Chun'an Xian",
                 "Shangcheng Qu", "Yuhang Qu", "Gongshu Qu", "Binjiang Qu",
                 "Jianggan Qu"],
    "Suqian": ["Shuyang Xian", "Siyang Xian", "Sihong Xian", "Sucheng Qu",
               "Suyu Qu"],
    "Weihai": ["Rushan Shi", "Wendeng Qu", "Rongcheng Shi", "Huancui Qu"],
    "Jiaxing": ["Nanhu Qu", "Haiyan Xian", "Haining Shi", "Pinghu Shi",
                "Tongxiang Shi", "Xiuzhou Qu", "Jiashan Xian"],
    "Xingtai": ["Wei Xian", "Linxi Xian", "Qinghe Xian", "Ningjin Xian",
                "Qiaodong Qu", "Baixiang Xian", "Pingxiang Xian", "Nangong Shi",
                "Julu Xian", "Xinhe Xian", "Longyao Xian", "Guangzong Xian",
                "Shahe Shi", "Ren Xian", "Qiaoxi Qu", "Lincheng Xian",
                "Nanhe Xian", "Neiqiu Xian", "Xingtai Xian"],
    "Harbin": ["Songbei Qu", "Bin Xian", "Daowai Qu", "Daoli Qu", "Nangang Qu",
               "xiangfang Qu", "Shuangcheng Qu", "Mulan Xian", "Tonghe Xian",
               "Shangzhi Shi", "Yanshou Xian", "Acheng Qu", "Yilan Xian",
               "Bayan Xian", "Fangzheng Xian", "Hulan Qu", "Pingfang Qu",
               "Wuchang Shi"],
    "Jiangmen": ["Jianghai Qu", "Taishan Shi", "Enping Shi", "Heshan Shi",
                 "Kaiping Shi", "Xinhui Qu", "Pengjiang Qu"],
    "Hezhou": ["Zhongshan Xian", "Fuchuanyaozu Zizhixian", "Zhaopingxina",
               "Babu Qu", "Pinggui Qu"],
    "Lüliang": ["Xing Xian", "Fangshan Xian", "Shilou Xian", "Jiaocheng Xian",
                "Xiaoyi Shi", "Lin Xian", "Lishi Qu", "Zhongyang Xian",
                "Wenshui Xian", "Lan Xian", "Fenyang Shi", "Jiaokou Xian",
                "Liulin Xian"],
    "Fushun": ["Qingyuan Manzu Zizhixian", "Fushun Xian",
               "Xinbin Manzu Zizhixian", "Xinfu Qu", "Dongzhou Qu",
               "Wanghua Qu", "Shuncheng Qu"],
    "Lianyungang": ["Guannan Xian", "Haizhou Qu", "Ganyu Qu", "Guanyun Xian",
                    "Donghai Xian", "Lianyun Qu"],
    "Laibin": ["Jinxiu Yaozu Zizhixian", "Xiangzhou Xian", "Heshan Shi",
               "Xincheng Xian", "Xingbin Qu", "Xuxuan Xian"],
    "Xinjie": [],
    "Ganzhou": ["Longnan Xian", "Quannan Xian", "Dingnan Xian", "Ruijin Shi",
                "Shangyou Xian", "Chongyi Xian", "Shicheng Xian", "Dayu Xian",
                "Yudu Xian", "Xunwu Xian", "Xinfeng Xian", "Zhanggong Qu",
                "Huichang Xian", "Ningdu Xian", "Nankang Qu", "Ganxian Qu",
                "Anyuan Xian", "Xingguo Xian"],
    "Xiantao": [],
    "Xiangxi": ["Huayuan Xian", "Luxi Xian", "Fenghuang Xian", "Guzhang Xian",
                "Yongshun Xian", "Baojing Xian", "Jishou Shi", "Longshan Xian"],
    "Wuhai": ["Hainan Qu", "Wuda Qu", "Haibowan Qu"],
    "Siping": ["Lishu Xian", "Gongzhuling Shi", "Tiexi Qu",
               "Yitong Manzu Zizhixian", "Tiedong Qu", "Shuangliao Shi"],
    "Wuwei": ["Minqin Xian", "Tianzhu Zangzu Zizhixian", "Gulang Xian",
              "Liangzhou Qu"],
    "Zhumadian": ["Zhengyang Xian", "Yicheng Qu", "Queshan Xian", "Pingyu Xian",
                  "Suiping Xian", "Runan Xian", "Xiping Xian", "Miyang Xian",
                  "Xincai Xian", "Jiyuan Shi", "Shangcai Xian"],
    "Linxia": ["Jishishan Bonanzu Dongxiangzu Salarzu Zizhixian", "Kangle Xian",
               "Linxia Xian", "Guanghe Xian", "Linxia Shi", "Hezheng Xian",
               "Yongjing Xian", "Dongxiangzu Zizhixian"],
    "Ji'an": ["Qingyuan Xian", "Xingan Xian", "Yongfeng Xian", "Wanan Xian",
              "Jinggangshan Shi", "Ji'an Xian", "Taihe Xian", "Xiajiang Xian",
              "Jizhou Xian", "Jishui Xian", "Yongxin Xian", "Anfu Xian",
              "Suichuan Xian"],
    "Guiyang": ["Guanshanhu Qu", "Kaiyang Xian", "Nanming Qu", "Baiyun Qu",
                "Yunyan Qu", "Huaxi Qu", "Xifeng Xian", "Qingzhen Shi",
                "Wudang Qu", "Xiuwen Xian"],
    "Chuzhou": ["Tianchang Shi", "Lai'an Xian", "Quanjiao Xian", "Langya Qu",
                "Nanqiao Qu", "Fengyang Xian", "Mingguang Shi",
                "Dingyuan Xian"],
    "Anshan": ["Tiedong Qu", "Qianshan Qu", "Tai'an Xian", "Lishan Qu",
               "Xiuyan Manzu Zizhixian", "Tiexi Qu", "Haicheng Shi"],
    "Changji": ["Jimsar Xian", "Mori Kazak Zizhixian", "Changji Shi",
                "Fukang Shi", "Manas Xian", "Qitai Xian", "Hutubi Xian"],
    "Anshun": ["Xixiu Qu", "Puding Xian", "Ziyun Miaozu Buyizu Zizhixian",
               "Zhenning Buyizu Miaozu Zizhixian", "Pingba Qu",
               "Guanling Buyizu Miaozu Zizhixian"],
    "Tianshui": ["Qincheng Qu", "Maiji Qu", "Gangu Xian",
                 "Zhangjiachuan Huizu Zizhixian", "Wushan Xian",
                 "Qingshui Xian", "Qin'an Xian"],
    "Jiayi": [],
    "Liaoyuan": ["Xian Qu", "Longshan Qu", "Dongfeng Xian", "Dongliao Xian"],
    "Wenzhou": ["Taishun Xian", "Pingyang Xian", "Ouhai Qu", "Longwan Qu",
                "Rui'an Shi", "Yongjia Xian", "Dongtou Qu", "Cangnan Xian",
                "Yueqing Shi", "Lucheng Qu", "Wencheng Xian"],
    "Fuyang": ["Taihe Xian", "Jieshou Shi", "Linquan Xian", "Yingdong Qu",
               "Yingshang Xian", "Yingquan Qu", "Yingzhou Qu", "Funan Xian"],
    "Hohhot": ["Huimin Qu", "Yuquan Qu", "Tuoketuo Xian", "Xincheng Qu",
               "Tumote Zuoqi", "Helingeer Xian", "Saihan Qu", "Qingshuihe Xian",
               "Wuchuan Xian"],
    "Aksu": ["Wensu Xian", "Kuqa Xian", "Awat Xian", "Kalpin Xian",
             "Baicheng（Bay）Xian", "Aksu Shi", "Xinhe（Toksu）Xian",
             "Wushi（Uqturpan）Xian", "Xayar Xian"],
    "Zhanghua": [],
    "Luzhou": ["Gulin Xian", "Longmatan Qu", "Jiangyang Qu", "Lu Xian",
               "Naxi Qu", "Xuyong Xian", "Hejiang Xian"],
    "Ma'anshan": ["Dangtu Xian", "He Xian", "Huashan Qu", "Hanshan Shan",
                  "Yushan Qu", "Bowang Qu"],
    "Huzhou": ["Changxing Xian", "Nanxun Qu", "Deqing Xian", "Anji Xian",
               "Wuxing Qu"],
    "Chaoyang": ["Chaoyang Xian", "Harqin Zuoyi Mongolzu Zizhixian",
                 "Lingyuan Shi", "Longcheng Qu", "Beipiao Shi", "Shuangta Qu",
                 "Jianping Xian"],
    "Shangluo": ["Luonan Xian", "Zhashui Xian", "Danfeng Xian", "Shangnan Xian",
                 "Shanyang Xian", "Zhenan Xian", "shangzhouqu"],
    "Liuzhou": ["Sanjiang Dongzu Zizhixian", "Liujiang Qu", "Rong'an Xian",
                "Chengzhong Qu", "Yufeng Qu", "Rongshui Miaozu Zizhixian",
                "Luzhai Xian", "Liubei Qu", "Liunan Qu", "Liucheng Xian"],
    "Tongling": ["Tongguan Qu", "Zongyang Xian", "Jiaoqu", "Yian Qu"],
    "Yueyang": ["Yueyanglou Qu", "Xiangyin Xian", "Linxiang Shi", "Miluo Shi",
                "Junshan Qu", "Yueyang Xian", "Huarong Xian", "Yunxi Qu",
                "Pingjiang Xian"],
    "Xuzhou": ["Quanshan Qu", "Jiawang Qu", "Suining Xian", "Pei Xian",
               "Gulou Qu", "Tongshan Qu", "Xinyi Shi", "Yunlong Qu",
               "Feng Xian", "Pizhou Shi"],
    "Zhangjiajie": ["Cili Xian", "Yongding Qu", "Wulingyuan Qu",
                    "Sangzhi Xian"],
    "Yinchuan": ["Yongning Xian", "Lingwu Qu", "XiXia Qu", "Xingqing Qu",
                 "Helan Xian", "jingfengqu"],
    "Bortala Mongol": ["Wenquan（Arixang）Xian", "Jinghe（Jing）Xian",
                       "Alashankou Shi", "Bole（Bortala）Shi"],
    "Pu'er": ["Jingdong Yizu Zizhixian",
              "Zhenyuan Yizu Hanizu Lahuzu Zizhixian",
              "Menglian Daizu Lahuzu Wazu Zizhixian", "Simao Qu",
              "Ximeng Wazu Zizhixian", "Lancang Lahuzu Zizhixian",
              "Jinggu Daizu Yizu Zizhixian", "Ninger Hanizu Yizu Zizhixian",
              "Mojiang Hanizu Zizhixian", "Jiangcheng Hanizu Yizu Zizhixian"],
    "Anqing": ["Taihu Xian", "Yuexi Xian", "Daguan Qu", "Huaining Xian",
               "Yingjiang Qu", "Susong Xian", "Wangjiang Xian", "Tongcheng Shi",
               "Qianshan Xian", "Yixiu Qu"],
    "Sanmenxia": ["Lushi Xian", "Hubin Qu", "Yima Shi", "Mianchi Xian",
                  "Shanzhou Qu", "Lingbao Shi"],
    "Tonghua": ["Meihekou Shi", "Huinan Xian", "Liuhe Xian", "Jian Shi",
                "Dongchang Qu", "Tonghua Xian", "Erdaojiang Qu"],
    "Zhaoqing": ["Gaoyao Qu", "Guangning Xian", "Duanzhou Qu", "Sihui Shi",
                 "Dinghu Qu", "Huaiji Xian", "Fengkai Xian", "Deqing Xian"],
    "Kizilsu Kirgiz": ["Akto Xian", "Artux Shi", "Akqi Xian",
                       "Wuqia（Ulugqat）Xian"],
    "Wuxi": ["Xinwu Qu", "Binhu Qu", "Liangxi Qu", "huishan Qu", "Jiangyin Shi",
             "Xishan Qu", "Yixing Shi"],
    "Taidong": [],
    "Handan": ["Quzhou Xian", "Fuxing Qu", "Jize Xian", "Guangping Xian",
               "Daming Xian", "Ci Xian", "Linzhang Xian", "Congtai Qu",
               "Qiu Xian", "Feixiang Qu", "Wu'an Shi", "Cheng'an Xian",
               "Guantao Xian", "Fengfeng Kuangqu", "She Xian", "Yongnian Qu",
               "Wei Xian", "Hanshan Qu"],
    "Xinyu": ["Fenyi Xian", "Yushui Qu"],
    "Zhengzhou": ["Xinmi Shi", "Guancheng Huizu Qu", "Gongyi Shi",
                  "Xinzheng Shi", "Shangjie Qu", "Erqi Qu", "Zhongyuan Qu",
                  "HuiJi Qu", "Zhongmou Xian", "Xingyang Shi", "Jinshui Qu",
                  "Dengfeng Shi"],
    "Hulun Buir": ["A'rong Qi", "Yakeshi Shi", "Chenba'er Huqi", "Haila'er Qu",
                   "Wenkezu Zizhiqi", "E'ergu'na Shi", "Xinba'er Huyouqi",
                   "E'lunchun Zizhiqi", "Manzhouli Shi", "Zhalainuo'er Qu",
                   "Molidawada Wo'erzu Zizhiqi", "Xinba'er Huzuoqi",
                   "Zhalantun Shi", "Genhe Shi"],
    "Hegang": ["Xingshan Qu", "Luobei Xian", "Gongnong Qu", "Xiangyang Qu",
               "Suibin Xian", "Nanshan Qu", "Dongshan Qu", "Xingan Qu"],
    "Suizhou": ["Guangshui Shi", "Sui Xian", "Cengdou Qu"],
    "Ziyang": ["Yanjiang Qu", "Anyue Xian", "Lezhi Xian"],
    "Zhangjiakou": ["Yu Xian", "Qiaoxi Qu", "Kangbao Xian", "Zhangbei Xian",
                    "Yangyuan Xian", "Qiaodong Qu", "Wanquan Qu",
                    "Huailai Xian", "Xuanhua Qu", "Chongli Qu", "Xiahuayuan Qu",
                    "Chicheng Xian", "Huai'an Xian", "Zhuolu Xian",
                    "Shangyi Xian", "Guyuan Xian"],
    "Shaoguan": ["Qujiang Qu", "Ruyuan Yaozu Zizhixian", "Nanxiong Shi",
                 "Zhenjiang Qu", "Xinfeng Xian", "Renhua Xian", "Wengyuan Xian",
                 "Shixing Xian", "Wujiang Qu", "Lechang Shi"],
    "Danzhou Shi": [],
    "Huanggang": ["Yingshan Xian", "Wuxue Shi", "Tuanfeng Xian", "Hong'an Xian",
                  "Huangmei Xian", "Luotian Xian", "Macheng Shi", "Qichun Xian",
                  "Huangzhou Qu", "Xishui Xian"],
    "Taibei": [],
    "Bazhong": ["Tongjiang Xian", "Bazhou Xian", "Enyang Xian", "Nanjiang Xian",
                "Pingchang Xian"],
    "Yongzhou": ["Lengshuitan Qu", "Shuangpai Xian", "Lanshan Xian",
                 "Jianghua Yaozu Zizhixian", "Lingling Qu", "Ningyuan Xian",
                 "Dong'an Xian", "Qiyang Xian", "Jiangyong Xian", "Dao Xian",
                 "Xintian Xian"],
    "Loudi": ["Shuangfeng Xian", "Lianyuan Shi", "Louxing Qu", "Xinhua Xian",
              "Lengshuijiang Shi"],
    "Bijie": ["Qixingguan Qu", "Qianxi Xian", "Zhijin Xian", "Dafang xian",
              "Weining Yizu Huizu Miaozu Zizhixian", "Hezhang Xian",
              "Jingsha Xian", "Nayong Xian"],
    "Ledong Lizu Zizhixian": [],
    "Zhongwei": ["Haiyuan Xian", "Zhongning Xian", "Shapotou Qu"],
    "Dongguan": [],
    "Heihe": ["Sunwu Xian", "Aihui Qu", "Xunke Xian", "Wudalianchi Shi",
              "Bei'an Shi", "Nenjiang Xian"],
    "Miaosu": [],
    "Jinchang": ["Yongchang Xian", "Jinchuan Qu"],
    "Shaoyang": ["Shaodong Xian", "Dongkou Xian", "Suining Xian",
                 "Xinshao Xian", "Xinning Xian", "Shaoyang Xian",
                 "Longhui Xian", "Chengbu Miaozu Zizhixian", "Shuangqing Qu",
                 "Wugang Shi", "Daxiang Qu", "Beita Qu"],
    "Longnan": ["Kang Xian", "Xihe Xian", "Cheng Xian", "Wudu Qu",
                "Liangdang Xian", "Hui Xian", "Li Xian", "Wen Xian",
                "Dangchang Xian"],
    "Shantou": ["Haojiang Qu", "Longhu Qu", "Chaonan Qu", "Nan'ao Xian",
                "Chaoyang Qu", "Chenghai Qu", "Jinping Qu"],
    "Tongren": ["Sinan Xian", "shiqian Xian", "Jiangkou Xian", "Dejiang Xian",
                "Songtao Miaozu Zizhixian", "Yinjiang Tujiazu Miaozu Zizhixian",
                "Yanhe Tujiazu Zizhixian", "Yuping Dongzu Zizhixian",
                "Bijiang Qu", "Wanshan qu"],
    "Taoyuan": [],
    "Jilong": [],
    "Nanning": ["Shanglin Xian", "Jiangnan Qu", "Mashan Xian", "Xingning Qu",
                "Xincheng Qu", "Long'an Xian", "Heng Xian", "Binyang Xian",
                "Yongning Qu", "Liangqing Qu", "Xixiangtang Qu", "Wuming Qu"],
    "Laiwu": ["Laicheng Qu", "Gangcheng Qu"],
    "Taiyuan": ["Jinyuan Qu", "Wanbailin Qu", "Jiancaoping Qu", "Yingze Qu",
                "Loufan Xian", "Xinghualing Qu", "Xiaodian Qu", "Qingxu Xian",
                "Yangqu Xian", "Gujiao Shi"],
    "Sanya": ["Zazhou Qu", "Hianya Qu", "Jiyang Qu", "Haitang Qu"],
    "Shangqiu": ["Sui Xian", "Minquan Xian", "Xiayi Xian", "Yongcheng Shi",
                 "Zhecheng Xian", "Liangyuan Qu", "Yucheng Xian",
                 "Ningling Xian", "Suiyang Qu"],
    "Jinan": ["Lixia Qu", "Shanghe Xian", "Tianqiao Qu", "Licheng Qu",
              "Jiyang Xian", "Zhangqiu Qu", "Huaiyin Qu", "Shizhong Qu",
              "Pingyin Xian", "Changqing Qu"],
    "Ningde": ["Shouning Xian", "Xiapu Xian", "Gutian Xian", "Pingnan Xian",
               "Fuan Shi", "Jiaocheng Qu", "Fuding Shi", "Zherong Xian",
               "Zhouning Xian"],
    "Longyan": ["Changting Xian", "Shanghang Xian", "Yongding Qu",
                "Wuping Xian", "Liancheng Xian", "Zhangping Shi", "Xinluo Qu"],
    "Guilin": ["Guanyang Xian", "Lingchuan Xian", "Qixing Qu", "Yongfu Xian",
               "Xiangshan Qu", "Ziyuan Xian", "Yangshuo Xian",
               "Gongcheng Yaozu Zizhixian", "Xing'an Xian", "Diecai Qu",
               "Lipu Xian", "Quanzhou Xian", "Xiufeng Qu", "Lingui Qu",
               "Pingle Xian", "Longsheng Gezu Zizhixian", "Yanshan Qu"],
    "Hotan": ["Yutian（Keriya）Xian", "Minfeng（Niya）Xian", "Moyu（Karakax）Xian",
              "Qira Xian", "Hotan Xian", "Lop Xian", "Pishan（Guma）Xian",
              "Hotan Shi"],
    "Dêqên": ["Weixi Lisuzu Zizhixian", "Dêqên Xian", "Xianggelila Shi"],
    "Baoding": ["Mancheng Qu", "Xinshi Qu", "Yi Xian", "Gaobeidian Shi",
                "Xiong Xian", "Anxin Xian", "Xushui Qu", "Dingxing Xian",
                "Anguo Shi", "Zhuozhou Shi", "Lianchi Qu", "Qingyuan Qu",
                "Boye Xian", "Quyang Xian", "Shunping Xian", "Laishui Xian",
                "Gaoyang Xian", "Tang Xian", "Wangdu Xian", "Fuping Xian",
                "Rongcheng Xian", "Li Xian", "Dingzhou Shi", "Laiyuan Xian"],
    "Turpan": ["Tuokexun Xian", "Shanshan Xian", "Gaochang Qu"],
    "Guyuan": ["Jingyuan Xian", "Longde Xian", "Pengyang Xian", "Xiji Xian",
               "Yuanzhou Qu"],
    "Huaihua": ["Chenxi Xian", "Yuanling Xian", "Zhijiang Dongzu Zizhixian",
                "Zhongfang Xian", "Huitong Xian", "Xupu Xian",
                "Xinhuang Dongzu Zizhixian", "Jingzhou Miaozu Dongzu Zizhixian",
                "Tongdao Dongzu Zizhixian", "Hecheng Qu",
                "Mayang Miaozu Zizhixian", "Hongjiang Shi"],
    "Ordos": ["Dongsheng Qu", "Hangjin Qi", "Kangbashi Qu", "Wushen Qi",
              "Dalate Qi", "E'tuoke Qi", "Zhunge'er Qi", "E'tuoke Qianqi",
              "Yijinhuoluo Qi"],
    "ShuangyaShan": ["Jianshan Qu", "Jixian Xian", "Baoqing Xian",
                     "Lingdong Qu", "Sifangtai Qu", "Youyi Xian", "Raohe Xian",
                     "Baoshan Qu"],
    "Chongqing": ["Hechuan Qu", "Wushan Xian", "Wulong Qu", "Yongnan Qu",
                  "Dadukou Qu", "Qijiang Qu", "Nanchuan Qu", "Beibei Qu",
                  "Jiangbei Qu", "Yubei Qu", "Chengkou Xian", "Tongnan Qu",
                  "Liangping Qu", "Yuzhong Qu", "Dazu Qu", "Shapingba Qu",
                  "Wuxi Xian", "Fengjie Xian", "Wanzhou Qu", "Bishan Qu",
                  "Yunyang Xian", "Banan Qu", "Jiangjin Qu", "Fengdu Xian",
                  "Nan'an Qu", "Jiulongpo Qu", "Kaizhou Qu", "Zhong Xian",
                  "Pengshui Miaozu Tujiazu Zizhixian", "Fuling Qu",
                  "Xiushan Tujiazu Miaozu Zizhixian",
                  "Youyang Tujiazu Miaozu Zizhixian",
                  "Shizhu Tujiazu Zizhixian", "Rongchang Qu", "Dianjiang Xian",
                  "Qianjiang Qu", "Changshou Qu", "Tongliang Qu"],
    "Yibin": ["Yibin Xian", "Nanxi Qu", "Pingshan Xian", "Gao Xian",
              "Gong Xian", "Changning Xian", "Cuiping Qu", "Junlian Xian",
              "Xingwen Xian", "Jiang'an Xian"],
    "Hainan": ["Xinghai Xian", "Tongde Xian", "Guide Xian", "Gonghe Xian",
               "Guinan Xian"],
    "Shuozhou": ["Shanyin Xian", "Huairen Xian", "Ying Xian", "Pinglu Qu",
                 "Shuocheng Qu", "Youyu Xian"],
    "Langfang": ["Wen'an Xian", "Daicheng Xian", "Xianghe Xian", "Guangyang Qu",
                 "Dachang Huizu Zizhixian", "Gu'an Xian", "Yongqing Xian",
                 "Anci Qu", "Sanhe Shi", "Bazhou Shi"],
    "Nanchang": ["Anyi Xian", "Qingshanhu Qu", "Jinxian Xian", "Xinjian Qu",
                 "Nanchang Xian", "Qingyunpu Qu", "Wanli Qu", "Donghu Qu",
                 "Xihu Qu"],
    "AnKang": ["Hanbin Qu", "Zhenping Xian", "Hanyin Xian", "Langao Xian",
               "Pingli Xian", "Ningshan Xian", "Xunyang Xian", "Ziyang Xian",
               "Shiquan Xian", "Baihe Xian"],
    "Zaozhuang": ["Xuecheng Qu", "Yicheng Qu", "Tai'erzhuang Qu",
                  "Tengzhou Shi", "Shanting Qu", "Shizhong Qu"],
    "Yuxi": ["Chengjiang Xian", "Yimen Xian", "Eshan Yizu Zizhixian",
             "Tonghai Xian", "Xinping Yizu Daizu Zizhixian",
             "Yuanjiang Hanizu Yizu Daizu Zizhixian", "Hongta Qu",
             "Jiangchuan Qu", "Huaning Xian"],
    "Hami": ["Yiwu xian", "Balikunhasake Zizhixian", "Yizhou Qu"],
    "Chizhou": ["Dongzhi Xian", "Qingyang Xian", "Shitai Xian", "Guichi Qu"],
    "Putian": ["Xianyou Xian", "Hanjiang Qu", "Chengxiang Qu", "Licheng Qu",
               "Xiuyu Qu"],
    "Zigong": ["Fushun Xian", "Rong Xian", "Da'an Qu", "Ziliujing Qu",
               "Gongjing Qu", "Yantan Qu"],
    "Neijiang": ["Zizhong Xian", "Longchang Qu", "Shizhong Qu", "Dongxing Qu",
                 "Weiyuan Xian"],
    "Kunming": ["Xishan Qu", "Panlong Qu", "Songming Xian", "Wuhua Qu",
                "Dongchuan Qu", "Shilin Yizu Zizhixian", "Fumin Xian",
                "Luchuan Yizu Miaozu Zizhixian", "Anning Shi",
                "Xundian Huizu Yizu Zizhixian", "Yiliang Xian", "Chenggong Qu",
                "Jinning Qu", "Guandu Qu"],
    "Haidong": ["Minhe HuizuTuzu Zizhixian", "Huzhu Tuzu Zizhixian",
                "Ping'an Qu", "Dunhuasala Zizhixian", "Ledu Xian",
                "Hualong Huizu zizhixian"],
    "Jiujiang": ["Xunyang Qu", "Wuning Xian", "Hukou Xian", "Ruichang Shi",
                 "Lushan Shi", "Chaisang Qu", "Lianxi Qu", "Xiushui Xian",
                 "De'an Xian", "Yongxiu Xian", "Gongqing Chengshi",
                 "Duchang Xian", "Pengze Xian"],
    "Changzhou": ["Jiaoqu", "Wujin Qu", "Tianning Qu", "Zhonglou Qu",
                  "Jintan Qu", "Liyang Shi"],
    "Baiyin": ["Huining Xian", "Pingchuan Qu", "Jingtai Xian", "Jingyuan Xian",
               "Baiyin Qu"],
    "Weinan": ["Pucheng Xian", "Huazhou Qu", "Linwei Qu", "Fuping Xian",
               "Baishui Xian", "Chengcheng Xian", "Heyang Xian", "Hancheng Shi",
               "Tongguan Xian", "Huayin Shi", "Dali Xian"],
    "Yingkou": ["Dashiqiao Shi", "Zhanqian Qu", "Bayuquan Qu", "Gaizhou Shi",
                "Xishi Qu", "Laobian Qu"],
    "Wuhu": ["Wuwei Xian", "Nanling Xian", "Fanchang Xian", "Wuhu Xian",
             "Sanshan Qu", "Jiujiang Qu", "Yijiang Qu", "Jinghu Qu"],
    "Zhongshan": [],
    "Baishan": ["Hunjiang Qu", "Jiangyuan Qu", "Fusong Xian",
                "Changbai Chaoxianzu Zizhixian", "Linjiang Shi", "Jingyu Xian"],
    "Songyuan": ["Fuyu Shi", "Ningjiang Qu", "Changling Xian",
                 "Qian Guoerluosi Mengguzu Zizhixian", "Qianan Xian"]
}

export const CHINESE_PROVINCES_CITIES = {
    "AnHui": [
        "Hefei",
        "Lu'an",
        "Huangshan",
        "Suzhou",
        "Xuancheng",
        "Huaibei",
        "Bengbu",
        "Huainan",
        "Bozhou",
        "Chuzhou",
        "Fuyang",
        "Ma'anshan",
        "Tongling",
        "Anqing",
        "Chizhou",
        "Wuhu"
    ],
    "BeiJing": ["Beijing"],
    "ChongQing": ["Chongqing"],
    "FuJian": [
        "Fuzhou",
        "Quanzhou",
        "Zhangzhou",
        "Sanming",
        "Nanping",
        "Xiamen",
        "Ningde",
        "Longyan",
        "Putian"
    ],
    "GanSu": [
        "Jiuquan",
        "Lanzhou",
        "Jiayuguan",
        "Qingyang",
        "Gannan",
        "Pingliang",
        "Zhangye",
        "Dingxi",
        "Wuwei",
        "Linxia",
        "Tianshui",
        "Jinchang",
        "Longnan",
        "Baiyin"
    ],
    "GuangDong": [
        "Chaozhou",
        "Meizhou",
        "Foshan",
        "Zhuhai",
        "Huizhou",
        "Yangjiang",
        "Guangzhou",
        "Shenzhen",
        "Yunfu",
        "Jieyang",
        "Shanwei",
        "Maoming",
        "Zhanjiang",
        "Qingyuan",
        "Heyuan",
        "Jiangmen",
        "Zhaoqing",
        "Shaoguan",
        "Dongguan",
        "Shantou",
        "Zhongshan"
    ],
    "GuangXi": [
        "Beihai",
        "Qinzhou",
        "Hechi",
        "Wuzhou",
        "Guigang",
        "Bose",
        "Chongzuo",
        "Fangchenggang",
        "Hezhou",
        "Laibin",
        "Yulin",
        "Liuzhou",
        "Nanning",
        "Guilin"
    ],
    "GuiZhou": [
        "Qianxinan",
        "Qiandongnan",
        "Qiannan",
        "Liupanshui",
        "Zunyi",
        "Guiyang",
        "Anshun",
        "Bijie",
        "Tongren"
    ],
    "HaiNan": [
        "Lingao Xian",
        "Baisha Lizu Zizhixian",
        "Qiongzhong Lizu Miaozu Zizhixian",
        "Tunchang Xian",
        "Chengmai Qu",
        "Wanning Qu",
        "Nansha",
        "Qionghai Shi",
        "Changjiang Lizu Zizhixian",
        "Dingan Xian",
        "Wenchang Qu",
        "Dongfang Shi",
        "Haikou",
        "Lingshui Lizu Zizhixian",
        "Wuzhishan Shi",
        "Baoting Lizu Miaozu Zizhixian",
        "Danzhou Shi",
        "Ledong Lizu Zizhixian",
        "Sanya"
    ],
    "HeBei": [
        "Hengshui",
        "Shijiazhuang",
        "Chengde",
        "Qian'an Shi",
        "Tangshan",
        "Qinhuangdao",
        "Cangzhou",
        "Xingtai",
        "Handan",
        "Zhangjiakou",
        "Baoding",
        "Langfang"
    ],
    "HeNan": [
        "Luohe",
        "Pingdingshan",
        "Jiaozuo",
        "Xinxiang",
        "Zhoukou",
        "Nanyang",
        "Kaifeng",
        "Puyang",
        "Anyang",
        "Hebi",
        "Luoyang",
        "Xuchang",
        "Xinyang",
        "Zhumadian",
        "Sanmenxia",
        "Zhengzhou",
        "Shangqiu"
    ],
    "HeiLongJiang": [
        "Jiamusi",
        "MudanJiang",
        "Jixi",
        "QitaiHe",
        "Daqing",
        "Yichun",
        "Da Hinggan Ling",
        "Qiqihar",
        "Suihua",
        "Harbin",
        "Hegang",
        "Heihe",
        "ShuangyaShan"
    ],
    "HuBei": [
        "Huangshi",
        "Ezhou",
        "Xianning",
        "Yichang",
        "Jingmen",
        "Enshi",
        "Xiaogan",
        "Shiyan",
        "Jingzhou",
        "Shennongjia",
        "Qianjiang",
        "Tianmen",
        "Wuhan",
        "Xiangfan",
        "Xiantao",
        "Suizhou",
        "Huanggang"
    ],
    "HuNan": [
        "Hengyang",
        "Changde",
        "Changsha",
        "Chenzhou",
        "Xiangtan",
        "Zhuzhou",
        "Yiyang",
        "Xiangxi",
        "Yueyang",
        "Zhangjiajie",
        "Yongzhou",
        "Loudi",
        "Shaoyang",
        "Huaihua"
    ],
    "JiLin": [
        "Jilin",
        "Changchun",
        "Baicheng",
        "Yanbian",
        "Siping",
        "Liaoyuan",
        "Tonghua",
        "Baishan",
        "Songyuan"
    ],
    "JiangSu": [
        "Yangzhou",
        "Yancheng",
        "Huai'an",
        "Nanjing",
        "Suzhou",
        "Zhenjiang",
        "Nantong",
        "Taizhou",
        "Suqian",
        "Lianyungang",
        "Xuzhou",
        "Wuxi",
        "Changzhou"
    ],
    "JiangXi": [
        "Yingtan",
        "Jingdezhen",
        "Yichun",
        "Shangrao",
        "Pingxiang",
        "Ganzhou",
        "Ji'an",
        "Fuzhou",
        "Xinyu",
        "Nanchang",
        "Jiujiang"
    ],
    "LiaoNing": [
        "Shenyang",
        "Jinzhou",
        "Liaoyang",
        "Dalian",
        "Benxi",
        "Panjin",
        "Tieling",
        "Huludao",
        "Dandong",
        "Fuxin",
        "Fushun",
        "Anshan",
        "Chaoyang",
        "Yingkou"
    ],
    "NeiMengGu": [
        "Alxa Meng",
        "Xilin Gol Meng",
        "Chifeng",
        "Tongliao",
        "Ulanqab",
        "Bayannur",
        "Baotou",
        "Xingan Meng",
        "Wuhai",
        "Hohhot",
        "Hulun Buir",
        "Ordos"
    ],
    "NingXia": [
        "Wuzhong",
        "Shizuishan",
        "Yinchuan",
        "Zhongwei",
        "Guyuan"
    ],
    "QingHai": [
        "Haixi",
        "Huangnan",
        "Xining",
        "Haibei",
        "Golog",
        "Yushu",
        "Hainan",
        "Haidong"
    ],
    "ShaanXi": [
        "Tongchuan",
        "Xianyang",
        "Yulin",
        "Yan'an",
        "Baoji",
        "Hanzhong",
        "Xi'an",
        "Shangluo",
        "AnKang",
        "Weinan"
    ],
    "ShanDong": [
        "Rizhao",
        "Weifang",
        "Liaocheng",
        "Tai'an",
        "Linyi",
        "Zibo",
        "Yantai",
        "Jining",
        "Binzhou",
        "Dongying",
        "Dezhou",
        "Heze",
        "Qingdao",
        "Weihai",
        "Laiwu",
        "Jinan",
        "Zaozhuang"
    ],
    "ShanXi": [
        "Xinzhou",
        "Yuncheng",
        "Jinzhong",
        "Datong",
        "Linfen",
        "Yangquan",
        "Jincheng",
        "Changzhi",
        "Lüliang",
        "Taiyuan",
        "Shuozhou"
    ],
    "ShangHai": ["Shanghai"],
    "SiChuan": [
        "Garzê",
        "Nanchong",
        "Aba",
        "Suining",
        "Meishan",
        "Leshan",
        "Guangan",
        "Ya'an",
        "Panzhihua",
        "Dazhou",
        "Guangyuan",
        "Deyang",
        "Mianyang",
        "Liangshan",
        "Chengdu",
        "Luzhou",
        "Ziyang",
        "Bazhong",
        "Yibin",
        "Zigong",
        "Neijiang"
    ],
    "TianJin": ["Tianjin"],
    "XiZang": [
        "Linzhi Shi",
        "Qamdo",
        "Shannan",
        "Lhasa",
        "Xigazê",
        "Nagqu",
        "Ngari"
    ],
    "XinJiang": [
        "Karamay",
        "Altay",
        "ürümqi",
        "Kashi",
        "Ili Kazak",
        "Tacheng",
        "Bayingolin Mongol",
        "Changji",
        "Aksu",
        "Bortala Mongol",
        "Kizilsu Kirgiz",
        "Hotan",
        "Turpan",
        "Hami"
    ],
    "YunNan": [
        "Dali",
        "Xishuangbanna",
        "Qujing",
        "Wenshan",
        "Zhaotong",
        "Lijiang",
        "Honghe",
        "Baoshan",
        "Nujiang",
        "Lincang",
        "Dehong",
        "Chuxiong",
        "Pu'er",
        "Dêqên",
        "Yuxi",
        "Kunming"
    ],
    "ZheJiang": [
        "Zhoushan",
        "Shaoxing",
        "Quzhou",
        "Lishui",
        "Ningbo",
        "Jinhua",
        "Hangzhou",
        "Jiaxing",
        "Wenzhou",
        "Huzhou",
        "Taizhou"
    ]
}

export const CHINESE_CITIES = [
    "Hefei",
    "Lu'an",
    "Huangshan",
    "Suzhou",
    "Xuancheng",
    "Huaibei",
    "Bengbu",
    "Huainan",
    "Bozhou",
    "Chuzhou",
    "Fuyang",
    "Ma'anshan",
    "Tongling",
    "Anqing",
    "Chizhou",
    "Wuhu",
    "Beijing",
    "Chongqing",
    "Fuzhou",
    "Quanzhou",
    "Zhangzhou",
    "Sanming",
    "Nanping",
    "Xiamen",
    "Ningde",
    "Longyan",
    "Putian",
    "Jiuquan",
    "Lanzhou",
    "Jiayuguan",
    "Qingyang",
    "Gannan",
    "Pingliang",
    "Zhangye",
    "Dingxi",
    "Wuwei",
    "Linxia",
    "Tianshui",
    "Jinchang",
    "Longnan",
    "Baiyin",
    "Chaozhou",
    "Meizhou",
    "Foshan",
    "Zhuhai",
    "Huizhou",
    "Yangjiang",
    "Guangzhou",
    "Shenzhen",
    "Yunfu",
    "Jieyang",
    "Shanwei",
    "Maoming",
    "Zhanjiang",
    "Qingyuan",
    "Heyuan",
    "Jiangmen",
    "Zhaoqing",
    "Shaoguan",
    "Dongguan",
    "Shantou",
    "Zhongshan",
    "Beihai",
    "Qinzhou",
    "Hechi",
    "Wuzhou",
    "Guigang",
    "Bose",
    "Chongzuo",
    "Fangchenggang",
    "Hezhou",
    "Laibin",
    "Yulin",
    "Liuzhou",
    "Nanning",
    "Guilin",
    "Qianxinan",
    "Qiandongnan",
    "Qiannan",
    "Liupanshui",
    "Zunyi",
    "Guiyang",
    "Anshun",
    "Bijie",
    "Tongren",
    "Lingao Xian",
    "Baisha Lizu Zizhixian",
    "Qiongzhong Lizu Miaozu Zizhixian",
    "Tunchang Xian",
    "Chengmai Qu",
    "Wanning Qu",
    "Nansha",
    "Qionghai Shi",
    "Changjiang Lizu Zizhixian",
    "Dingan Xian",
    "Wenchang Qu",
    "Dongfang Shi",
    "Haikou",
    "Lingshui Lizu Zizhixian",
    "Wuzhishan Shi",
    "Baoting Lizu Miaozu Zizhixian",
    "Danzhou Shi",
    "Ledong Lizu Zizhixian",
    "Sanya",
    "Hengshui",
    "Shijiazhuang",
    "Chengde",
    "Qian'an Shi",
    "Tangshan",
    "Qinhuangdao",
    "Cangzhou",
    "Xingtai",
    "Handan",
    "Zhangjiakou",
    "Baoding",
    "Langfang",
    "Luohe",
    "Pingdingshan",
    "Jiaozuo",
    "Xinxiang",
    "Zhoukou",
    "Nanyang",
    "Kaifeng",
    "Puyang",
    "Anyang",
    "Hebi",
    "Luoyang",
    "Xuchang",
    "Xinyang",
    "Zhumadian",
    "Sanmenxia",
    "Zhengzhou",
    "Shangqiu",
    "Jiamusi",
    "MudanJiang",
    "Jixi",
    "QitaiHe",
    "Daqing",
    "Yichun",
    "Da Hinggan Ling",
    "Qiqihar",
    "Suihua",
    "Harbin",
    "Hegang",
    "Heihe",
    "ShuangyaShan",
    "Huangshi",
    "Ezhou",
    "Xianning",
    "Yichang",
    "Jingmen",
    "Enshi",
    "Xiaogan",
    "Shiyan",
    "Jingzhou",
    "Shennongjia",
    "Qianjiang",
    "Tianmen",
    "Wuhan",
    "Xiangfan",
    "Xiantao",
    "Suizhou",
    "Huanggang",
    "Hengyang",
    "Changde",
    "Changsha",
    "Chenzhou",
    "Xiangtan",
    "Zhuzhou",
    "Yiyang",
    "Xiangxi",
    "Yueyang",
    "Zhangjiajie",
    "Yongzhou",
    "Loudi",
    "Shaoyang",
    "Huaihua",
    "Jilin",
    "Changchun",
    "Baicheng",
    "Yanbian",
    "Siping",
    "Liaoyuan",
    "Tonghua",
    "Baishan",
    "Songyuan",
    "Yangzhou",
    "Yancheng",
    "Huai'an",
    "Nanjing",
    "Suzhou",
    "Zhenjiang",
    "Nantong",
    "Taizhou",
    "Suqian",
    "Lianyungang",
    "Xuzhou",
    "Wuxi",
    "Changzhou",
    "Yingtan",
    "Jingdezhen",
    "Yichun",
    "Shangrao",
    "Pingxiang",
    "Ganzhou",
    "Ji'an",
    "Fuzhou",
    "Xinyu",
    "Nanchang",
    "Jiujiang",
    "Shenyang",
    "Jinzhou",
    "Liaoyang",
    "Dalian",
    "Benxi",
    "Panjin",
    "Tieling",
    "Huludao",
    "Dandong",
    "Fuxin",
    "Fushun",
    "Anshan",
    "Chaoyang",
    "Yingkou",
    "Alxa Meng",
    "Xilin Gol Meng",
    "Chifeng",
    "Tongliao",
    "Ulanqab",
    "Bayannur",
    "Baotou",
    "Xingan Meng",
    "Wuhai",
    "Hohhot",
    "Hulun Buir",
    "Ordos",
    "Wuzhong",
    "Shizuishan",
    "Yinchuan",
    "Zhongwei",
    "Guyuan",
    "Haixi",
    "Huangnan",
    "Xining",
    "Haibei",
    "Golog",
    "Yushu",
    "Hainan",
    "Haidong",
    "Tongchuan",
    "Xianyang",
    "Yulin",
    "Yan'an",
    "Baoji",
    "Hanzhong",
    "Xi'an",
    "Shangluo",
    "AnKang",
    "Weinan",
    "Rizhao",
    "Weifang",
    "Liaocheng",
    "Tai'an",
    "Linyi",
    "Zibo",
    "Yantai",
    "Jining",
    "Binzhou",
    "Dongying",
    "Dezhou",
    "Heze",
    "Qingdao",
    "Weihai",
    "Laiwu",
    "Jinan",
    "Zaozhuang",
    "Xinzhou",
    "Yuncheng",
    "Jinzhong",
    "Datong",
    "Linfen",
    "Yangquan",
    "Jincheng",
    "Changzhi",
    "Lüliang",
    "Taiyuan",
    "Shuozhou",
    "Shanghai",
    "Garzê",
    "Nanchong",
    "Aba",
    "Suining",
    "Meishan",
    "Leshan",
    "Guangan",
    "Ya'an",
    "Panzhihua",
    "Dazhou",
    "Guangyuan",
    "Deyang",
    "Mianyang",
    "Liangshan",
    "Chengdu",
    "Luzhou",
    "Ziyang",
    "Bazhong",
    "Yibin",
    "Zigong",
    "Neijiang",
    "Tianjin",
    "Linzhi Shi",
    "Qamdo",
    "Shannan",
    "Lhasa",
    "Xigazê",
    "Nagqu",
    "Ngari",
    "Karamay",
    "Altay",
    "ürümqi",
    "Kashi",
    "Ili Kazak",
    "Tacheng",
    "Bayingolin Mongol",
    "Changji",
    "Aksu",
    "Bortala Mongol",
    "Kizilsu Kirgiz",
    "Hotan",
    "Turpan",
    "Hami",
    "Dali",
    "Xishuangbanna",
    "Qujing",
    "Wenshan",
    "Zhaotong",
    "Lijiang",
    "Honghe",
    "Baoshan",
    "Nujiang",
    "Lincang",
    "Dehong",
    "Chuxiong",
    "Pu'er",
    "Dêqên",
    "Yuxi",
    "Kunming",
    "Zhoushan",
    "Shaoxing",
    "Quzhou",
    "Lishui",
    "Ningbo",
    "Jinhua",
    "Hangzhou",
    "Jiaxing",
    "Wenzhou",
    "Huzhou",
    "Taizhou",
]


export const CHINESE_PROVINCES = [
    "AnHui",
    "BeiJing",
    "ChongQing",
    "FuJian",
    "GanSu",
    "GuangDong",
    "GuangXi",
    "GuiZhou",
    "HaiNan",
    "HeBei",
    "HeNan",
    "HeiLongJiang",
    "HuBei",
    "HuNan",
    "JiLin",
    "JiangSu",
    "JiangXi",
    "LiaoNing",
    "NeiMengGu",
    "NingXia",
    "QingHai",
    "ShaanXi",
    "ShanDong",
    "ShanXi",
    "ShangHai",
    "SiChuan",
    "TianJin",
    "XiZang",
    "XinJiang",
    "YunNan",
    "ZheJiang"
]

export default {CHINESE_COUNTIES, CHINESE_PROVINCES_CITIES, CHINESE_PROVINCES, CHINESE_CITIES}