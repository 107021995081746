<template>
    <svg :width="width" :height="height" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.8033 3.1967C12.7544 2.14781 11.418 1.4335 9.96318 1.14411C8.50832 0.854725 7.00032 1.00325 5.62987 1.57091C4.25943 2.13856 3.08809 3.09986 2.26398 4.33323C1.43987 5.5666 1 7.01664 1 8.5C1 9.98336 1.43987 11.4334 2.26398 12.6668C3.08809 13.9001 4.25943 14.8614 5.62988 15.4291C7.00032 15.9968 8.50832 16.1453 9.96318 15.8559C11.418 15.5665 12.7544 14.8522 13.8033 13.8033M13.8033 3.1967L13.8033 0M13.8033 3.1967L11 4"
            :stroke="stroke"/>
    </svg>
</template>

<script>
export default {
    name: "ArrowLoader",
    props: {
        stroke: {
            default: '#000000'
        },
        width: {
            default: 15
        },
        height: {
            default: 17
        }
    }
}
</script>