<template>
  <button
    :class="['btn__copy hovered', {'btn__copy-bg': isBackground, 'btn__copy-success': success}]"
    @click="copy()"
    :disabled="disabled">
    <input
      type="hidden"
      :value="text"
      readonly
      :ref="uid"
    >
    <span role="img">
      <CopyIcon
          stroke="var(--description-text-darken)"
      />
    </span>
    <span
      v-if="title"
      class="btn__copy-title"
    >
      {{ title }}
    </span>
  </button>
</template>

<script>

/* eslint no-underscore-dangle: 0 */
import CopyIcon from "@/components/icons/CopyIcon.vue";

export default {
    components: {CopyIcon},
  data() {
    return {
      uid: null,
      success: false,
    };
  },
  props: {
    title: {
      default: '',
    },
    text: {
      default: '',
    },
    disabled: {
      default: false,
    },
    isBackground: {
      default: false
    }
  },
  mounted() {
    this.uid = `copy${this._uid}`;
  },
  methods: {
    copy() {
      const copyText = this.$refs[this.uid];
      copyText.type = 'text';
      copyText.select();
      document.execCommand('copy');
      copyText.type = 'hidden';
      this.success = true;
      setTimeout(() => {
        this.success = false;
      }, 1000);
    },
  },
};
</script>

<style lang="sass">
.btn__copy
  -webkit-appearance: none
  outline: none
  display: flex
  border: 0
  align-items: center
  margin: 0
  padding: 0
  color: var(--primary)
  cursor: copy
  transition: .3s
  background: none
  padding: 3px
  border-radius: 2px
  span
    height: 17px
  &:hover
      svg > path, rect
        stroke: var(--main_black)

  &-success
    background: rgb(182, 234, 182) !important
    svg > path, rect
      stroke: var(--success) !important

  &-bg
    background: #EBEBEB

  &-title
    margin-left: .3rem


  &:disabled
    cursor: default
    opacity: .6


  &-img
    font-size: 14px
    transform: translateY(1px)


</style>
