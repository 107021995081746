<template>
    <div class="photo-field">

        <div
            class="file-field__block photo-field__block"
            @click="openSystemFile"
            @dragover.prevent="onDragOver"
            @drop.prevent="onFileDrop"
            :class="{'file-field__block-active': dragging}"
        >
            <img class="file-field__block-bg" src="@/assets/images/photo-bg.svg" alt="arrow">
            <input
                type="file"
                ref="openFile"
                accept="image/jpg, image/jpeg, image/png"
                @change="selectFile"
                style="display: none;"
            />
            <div class="file-field__block-text">
                <div>
                    <img src="@/assets/images/file-arrow.svg" alt="arrow">
                    <p style="margin: 0;">{{ isCropImage ? 'Change' : 'Upload' }} photo</p>
                </div>
            </div>
            <img
                v-if="isCropImage"
                class="photo-field__result-img"
                height="300"
                :src="`/frontend_api/get_image/${modelValue}`"
                alt=""
            >
        </div>
        <div
            v-show="!isCropImage && isShowCropper"
            class="photo-field__modal">
            <div class="photo-field__modal-inner">
                <VuePictureCropper
                    :boxStyle="{
                    width: '100%',
                    height: '450px',
                    backgroundColor: '#F2F2F2',
                    margin: 'auto',
                }"
                    :img="pic"
                    :options="{
                    viewMode: 1,
                    dragMode: 'crop',
                    aspectRatio: 1,
                }"
                />
                <button class="--btn-next" @click.prevent="getResult" :disabled="!pic">Save image</button>
            </div>
        </div>
    </div>
</template>

<script>
import VuePictureCropper, {cropper} from 'vue-picture-cropper'

export default {
    components: {VuePictureCropper},
    data() {
        return {
            isCropImage: false,
            pic: '',
            dataURL: '',
            dragging: false,
            isShowCropper: false
        }
    },
    props: {
        questionnaireId: Number,
        fieldName: String,
        sectionName: String,
        modelValue: {
            type: String,
            default: ''
        },
        validators: {
            type: Array,
            default: []
        }
    },
    methods: {
        openSystemFile() {
            this.$refs.openFile.click()
        },
        async selectFile(e) {
            this.reset()

            const {files} = e.target
            if (!files || !files.length) return
            const file = files[0]

            const reader = new FileReader()
            console.log(files[0])
            console.log(this.$refs.openFile.files[0])
            reader.readAsDataURL(file)
            reader.onload = () => {
                this.pic = String(reader.result)
                if (!this.$refs.openFile) return
                this.$refs.openFile.value = ''
            }
            console.log(this.pic)
            this.isShowCropper = true
            console.log(reader)
        },
        async getResult() {
            if (!cropper) return

            const formData = new FormData();
            formData.append('questionnaire_id', this.questionnaireId)
            formData.append('field_name', this.fieldName)
            formData.append('section_name', this.sectionName)
            const blob = await cropper.getBlob()
            console.log(blob)
            formData.append('file', blob, 'image.jpg');

            const response = await fetch('/frontend_api/upload_image/', {
                method: "POST",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {},
                body: formData,
            });
            const json = await response.json()
            console.log(json)
            this.$emit('updatePhoto', json)
            this.$emit('update:modelValue', json.answers[this.sectionName][this.fieldName])
            await this.$nextTick()
            this.isShowCropper = false
            this.isCropImage = true
        },
        reset() {
            this.isCropImage = false
            this.pic = ''
            this.dataURL = ''
            this.$emit('update:modelValue', '')
        },
        onDragOver() {
            this.dragging = true
        },
        onFileDrop(event) {
            console.log('kek')
            this.dragging = false
            const files = event.dataTransfer.files;
            if (!files || !files.length) return
            this.file = files[0]
        },

    },
    async created() {
        if (this.modelValue) this.isCropImage = true;
    },
}
</script>

<style lang="sass">

.cropper-dashed.dashed-h
    display: none


.cropper-dashed.dashed-v
    background: url("@/assets/images/face.png") no-repeat center
    background-size: contain
    width: 100%
    height: 100%
    left: auto
    border: none


nav
    padding: 30px

    a
        font-weight: bold
        color: #2c3e50

        &.router-link-exact-active
            color: #42b983


.photo-field

    &__block
        position: relative
        overflow: hidden

    &__button
        background: var(--primary-lighten)
        color: var(--primary)
        border: 1px solid var(--primary)
        padding: 8px 16px
        border-radius: 4px
        transition: 0.2s

        &[disabled]
            background: var(--primary-lighten) !important
            color: var(--primary) !important
            border: 1px solid var(--primary) !important
            cursor: not-allowed
            opacity: 0.6

        &:hover
            background: var(--primary)
            color: var(--primary-lighten)
            border: 1px solid var(--primary-lighten)

        &-reset
            background: var(--error)
            border: 1px solid var(--error_red)
            color: var(--error_red)
            border-radius: 4px
            padding: 8px 16px
            transition: 0.2s

            &:hover
                opacity: 0.6

    &__modal
        position: fixed
        top: 0
        left: 0
        right: 0
        bottom: 0
        display: flex
        z-index: 100
        justify-content: center
        align-items: center
        background: var(--primary-lighten)

        &-inner
            width: 500px

    &__result-img
        position: absolute
        top: 0
        right: 0
        max-height: 134px

    &__group-btn
        width: 100%
        display: flex
        justify-content: space-between

        & .--btn-next
            width: auto
            padding: 9px 16px
            height: auto
            margin-right: 16px


</style>
