<template>
    <Header v-if="!hideHeader"/>
    <slot></slot>
    <!--    <Footer v-if="!hideHeader" />-->
</template>

<style scoped lang="sass">

</style>
<script>
import Header from "@/components/elements/Header.vue";
import Footer from "@/components/elements/Footer.vue";

export default {
    props: {
        hideHeader: {
            type: Boolean,
            default: false,
        },
    },
    components: {Header, Footer,},
}

</script>