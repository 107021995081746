<template>
    <v-select
        class="select-field"
        :options="choices"
        v-model="selectedValue"
    >
    </v-select>
</template>

<script>
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select'

export default {
    components: {vSelect},
    props: {
        choices: {
            type: Array,
            default: []
        },
        modelValue: String,
        validators: {
            type: Array,
            default: []
        },
    },
    computed: {
        selectedValue: {
            get() {
                return this.modelValue
            },
            set(newValue) {
                this.$emit('update:modelValue', newValue)
            }
        }
    }

}
</script>

<style lang="sass">
\:root
    --vs-dropdown-option--active-bg: var(--description-text-darken) !important

.select-field
    width: 100%
    &__option
        display: block
        width: 100%
        height: 100%
        margin: 0
        padding: 0

    & .vs
        &__search
            padding-left: 16px !important

        &__actions
            padding-right: 12px

        &__dropdown-toggle
            background: var(--background-input)
            border: 1px solid var(--border-form-input)
            min-height: var(--input-height)
            transition: 0.2s
            border-radius: var(--border-radius-input) !important
            &[aria-expanded="true"]
                border-radius: var(--border-radius-input) var(--border-radius-input) 0 0 !important

            &:hover
                border: 1px solid var(--description-text-darken)

        &__selected
            position: relative !important
            padding-left: 16px !important

        &__dropdown-menu
            background: var(--background-input)
            border: 1px solid var(--description-text-darken)
            border-top: none

    &.vs--open
        .vs__dropdown-toggle
            border: 1px solid var(--description-text-darken) !important

    &-valid
        & .vs
            &__actions
                svg
                    path
                        fill: var(--primary) !important

            &__dropdown-toggle
                background: var(--primary-lighten) !important
                border: 1px solid var(--primary) !important

                &:hover
                    border: 1px solid var(--primary) !important

            &__dropdown-menu
                background: var(--primary-lighten) !important
                border: 1px solid var(--primary) !important
                border-top: none !important

            &.vs--open
                .vs__dropdown-toggle
                    border: 1px solid var(--primary) !important

    &-invalid
        & .vs
            &__actions
                svg
                    path
                        fill: var(--error_red) !important

            &__dropdown-toggle
                background: var(--error) !important
                border: 1px solid var(--error_red) !important

                &:hover
                    border: 1px solid var(--error_red) !important

            &__dropdown-menu
                background: var(--error) !important
                border: 1px solid var(--error_red) !important
                border-top: none !important

            &.vs--open
                .vs__dropdown-toggle
                    border: 1px solid var(--error_red) !important

</style>