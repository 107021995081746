<template>
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="5.5" y="5.5" width="11" height="11" stroke="#757575"/>
        <path d="M3.37037 11.6667H1V1H11.6667V3.37037" stroke="#757575"/>
    </svg>
</template>

<script>
export default {
    name: "CopyIcon",
    props: {
        stroke: {
            default: '#000000'
        },
        width: {
            default: 17
        },
        height: {
            default: 17
        }
    }
}
</script>

<style scoped lang="sass">

</style>