<template>
    <div>
        <input
            class="string-field"
            :value="modelValue"
            @change="$emit('update:modelValue', $event.target.value)"
        >
    </div>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        validators: {
            type: Array,
            default: []
        }

    }
}
</script>

<style scoped lang="sass">
div
    width: 100%

.string-field
    width: 100%
    height: var(--input-height)
    background: var(--background-input)
    border: 1px solid
    border-color: var(--border-form-input)
    padding-left: 20px
    padding-right: 8px
    outline: none
    font-size: 16px
    color: inherit
    transition: 0.2s
    border-radius: var(--border-radius-input)

    &:focus
        border-color: var(--primary) !important
    &:hover
        border-color: var(--description-text-darken)

    &-valid
        background: var(--primary-lighten) !important
        border-color: var(--primary) !important

    &-invalid
        background: var(--error) !important
        border-color: var(--error_red) !important
        border-radius: 3px 3px 0 0
        color: var(--error_red)
</style>