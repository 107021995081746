<template>
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            :class="{'valid': isValid && touch, 'invalid': !isValid && touch}"
            fill-rule="evenodd" clip-rule="evenodd"
            d="M1.88556 0.510194C2.06054 0.335474 2.27066 0.199938 2.50198 0.112565C2.73331 0.0251932 2.98056 -0.0120195 3.22736 0.00339337C3.47415 0.0188063 3.71486 0.0864929 3.93351 0.201968C4.15217 0.317442 4.34379 0.478068 4.49568 0.673201L6.29076 2.97931C6.61977 3.40233 6.73578 3.95335 6.60577 4.47338L6.05875 6.66348C6.03047 6.77691 6.03199 6.89573 6.06318 7.0084C6.09437 7.12107 6.15417 7.22376 6.23676 7.30651L8.69387 9.76362C8.77671 9.84638 8.87957 9.90627 8.99243 9.93746C9.1053 9.96866 9.22432 9.97009 9.3379 9.94163L11.527 9.3946C11.7836 9.33044 12.0515 9.32545 12.3103 9.38003C12.5691 9.4346 12.8122 9.5473 13.0211 9.70962L15.3272 11.5037C16.1562 12.1487 16.2322 13.3738 15.4902 14.1148L14.4561 15.1489C13.7161 15.8889 12.61 16.2139 11.579 15.8509C8.94006 14.9224 6.54404 13.4116 4.56868 11.4307C2.58788 9.45562 1.07713 7.05995 0.148478 4.42137C-0.213539 3.39133 0.111476 2.28428 0.85151 1.54424L1.88556 0.510194Z"
            fill="#B9B9B9"/>
    </svg>
</template>

<script>
    export default {
        props: {
            isValid: Boolean,
            touch: Boolean
        }
    }
</script>

<style scoped lang="sass">
.invalid
    fill: var(--error_red) !important

.valid
    fill: var(--primary) !important
</style>