<template>
    <div class="pagination">
        <a
            class="pagination__link-end"
            href="#"
            @click.prevent="$emit('clickStartPage')"
        >
            <PaginatorArrowEnd
                direction="left"
                stroke="var(--description-text)"
            />
        </a>
        <a
            href="#"
            style="margin-right: 8px;"
            @click.prevent="$emit('clickPerPage')"
        >
            <PaginatorArrow
                direction="left"
                stroke="var(--description-text)"
            />
        </a>
        <ul class="pagination__list">
            <li
                v-for="page in pages"
                :key="'page_' + page"
                :class="{'pagination__list-active': (currentPage) === page}"
                @click.prevent="$emit('clickPage', page)"
            >
                {{ page }}
            </li>
        </ul>
        <a
            href="#"
            style="margin-left: 8px;"
            @click.prevent="$emit('clickNextPage')"
        >
            <PaginatorArrow
                stroke="var(--description-text)"
            />
        </a>
        <a
            class="pagination__link-end"
            href="#"
            @click.prevent="$emit('clickEndPage', endPage)"
        >
            <PaginatorArrowEnd
                stroke="var(--description-text)"
            />
        </a>
    </div>
</template>

<script>
import PaginatorArrowEnd from "@/components/icons/PaginatorArrowEnd.vue";
import PaginatorArrow from "@/components/icons/PaginatorArrow.vue";

export default {
    name: "Paginator",
    components: {PaginatorArrow, PaginatorArrowEnd},
    props: {
        pagination: {
            total: {
                type: Number,
                default: 0
            },
            offset: {
                type: Number,
                default: 0
            },
            limit: {
                type: Number,
                default: 10
            }
        },
        pageRange: {
            type: Number,
            default: 2
        }
    },
    emits: ['clickStartPage', 'clickPerPage', 'clickPage', 'clickNextPage', 'clickEndPage'],
    computed: {
        endPage() {
            return Math.floor(this.pagination.total / this.pagination.limit) * this.pagination.limit
        },
        currentPage() {
            return Math.ceil(this.pagination.offset / this.pagination.limit) + 1 || 1
        },
        rangeStart() {
            const start = this.currentPage - this.pageRange
            return start > 0 ? start : 1
        },
        rangeEnd() {
            const end = this.currentPage + this.pageRange
            return end < this.totalPages ? end : this.totalPages
        },
        totalPages() {
            return Math.ceil(this.pagination.total / this.pagination.limit)
        },
        pages() {
            const pages = []
            for (let i = this.rangeStart; i <= this.rangeEnd; i++) {
                pages.push(i)
            }
            return pages
        }
    }
}
</script>

<style lang="sass">

.pagination
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    margin-top: 32px

    &__link-end
        svg
            display: block
            margin: -3px 8px 0

    &__list
        display: flex
        align-items: center
        justify-content: center
        list-style-type: none
        padding: 0
        min-width: 215px
        margin: 0

        &-active
            background: var(--paginator-active)
            color: var(--main_black) !important

        li
            border-radius: 2px
            cursor: pointer
            padding: 4px
            min-width: 35px
            height: 35px
            display: flex
            align-items: center
            justify-content: center
            color: var(--description-text)





</style>