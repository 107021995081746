<template>
  <div :class="['recursive-component', {'recursive-component__string': typeof item === 'string'}]">
    <span v-if="typeof item === 'string'">{{ item }}</span>

    <div v-else-if="typeof item === 'object'" style="display: flex; flex-direction: column;">
      <div v-for="(value, key) in item" :key="key"
           :class="['recursive-component__item', {'recursive-component__item-obj': typeof value === 'object'}]">
        <strong style="text-transform: capitalize" v-if="isNaN(key) && typeof value !== 'object'">{{ key.replace(/_/g, ' ') }}:</strong>
        <RecursiveComponent :item="value"></RecursiveComponent>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecursiveComponent',
  props: ['item']
};
</script>

<style lang="sass">
.recursive-component
  width: calc(100% - 16px)
  margin-left: 16px
  &__string
    width: auto
    margin-left: 8px

  strong
    width: 50%
    color: var(--description-text-darken)
    font-weight: 400
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden

  &__item
    width: 100%
    display: flex
    justify-content: space-between

    .recursive-component
      width: auto
      margin-left: 0

    &-obj
      flex-direction: column
      margin-top: 6px

</style>
