<template>
    <svg :width="width" :height="height" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M1 0C0.447715 0 0 0.447715 0 1V15C0 15.5523 0.447716 16 1 16H13C13.5523 16 14 15.5523 14 15V1C14 0.447715 13.5523 0 13 0H1ZM9.80521 5.8477L10.5978 5.238L11.3905 4.62829C11.2449 4.4391 11.086 4.25784 10.914 4.08584C10.742 3.91385 10.5608 3.75495 10.3716 3.60942L9.76189 4.40204L9.15218 5.19467C9.27298 5.2876 9.38916 5.38939 9.49983 5.50006C9.61049 5.61072 9.71229 5.7269 9.80521 5.8477ZM7.59123 3.50308L7.46141 4.49462C7.15514 4.45452 6.84463 4.45452 6.53836 4.49462L6.40854 3.50308L6.27872 2.51155C6.75734 2.44888 7.24242 2.44888 7.72105 2.51155L7.59123 3.50308ZM4.23788 4.40204L4.84759 5.19467C4.72678 5.2876 4.61061 5.38939 4.49994 5.50006C4.38928 5.61072 4.28748 5.7269 4.19456 5.8477L3.40193 5.238L2.6093 4.62829C2.75483 4.4391 2.91374 4.25784 3.08573 4.08584C3.25772 3.91385 3.43899 3.75495 3.62818 3.60942L4.23788 4.40204ZM2.50297 7.40865L3.49451 7.53847C3.45441 7.84474 3.45441 8.15526 3.49451 8.46153L2.50297 8.59135L1.51143 8.72117C1.44877 8.24254 1.44877 7.75746 1.51143 7.27883L2.50297 7.40865ZM4.49994 10.4999C4.38928 10.3893 4.28748 10.2731 4.19456 10.1523L3.40193 10.762L2.6093 11.3717C2.75483 11.5609 2.91374 11.7422 3.08573 11.9142C3.25772 12.0861 3.43899 12.2451 3.62818 12.3906L4.23788 11.598L4.84759 10.8053C4.72678 10.7124 4.61061 10.6106 4.49994 10.4999ZM6.40854 12.4969L6.53836 11.5054C6.84463 11.5455 7.15514 11.5455 7.46141 11.5054L7.59123 12.4969L7.72105 13.4885C7.24242 13.5511 6.75734 13.5511 6.27872 13.4885L6.40854 12.4969ZM9.76189 11.598L9.15218 10.8053C9.27298 10.7124 9.38916 10.6106 9.49983 10.4999C9.61049 10.3893 9.71229 10.2731 9.80521 10.1523L10.5978 10.762L11.3905 11.3717C11.2449 11.5609 11.086 11.7422 10.914 11.9142C10.742 12.0861 10.5608 12.2451 10.3716 12.3906L9.76189 11.598ZM11.4968 8.59135L10.5053 8.46153C10.5454 8.15526 10.5454 7.84474 10.5053 7.53847L11.4968 7.40865L12.4883 7.27883C12.551 7.75746 12.551 8.24254 12.4883 8.72117L11.4968 8.59135Z"
              :fill="fill"/>
    </svg>
</template>

<script>
export default {
    name: "StatusIcon",
    props: {
        fill: {
            default: '#000000'
        },
        width: {
            default: 14
        },
        height: {
            default: 14
        }
    }
}
</script>