const typeDocuments = {
    CHINESE_VISA: {
        value: 'China',
        iso3_code: 'chn',
        first_section: 'passport_data'
    },
    INDIAN_E_VISA: {
        value: 'India',
        iso3_code: 'ind',
        first_section: 'registration_data'
    },
    SRI_LANKA_E_VISA: {
        value: 'Sri Lanka',
        iso3_code: 'lka',
        first_section: 'applicant_data'
    },
    UAE_E_VISA: {
        value: 'UAE',
        iso3_code: 'uae',
        first_section: 'applicant_data'
    },
    SAUDI_E_VISA: {
        value: 'SAU',
        iso3_code: 'sau',
        first_section: 'applicant_data'
    }
}

export default typeDocuments