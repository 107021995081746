<template>
    <v-select
        class="multi-select-field"
        :options="options"
        multiple
        v-model="selectedValues"
    >
    </v-select>
</template>

<script>
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select'

export default {
    components: {vSelect},
    props: {
        options: {
            type: Array,
            default: []
        },
        modelValue: Array,
        validators: {
            type: Array,
            default: []
        },
    },
    computed: {
        selectedValues: {
            get() {
                return this.modelValue ? this.modelValue.slice() : null
            },
            set(newValues) {
                this.$emit('update:modelValue', newValues);
            }
        }
    },
}
</script>

<style lang="sass">
\:root
    --vs-dropdown-option--active-bg: var(--description-text-darken) !important

.multi-select-field
    width: 100%
    &__option
        display: block
        width: 100%
        height: 100%
        margin: 0
        padding: 0

    & .vs
        &__actions
            padding-right: 12px

        &__dropdown-toggle
            background: var(--background-input)
            border: 1px solid var(--border-form-input)
            min-height: var(--input-height)
            transition: 0.2s
            border-radius: var(--border-radius-input) !important
            &[aria-expanded="true"]
                border-radius: var(--border-radius-input) var(--border-radius-input) 0 0 !important

            &:hover
                border: 1px solid var(--description-text-darken)

        &__selected
            position: relative !important

        &__dropdown-menu
            background: var(--background-input)
            border: 1px solid var(--description-text-darken)
            border-top: none

    &.vs--open
        .vs__dropdown-toggle
            border: 1px solid var(--description-text-darken) !important

    &-valid
        & .vs

            &__selected
                border: 1px solid var(--primary) !important
                background: var(--primary-lighten) !important

            &__dropdown-toggle
                background: var(--primary-lighten) !important
                border: 1px solid var(--primary) !important
                svg
                    path
                        fill: var(--primary) !important

                &:hover
                    border: 1px solid var(--primary) !important

            &__dropdown-menu
                background: var(--primary-lighten) !important
                border: 1px solid var(--primary) !important
                border-top: none !important

            &.vs--open
                .vs__dropdown-toggle
                    border: 1px solid var(--primary) !important

    &-invalid
        & .vs
            &__selected
                border: 1px solid var(--error_red) !important
                background: var(--error) !important
                color: var(--error_red) !important

            &__dropdown-toggle
                background: var(--error) !important
                border: 1px solid var(--error_red) !important
                svg
                    path
                        fill: var(--error_red) !important

                &:hover
                    border: 1px solid var(--error_red) !important

            &__dropdown-menu
                background: var(--error) !important
                border: 1px solid var(--error_red) !important
                border-top: none !important

            &.vs--open
                .vs__dropdown-toggle
                    border: 1px solid var(--error_red) !important

</style>