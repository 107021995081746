const documentStatus = {
    CREATED: 'Created',
    NOT_FULLY_FILLED: 'Created',
    FILLED: 'Filled',
    NOT_STARTED: 'Filled',
    STARTED: 'In progress',
    IN_PROGRESS: 'In progress',
    ERROR: 'Failed to generate',
    DONE: 'Generated',
    FAILED_TO_PAY: 'Payment failed'
}

export default documentStatus