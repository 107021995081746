<template>
    <div class="policies__content form shadow">
        Diese Datenschutzerklärung gilt für die Website <a href="#">visum.io</a>
        <br><br>
        Verantwortlicher:
        <br><br>
        Visum.IO UG (haftungsbeschränkt) <br>
        Christian-Pleß-Straße 14 <br>
        63069 Offenbach am Main <br>
        <a href="mailto:info@visum.io">info@visum.io</a>
        <br><br><br>
        <span class="bold">1. Verarbeitung von personenbezogenen Daten</span>
        <br><br>
        Wir verarbeiten im Rahmen der Website personenbezogene Daten von Ihnen unter anderem wie folgt
        (weitere
        Datenverarbeitungen im Rahmen der Website entnehmen Sie bitte den nachfolgenden Ziffern dieser
        Datenschutzerklärung):
        <br><br>
        <span class="underline">Logfiles beim Besuch der Website</span>
        <br><br>
        Bei der Nutzung unserer Webseite werden von unserem Hostinganbieter bei jedem Zugriff auf die Server
        sog.
        „Logfile“-Daten protokolliert wie bspw. Name der aufgerufenen Website, zuvor besuchte Seite
        („Referrer“-URL), Produkt- und Versionsinformation des verwendeten Browsers und des Betriebssystems,
        anfragender Provider, Datum und Uhrzeit des Zugangs, verwendete Suchmaschinen, Land des Zugriffs,
        übertragene Datenmenge, Namen heruntergeladener Dateien und IP-Adresse.
        <br><br>
        Rechtsgrundlage für die Verarbeitung ist Artikel 6 Absatz 1 f) DSGVO. Unser berechtigtes Interesse
        zur
        Speicherung der Logfile-Daten liegt in der Gewährleistung der Systemsicherheit einschließlich der
        Aufklärung
        von Missbrauch. Die IP-Adressen wird nach höchstens 7 Tagen gelöscht, sofern sie nicht aufgrund
        eines
        sicherheitsrelevanten Vorfalls länger benötigt wird, bspw. zur Aufklärung oder zu Beweiszwecken.
        <br><br>
        <span class="underline">Kontaktanfragen:</span>
        <br><br>
        Bei Kontaktanfragen verarbeiten wir Ihre personenbezogenen Daten wie Name, Anschrift,
        E-Mail-Adresse,
        Telefonnummer o.ä., die wir für die Beantwortung Ihrer Anfrage benötigen.
        <br><br>
        Rechtsgrundlage für die Verarbeitung Ihrer personenbezogenen Daten im Rahmen von Kontaktanfragen ist
        Art. 6
        Abs. 1 b) Datenschutzgrundverordnung.
        <br><br>
        Im Rahmen von Kontaktanfragen speichern wir Ihre personenbezogenen Daten so lange, wie es für die
        Bearbeitung Ihrer Anfrage erforderlich ist, zuzüglich einer angemessenen Aufbewahrungsfrist für
        Rückfragen.
        <br><br>
        Die Bereitstellung dieser personenbezogenen Daten ist nicht gesetzlich oder vertraglich
        vorgeschrieben oder
        für einen Vertragsschluss erforderlich. Wenn Sie uns diese Daten nicht zur Verfügung stellen, können
        wir
        allerdings Ihre Kontaktanfrage nicht oder - im Falle eingeschränkter Kontaktangaben – nicht auf
        allen
        abgefragten Kommunikationswegen beantworten.
        <br><br>
        <span class="underline">Registrierung/ Bestellungen:</span>
        <br><br>
        Bei Ihrer Registrierung oder Bestellungen verarbeiten wir Ihre personenbezogenen Daten wie Name,
        Anschrift,
        E-Mail-Adresse, Telefonnummer, Geburtsdatum, selbst gewählter Benutzername, Zahlungsdaten o.ä., die
        wir für
        die Erfüllung des Vertragsverhältnisses mit Ihnen oder zur Durchführung vorvertraglicher Maßnahmen,
        die auf
        Ihre Anfrage hin erfolgen, benötigen.
        <br><br>
        Ihre im Rahmen von Registrierung bzw. Bestellungen erhobenen personenbezogene Daten speichern wir
        jeweils so
        lange, wie es für die Erfüllung des Vertragsverhältnisses (ggf. einschließlich der Bereitstellung
        des
        Kundenkontos) und/oder zur Durchführung auf Ihre Anfrage hin erfolgender vorvertraglicher Maßnahmen
        und/oder
        im Hinblick auf Gewährleistungs-, Garantie- oder vergleichbare Verpflichtungen und/oder oder im
        Hinblick auf
        gesetzliche Aufbewahrungsfristen erforderlich ist.
        <br><br>
        Rechtsgrundlagen für die Verarbeitung Ihrer im Rahmen von Registrierung bzw. Bestellungen erhobenen
        personenbezogenen Daten sind Art. 6 Abs. 1 b) und Art. 6 Abs. 1 c) Datenschutzgrundverordnung.
        <br><br>
        Die Bereitstellung dieser personenbezogenen Daten ist nicht gesetzlich oder vertraglich
        vorgeschrieben. Sie
        ist aber für den Vertragsabschluss, also die Durchführung der Registrierung bzw. Bestellung
        erforderlich,
        soweit die betreffenden Angaben in unserem Registrierungs-/Bestellablauf zwingend (statt nur
        freiwillig)
        vorzunehmen sind.
        <br><br>
        <span class="underline">Nutzung unserer Visen-Dienstleistungen</span>
        <br><br>
        Wir erheben und verarbeiten von Ihnen alle personenbezogenen Daten, die dazu erforderlich sind, um
        für Sie
        das Visum sowie ggf. eine dafür erforderliche Einladung zu beantragen und ggf. einzuholen. Dazu
        gehört
        beispielsweise der Name, die Adresse, die Nummer des Personalausweises oder Reisepassnummer.
        <br><br>
        Ihre Daten werden von uns über den einzelnen Vorgang hinaus aufbewahrt, um Ihnen die zukünftige
        Beantragung
        von Visen schnell und komfortabel zu ermöglichen.
        <br><br>
        Wir speichern Ihre Daten, die wir für die Beantragung bzw. ggf. Beschaffung von Visen und/oder dafür
        erforderlicher Einladungen benötigen, für die Dauer unserer betreffenden Dienstleistungen zzgl.
        einer
        angemessenen Löschfrist von bis zu 3 Monaten. Unabhängig davon können Sie jederzeit die Löschung
        dieser
        Daten verlangen. Eine fortgesetzte Verarbeitung einzelner oder aller personenbezogener Daten
        aufgrund
        anderer Rechtsgrundlagen wie bspw. im Hinblick auf Gewährleistungs-, Garantie- oder vergleichbare
        Verpflichtungen und/oder oder im Hinblick auf gesetzliche Aufbewahrungsfristen bleibt unberührt.
        <br><br>
        Rechtsgrundlagen für die Verarbeitung Ihrer im Rahmen von Registrierung bzw. Bestellungen erhobenen
        personenbezogenen Daten sind Art. 6 Abs. 1 b) und Art. 6 Abs. 1 c) Datenschutzgrundverordnung.
        <br><br>
        Die Bereitstellung dieser personenbezogenen Daten ist nicht gesetzlich oder vertraglich
        vorgeschrieben. Sie
        ist aber für den Vertragsabschluss, also die Durchführung unserer Dienstleistungen für Visen
        erforderlich.
        <br><br>
        <span class="underline">Übermittlung der Daten ins Ausland im Rahmen unserer Dienstleistungen für Visen und ggf. dafür erforderlicher Einladungen:</span>
        <br><br>
        Ihre personenbezogenen Daten übermitteln wir zur Erfüllung des Vertrages mit Ihnen ggf. an Empfänger
        in dem
        jeweiligen Land, für das das Visum jeweils beantragt werden soll, und zwar zu folgenden Zwecken an
        folgende
        Empfänger:
        <br><br>
        - zur automatisierten Ausfüllung von Visa-Antragsformularen auf den Internetseiten der betreffenden
        Stellen
        im Drittland
        <br>
        - zur Beschaffung von Einladungen in dem jeweiligen Land, wie sie zur Beantragung von Visa in
        bestimmten
        Ländern erforderlich sind; in diesem Zusammenhang übermitteln wir Ihre insoweit erforderlichen Daten
        an die
        einladenden Personen oder Stellen sowie ggf. an Dienstleister, die uns bei der Beschaffung der
        Einladungen
        unterstützen.
        <br>
        - zur Unterbeauftragung von Dienstleistern in dem jeweiligen Land, die uns bei der Erbringung
        unserer
        vertraglichen Leistungen unterstützen
        <br><br>
        Dabei kommt es je nach Auftrag des Kunden auch zur Übermittlung der Daten in Drittländer, also
        Staaten
        außerhalb der EU, darunter Staaten ohne angemessenes Datenschutzniveau. Die Datenübermittlung ins
        Ausland
        erfolgt nur auf Ihren Antrag hin.
        <br><br><br>
        <span class="bold">2. Verwendung von Cookies</span>
        <br><br>
        In diesem Abschnitt informieren wir Sie über den Einsatz von Cookies auf unserer Website.
        <br><br>
        Beschreibung und Funktionsweise
        <br><br>
        Cookies sind kleine Textdateien, die auf dem Computer des Nutzers gespeichert werden und die eine
        Analyse
        der Benutzung der Website durch den Nutzer ermöglichen.
        <br><br>
        Eigene Cookies
        <br><br>
        Wir setzen Cookies ein, um die Nutzung der Website für den Besucher einfacher und bequemer zu
        gestalten bzw.
        bestimmte Funktionen überhaupt erst zu ermöglichen.
        <br><br>
        Rechtsgrundlage für die Verarbeitung Ihrer personenbezogenen Daten durch uns im Zusammenhang mit der
        Verwendung von Cookies ist Art. 6 Abs. 1 f) Datenschutzgrundverordnung („berechtigtes Interesse“).
        Das
        berechtigte Interesse ergibt sich aus den oben genannten Zwecken.
        <br><br>
        Im Rahmen der Nutzung von Cookies speichern wir Ihre personenbezogenen Daten so lange, wie es nötig
        ist, um
        die Nutzung unserer Webseite einfacher und bequemer zu gestalten.
        <br><br>
        Cookies Dritter
        <br><br>
        Auf der Website können auch Cookie von Drittanbietern verwendet werden, um Informationen von unserer
        Webseite und anderen Stellen im Internet zu erfassen oder zu erhalten und diese Informationen dann
        zu
        nutzen, um bspw. Webtracking-Dienste, Bewertungsdienste oder auf Zielgruppen ausgerichtete
        Werbeanzeigen
        anzubieten.
        <br><br>
        Im Rahmen der Nutzung von Cookies werden Ihre personenbezogenen Daten so lange gespeichert, wie es
        nötig
        ist, um die oben beschriebenen Zwecke zu erreichen.
        <br><br>
        Soweit die Datenverarbeitung für die oben beschriebenen Zwecke mit Ihrer Einwilligung erfolgt, ist
        Rechtsgrundlage Artikel 6 Absatz 1 a) DSGVO (Einwilligung). Im Übrigen erfolgt die Datenverarbeitung
        auf
        Grundlage von Artikel 6 Absatz 1 f) DSGVO („berechtigte Interessen“), wobei die berechtigten
        Interessen in
        den oben genannten Zwecken liegen.
        d) Widerruf/ Widerspruch/ Einstellungen
        <br><br>
        Sie haben jederzeit die Möglichkeit, eine erteilte Einwilligung in das Setzen von Cookies zu
        widerrufen oder
        der Datenverarbeitung durch Cookies zu widersprechen, indem Sie die Cookies in den Einstellungen
        Ihres
        Browsers löschen.
        <br><br>
        Auch können Sie Ihren Browser so einstellen, dass eine Speicherung von Cookies nur akzeptiert wird,
        wenn Sie
        dem zustimmen,
        <br><br>
        Was Anzeigen-Cookies anbelangt, so können Sie viele davon über folgende Dienste blockieren und/oder
        verwalten:
        <br><br>
        <a target="_blank" href="https://optout.aboutads.info/?c=2&lang=EN">www.aboutads.info/choices/</a>
        <br><br>
        <a target="_blank" href="http://www.youronlinechoices.com/uk/your-ad-choices">www.youronlinechoices.com/uk/your-ad-choices/</a>
        <br><br>
        <a target="_blank" href="https://optout.networkadvertising.org/?c=1">www.networkadvertising.org/managing/opt_out.asp</a>
        <br><br>
        Wenn Sie jedoch Cookies ablehnen, können Sie bestimmte Website-Funktionen, Services, Anwendungen
        oder Tools
        möglicherweise nicht nutzen.
        <br><br><br>
        <span class="bold">3. Google Analytics</span>
        <br><br>
        Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Ireland Limited, Gordon
        House,
        Barrow Street, Dublin 4, Irland. Google Analytics verwendet sog. „Cookies“, Textdateien, die auf
        Ihrem
        Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen.
        Die durch
        den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen
        Server
        von Google in den USA übertragen und dort gespeichert. Die erhobenen Daten werden an die Server des
        Anbieters in den USA übermittelt. Diese Datenübermittlung stützt Google auf die
        EU-Standardvertragsklauseln.
        <br>
        Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite wird Ihre IP-Adresse von Google
        jedoch
        innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens
        über den
        Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen
        Server
        von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird
        Google
        diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die
        Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung
        verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google
        Analytics
        von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
        Soweit
        Cookies auf der Website nicht ohnehin lediglich bei Erteilung einer entsprechenden Einwilligung
        gesetzt
        werden, können Sie die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
        Browser-Software
        verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
        Funktionen dieser Website vollumfänglich werden nutzen können.
        <br><br>
        Die im Rahmen der Verwendung von Google Analytics verarbeiteten Daten werden nach 26 Monate
        automatisch
        gelöscht.
        <br><br>
        Soweit die Datenverarbeitung für die oben beschriebenen Zwecke mit Ihrer Einwilligung erfolgt, ist
        Rechtsgrundlage Artikel 6 Absatz 1 a) DSGVO (Einwilligung). Im Übrigen erfolgt die Datenverarbeitung
        auf
        Grundlage von Artikel 6 Absatz 1 f) DSGVO („berechtigte Interessen“), wobei die berechtigten
        Interessen in
        den oben genannten Zwecken liegen.
        <br>
        Sie können die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen
        Daten
        (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern,
        indem Sie
        das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren:
        <br><br>
        <a target="_blank" href="https://tools.google.com/dlpage/gaoptout?hl=de%E2%80%A8">https://tools.google.com/dlpage/gaoptout?hl=de </a>
        <br><br><br>
        <span class="bold">4. YouTube (im sog. erweiterten Datenschutzmodus)</span>
        <br><br>
        Diese Website nutzt zur Darstellung von Videos ein Plugin der Google Ireland Limited, Gordon House,
        Barrow
        Street, Dublin 4, Irland. Die Einbindung der Videos erfolgt im sog. erweiterten Datenschutzmodus.
        Informationen zum erweiterten Datenschutzmodus erhalten Sie unter
        <br>
        <a href="https://support.google.com/youtube/answer/171780?hl=de">https://support.google.com/youtube/answer/171780?hl=de</a>
        <br>
        Wenn Sie ein von uns eingebundenes Video abspielen, wird Ihre IP-Adresse von YouTube erfasst.
        <br>
        Darüber hinaus können von Google weitere Daten über Ihr Nutzungsverhalten erhoben werden, um
        personalisierte
        Werbung schalten zu können. Die Datenerhebung kann dabei bereits ab dem Zeitpunkt erfolgen, in dem
        Sie
        unsere Seite aufrufen.
        <br>
        Die erhobenen Daten werden an die Server des Anbieters in den USA übermittelt. Diese
        Datenübermittlung
        stützt Google auf die EU-Standardvertragsklauseln.
        <br>
        Sie können der Erfassung Ihres Nutzungsverhaltens zu Werbezwecken jederzeit widersprechen. Siehe
        dazu
        Buchstabe d. im Abschnitt „Cookies“ dieser Datenschutzerklärung. Auf
        <br>
        <a href="https://adssettings.google.com/authenticated">https://adssettings.google.com</a> können Sie
        außerdem einstellen, welche Arten von Anzeigen Ihnen im Zusammenhang mit den Google-Diensten
        angezeigt
        werden sollen.
        <br>
        Soweit die Datenverarbeitung für die oben beschriebenen Zwecke mit Ihrer Einwilligung erfolgt, ist
        Rechtsgrundlage Artikel 6 Absatz 1 a) DSGVO (Einwilligung). Im Übrigen erfolgt die Datenverarbeitung
        auf
        Grundlage von Artikel 6 Absatz 1 f) DSGVO („berechtigte Interessen“), wobei die berechtigten
        Interessen in
        der ansprechenden Darstellung unserer Online-Angebote liegen.
        <br><br>
        Weitere Informationen zur Nutzung personenbezogener Daten durch Google im Zusammenhang mit Youtube
        finden
        Sie in deren Datenschutzerklärung von Google unter
        <br>
        <a href="https://policies.google.com/privacy?hl=de&gl=de">https://policies.google.com/privacy?hl=de&gl=de</a>
        <br><br><br>
        <span class="bold">5. Betroffenenrechte</span>
        <br><br>
        Sie haben gemäß Art. 15 Datenschutzgrundverordnung das Recht Auskunft über die Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen ("Auskunftsrecht der betroffenen Person").
        <br><br>
        Sie haben gemäß Art. 16 Datenschutzgrundverordnung das Recht, die Berichtigung und Löschung Sie
        betreffender
        unrichtiger personenbezogener Daten zu verlangen ("Recht auf Berichtigung").
        <br><br>
        Gemäß Art. 17 Datenschutzgrundverordnung können Sie die Löschung Sie betreffender personenbezogener
        Daten
        verlangen, sofern einer der dort aufgeführten Gründe zutrifft ("Recht auf Vergessenwerden").
        <br><br>
        Ebenso haben Sie gemäß Art. 18 Datenschutzgrundverordnung das Recht, die Einschränkung der
        Verarbeitung Sie
        betreffender personenbezogener Daten zu verlangen, wenn eine der dort aufgeführten Voraussetzungen
        zutrifft
        ("Recht auf Einschränkung der Verarbeitung").
        <br><br>
        Sie haben gemäß Art. 20 Datenschutzgrundverordnung das Recht, Sie betreffende personenbezogene Daten
        zur
        Verfügung gestellt zu bekommen und diese Daten einem anderen Verantwortlichen übermitteln zu lassen
        ("Recht
        auf Datenübertragbarkeit").
        <br><br>
        Widerruf von Einwilligungen: S. Abschnitt „Widerrufsrecht“ in dieser Datenschutzerklärung.
        <br><br>
        Widerspruchsrecht: Siehe Abschnitt „Widerspruchsrecht“ in dieser Datenschutzerklärung.
        <br><br>
        Sie haben das Recht sich bei der zuständigen Aufsichtsbehörde zu beschweren. Die zuständige
        Aufsichtsbehörde
        ist der Hessische Beauftragte für Datenschutz und Informationsfreiheit, Postanschrift: Postfach
        3163, 65021
        Wiesbaden, Hausanschrift: Gustav-Stresemann-Ring 1, 65189 Wiesbaden.
        <br><br><br>
        <span class="bold">6. Widerrufsrecht</span>
        <br><br>
        Ihre ggf. erteilte Einwilligung in die Verarbeitung Ihrer personenbezogenen Daten können Sie
        jederzeit
        widerrufen, bspw. per E-Mail an unsere eingangs genannte E-Mail-Adresse. Die Rechtmäßigkeit der
        aufgrund der
        Einwilligung bis zum Widerruf erfolgten Verarbeitung wird dadurch nicht berührt.
        <br><br><br>
        <span class="bold">7. Widerspruchsrecht</span>
        <br><br>
        Soweit unsere Datenverarbeitung auf Artikel 6 Absatz 1 f) DSGVO beruht („berechtigte Interessen“),
        haben Sie
        nach näherer Maßgabe des Artikel 21 DSGVO das Recht, der Verarbeitung Ihrer personenbezogenen Daten
        zu
        widersprechen.
        <br><br><br>
        <span class="bold">8. Weitergabe Ihrer Daten</span>
        <br><br>
        Soweit nicht bereits an anderer Stelle in dieser Datenschutzerklärung aufgeführt, geben wir Ihre
        personenbezogenen Daten an folgende weiteren Empfänger beziehungsweise Kategorien von Empfängern
        weiter
        <br>
        - Versanddienstleister
        <br>
        - Zahlungsdienstleister
        <br>
        - Unterbeauftragte Dienstleister, die uns bei der Erbringung unserer vertraglichen Leistungen
        unterstutzen
        (z.B.: Visabeschaffungsdienstleister).
        <br>
        - Stellen, die für die Annahme der Visumanträge, sowie für die Erteilung und Herausgabe der
        erteilten Visen
        zuständig sind.
        <br><br>
        Soweit nicht an anderer Stelle dieser Datenschutzerklärung bereits angegeben, haben wir die Absicht
        Ihre
        personenbezogenen Daten an folgendes Drittland beziehungsweise folgende internationale Organisation
        zu
        übermitteln.
        <br>
        - Versanddienstleister
        <br>
        - Zahlungsdienstleister
    </div>
</template>

<script>
import Base from "@/layouts/base.vue";

export default {
    name: "data_protection",
    components: {Base},
    provide() {
        return {
            mainTitle: 'Datenschutz'
        }
    }
}
</script>

<style lang="sass">

</style>