<template>
    <Base>
        <div class="container questionnaires notifications">
            <div>
                <h2>Notifications</h2>
                <div class="notifications__list">
                    <div class="notifications__item">
                        <div class="notifications__data">
                            <div class="notifications__data-country">
                                <img src="@/assets/images/questionnaires/chn_selected.svg" alt="">
                            </div>
                            <div class="notifications__data-text">
                                <span style="color: var(--main_black)">Electronic visa</span>
                                <span style="color: var(--primary)"> 1233 </span>
                                <span
                                    style="color: var(--description-text)">(Mittermayer, Bettina) has been granted. </span>
                            </div>
                        </div>
                        <div class="notifications__time">Now</div>
                    </div>
                    <div class="notifications__item">
                        <div class="notifications__data">
                            <div class="notifications__data-country">
                                <img src="@/assets/images/questionnaires/lka_selected.svg" alt="">
                            </div>
                            <div class="notifications__data-text">
                                <span style="color: var(--main_black)">Electronic visa</span>
                                <span style="color: var(--primary)"> 1233 </span>
                                <span
                                    style="color: var(--description-text)">(Mittermayer, Bettina) has been granted. </span>
                            </div>
                        </div>
                        <div class="notifications__time">Now</div>
                    </div>
                    <div class="notifications__item">
                        <div class="notifications__data">
                            <div class="notifications__data-country">
                                <img src="@/assets/images/questionnaires/ind_selected.svg" alt="">
                            </div>
                            <div class="notifications__data-text">
                                <span style="color: var(--main_black)">Electronic visa</span>
                                <span style="color: var(--primary)"> 1233 </span>
                                <span
                                    style="color: var(--description-text)">(Mittermayer, Bettina) has been granted. </span>
                            </div>
                        </div>
                        <div class="notifications__time">Now</div>
                    </div>
                </div>
            </div>
            <Paginator :pagination="{offset: 0, total: 100, limit: 10}"/>
        </div>
    </Base>
</template>

<script>
import Base from "@/layouts/base.vue";
import Paginator from "@/components/elements/Paginator.vue";

export default {
    name: "notifications",
    components: {Paginator, Base},
    created() {
        document.body.style.background = 'var(--background)'
    }
}
</script>

<style scoped lang="sass">
.notifications
    min-height: calc(100vh - 80px)
    display: flex
    flex-direction: column
    justify-content: space-between
    padding-bottom: 22px

    &__item
        display: flex
        justify-content: space-between
        align-items: center
        background: var(--white)
        padding: 12px 24px
        margin-top: 8px

        &:first-child
            margin-top: 0

    &__data
        display: flex
        align-items: center

        &-country
            width: 28px
            height: 28px
            border: 6px solid var(--primary)
            display: flex
            align-items: center
            justify-content: center
            border-radius: 100%
            margin-right: 36px

            img
                width: 22px
                height: 22px

        &-text
            font-weight: 500

    &__time
        color: var(--description-text)

</style>