<template>
    <div class="policies__content form shadow">
        Verbraucher haben das folgende Widerrufsrecht:
        <br><br><br>
        <span class="bold">Widerrufsrecht</span>
        <br><br>
        Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die
        Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses.
        <br><br>
        Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Visum.IO UG (haftungsbeschränkt),
        ChristianPleß-Straße
        14, 63069 Offenbach am Main, Tel.:+49 (0) 69 46 90 81 63, E-Mail: info@visum.io) mittels einer
        eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren
        Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte
        Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
        <br><br>
        Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des
        Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
        <br><br><br>
        <span class="bold">Folgen des Widerrufs</span>
        <br><br>
        Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten
        haben,
        einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass
        Sie
        eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt
        haben),
        unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung
        über
        Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe
        Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen
        wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung
        Entgelte berechnet.
        <br><br>
        Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen sollen, so haben
        Sie
        uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von
        der
        Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags unterrichten, bereits erbrachten
        Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen
        entspricht.
        <br><br>
        Das Widerrufsrecht erlischt bei einem Vertrag zur Erbringung von Dienstleistungen unter anderem
        dann,
        wenn der Unternehmer die Dienstleistung vollständig erbracht hat und mit der Ausführung der
        Dienstleistung erst begonnen hat, nachdem der Verbraucher dazu seine ausdrückliche Zustimmung
        gegeben
        hat und gleichzeitig seine Kenntnis davon bestätigt hat, dass er sein Widerrufsrecht bei
        vollständiger
        Vertragserfüllung durch den Unternehmer verliert.
        <br><br><br>
        <span class="bold">Muster-Widerrufsformular</span>
        <br><br>
        (Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es
        zurück.)
        <br><br>
        An Visum.IO UG (haftungsbeschränkt), Christian-Pleß-Straße 14, 63069 Offenbach am Main, E-Mail: <a
        href="mailto:info@visum.io">info@visum.io</a>.
        <br><br>
        – Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der
        folgenden Waren (*)/die Erbringung der folgenden Dienstleistung (*)
        <br>
        ……………………
        <br>
        – Bestellt am (*)/erhalten am (*)
        <br>
        ……………………
        <br>
        – Name des/der Verbraucher(s)
        <br>
        ……………………
        <br>
        – Anschrift des/der Verbraucher(s)
        <br>
        ……………………
        <br>
        – Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
        <br>
        ……………………
        <br>
        – Datum
        <br>
        ……………………
        <br>
        (*) Unzutreffendes streichen.

    </div>
</template>

<script>
import Base from "@/layouts/base.vue";

export default {
    name: "cancellation_policy",
    components: {Base},
    provide() {
        return {
            mainTitle: 'Widerrufsbelehrung'
        }
    }
}
</script>

<style lang="sass">

</style>