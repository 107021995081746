<template>
    <div class="settings__content" v-if="structure">
        <div style="display: flex" v-if="structure">
            <div style="width: 50%;">
                <h2>API settings</h2>
                <div class="api-settings__generates-api">
                    <div class="api-settings__api">
                        <div class="settings-input settings-copy">
                            <label>API Integration key</label>
                            <input disabled type="text" v-model="apiToken">
                            <CopyButton/>
                        </div>
                        <button class="btn-save">
                            <ArrowLoader
                                stroke="var(--description-text-darken)"
                            />
                            reset
                        </button>
                    </div>
                    <div class="api-settings__api">
                        <div class="settings-input settings-copy" style="margin-top: 16px">
                            <label>Frontend Integration key</label>
                            <input disabled type="text">
                            <CopyButton/>
                        </div>
                        <button class="btn-save">
                            <ArrowLoader
                                stroke="var(--description-text-darken)"
                            />
                            reset
                        </button>
                    </div>
                </div>
            </div>
            <div style="margin-left: 32px;">
                <h2>Sandbox mode</h2>
                <Switch
                    name="sandboxMode"
                />
            </div>
        </div>
        <h2 style="margin-top: 60px;">API Documentation</h2>
        <div class="api-settings__swagger">
            <h3>General requests data</h3>
            <h4>Requests headers</h4>
            <ul>
                <li v-for="header in structure.headers">
                    <span>{{ header.name }}:</span>
                    <p>{{ header.value }}</p>
                    <template v-if="header.description"> - {{ header.description }}</template>
                </li>
            </ul>
            <h4>Response status codes</h4>
            <ul>
                <li v-for="status_code in structure.status_codes">
                    <b>{{ status_code.name }}</b> - {{ status_code.description }}
                </li>
            </ul>
            <APIHandler
                v-for="handler in structure.handlers"
                :key="`api-handler__${handler.url}`"
                :api-token="apiToken"
                :headers="structure.headers"
                :title="handler.title"
                :method="handler.method"
                :url="handler.url"
                :description="handler.description"
                :getParams="handler.get"
                :postParams="handler.post"
                :response-json="handler.response_json"
                :status-codes="structure.status_codes"
            />
        </div>
    </div>
</template>

<script>

import CopyButton from "@/components/elements/CopyButton.vue";
import ArrowLoader from "@/components/icons/ArrowLoader.vue";
import VueJsonPretty from "vue-json-pretty";
import Switch from "@/components/fields/Switcher.vue";
import Input from "@/components/fields/fillingPage/IntegerField.vue";
import Checkbox from "@/components/fields/Checkbox.vue";
import StringField from "@/components/fields/fillingPage/StringField.vue";
import SelectField from "@/components/fields/fillingPage/SelectField.vue";
import TextField from "@/components/fields/fillingPage/TextField.vue";
import APIHandler from "@/components/APIHandler.vue";

export default {
    name: "api_key_settings",
    components: {
        APIHandler,
        TextField, SelectField, StringField, Checkbox, Input, Switch, VueJsonPretty, ArrowLoader, CopyButton},
    data: () => ({
        apiToken: 'bb5b5d73-ae3e-4bcb-b9f8-fd15533c8b6a',
        structure: null,
    }),
    async mounted() {
        this.structure = await this.$services.settings.apiStructure()
    },
}
</script>

<style lang="sass">
.api-settings
    &__generates-api
        max-width: 596px

        .btn-save
            padding: 0 16px
            display: flex
            align-items: center
            height: var(--input-height)
            margin-left: 24px

            svg
                margin-right: 14px

    &__api
        display: flex
        align-items: flex-end

    &__swagger
        .--btn-next
            width: 150px
            margin-top: 32px
            & + .vjs-tree
                margin-top: 48px

        .vjs-tree
            background: var(--settings-json-bg)
            padding: 28px 24px
            border-radius: 2px
            border: 1px solid var(--settings-sidebar-border-color)

        &-item
            margin-top: 56px

            &:first-child
                margin-top: 0

        h3
            font-size: 22px
            width: 100%
            padding-bottom: 4px
            border-bottom: 1px solid var(--primary)

        h4
            margin-top: 40px
            margin-bottom: 20px
            font-size: 20px
            font-weight: 500
            letter-spacing: 0.01em

        ul
            margin-bottom: 32px
            li
                margin-top: 8px

                &:first-child
                    margin-top: 0

                p, span
                    display: inline-block

                p
                    margin: 0
                    margin-left: 4px
                    font-size: 14px
                    padding: 5px 7px
                    border-radius: 1px
                    background: var(--settings-sidebar-border-color)
                    color: var(--description-text-darken)

        table
            border-collapse: collapse
            margin-top: 16px

            th, td
                text-align: center
                padding: 12px
                border: 1px solid var(--settings-sidebar-border-color)
                background: white

            tbody
                tr:nth-child(even)
                    td
                        background: var(--settings-json-bg)


    &__request-example
        background: var(--settings-json-bg)
        padding: 28px 24px
        border-radius: 2px
        border: 1px solid var(--settings-sidebar-border-color)
        color: var(--main_black)
        font-family: monospace
        font-size: 18px

    &__table
        width: 100%

        td:nth-child(1)
            width: 192px

        td:nth-child(2)
            width: 192px

        &-try-request
            width: 100%
            margin-top: 32px
            tbody
                td:first-child
                    width: 20%
                td
                    input
                        width: 100%
                        height: var(--input-height)
                        border-radius: var(--border-radius-input)
                        border: 1px solid var(--border-form-input)
                        outline: none
                        transition: 0.2s
                        padding-left: 16px
                        padding-right: 16px
                        &:hover
                            border-color: var(--description-text-darken)
                        &:focus
                            border-color: var(--primary)

h3 pre
    font-size: 16px

.row
    display: flex

    & > div
        max-width: 50%
        width: 100%
        overflow-x: auto

    div + div
        margin-left: 16px

    h4
        text-align: center

    pre
        width: 100%
        height: 500px
        overflow-y: auto
        overflow-x: auto
</style>
