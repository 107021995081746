<template>
    <div
        class="phone-field"
    >
        <v-select
            class="select-field"
            :options="optionsCodeCountry"
            label="code"
            :reduce="option => option.code + option.iso"
            v-model="selectedCode"
        >
            <template #option="{iso, code}">
                <div>
                    <img :src="iso ? require(`@/libs/flag-icons/${iso}.gif`): ''" alt="">
                    {{ code }}
                </div>
            </template>
            <template #selected-option="{code, iso}">
                <div>
                    <img :src="iso ? require(`@/libs/flag-icons/${iso}.gif`): ''" alt="">
                    {{ code }}
                </div>
            </template>
        </v-select>
        <input
            class="phone-input"
            @change="validatePhoneNumber"
            @keydown="filterInput"
            v-model="phone"
        >
    </div>
</template>

<script>
import PhoneNumberUtil from "google-libphonenumber"
import PhoneIcon from "@/components/icons/PhoneIcon.vue";
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import COUNTRIES from "@/dictionaries/countries";

export default {
    components: {vSelect, PhoneIcon},
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        validators: {
            type: Array,
            default: []
        }

    },
    data() {
        return {
            phone: null,
            isValidPhoneNumber: false,
            touch: false,
            optionsCodeCountry: [],
            selectedCode: {code: '+49', iso: 'deu'}
        }
    },
    methods: {
        validatePhoneNumber(event) {
            this.touch = true;
            let value
            if (typeof this.selectedCode === "string") {
                value = this.selectedCode.slice(0, -3) + event.target.value
            } else {
                value = this.selectedCode.code + event.target.value
            }
            console.log(value)
            this.$emit("update:modelValue", value);
            const phoneNumberUtil = PhoneNumberUtil.PhoneNumberUtil.getInstance();
            try {
                const phoneNumber = phoneNumberUtil.parse(value, null);
                this.isValidPhoneNumber = phoneNumberUtil.isValidNumber(phoneNumber);
            } catch (error) {
                this.isValidPhoneNumber = false;
            }
        },
        filterInput(event) {
            if (((event.metaKey || event.ctrlKey) && [67, 86, 88, 65].includes(event.keyCode)) ||
                (event.keyCode === 8 || event.keyCode === 9 || event.keyCode === 46 || event.key === '+' ||
                    event.keyCode === 37 || event.keyCode === 39)) {
                return true;
            } else if (event.keyCode === 46 || !/^[0-9]$/.test(event.key)) {
                event.preventDefault();
            }
        }
    },
    created() {
        const PhoneNumberUtil = require('google-libphonenumber').PhoneNumberUtil;

        const phoneNumberUtil = PhoneNumberUtil.getInstance();
        const supportedRegions = phoneNumberUtil.getSupportedRegions();

        supportedRegions.forEach(region => {
            const countryCode = phoneNumberUtil.getCountryCodeForRegion(region);
            for (const country in COUNTRIES) {
                if (COUNTRIES[country].iso_code === region.toUpperCase()) {
                    this.optionsCodeCountry.push({
                        code: `+${countryCode}`,
                        iso: COUNTRIES[country].iso3_code.toLowerCase()
                    })
                }
            }
        });
        console.log(this.optionsCodeCountry)
        this.optionsCodeCountry.find(item => {
            if (this.modelValue.includes(item.code)) {
                this.phone = this.modelValue.replace(item.code, '')
                this.selectedCode = item
            }
        })
        this.optionsCodeCountry = this.optionsCodeCountry.filter(item => item.iso !== 'sjm');

    }
}
</script>

<style lang="sass">
.phone-field
    position: relative
    width: 100%

.phone-field
    display: flex

    .select-field
        width: 90px

        .vs
            &__dropdown-toggle
                border-radius: var(--border-radius-input) 0 0 var(--border-radius-input) !important

                &:hover
                    border-color: var(--description-text-darken) !important

            &__selected
                padding-left: 4px !important
                padding-right: 0
                margin-right: 0

                &-options
                    flex-wrap: initial

                div
                    display: flex
                    align-items: center
                    justify-content: space-between

                    img
                        margin-right: 8px

            &__actions
                display: none

            &__search
                padding-left: 4px !important

            &__dropdown-menu
                width: 80px !important
                min-width: 80px !important

            &__dropdown-option
                padding-left: 8px


.phone-input
    width: 100%
    height: var(--input-height)
    background: var(--background-input)
    border: 1px solid
    border-color: var(--border-form-input)
    padding-left: 8px
    padding-right: 8px
    outline: none
    font-size: 16px
    color: inherit
    transition: 0.2s
    border-radius: 0 var(--border-radius-input) var(--border-radius-input) 0

    &:hover
        border-color: var(--description-text)

    &:focus
        border-color: var(--primary)

    &-valid
        input
            background: var(--primary-lighten) !important
            border-color: var(--primary) !important

        &:after
            background: var(--primary)

    &-invalid
        input
            background: var(--error) !important
            border-color: var(--error_red) !important
            border-radius: 3px 3px 0 0
            color: var(--error_red)

        &:after
            background: var(--error_red)
</style>