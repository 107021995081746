<template>
    <div class="file-field">
        <div
            class="file-field__block"
            @click="openSystemFile"
            @dragover.prevent="onDragOver"
            @drop.prevent="onFileDrop"
            :class="{'file-field__block-active': dragging}"
        >
            <img class="file-field__block-bg" src="@/assets/images/file-bg.svg" alt="arrow">
            <input
                type="file"
                ref="openFile"
                @change="updateFile"
                style="display: none;"
            />
            <div class="file-field__block-text">
                <div>
                    <img src="@/assets/images/file-arrow.svg" alt="arrow">
                    <p style="margin: 0;">{{ !file && !modelValue ? 'Upload' : 'Change' }} file</p>
                </div>
                <span v-if="file" style="padding: 0 8px">File: {{ file.name }}</span>
                <span v-else style="padding: 0 8px">File: {{ modelValue }}</span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            isCropImage: false,
            pic: '',
            dataURL: '',
            file: null,
            dragging: false
        }
    },
    props: {
        questionnaireId: Number,
        fieldName: String,
        sectionName: String,
        modelValue: {
            type: String,
            default: ''
        },
        validators: {
            type: Array,
            default: []
        }
    },
    methods: {
        openSystemFile() {
            this.$refs.openFile.click()
        },
        async updateFile(e) {
            const {files} = e.target
            if (!files || !files.length) return
            this.file = files[0]
            await this.getResult()
        },
        async getResult() {
            const formData = new FormData();
            console.log(this.file)
            formData.append('questionnaire_id', this.questionnaireId)
            formData.append('field_name', this.fieldName)
            formData.append('section_name', this.sectionName)
            formData.append('file', this.file, 'passport.pdf');

            const response = await fetch('/frontend_api/upload_file/', {
                method: "POST",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {},
                body: formData,
            });
            const json = await response.json()
            this.$emit('updateFile', json)
            this.$emit('update:modelValue', json.answers[this.sectionName][this.fieldName])
            await this.$nextTick()
            this.isCropImage = true
        },
        reset() {
            this.$emit('update:modelValue', '')
        },
        onDragOver() {
            this.dragging = true
        },
        onFileDrop(event) {
            this.dragging = false
            const files = event.dataTransfer.files;
            if (!files || !files.length) return
            this.file = files[0]
        },

    },
    async created() {
    },
}
</script>

<style lang="sass">

.cropper-dashed.dashed-h
    display: none


.cropper-dashed.dashed-v
    background: url("@/assets/images/face.png") no-repeat center
    background-size: contain
    width: 100%
    height: 100%
    left: auto
    border: none


nav
    padding: 30px

    a
        font-weight: bold
        color: #2c3e50

        &.router-link-exact-active
            color: #42b983


.file-field
    min-width: 400px
    width: 100%

    &__button
        background: var(--primary-lighten)
        color: var(--primary)
        border: 1px solid var(--primary)
        padding: 8px 16px
        border-radius: 4px
        transition: 0.2s

        &[disabled]
            background: var(--primary-lighten) !important
            color: var(--primary) !important
            border: 1px solid var(--primary) !important
            cursor: not-allowed
            opacity: 0.6

        &:hover
            background: var(--primary)
            color: var(--primary-lighten)
            border: 1px solid var(--primary-lighten)

        &-reset
            background: var(--error)
            border: 1px solid var(--error_red)
            color: var(--error_red)
            border-radius: 4px
            padding: 8px 16px
            transition: 0.2s

            &:hover
                opacity: 0.6

    &__crop
        width: 100%
        display: flex
        justify-content: center
        margin-bottom: 32px

    &__result-img
        border: 1px solid black

    &__group-btn
        width: 100%
        display: flex
        justify-content: space-between
        align-items: center

        & .--btn-next
            width: auto
            padding: 9px 16px
            height: auto
            margin-right: 16px


    &__block
        position: relative
        border: 2px dashed var(--border-form-input)
        padding: 20px
        text-align: center
        cursor: pointer
        background: var(--background)
        border-radius: 8px
        transition: 0.2s
        height: 138px
        display: flex
        align-items: center
        justify-content: center
        &:before
            position: absolute
            content: ''
            top: 0
            left: 0
            right: 0
            bottom: 0
            width: 100%
            height: 100%
            background: var(--primary-lighten)
            z-index: -1
            opacity: 0
            transition: 0.2s

        &-active
            cursor: copy !important
            &:before
                z-index: 100 !important
                opacity: 1 !important


        &-text
            position: relative
            z-index: 2
            display: flex
            flex-direction: column
            align-items: center

            img
                border: 2px solid #90999E
                border-radius: 100%
                padding: 10px

            span
                font-weight: 600
                color: var(--primary)

        &-bg
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            z-index: 1

        &:hover
            border-color: var(--description-text)


</style>
