<template>
    <div class="settings__content credentials">
        <h2>Credentials</h2>
        <form @submit.prevent="changePassword">
            <div class="settings-input settings-password">
                <label>Old password</label>
                <input type="password"
                       v-model="old_password"
                       ref="oldPassword"
                >
                <button @click.prevent="showPassword('oldPassword')"><img src="@/assets/images/settings/eye.svg" alt="">
                </button>
            </div>
            <div class="settings-input settings-password" style="margin-top: 16px">
                <label>New password</label>
                <input type="password"
                       v-model="new_password"
                       ref="newPassword"
                       :class="{'credentials__invalid': !isValidRepeatPassword}"
                >
                <button @click.prevent="showPassword('newPassword')"><img src="@/assets/images/settings/eye.svg" alt="">
                </button>
            </div>
            <div class="settings-input settings-password" style="margin-top: 16px">
                <label>Repeat password</label>
                <input type="password"
                       v-model="repeat_password"
                       ref="repeatNewPassword"
                       :class="{'credentials__invalid': !isValidRepeatPassword}"
                >
                <button @click.prevent="showPassword('repeatNewPassword')"><img src="@/assets/images/settings/eye.svg"
                                                                                alt=""></button>
            </div>
            <button class="--btn-next">Save Information</button>
        </form>
    </div>
</template>

<script>
export default {
    name: "credentials",
    data: () => ({
        old_password: null,
        new_password: null,
        repeat_password: null,
        isValidRepeatPassword: true
    }),
    methods: {
        async changePassword() {
            let info

            if (this.new_password === this.repeat_password) {
                this.isValidRepeatPassword = true
                info = await this.$services.settings.changePassword(this.old_password, this.new_password)
                console.log(info)
            } else {
                this.isValidRepeatPassword = false
            }
        },
        showPassword(ref) {
            if (this.$refs[ref].getAttribute('type') === 'password') {
                this.$refs[ref].setAttribute('type', 'text')
            } else {
                this.$refs[ref].setAttribute('type', 'password')
            }
        }
    }
}
</script>

<style scoped lang="sass">
.credentials
    form
        max-width: 328px

    .--btn-next
        margin-top: 32px
    &__invalid
        border-color: var(--error_red)

</style>