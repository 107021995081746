<template>
    <div class="form-aside__container">
        <aside class="form-aside" id="sidebar">
            <menu class="form-aside__sections list-group border-0">
                <a href="#"
                   @click="setSection(section.name)"
                   v-for="(section, index) in sections"
                   :class="{
                       'active': $route.query.section === section.name,
                       'complete': section.valid_fields_count === section.required_fields_count,
                       'invalid': section.invalid_fields_count > 0
                   }"
                   style="display: flex"
                   class="form-aside__section-item">
                    <span style="display: flex; align-items: center">
                        <span class="form-aside__icon">
                            <Component :is="section.icon_name.charAt(0).toUpperCase() + section.icon_name.slice(1).toLowerCase()" />
                            <svg v-if="section.valid_fields_count !== section.required_fields_count"
                                 class="form-aside__progress" width="45" height="45" viewBox="0 0 120 120">
                                <circle
                                    cx="60"
                                    cy="60"
                                    r="54"
                                    fill="none"
                                    stroke="#007bff"
                                    stroke-width="4"
                                    stroke-dasharray="339.292"
                                    :stroke-dashoffset="339.292 * (1 - (section.valid_fields_count / section.required_fields_count))"
                                    stroke-linecap="round"
                                    transform="rotate(-90 60 60)"
                                />
                            </svg>
                        </span>
                        <span class="form-aside__text">
                            <span class="form-aside__step">
                                Step {{ index + 1 }}/{{ sections.length }}
                            </span>
                            <span class="form-aside__label">
                                {{ section.label }}
                            </span>
                        </span>
                    </span>
                </a>
                <router-link
                    :to="{
                        name: 'check',
                        params: {
                            questionnaireId: questionnaireId
                        },
                    }"
                    :class="{
                       'active': $route.name === 'check',
                   }"
                    style="display: flex"
                    class="form-aside__section-item">
                    <span style="display: flex; align-items: center">
                        <span class="form-aside__icon">
                            <Dollars/>
                        </span>
                        <span class="form-aside__text">
                            <span class="form-aside__step">
                                Step final
                            </span>
                            <span class="form-aside__label">
                                Check your data
                            </span>
                        </span>
                    </span>
                </router-link>
            </menu>
        </aside>
    </div>
</template>

<script>
import Case from "@/components/icons/sidebar/Case.vue";
import Suitcase from "@/components/icons/sidebar/Suitcase.vue";
import Dollars from "@/components/icons/sidebar/Dollars.vue";
import Graph from "@/components/icons/sidebar/Graph.vue";
import Passport from "@/components/icons/sidebar/Passport.vue";
import Person from "@/components/icons/sidebar/Person.vue";
import Picture from "@/components/icons/sidebar/Picture.vue";

export default {
    name: "FormAside",
    components: {Picture, Person, Passport, Graph, Dollars, Suitcase, Case},
    props: {
        sections: Array,
        questionnaireId: Number,
    },
    methods: {
        setSection(sectionName) {
            this.$router.push({
                name: 'filling_page',
                params: {
                    questionnaireId: this.questionnaireId,
                },
                query: {
                    ...this.$route.query,
                    section: sectionName,
                }
            });
            this.$emit('sectionChanged')
        },
    },
    mounted() {
        if (!this.$route.query.section && this.$route.name !== 'check') {
            this.setSection(this.sections[0].name)
        }
    }
}
</script>

<style lang="sass">
.form-aside
    min-width: auto !important
    background: var(--form-background)
    padding: 32px
    box-shadow: 0 0 10px 0 rgba(211, 225, 235, 0.2)
    border-radius: var(--sidebar-radius)

    &__line
        margin: 8px 0
        margin-left: 16px
        min-height: 36px
        width: 2px
        background: var(--sidebar-label-color)
        display: block
        &-complete
            background: var(--success)

    &__icon
        position: relative
        width: 36px
        height: 36px
        display: flex
        align-items: center
        justify-content: center
        border-radius: 100%
        border: 1px solid var(--border-form-input)

        svg:first-child
            path
                fill: var(--sidebar-label-color)
                stroke: var(--sidebar-label-color)

            circle
                fill: var(--sidebar-label-color) !important

    &__text
        display: flex
        flex-direction: column
        margin-left: 24px

        span
            line-height: 1

    &__progress
        position: absolute
        left: 50%
        top: 50%
        transform: translate(-50%, -50%)
        circle
            stroke: var(--primary)

    &__step
        color: var(--sidebar-label-color)
        font-size: 14px
        margin-bottom: 4px

    &__label
        color: var(--sidebar-label-color)

    &__container
        position: sticky
        top: 90px
        min-width: var(--sidebar-min-width)
        height: 20px
        margin-right: var(--sidebar-margin-r)

    &__section-item
        list-style-type: none
        background: none
        max-width: 296px
        border-radius: 5px
        padding-left: 16px
        color: var(--description-text)
        display: flex
        flex-direction: column
        align-items: flex-start
        margin-top: 32px
        &:first-child
            margin-top: 0

        &:hover
            color: var(--main_black)
            text-decoration: none

    &__section-item
        &.invalid
            .form-aside__label
                color: var(--error_red) !important

            .form-aside__icon
                background: var(--error_red) !important

                svg:first-child
                    path
                        fill: var(--white)
                        stroke: var(--white)

                    circle
                        fill: var(--white) !important
            .form-aside__progress
                circle
                    stroke: var(--error_red) !important
        &.active
            .form-aside__label
                color: var(--primary)

            .form-aside__icon
                background: var(--primary)

                svg:first-child
                    path
                        fill: var(--white)
                        stroke: var(--white)

                    circle
                        fill: var(--white) !important

        &.complete
            .form-aside__icon
                background: var(--success)
                position: relative
                display: flex
                align-items: center
                justify-content: center

                &:after
                    content: ''
                    display: block
                    width: 7px
                    height: 12px
                    border-bottom: 2px solid white
                    border-right: 2px solid white
                    transform: rotate(45deg) translate(-1px, -1px)

                svg
                    display: none

    &__sections
        padding: 0
        display: flex
        flex-direction: column
        min-width: 256px
        margin-top: 0

    &__counter
        font-size: 13px
        color: #888
        display: block
        margin-right: 16px
        font-weight: bold


@media screen and (max-width: 644px)
    .form-aside__sections, .form-aside
        margin-right: 0 !important

    .form-aside__sections
        margin-bottom: 16px

    .form-aside__section-item
        max-width: none !important


</style>