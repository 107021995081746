<template>
    <footer class="footer text-center">
        <div class="footer__inner">
            <div class="footer__content">
                <div class="footer__list">
                    <router-link to="/policies/impressum/" tag="a" class="footer__link" style="min-width:auto;">
                        Impressum
                    </router-link>
                    <router-link to="/policies/contact/" tag="a" class="footer__link"
                       style="min-width:auto;">
                        Kontakt
                    </router-link>
                    <router-link to="/policies/agb/" tag="a" class="footer__link"
                       style="min-width:auto;">
                        AGB
                    </router-link>
                    <router-link to="/policies/datenschutz/" tag="a" class="footer__link"
                       style="min-width:auto;">
                        Datenschutz
                    </router-link>
                    <router-link to="/policies/widerrufsbelehrung/" tag="a" class="footer__link"
                       style="min-width:auto;">
                        Widerrufsbelehrung
                    </router-link>
                </div>
            </div>
            <div class="footer__reserved">
                Visum.IO Copyright. All rights reserved
            </div>
        </div>
    </footer>
</template>

<style lang="sass">
.footer
  position: absolute
  bottom: 0
  right: 0
  width: 100%
  margin-top: 20px
  padding: 32px 0 32px
  background: var(--background)

  &__inner
    max-width: var(--container-width)
    width: 100%
    vertical-align: bottom
    padding: 0 30px
    margin: 0 auto

  &__content
    display: flex
    align-content: center
    justify-content: flex-end

  &__link
    text-decoration: none !important
    margin-left: 30px
    color: var(--main_black)
    transition: 0.2s
    &:hover
      color: var(--primary) !important

  &__reserved
    font-size: 13px
    text-align: end


</style>