<template>
    <section class="auth">
        <div class="auth__gray-background"></div>
        <div class="auth__inner">
            <div class="auth__header">
                <a href="/"><img src="@/assets/images/logo.svg" alt=""></a>
            </div>
            <h1 class="auth__title">SIGN IN</h1>
            <div class="error" v-if="error">{{ error }}</div>
            <form action="" class="auth__registration">
                <div class="settings-input">
                    <label for="">E-MAIL ADDRESS</label>
                    <input type="text" v-model="email">
                </div>
                <div class="settings-input">
                    <label for="">PASSWORD</label>
                    <input type="password" v-model="password" >
                </div>
                <button class="--btn-next" @click="authorize" type="button">
                    AUTHORIZE
                </button>
            </form>
            <div class="auth__doc">
                <router-link target="_blank" tag="a" to="/policies/data_protection/">Datenschutz</router-link>
                <span></span>
                <router-link target="_blank" tag="a" to="/policies/imprint/">Impressum</router-link>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: "auth",
    data: () => ({
        email: '',
        password: '',
        error: '',
    }),
    methods: {
        async authorize() {
            const {ok} = await this.$services.questionnaires.authorize(this.email, this.password);
            if (ok) {
                this.$router.push('/')
            } else {
                this.error = 'Bad credentials'
            }
        },
    }
}
</script>

<style lang="sass">
.error
    border: 1px solid red
    background: lightcoral
    padding: 16px 8px
    font-weight: bold
    text-align: center
    color: white


.auth
    width: 100%
    min-height: 100vh
    display: flex
    justify-content: center

    &__inner
        width: 522px
        padding-top: 42px

    &__gray-background
        background: var(--background)
        height: 256px
        position: absolute
        top: 0
        left: 0
        right: 0
        z-index: -5

    &__header
        display: flex
        align-items: center
        justify-content: space-between

    &__profile
        display: flex
        align-items: center
        color: black
        text-decoration: none

        img
            margin-right: 8px
            margin-top: -5px

    &__title
        font-size: 24px
        text-align: center
        font-weight: 700
        margin-top: 80px

    &__registration
        padding: 24px
        width: 100%
        border-radius: 10px
        box-shadow: 0 10px 20px 4px rgba(#000000, 0.1)
        display: flex
        flex-direction: column
        align-items: center
        background: var(--white)

        .settings-input
            width: 100%
            margin-top: 16px
            &:first-child
                margin-top: 0

    .--btn-next
        margin-top: 40px

    &__doc
        margin-top: 56px
        display: flex
        align-items: center
        justify-content: center

        a
            text-decoration: none
            color: var(--main_black)
            font-size: 16px

        span
            height: 16px
            width: 1px
            background: var(--main_black)
            margin: 0 16px
            display: block

</style>