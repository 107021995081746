<template>
  <Base>
    <WorldMap :country="country" class="form-select__world-map"/>
    <form action="" class="form-select" method="post">
      <div class="form-select__container">
        <h1 class="form-select__title">What country are you applying for?</h1>
        <CountryBigSelect
          v-model="country"
          :options="optionsCountry"
          @JumpToQuestionnaire="JumpToQuestionnaire"
        />
      </div>

    </form>
  </Base>
</template>

<script>
import Base from '@/layouts/base.vue'
import CountryBigSelect from "@/components/fields/CountryBigSelect.vue";
import InputSearch from "@/components/fields/InputSearch.vue";
import WorldMap from "@/components/icons/WorldMap.vue";

export default {
  name: "form-select",
  components: {WorldMap, InputSearch, Base, CountryBigSelect},
  data() {
    return {
      search: '',
      country: '',
      optionsCountry: [
        {
          label: 'China',
          value: 'China'
        },
        {
          label: 'India',
          value: 'India'
        },
        {
          label: 'Sri Lanka',
          value: 'Sri Lanka'
        },
         {
            label: 'United Arab Emirates',
            value: 'United Arab Emirates'
        },
        {
            label: 'Saudi Arabia',
            value: 'Saudi Arabia'
        },

      ]
    }
  },
  computed: {
    documentType() {
      return {
        'China': 'CHINESE_VISA',
        'India': 'INDIAN_E_VISA',
        'Sri Lanka': 'SRI_LANKA_E_VISA',
        'United Arab Emirates': 'UAE_E_VISA',
        'Saudi Arabia': 'SAUDI_E_VISA',
      }[this.country]
    },
  },
  methods: {
    async JumpToQuestionnaire() {
      const questionnaire = await this.$services.questionnaires.createQuestionnaire(this.documentType);
      this.$router.push({
        name: 'filling_page',
        params: {
          questionnaireId: questionnaire.questionnaire_id,
        }
      })
    }
  }
}
</script>

<style lang="sass">
.form-select
  width: 100%
  min-height: calc(100vh - 104px)
  padding-bottom: 150px

  &__world-map
    position: fixed
    top: 0
    left: 0
    bottom: 0
    right: 0
    min-width: 100vh
    min-height: 100vh
    transform: scale(1.15)
    z-index: -1

  &__container
    max-width: 570px
    width: 100%
    margin: 0 auto
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

    .remove-after
      display: flex
      align-items: center

  &__title
    margin-top: 100px
    margin-bottom: 60px
    font-size: 53px
    font-weight: 700
    color: var(--primary)
    text-align: center
    line-height: 1.25

  &__form
    max-width: 567px
    width: 100%
    position: relative

    &:before
      content: ''
      display: block
      background: url("@/assets/images/start-to-fill_select.svg") no-repeat center
      background-size: contain
      width: 30px
      height: 30px
      position: absolute
      top: 31px
      left: 41px

  &__select
    outline: none
    max-width: 567px
    width: 100%
    height: 97px
    border-radius: 60px
    border: 8px solid var(--primary-middle)
    font-size: 16px
    font-weight: 600
    color: var(--main_black)
    padding-left: 78px

  &__profile-subtitle
    font-size: 16px
    color: var(--description-text)
    font-weight: 400
    margin-top: 150px
    margin-bottom: 16px

  &__profile-item
    display: flex
    align-items: center
    margin-top: 16px

  &__profile-name
    margin-left: 20px
    color: var(--description-text)
    font-size: 16px
    font-weight: 400

  &__select-checked
    max-width: 567px
    width: 100%
    height: 94px
    border-radius: 60px
    border: 8px solid var(--primary-lighten)
    background: var(--white)
    color: var(--main_black)
    font-size: 16px
    font-weight: 600
    padding-left: 78px
    padding-top: 26px
    position: relative

    &:before
      content: ''
      display: block
      background: url("@/assets/images/china-icon.svg") no-repeat center
      background-size: contain
      width: 30px
      height: 30px
      position: absolute
      left: 34px
      top: 23px

  &__select-btn
    width: 188px
    height: 78px
    border-radius: 60px
    background: var(--primary)
    border: none
    color: var(--white)
    font-size: 20px
    font-weight: 700
    cursor: pointer

  &__label
    position: relative
    display: inline-block
    width: 38px
    height: 16px

    input
      display: none

  &__container-select
    max-width: 567px
    width: 100%

    & > .form-select__container-inner-select
      border: 8px solid var(--primary-middle)
      border-radius: 60px
      height: 94px
      z-index: 10

  &__container-inner-select
    position: relative

</style>