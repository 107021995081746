<template>
    <div>
        <AddressIcon/>
        <input
            class="address-string-field"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
            ref="autocompleteInput"
        >
    </div>
</template>

<script>
import AddressIcon from "@/components/icons/AddressIcon.vue";

export default {
    components: {AddressIcon},
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        validators: {
            type: Array,
            default: []
        }

    },
    mounted() {
        const input = this.$refs.autocompleteInput;

        const autocomplete = new google.maps.places.Autocomplete(input);

        autocomplete.addListener("place_changed", () => {
            const selectedPlace = autocomplete.getPlace();
            if (selectedPlace.geometry && selectedPlace.formatted_address) {
                this.$emit('update:modelValue', selectedPlace.formatted_address)
            }
        });
    },
}
</script>

<style scoped lang="sass">
div
    width: 100%
    position: relative
    svg
        position: absolute
        top: 50%
        left: 12px
        transform: translateY(-50%)

.address-string-field
    width: 100%
    height: var(--input-height)
    background: var(--background-input)
    border: 1px solid
    border-color: var(--border-form-input)
    padding-left: 36px
    padding-right: 8px
    outline: none
    font-size: 16px
    color: inherit
    transition: 0.2s
    border-radius: var(--border-radius-input)

    &:hover
        border-color: var(--description-text)

    &:focus
        border-color: var(--primary)

    &-valid
        background: var(--primary-lighten) !important
        border-color: var(--primary) !important

    &-invalid
        background: var(--error) !important
        border-color: var(--error_red) !important
        border-radius: 3px 3px 0 0
        color: var(--error_red)
</style>