<template>
    <Base>
        <div class="policies__container">
            <section class="policies">
                <router-view></router-view>
            </section>
        </div>
    </Base>
</template>

<script>
import Base from "@/layouts/base.vue";

export default {
    name: "policies_layout",
    components: {Base}
}
</script>

<style lang="sass">
.policies
    background: var(--background)
    padding: 50px 200px

    .underline
        text-decoration: underline

    &__container
        min-height: 100vh
        background: var(--background)

    &__content
        max-width: 100%
        padding: 58px 88px
        margin-bottom: 100px
        background: var(--white)
        border-radius: 50px
        box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.1)
        &:after
            display: none !important

    &__title
        font-size: 30px

    &__row
        display: flex
        justify-content: space-between
        margin-bottom: 20px

        &:last-child
            margin-bottom: 0

    &__row-label
        font-weight: 700

    &__row-value
        width: 50%

</style>