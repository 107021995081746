const ERRORS_MESSAGES = {
    SHORTER_THAN_MINIMAL_LENGTH: 'Shorter than minimal length',
    LONGER_THAN_MAXIMAL_LENGTH: 'Longer than maximal length',
    LENGTH_NOT_BETWEEN_MINIMAL_AND_MAXIMAL_LENGTH: 'Length not between minimax and maximal length',
    LENGTH_IS_NOT_EQUAL: 'Length is not equals to constant value',
    INVALID_URL: 'Invalid URL',
    INVALID_EMAIL: 'Invalid email',
    NOT_IN_RANGE: 'Number not in range',
    STRING_NOT_MATCH_TO_EXPECTED_PATTERN: 'String does not match to expected pattern',
    NOT_VALID_OPTION: 'Not a valid option',
    NOT_EXPECTED_VALUE: 'Not expected value',
    REQUIRED: 'Field is required',

    INVALID_STRING: 'Invalid string',
    INVALID_UTF_8_STRING: 'Invalid string',
    INVALID_DATE: 'Invalid date',
    INVALID_COUNTRY_ISO3_CODE: 'Invalid country',
    INVALID_ADDRESS: 'Invalid address',
    INVALID_PHONE: 'Invalid phone',
    INVALID_ADDRESS_COUNTRY: 'Invalid address country',
    CANNOT_BE_NULL: 'Cannot be null',
    VALIDATOR_FAILED: 'Validator failed',
    INVALID_TYPE: 'Invalid type',

    ADDRESS_COUNTRY_IS_REQUIRED: 'Address country is required',
    ADDRESS_STATE_IS_REQUIRED: 'Address state is required',
    ADDRESS_POSTCODE_IS_REQUIRED: 'Address postcode is required',
    ADDRESS_CITY_IS_REQUIRED: 'Address city is required',
    ADDRESS_STREET_IS_REQUIRED: 'Address street is required',
    ADDRESS_HOUSE_IS_REQUIRED: 'Address house is required',

    LOWER_THAN_TRAVEL_START_DATE: 'Lower than travel start date',
    MUST_BE_LATER_THAN_TODAY: 'Date must be later than today',
    MUST_BE_EARLIER_THAN_TODAY: 'Date must be earlier than today',
    MUST_BE_LATER_THAN_COMPARISON_VALUE: 'Date must be later than comparison value',
    MUST_BE_LATER_OR_EQUAL_THAN_COMPARISON_VALUE: 'Date must be later or equal than today',
    MUST_BE_EARLIER_THAN_COMPARISON_VALUE: 'Date must be earlier than today',
    MUST_BE_EARLIER_OR_EQUAL_THAN_COMPARISON_VALUE: 'Date must be earlier or equal than today',
    MUST_BE_EQUAL_TO_COMPARISON_VALUE: 'Date must be equals to comparison value',
    MUST_BE_LATER_THAN_INTENDED_DATE_OF_ARRIVAL: 'Must be later than intended date of arrival',

    MUST_BE_LATER_THAN_DATE_OF_BIRTH: 'Date must be later than date of birth',
    MUST_BE_LATER_THAN_PASSPORT_DATE_OF_ISSUE: 'Date must be later than passport date of issue',
    MUST_BE_LATER_THAN_DATE_OF_START_LAST_TRIP_RUS: 'Date must be later than date of last trip start',
    MUST_BE_LATER_THAN_DATE_OF_ENTRY_RUS: 'Date must be later than date of entry',
    MUST_BE_LEAST_4_DAYS_LATER_THAN_TODAY: 'Must be at least 4 days old and later than today',

    MINIMUM_2_SYMBOLS: 'Minimum 2 symbols',
    MAXIMUM_20_SYMBOLS: 'Maximum 20 symbols',
    MAXIMUM_30_SYMBOLS: 'Maximum 30 symbols',
    MAXIMUM_40_SYMBOLS: 'Maximum 40 symbols',
    MAXIMUM_44_SYMBOLS: 'Maximum 44 symbols',
    MAXIMUM_50_SYMBOLS: 'Maximum 50 symbols',

    ONLY_LATIN_SYMBOLS_AND_NUMBERS: 'Only latin symbols and numbers are allowed',
    ONLY_LATIN_SYMBOLS_AND_SPACES: 'Only latin symbols and spaces are allowed',
    ONLY_LATIN_SYMBOLS_NUMBERS_AND_SPACES: 'Only latin symbols, numbers and spaces are allowed',
    ONLY_LATIN_SYMBOLS_DASHES_AND_SPACES: 'Only latin symbols, dashes and spaces are allowed',
    ONLY_LATIN_SYMBOLS_DASHES_DOTS_AND_SPACES: 'Only latin symbols, dashes, dots and spaces are allowed',
    ONLY_LATIN_SYMBOLS_NUMBERS_DASHES_AND_SPACES: 'Only latin symbols, numbers, dashes and spaces are allowed',
    ONLY_LATIN_SYMBOLS_NUMBERS_DASHES_DOTS_COMMAS_AND_SPACES: 'Only latin symbols, numbers, dashes, dots, commas and spaces are allowed',
    ONLY_LATIN_SYMBOLS_NUMBERS_SLASHES_AND_SPACES: 'Only latin symbols, numbers, slashes and spaces are allowed',
    ONLY_LATIN_SYMBOLS_NUMBERS_DASHES_DOTS_SLASHES_AND_SPACES: 'Only latin symbols, numbers, dashes, dots, slashes and spaces are allowed',
    ONLY_NUMBERS: 'Only numbers are allowed',
    ONLY_8_OR_9_SYMBOLS: 'Only 8 or 9 symbols are allowed',
    ONLY_6_SYMBOLS: 'Only 6 symbols are allowed',
    ONLY_CAPS_SYMBOLS_WITHOUT_O_AND_NUMBERS: 'Only caps symbols without "O" and numbers are allowed',
}

export default ERRORS_MESSAGES