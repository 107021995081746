<template>
    <section>
        <div class="visard">
            <div @click="toggleVisard" class="visard__inner">
                <div class="visard__logo"><img src="../../assets/images/visard/visard-logo.svg" alt="Visard"></div>
                <div class="visard__title">Visard</div>
                <div class="visard__linear-bg"></div>
            </div>
            <VisardStar class="visard__star-1" width="9" height="9" fill="white" />
            <VisardStar class="visard__star-2" width="15" height="15" fill="var(--primary)" />
            <VisardStar class="visard__star-3" width="14" height="14" fill="var(--primary)" />
            <VisardStar class="visard__star-4" width="18" height="18" fill="white" />
        </div>
        <div v-if="isShow" class="visard__content">
            <div v-if="togglePage === 'faq' || togglePage === 'message'"
                 class="visard__content-search"
            >
                <SearchInputIcon
                    stroke="var(--primary)"
                    width="18"
                    height="18"
                />
                <input type="text" placeholder="Search...">
            </div>
            <div class="visard__body">
                <button @click="toggleVisard" class="visard__close-btn"></button>
                <div v-if="togglePage === 'start'">
                    <h3 class="visard__content-title">Welcome 👋</h3>
                    <span class="visard__content-subtitle"> I’m Visard your step by step assistant!</span>
                    <div class="visard__content-block">
                        I’m going to assist you <span>with hints and checks</span> on every point of your application.
                    </div>
                    <div class="visard__content-block">
                        Also you can see <span>examples</span> of how it should be and see
                        <span>frequently asked questions</span> that other applicants had.
                    </div>
                    <div style="background: #E5F2FF; margin-top: 48px;" class="visard__content-block">
                        <button class="--btn-next">Toggle hints and checks</button>
                    </div>
                </div>
                <div v-if="togglePage === 'example'" class="visard__content-examples">
                    <div>
                        <p>Visa Application</p>
                        <img src="@/assets/images/visard/visard-example1.png" alt="">
                    </div>
                    <div>
                        <p>eVisa</p>
                        <img src="@/assets/images/visard/visard-example2.png" alt="">
                    </div>
                </div>
                <div v-if="togglePage === 'faq'" class="visard__content-faq">
                    <h5>Search above for a topic you interested in.</h5>
                    <div style="background: #E5F2FF" class="visard__content-block">
                        Please note that the FAQs and answers provided here are intended as a guide for you and are not
                        official information. It is advisable to consider the currency of the chat history.
                    </div>
                    <span>Current inquiries</span>
                    <div class="visard__content-faq-list">
                        <div @click="togglePage = 'message'" class="visard__content-block visard__content-faq-item">
                            validity passport electonic visa
                        </div>
                        <div @click="togglePage = 'message'" class="visard__content-block visard__content-faq-item">
                            validity passport electonic visa
                        </div>
                        <div @click="togglePage = 'message'" class="visard__content-block visard__content-faq-item">
                            validity passport electonic visa
                        </div>
                        <div @click="togglePage = 'message'" class="visard__content-block visard__content-faq-item">
                            validity passport electonic visa
                        </div>
                        <div @click="togglePage = 'message'" class="visard__content-block visard__content-faq-item">
                            validity passport electonic visa
                        </div>
                    </div>
                </div>
                <div v-if="togglePage === 'message'" class="visard__content-message">
                    <div class="visard__content-message-dialog">
                        <div class="visard__content-message-item visard__content-message-item-incoming">
                            <p>Hi, I am applying for an electronic visa. My passport is at this point valid for about 8
                                months. Is the validity sufficient to apply for the visa?</p>
                            <span>{{ moment().format('DD.MM.YYYY HH:mm') }}</span>
                        </div>
                        <div class="visard__content-message-item visard__content-message-item-outgoing">
                            <p>Hi, I am applying for an electronic visa. My passport is at this point valid for about 8
                                months. Is the validity sufficient to apply for the visa?</p>
                            <span>{{ moment().format('DD.MM.YYYY HH:mm') }}</span>
                        </div>
                        <div class="visard__content-message-item visard__content-message-item-incoming">
                            <p>Hi, I am applying for an electronic visa. My passport is at this point valid for about 8
                                months. Is the validity sufficient to apply for the visa?</p>
                            <span>{{ moment().format('DD.MM.YYYY HH:mm') }}</span>
                        </div>
                        <div class="visard__content-message-item visard__content-message-item-outgoing">
                            <p>Hi, I am applying for an electronic visa. My passport is at this point valid for about 8
                                months. Is the validity sufficient to apply for the visa?</p>
                            <span>{{ moment().format('DD.MM.YYYY HH:mm') }}</span>
                        </div>
                        <div class="visard__content-message-item visard__content-message-item-incoming">
                            <p>Hi, I am applying for an electronic visa. My passport is at this point valid for about 8
                                months. Is the validity sufficient to apply for the visa?</p>
                            <span>{{ moment().format('DD.MM.YYYY HH:mm') }}</span>
                        </div>
                        <div class="visard__content-message-item visard__content-message-item-outgoing">
                            <p>Hi, I am applying for an electronic visa. My passport is at this point valid for about 8
                                months. Is the validity sufficient to apply for the visa?</p>
                            <span>{{ moment().format('DD.MM.YYYY HH:mm') }}</span>
                        </div>
                    </div>
                </div>

            </div>
            <div class="visard__content-footer">
                <a href="" @click.prevent="showNotification">
                    <VisardLinkOne/>
                    Visard
                </a>
                <a
                    href="" @click.prevent="togglePage = 'example'"
                    :class="{'visard__content-footer-link-active': togglePage === 'example'}"
                >
                    <VisardLinkTwo
                        :stroke="togglePage === 'example' ? 'var(--primary)' : '#232323'"
                    />
                    Examples
                </a>
                <a href="" @click.prevent="togglePage = 'faq'"
                   :class="{'visard__content-footer-link-active': togglePage === 'faq'}"
                >
                    <VisardLinkThree
                        :stroke="togglePage === 'faq' ? 'var(--primary)' : '#232323'"
                    />
                    FAQ
                </a>
            </div>
        </div>
        <div v-if="isShowNotification" class="visard__notification">
            <button @click.prevent="closeNotification"
                    class="visard__close-btn visard__notification-close-btn"></button>
            <VisardMessage/>
            <span>
                Enter the number of your passport here. You can find the number in the upper right corner of your passport.
            </span>
        </div>
    </section>

</template>

<script>
import VisardLinkOne from "@/components/icons/visard/VisardLinkOne.vue";
import VisardLinkTwo from "@/components/icons/visard/VisardLinkTwo.vue";
import VisardLinkThree from "@/components/icons/visard/VisardLinkThree.vue";
import VisardNotification from "@/components/icons/visard/VisardNotification.vue";
import moment from "moment";
import SearchInputIcon from "@/components/icons/SearchInputIcon.vue";
import Input from "@/components/fields/fillingPage/IntegerField.vue";
import VisardStar from "@/components/icons/visard/VisardStar.vue";

export default {
    name: "Visard",
    components: {
        VisardStar,
        Input,
        SearchInputIcon, VisardMessage: VisardNotification, VisardLinkThree, VisardLinkTwo, VisardLinkOne},
    data: () => ({
        isShow: false,
        isShowNotification: false,
        togglePage: 'start',
        moment: moment

    }),
    methods: {
        showNotification() {
            this.isShow = false
            this.togglePage = 'start'
            this.isShowNotification = true
        },
        toggleVisard() {
            this.togglePage = 'start'
            this.isShow = !this.isShow
            this.isShowNotification = false
        },
        closeNotification() {
            this.togglePage = 'start'
            this.isShowNotification = !this.isShowNotification
        }
    }
}
</script>

<style scoped lang="sass">

.visard
    cursor: pointer
    position: fixed
    bottom: 32px
    right: 32px
    background: #E5F2FF
    border-radius: 100%
    width: 88px
    height: 88px
    display: flex
    align-items: center
    justify-content: center
    box-shadow: 4px 3px 18px 0 rgba(0, 0, 0, 0.2)
    z-index: 100

    &__star
        &-1
            position: absolute
            left: 15px
            top: 5px

        &-2
            position: absolute
            left: -10px
            top: 50%

        &-3
            position: absolute
            right: -10px
            top: 43%

        &-4
            position: absolute
            bottom: 0
            right: 18px

    &__notification
        position: fixed
        bottom: 32px
        right: 145px
        width: 400px
        background: #FCFEFF
        border-radius: 6px 6px 0 6px
        border: 1px solid #E5F2FF
        padding: 18px 36px 18px 24px
        display: flex
        align-items: center
        z-index: 100
        box-shadow: 0 0 20px 0 #0000001A

        &-close-btn
            right: 7px !important
            top: 5px !important

            &:after, &:before
                width: 12px !important
                height: 1px !important
                background: #9D9D9D !important

        svg
            min-width: 32px
            margin-right: 24px

        span
            font-size: 14px
            line-height: 1.5

    &__body
        height: calc(100% - 100px)
        overflow: auto
        padding: 50px 16px 16px 16px
        width: 100%

    &__content
        position: fixed
        z-index: 101
        bottom: 32px
        right: 32px
        width: 374px
        background: linear-gradient(165.26deg, #3395FF -0.65%, #BE33FF 94.04%)
        box-shadow: 0 14px 30px 0 #4536911A
        height: 720px

        &-search
            width: 100%
            position: absolute !important
            z-index: 110 !important
            left: 0
            top: 0
            background: white
            height: 58px

            svg
                position: absolute
                left: 24px
                top: 50%
                transform: translateY(-50%)
            input
                width: 100%
                height: 100%
                border: none
                outline: none
                padding-left: 50px
                background: none
                &::placeholder
                    color: var(--primary)

        &-message
            padding-top: 58px
            &-dialog
                display: flex
                flex-direction: column
            &-item
                background: #E5F2FF
                border-radius: 0 20px 20px 20px
                padding: 12px 16px 0
                margin-top: 32px
                width: 294px
                align-self: flex-start
                &:nth-child(even)
                    align-self: flex-end
                p
                    margin-top: 0
                    margin-bottom: 2px
                    font-size: 14px
                    color: black
                span
                    display: block
                    text-align: end
                    color: black
                    opacity: 0.7
                    font-size: 10px
                    line-height: 2
                &:first-child
                    margin-top: 0
                &-outgoing
                    border-radius: 20px 20px 0 20px !important
                    background: var(--primary) !important
                    p, span
                        color: white !important

        &-faq
            padding-top: 58px
            h5
                font-size: 20px
                line-height: 1.5
                color: white
                margin-top: 0
                margin-bottom: 24px

            span
                color: white
                margin-top: 24px
                margin-bottom: 12px
                display: block

            &-item
                color: #23232399
                padding: 16px !important
                padding-right: 32px !important
                position: relative
                margin-top: 16px
                cursor: pointer

                &:first-child
                    margin-top: 0

                &:after
                    content: ''
                    display: block
                    width: 8px
                    height: 8px
                    border-bottom: 2px solid var(--main_black)
                    border-right: 2px solid var(--main_black)
                    transform: rotate(-45deg) translateY(-48%)
                    position: absolute
                    top: 48%
                    right: 16px

        &-examples
            div
                margin-top: 36px

                &:first-child
                    margin-top: 0

                p
                    color: white
                    margin-left: 8px
                    font-size: 20px
                    line-height: 1.5
                    font-weight: 700
                    margin-top: 0
                    margin-bottom: 8px


        &-footer
            background: white
            padding: 20px 32px 16px
            display: flex
            justify-content: space-between
            align-items: center
            border-top: 1px solid #E5F2FF

            &-link-active
                color: var(--primary) !important
                font-weight: 700

                svg
                    opacity: 1 !important

            a
                display: flex
                flex-direction: column
                align-items: center
                font-size: 14px
                line-height: 1.5
                color: var(--main_black)

                svg
                    margin-bottom: 8px

                &:hover
                    svg
                        opacity: 0.5

        &-block
            border-radius: 6px
            padding: 16px 24px
            background: white
            margin-top: 16px

            span
                font-weight: 600
                text-decoration: underline

        &:after
            display: block
            content: ''
            background: url("@/assets/images/visard/visard-bg-content.svg") no-repeat center
            background-size: cover
            position: absolute
            top: 0
            left: 0
            right: 0
            bottom: 0
            z-index: 1

        div
            position: relative
            z-index: 2

        &-title
            font-size: 32px
            font-weight: 700
            color: white
            margin-bottom: 10px
            line-height: 1.5
            margin-top: 0
            margin-left: 4px

        &-subtitle
            font-size: 20px
            line-height: 1.5
            color: white
            margin-left: 4px
            margin-bottom: 40px
            display: block

    &__close-btn
        position: absolute
        right: 16px
        top: 16px
        border: none
        width: 20px
        height: 20px
        background: none
        z-index: 10

        &:after, &:before
            display: block
            content: ''
            width: 24px
            border-radius: 3px
            height: 2px
            background: white
            position: absolute
            top: 50%
            left: 50%

        &:after
            transform: translate(-50%, -50%) rotate(45deg)

        &:before
            transform: translate(-50%, -50%) rotate(-45deg)


    &__inner
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        width: 80px
        height: 80px
        position: relative
        border-radius: 100%

    &__logo
        background: white
        border-radius: 100%
        display: flex
        align-items: center
        justify-content: center
        width: 36px
        height: 36px
        position: relative
        z-index: 1
        margin-top: -4px

    &__title
        font-size: 14px
        color: white
        font-weight: 600
        position: relative
        margin-top: 4px
        z-index: 1

    &__linear-bg
        border-radius: 0 10px 30px 30px
        width: 65px
        height: 80px
        position: absolute
        bottom: 3px
        left: 50%
        transform: translateX(-50%)
        background: linear-gradient(165.26deg, #3395FF -0.65%, #BE33FF 94.04%), linear-gradient(0deg, #007BFF, #007BFF), linear-gradient(165.26deg, #3395FF -0.65%, #BE33FF 94.04%)

</style>