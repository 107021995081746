<template>
  <div class="pdf-doc">
    <div v-if="pdfDoc" class="pdf-doc__doc">
      <button class="pdf-doc__prev" @click="onPrevPage"></button>
      <button class="pdf-doc__next" @click="onNextPage"></button>
      <span class="pdf-doc__qty"><span>{{ pageNum }}</span> / <span>{{ page_count }}</span></span>
      <a :href="srcUrl" target="_blank" rel="noopener noreferrer">
        <canvas class="pdf-doc__canvas" ref="pdfCanvas"></canvas>
      </a>
    </div>

    <div class="pdf-doc__empty" v-else-if="!pdfDoc && srcUrl">
      <div class="loader"></div>
    </div>

    <div class="pdf-doc__empty pdf-doc__not-found" v-else-if="!pdfDoc && !srcUrl">
      <span v-if="fillingStatus === 'FILLED'">
        Start generate
      </span>
      <span v-else>
        <img src="@/assets/images/example_icon.svg" alt="">
        Applied
      </span>
    </div>
  </div>
</template>

<script>
import {getDocument, GlobalWorkerOptions} from 'pdfjs-dist';
import {markRaw} from 'vue';

export default {
  data() {
    return {
      pdfDoc: null,
      pageNum: 1,
      page_count: 0,
      scale: 1.5
    };
  },
  props: {
    srcUrl: null,
    status: null,
    fillingStatus: null,
  },
  methods: {
    async renderPage(num) {
      if (!this.pdfDoc) {
        this.pdfDoc = false;
        console.error("PDF document is not loaded yet.");
        return;
      }

      try {
        const page = await this.pdfDoc.getPage(num);
        const viewport = page.getViewport({scale: this.scale});
        const canvas = this.$refs.pdfCanvas;
        const context = canvas.getContext('2d');

        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const renderContext = {
          canvasContext: context,
          viewport
        };

        await page.render(renderContext).promise;
        this.pageNum = num;
      } catch (error) {
        console.error('Error rendering page:', error);
      }
    },
    onPrevPage() {
      if (this.pageNum > 1) {
        this.renderPage(this.pageNum - 1);
      }
    },
    onNextPage() {
      if (this.pageNum < this.page_count) {
        this.renderPage(this.pageNum + 1);
      }
    },
    async loadPdf() {
      try {
        const loadingTask = getDocument(this.srcUrl);
        this.pdfDoc = markRaw(await loadingTask.promise);
        this.page_count = this.pdfDoc.numPages;
        await this.renderPage(this.pageNum);
      } catch (error) {
        console.error('Error loading PDF:', error);
      }
    }
  },
  mounted() {
    GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.2.67/pdf.worker.mjs';
  },
  watch: {
    async srcUrl() {
      await this.loadPdf();
    }
  }
};

</script>

<style lang="sass">

.pdf-doc
  max-width: 200px
  width: 100%
  position: relative
  height: 282px
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1)
  border-radius: 16px
  overflow: hidden
  background: white

  &__not-found
    background: url("@/assets/images/example_visa.png") no-repeat center
    background-size: cover
    position: relative
    span
      position: relative
      z-index: 2
      display: flex
      flex-direction: column
      align-items: center
      color: white
      img
        margin-bottom: 6px
    &:after
      content: ''
      background: rgba(98, 94, 227, 0.5)
      width: 100%
      height: 100%
      position: absolute
      z-index: 1
      top: 0
      left: 0

  &__empty
    display: flex
    align-items: center
    justify-content: center
    height: 100%

  &__doc
    &:hover
      & .pdf-doc
        &__prev,
        &__next,
        &__qty
          opacity: 0.8
          z-index: 1

  &__prev,
  &__next,
  &__qty
    transition: 0.2s
    opacity: 0
    z-index: -1

  &__prev,
  &__next
    position: absolute
    top: 50%
    transform: translateY(-50%)
    border: none
    background: var(--description-text-darken)
    border-radius: 100%
    width: 20px
    height: 20px

    &:after
      content: ''
      padding: 0
      background: none
      width: 7px
      height: 7px
      display: block
      border-bottom: 1px solid white
      border-right: 1px solid white


  &__prev
    left: 10px

    &:after
      transform: rotate(135deg)
      position: absolute
      top: 6.5px
      left: 7.5px

  &__next
    right: 10px

    &:after
      transform: rotate(-45deg)
      position: absolute
      top: 6.5px
      right: 7.5px


  &__canvas
    width: 100%

  &__qty
    font-size: 12px
    background: var(--primary)
    color: white
    padding: 4px 8px
    border-radius: 10px
    position: absolute
    right: 8px
    bottom: 16px


</style>
