<template>
  <router-view/>
</template>

<script>

const CSS_PROPERTIES = [
  '--fonts',
  '--container-width',
  '--primary',
  '--primary-middle',
  '--primary-darken',
  '--primary-lighten',
  '--success',
  '--background',
  '--background-input',
  '--border-form-input',
  '--main_black',
  '--white',
  '--label-color',
  '--label-margin-r',
  '--error_red',
  '--error',
  '--input-height',
  '--input-padding',
  '--border-radius-input',
  '--margin-row-field',
  '--padding-form',
  '--width-label',
  '--width-input',
  '--form-field-display',
  '--form-radius',
  '--form-triangle-display',
  '--form-background',
  '--form-title-size',
  '--form-title-weight',
  '--form-line-display',
  '--sidebar-margin-r',
  '--sidebar-min-width',
  '--substrate-display',
  '--step-display',
  '--sidebar-label-color',
  '--sidebar-radius',
  '--btn-edit-questionnaire-bg',
  '--btn-edit-questionnaire-radius',
  '--btn-edit-questionnaire-color',
  '--btn-edit-questionnaire-padding',
  '--btn-edit-questionnaire-active',
  '--btn-save-bg',
  '--btn-save-height',
  '--btn-save-padding',
  '--header-icons',
  '--description-text',
  '--description-text-darken',
  '--paginator-active',
  '--settings-sidebar-border-color',
  '--settings-json-bg',
]

export default {
  async mounted() {
    const data = await this.$services.questionnaires.authenticate();
    if (!data) {
      this.$router.push('/auth/')
      return
    }
    this.$store.commit('USER', data)
    this.$store.commit('SHOW_IN_PARTNER_DESIGN', data.show_in_partner_design)
  },
  methods: {
    setDesignMode(/** @type {boolean} */ isPartnerDesign) {

    }
  },
  // watch: {
  //   $route: {
  //     immediate: true,
  //     handler(to) {
  //       document.title = to.meta.title || 'Visa Ease';
  //     },
  //   },
  // },
}
</script>

<style lang="sass">
@import "@/assets/styles/variables--default"
@import "@/assets/styles/variables--partners"
@import "@/assets/styles/variables"
@import "@/assets/styles/fonts"
@import "@/assets/styles/global"
@import "@/assets/styles/form"
@import "@/assets/styles/buttons"
@import "@/assets/styles/error-message"
@import "@/assets/styles/profile-data"
@import "@/assets/styles/fields/common"
@import "@/assets/styles/fields/fields-error"
@import "@/assets/styles/select-filter"
@import "@/assets/styles/settings-field"
@import "@/assets/styles/loader"
@import "@/assets/styles/floating-style-fields/floating-input"
@import "@/assets/styles/floating-style-fields/floating-select"

body
  color: var(--main_black)
  font-family: var(--fonts)
  margin: 0
  box-sizing: border-box
  min-height: 100vh
  position: relative
  overflow-x: hidden

button
  cursor: pointer

select
  margin-top: 0 !important

*,
*:before,
*:after
  box-sizing: border-box

a
  text-decoration: none

tr
  &.--description-text
    td
      color: var(--gray)

.--btn-next[disabled]
  width: 100%
  background: var(--primary-darken)
  border: none
  border-radius: var(--border-radius-input)
  color: var(--white)
  cursor: not-allowed

</style>
