<template>
    <div>
        <input
            class="input"
            :value="modelValue"
            type="number"
            step="1"
            @change="$emit('update:modelValue', $event.target.value)"
            @keydown="filterInput"
            min="0"
        >
    </div>
</template>

<script>
export default {
    name: "Input",
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        validators: {
            type: Array,
            default: []
        }

    },
    methods: {
        filterInput(event) {
            if (((event.metaKey || event.ctrlKey) && [67, 86, 88, 65].includes(event.keyCode)) ||
                (event.keyCode === 8 || event.keyCode === 9 || event.keyCode === 46 || event.key === '.')) {
                return true;
            } else if (event.keyCode === 46 || !/^[0-9]$/.test(event.key)) {
                event.preventDefault();
            }
        },
    }
}
</script>

<style scoped lang="sass">
div
    width: 100%

.input
    width: 100%
    height: var(--input-height)
    background: var(--background-input)
    border: 1px solid
    border-color: var(--border-form-input)
    padding-left: 20px
    padding-right: 8px
    outline: none
    font-size: 16px
    color: inherit
    transition: 0.2s
    border-radius: var(--border-radius-input)

    &:hover
        border-color: var(--description-text)

    &:focus
        border-color: var(--primary)

    &-valid
        background: var(--primary-lighten) !important
        border-color: var(--primary) !important

    &-invalid
        background: var(--error) !important
        border-color: var(--error_red) !important
        border-radius: 3px 3px 0 0
        color: var(--error_red)
</style>