<template>
    <div class="switch">
        <input
            type="checkbox"
            :id="name"
            :checked="modelValue"
            @change="handleChange"
            hidden
        />
        <label :for="name" class="switch-label"></label>
    </div>
</template>

<script>
export default {
    name: 'Switch',
    props: {
        modelValue: Boolean,
        name: String
    },
    emits: ['update:modelValue'],
    methods: {
        handleChange(event) {
            this.$emit('update:modelValue', event.target.checked);
        },
    },
};
</script>

<style lang="sass">
.switch-label
    cursor: pointer
    text-indent: -9999px
    width: 35px
    height: 20px
    background: grey
    display: block
    border-radius: 100px
    position: relative
    transition: 0.2s

    &:after
        content: ""
        position: absolute
        top: 2px
        left: 2px
        width: 16px
        height: 16px
        background: #fff
        border-radius: 90px
        transition: 0.3s

    input:checked + &
        background: var(--primary)

        &:after
            left: calc(100% - 19px)

input[type="checkbox"]
    visibility: hidden
    position: absolute
</style>
