<template>
    <section>
        <div v-if="isShow" @click="togglePopup" class="mask"></div>
        <transition name="fade">
            <div v-if="isShow" class="popup-hook">
                <h4>Create webhook</h4>
                <button @click.prevent="togglePopup" class="close-btn"></button>
                <div>
                    <div class="settings-input">
                        <label for="">Event</label>
                        <v-select v-model="name" :options="types" />
                    </div>
                    <div class="settings-input">
                        <label for="">URL</label>
                        <input v-model="url" type="text">
                    </div>
                    <button @click="create" class="--btn-next">Create</button>
                </div>
            </div>
        </transition>
    </section>
</template>
<script>
import vSelect from "vue-select";
import Input from "@/components/fields/fillingPage/IntegerField.vue";

export default {
    name: "PopupHook",
    components: {Input, vSelect},
    data: () => ({
        isShow: false,
        isEdit: false,
        types: [
            'questionnaire_created',
            'questionnaire_updated',
            'questionnaire_filled',
            'questionnaire_document_generation_started',
            'questionnaire_document_generation_success',
            'questionnaire_document_generation_failed',
        ],
        webhooks: null,
        name: 'questionnaire_created',
        url: '',
    }),
    methods: {
        togglePopup() {
            this.isShow = !this.isShow
            if (!this.isShow) {
                this.isEdit = false
            }
        },
        isEditPopup(edit = false, data) {
            this.isEdit = edit
            this.isShow = true

            // this.data = data
        },
        async create() {
            this.$services.settings.addWebhook(this.name, this.url)
            this.isShow = false
        }
    },
    async mounted() {
        this.webhooks = await this.$services.settings.webhooks()
    },
}
</script>

<style lang="sass">

.fade-enter-active, .fade-leave-active
    transition: opacity 0.5s

.fade-enter-from, .fade-leave-to
    opacity: 0

.popup-hook
    position: fixed
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    background: white
    border: 1px solid var(--settings-sidebar-border-color)
    width: 500px
    padding: 32px
    z-index: 101

    h4
        margin: 0 0 32px
        font-size: 20px

    .close-btn
        position: absolute
        top: 36px
        right: 32px

    .settings-input
        margin-bottom: 16px

    .--btn-next
        margin-top: 32px

    .vs__selected-options
        flex-wrap: wrap !important
</style>