<template>
    <v-select
        class="country-field"
        :options="options"
        :reduce="(option) => option.value"
        v-model="selectedValues"
        label="country"
    >
        <template #option="option">
            <div class="country-field__option">
                <img :src="flagIconPath(option)" alt="">
                {{ option.country }}
            </div>
        </template>
        <template #selected-option="value">
            <div class="country-field__selected">
                <img :src="flagIconPath(value)" alt="">
                {{ value.country }}
            </div>
        </template>
    </v-select>
</template>

<script>
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select'
import COUNTRIES from '@/dictionaries/countries'

export default {
    components: {vSelect},
    data: () => ({
        COUNTRIES,
    }),
    props: {
        modelValue: String,
        validators: {
            type: Array,
            default: []
        },
        label: {
            type: String,
            default: "country"
        },
        choices: {
            type: Array,
            default: [],
        },
    },
    computed: {
        options() {
            const array = [];
            for (const iso3Code in COUNTRIES) {
                if (!this.choices.length || this.choices.includes(iso3Code)) {
                    const country = COUNTRIES[iso3Code];
                    array.push({
                        country: country.name,
                        value: country.iso3_code,
                    });
                }
            }
            return array;
        },
        selectedValues: {
            get() {
                return this.modelValue ? {
                    country: COUNTRIES[this.modelValue.toUpperCase()].name,
                    value: this.modelValue,
                } : null
            },
            set(newValues) {
                console.log('SET VALUE', newValues)
                this.$emit('update:modelValue', newValues);
            }
        }
    },
    methods: {
        transformText(iso3_code) {
            if (COUNTRIES[iso3_code.toUpperCase()]) {
                return COUNTRIES[iso3_code.toUpperCase()].name
            }
        },
        flagIconPath({name, value}) {
            if (!value) return null;
            return require(`@/libs/flag-icons/${value.toLowerCase()}.gif`);
        },
    }
}
</script>

<style lang="sass">
\:root
    --vs-dropdown-option--active-bg: var(--description-text-darken) !important

.country-field
    width: 100%

    &__option
        display: block
        width: 100%
        height: 100%
        margin: 0
        padding: 0
        padding-left: 1px

        img
            margin-right: 8px

    &__selected
        img
            margin-right: 8px

    & .vs
        &__search
            padding-left: 16px !important

        &__actions
            padding-right: 12px

        &__dropdown-toggle
            background: var(--background-input)
            border: 1px solid var(--border-form-input)
            min-height: var(--input-height)
            transition: 0.2s
            border-radius: var(--border-radius-input) !important
            &[aria-expanded="true"]
                border-radius: var(--border-radius-input) var(--border-radius-input) 0 0 !important

            &:hover
                border: 1px solid var(--description-text-darken)

        &__selected
            position: relative !important
            padding-left: 16px !important

        &__dropdown-menu
            background: var(--background-input)
            border: 1px solid var(--description-text-darken)
            border-top: none

    &.vs--open
        .vs__dropdown-toggle
            border: 1px solid var(--description-text-darken) !important

    &-valid
        & .vs
            &__actions
                svg
                    path
                        fill: var(--primary) !important

            &__dropdown-toggle
                background: var(--primary-lighten) !important
                border: 1px solid var(--primary) !important

                &:hover
                    border: 1px solid var(--primary) !important

            &__dropdown-menu
                background: var(--primary-lighten) !important
                border: 1px solid var(--primary) !important
                border-top: none !important

            &.vs--open
                .vs__dropdown-toggle
                    border: 1px solid var(--primary) !important

    &-invalid
        & .vs
            &__actions
                svg
                    path
                        fill: var(--error_red) !important

            &__dropdown-toggle
                background: var(--error) !important
                border: 1px solid var(--error_red) !important

                &:hover
                    border: 1px solid var(--error_red) !important

            &__dropdown-menu
                background: var(--error) !important
                border: 1px solid var(--error_red) !important
                border-top: none !important

            &.vs--open
                .vs__dropdown-toggle
                    border: 1px solid var(--error_red) !important

</style>