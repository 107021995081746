<template>
    <Base :hide-header="isIntegration">
        <Visard/>
        <div
            :class="[
                'container-content',
                {
                    'hide-menu': isIntegration && integrations_config.hide_menu,
                    'hide-menu-info': isIntegration && integrations_config.hide_menu_info,
                    'horizontal-menu': isIntegration && integrations_config.horizontal_menu,
                    'integrations': isIntegration
                }
            ]">
            <div style="position: relative" class="application" v-if="form">
                <FormAside
                    :sections="form.sections"
                    @sectionChanged="showRequiredErrors = false"
                    :questionnaire-id="questionnaireId"
                />
                <div class="form" v-for="(section, index) in form.sections"
                     v-show="$route.query.section === section.name">
                    <div class="form__substrate"></div>
                    <div class="form__triangles">
                        <div></div>
                        <div></div>
                    </div>
                    <div style="position: relative; z-index: 1;">
                        <p class="form__step">
                            Step {{ index + 1 }}/{{ form.sections.length }}
                        </p>
                        <h3 class="form__title">{{ section.label }}</h3>
                        <div class="form__line"></div>
                        <div class="form__card-body">
                            <div class="error-message" v-if="false">
                                <h5>Please, fix validation errors before continue filling form</h5>
                                <a style="" href="#">
                                    Not fully filled
                                </a>
                                <br>
                            </div>
                            <div>
                                <FormField
                                    :show-required-errors="showRequiredErrors"
                                    v-for="field in section.fields"
                                    :key="field.name"
                                    :field-name="field.name"
                                    :section-name="section.name"
                                    :questionnaire-id="questionnaireId"
                                    v-show="field.required"
                                    v-model="answers[section.name][field.name]"
                                    :label="field.label"
                                    :address-fields="field.address_fields"
                                    :address-country-only="field.address_country_only"
                                    :type="field.type"
                                    :choices="field.choices"
                                    :fields="field.nested_fields"
                                    :errors="section.errors[field.name]"
                                    @updatePhoto="processFileUpload"
                                    @validValue="processValidValue(section.name, field.name, $event)"
                                />
                            </div>

                            <button style="margin-top: 40px;" class="--btn-next" @click="nextPage">Next</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Base>
</template>

<script>
import Base from "@/layouts/base.vue";
import FormAside from "@/components/elements/FormAside.vue";
import FormField from "@/components/fields/FormField.vue";
import Visard from "@/components/elements/Visard.vue";

export default {
    name: "filling_page",
    components: {Visard, FormField, FormAside, Base},
    data: () => ({
        form: null,
        answers: null,
        showRequiredErrors: false,
        questionnaireId: null,
        accessToken: null,
        integrations_config: {
            hide_menu: false,
            hide_menu_info: false,
            horizontal_menu: false
        }
    }),
    computed: {
        isValidSection() {
            const section = this.form.sections.find(s => s.name === this.$route.query.section);
            return section.is_valid;
        },
        isIntegration() {
            return this.accessToken !== null;
        }
    },
    methods: {
        async processValidValue(sectionName, fieldName, value) {
            const questionnaire = await this.$services.questionnaires.setFieldValue(
                this.questionnaireId,
                sectionName, fieldName, value
            )
            this.form = questionnaire.form;
        },
        async processFileUpload(questionnaire) {
            console.log('PROCESSING', questionnaire)
            this.form = questionnaire.form;
        },
        nextPage() {
            if (!this.isValidSection) {
                this.showRequiredErrors = true;
                return;
            } else {
                this.showRequiredErrors = false;
            }


            const sectionIndex = this.form.sections.findIndex(s => s.name === this.$route.query.section);
            console.log(sectionIndex);
            if (sectionIndex < this.form.sections.length - 1) {
                this.$router.push({
                    name: 'filling_page',
                    params: {
                        questionnaireId: this.questionnaireId,
                    },
                    query: {
                        section: this.form.sections[sectionIndex + 1].name,
                    }
                })
            } else {
                this.$router.push({
                    name: 'check',
                    params: {
                        questionnaireId: this.questionnaireId,
                    },
                })
            }
        },
    },
    async mounted() {
        const questionnaireId = this.$route.params.questionnaireId;
        let questionnaire;
        if (questionnaireId) {
            questionnaire = await this.$services.questionnaires.getQuestionnaire({
                questionnaire_id: questionnaireId,
            });
        } else {
            const accessToken = this.$route.params.accessToken;
            this.accessToken = accessToken;
            questionnaire = await this.$services.questionnaires.getQuestionnaire({
                access_token: accessToken,
            });
        }
        this.questionnaireId = questionnaire.questionnaire_id;
        this.answers = questionnaire.answers;
        this.form = questionnaire.form;
    },
}
</script>

<style lang="sass">
@import "@/assets/styles/integrations"

</style>