<template>
    <div class="api-handler">
        <div class="api-settings__swagger-item">
            <h3>
                {{ title }}
                <pre>{{ method }} {{ url }}</pre>
            </h3>
            <p>{{ description }}</p>
            <h4>Request Query parameters</h4>
            <table class="api-settings__table">
                <thead>
                    <tr>
                        <th>Parameter</th>
                        <th>Type</th>
                        <th style="text-align: start">Description</th>
                        <th>Required</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="param in getParams">
                        <td>{{ param.name }}</td>
                        <td>{{ param.type }}</td>
                        <td style="text-align: start" v-html="param.description"></td>
                        <td>{{ param.required ? '+' : '-' }}</td>
                    </tr>
                </tbody>
            </table>
            <template v-if="postParams.length">
                <h4>Request JSON fields</h4>
                <table class="api-settings__table">
                    <thead>
                        <tr>
                            <th>Parameter</th>
                            <th>Type</th>
                            <th style="text-align: start">Description</th>
                            <th>Required</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="param in postParams">
                            <td>{{ param.name }}</td>
                            <td>{{ param.type }}</td>
                            <td style="text-align: start" v-html="param.description"></td>
                            <td>{{ param.required ? '+' : '-' }}</td>
                        </tr>
                    </tbody>
                </table>
            </template>
            <h4>Response JSON fields</h4>
            <table class="api-settings__table">
                <thead>
                    <tr>
                        <th>Parameter</th>
                        <th>Type</th>
                        <th style="text-align: start">Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="param in responseJson">
                        <td>{{ param.name }}</td>
                        <td>{{ param.type }}</td>
                        <td style="text-align: start" v-html="param.description"></td>
                    </tr>
                </tbody>
            </table>
            <h4>Send API request</h4>
            <table class="api-settings__table">
                <thead>
                    <tr>
                        <th>Parameter</th>
                        <th>Use</th>
                        <th style="text-align: start">Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="param in requestParams">
                        <td>{{ param.name }}</td>
                        <td>
                            <Checkbox
                                :disabled="param.required"
                                v-model="param.use"
                                :name="`${url}_${method}_use_${param.name}`"
                            />
                        </td>
                        <td style="text-align: start">
                            <template v-if="param.use">
                                <StringField
                                    v-if="param.type === 'string' && !param.choices"
                                    v-model="param.value"
                                />
                                <SelectField
                                    v-if="param.type === 'string' && param.choices"
                                    :choices="param.choices"
                                    v-model="param.value"
                                />
                                <TextField
                                    v-if="param.type === 'object'"
                                    v-model="param.value"
                                />
                                <BooleanField
                                    v-if="param.type === 'boolean'"
                                    :model-value="param.value ? 'Yes' : 'No'"
                                    @update:modelValue="v => param.value = v === 'Yes'"
                                    :name="`param_${param.name}`"
                                />
                            </template>
                        </td>
                    </tr>
                </tbody>
            </table>
            <button class="--btn-next" @click="request">Try it</button>
            <div class="row">
            <div>
                <h4>Raw request</h4>
                <pre class="api-settings__request-example">
{{ method }} {{ requestUrl }}
<template v-for="header in headers">{{ header.name }}: {{ headerValue(header.value) }}<br></template>
{{ requestJSON }}
                </pre>
            </div>
            <div>
                <h4>Raw response</h4>
                <pre class="api-settings__request-example">{{ response }}</pre>
            </div>
            </div>
        </div>
    </div>
</template>

<script>

import Checkbox from "@/components/fields/Checkbox.vue";
import TextField from "@/components/fields/fillingPage/TextField.vue";
import SelectField from "@/components/fields/fillingPage/SelectField.vue";
import BooleanField from "@/components/fields/fillingPage/BooleanField.vue";
import StringField from "@/components/fields/fillingPage/StringField.vue";
import {v4 as uuidv4} from 'uuid';

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === name + "=") {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

export default {
    components: {StringField, BooleanField, SelectField, TextField, Checkbox},
    data: () => ({
        requestParams: [],
        randomUUID: uuidv4(),
        response: 'Not processed',
    }),
    props: {
        apiToken: String,
        headers: Array,
        statusCodes: Array,
        title: String,
        method: String,
        url: String,
        description: String,
        getParams: Array,
        postParams: Array,
        responseJson: Array,
    },
    computed: {
        requestUrl() {
            let result = `${location.origin}${this.url}`
            let paramsStrings = []
            for (const param of this.requestParams) {
                if (param.method !== 'GET') continue
                if (!param.use) continue
                if (param.type === 'string') {
                    paramsStrings.push(`${param.name}=${param.value}`)
                } else if (param.type === 'boolean' && param.value) {
                    paramsStrings.push(`${param.name}`)
                }
            }
            if (paramsStrings.length) {
                result += '?' + paramsStrings.join('&')
            }
            return result
        },
        requestJSON() {
            let result = {}
            for (const param of this.requestParams) {
                if (param.method !== 'POST') continue
                if (!param.use) continue
                if (param.type === 'object') {
                    try {
                        result[param.name] = JSON.parse(param.value)
                    } catch {
                        result[param.name] = 'Error JSON decoding'
                    }
                } else {
                    result[param.name] = param.value
                }
            }
            return JSON.stringify(result, null, 4)
        },
    },
    methods: {
        headerValue(v) {
            if (v === 'Your API Token') return this.apiToken
            if (v === 'Random UUID4') return this.randomUUID
            return v
        },
        responseText(statusCode, statusText, headers, responseText) {
            let result = (
                `${this.method} ${this.requestUrl}\n` +
                `HTTP ${statusCode} ${statusText}\n`
            )
            for (const headerName of headers.keys()) {
                result += `${headerName}: ${headers.get(headerName)}\n`
            }
            result += '\n'
            try {
                result += JSON.stringify(JSON.parse(responseText), null, 4)
            } catch {
                result += responseText
            }
            return result
        },
        async request() {
            const headers = {
                'X-CSRFTOKEN': getCookie('csrftoken'),
            };
            for (const header of this.headers) {
                headers[header.name] = this.headerValue(header.value)
            }
            const response = await fetch(this.requestUrl, {
                headers: headers,
                method: this.method,
                body: this.method === 'POST' ? this.requestJSON : undefined,
            })
            console.log()
            const responseBody = await response.text()
            console.log(JSON.parse(responseBody))
            this.response = this.responseText(response.status, response.statusText, response.headers, responseBody)
            this.randomUUID = uuidv4()
        },
    },
    mounted() {
        const params = [];
        for (const param of this.getParams) {
            params.push({
                ...param,
                use: param.required === true,
                method: 'GET',
            })
        }
        for (const param of this.postParams) {
            params.push({
                ...param,
                use: param.required === true,
                method: 'POST',
                value: null,
            })
        }
        this.requestParams = params;
    }
}
</script>

<style lang="sass">

</style>