<template>
    <svg :width="width" :height="height" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.42 3.28848L0 4.50001L3.24 5.71155L4.5 9L5.58 5.71155L9 4.50001L5.58 3.28848L4.5 0L3.42 3.28848Z"
              :fill="fill"/>
    </svg>
</template>

<script>
export default {
    name: "VisardStar",
    props: {
        fill: '#ffffff',
        width: 9,
        height: 9
    }
}
</script>

<style scoped lang="sass">

</style>