<template>
    <div class="settings__content webhooks">
        <div style="display: flex; align-items: center; justify-content: space-between">
            <h2>Webhooks configuration</h2>
            <button @click.prevent="$refs.popupHook.togglePopup"
                    class="webhooks__add-btn --btn-next">New hook</button>
        </div>

        <div class="webhooks__fields" v-if="webhooks" v-for="hook in webhooks">
            <div class="settings-select">
                <label for="">Event</label>
                <v-select v-model="hook.event" disabled :options="types" />
            </div>
            <div class="settings-input">
                <label for="">URL</label>
                <input :value="hook.url" disabled type="text">
            </div>
        </div>

        <div class="settings-json">
            <h5>Response</h5>
            <vue-json-pretty :data="json"/>
        </div>
        <PopupHook ref="popupHook"/>
    </div>
</template>

<script>

import vSelect from "vue-select";
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import PopupHook from "@/components/elements/PopupHook.vue";
import Input from "@/components/fields/fillingPage/IntegerField.vue";


export default {
    name: "webhooks",
    components: {Input, PopupHook, vSelect, VueJsonPretty},
    data: () => ({
        json: {
            "document_type": "INDIAN_E_VISA",
            "access_token": "0d023c4d2c3a1e2b06e94fc74f170c1398c0280d25e67c8286ddce72e1f74ff0",
            "filling_status": "CREATED",
            "visa_status": "NOT_STARTED",
            "answers": {
                "registration_data": {},
                "applicant_details": {},
                "passport_data": {},
                "applicants_address_details": {},
                "family_details": {},
                "profession": {},
                "details_of_visa_sought": {},
                "previous_visa": {},
                "other_info": {}
            },
        },
        webhooks: null,
        nameHook: null,
        urlHook: null,
        types: [
            'questionnaire_created',
            'questionnaire_updated',
            'questionnaire_filled',
            'questionnaire_document_generation_started',
            'questionnaire_document_generation_success',
            'questionnaire_document_generation_failed',
        ],
    }),
    async mounted() {
        this.webhooks = await this.$services.settings.webhooks()
    },
}
</script>

<style lang="sass">
.webhooks
    & .settings-json
        margin-top: 64px

    &__fields
        max-width: 486px

        & + &
            margin-top: 48px

        & .settings-input
            margin-top: 16px

    &__add-btn
        width: auto
        height: 24px
        font-size: 14px
        padding: 0 12px

input[disabled]
    background-color: var(--vs-disabled-bg)
</style>