<template>
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.9853 11.4622H5.18487C2.87363 11.4622 1 13.3358 1 15.647V26.1092C1 28.4205 2.87363 30.2941 5.18487 30.2941H8.84663L11.4622 32.9096C11.6552 33.1027 11.9853 32.966 11.9853 32.693V30.2941H18.7857C21.0969 30.2941 22.9706 28.4205 22.9706 26.1092V20.8781M3.61554 17.2164H13.5546M3.61554 20.8781H18.2626M3.61554 24.5399H15.647M17.7395 24.5399H18.7857M20.8781 6.02521C21.0891 5.3962 21.5055 4.86579 22.0535 4.52793C22.6016 4.19007 23.2459 4.06657 23.8725 4.1793C24.499 4.29203 25.0673 4.63371 25.4767 5.14383C25.8861 5.65395 26.1102 6.29959 26.1092 6.96639C26.1092 8.84874 23.4174 9.78991 23.4174 9.78991V11.6723M23.4892 13.5546H23.4982M23.4937 17.7395C18.0045 17.7395 13.5546 13.9922 13.5546 9.36974C13.5546 4.74726 18.0045 1 23.4937 1C28.9829 1 33.4327 4.74726 33.4327 9.36974C33.4327 12.1863 31.7806 14.678 29.2479 16.195V20.355L26.2806 17.406C25.3964 17.6231 24.4612 17.7395 23.4937 17.7395Z"
            :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: "VisardLinkThree",
    props: {
        stroke: '#232323'
    }
}
</script>

<style scoped lang="sass">

</style>