<template>
    <div>
        <textarea
            @keydown.tab.prevent="addTab"
            rows="10"
            class="text-field"
            :value="modelValue"
            @change="onChange"
            @input="onInput"
        ></textarea>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        validators: {
            type: Array,
            default: []
        }

    },
    methods: {
        addTab($event) {
            const value = $event.target.value
            if ($event.shiftKey) {
                console.log('halting event due to SHIFT+TAB');
                const cursor = $event.target.selectionStart
                const cursorEnd = $event.target.selectionEnd
                const startPart = value.slice(0, cursor)
                if (!startPart.endsWith('    ')) return;
                const endPart = value.slice(cursor, value.length)
                $event.target.value = startPart.slice(0, cursor - 4) + endPart
                $event.target.selectionStart = cursor - 4
                $event.target.selectionEnd = cursorEnd - 4
                $event.target.dispatchEvent(new Event('change'));
                return;
            }
            const cursor = $event.target.selectionStart
            const cursorEnd = $event.target.selectionEnd
            const startPart = value.slice(0, cursor)
            const endPart = value.slice(cursor, value.length)
            $event.target.value = startPart + '    ' + endPart
            $event.target.selectionStart = cursor + 4
            $event.target.selectionEnd = cursorEnd + 4
            $event.target.dispatchEvent(new Event('change'));
        },
        onChange($event) {
            console.log('changed')
            this.$emit('update:modelValue', $event.target.value)
        },
        onInput($event) {
            console.log('changed')
            this.$emit('update:modelValue', $event.target.value)
        },
    },
}
</script>

<style scoped lang="sass">
div
    width: 100%

.text-field
    resize: vertical
    width: 100%
    background: var(--background-input)
    border: 1px solid
    border-color: var(--border-form-input)
    padding-left: 20px
    padding-right: 8px
    outline: none
    font-size: 16px
    color: inherit
    border-radius: var(--border-radius-input)

    &:focus
        border-color: var(--primary) !important
    &:hover
        border-color: var(--description-text-darken)

    &-valid
        background: var(--primary-lighten) !important
        border-color: var(--primary) !important

    &-invalid
        background: var(--error) !important
        border-color: var(--error_red) !important
        border-radius: 3px 3px 0 0
        color: var(--error_red)
</style>