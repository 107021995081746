<template>
    <svg width="26" height="34" viewBox="0 0 26 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1 27.4828C1 30.5298 3.47015 33 6.51724 33H19.7586C19.9897 33 20.2175 32.9858 20.4411 32.9582C23.1662 32.622 25.2759 30.2988 25.2759 27.4828V7.62069M19.2069 1H6.51724C4.15541 1 2.14021 2.48405 1.35326 4.57053C1.12493 5.1759 1 5.83198 1 6.51724V19.7586M19.2069 1L25.2759 7.62069M19.2069 1V6.51724C19.2069 7.12666 19.7009 7.62069 20.3103 7.62069H25.2759M4.31034 6.51724H13.1379M4.31034 9.82759H13.1379M4.31034 23.069H13.1379M4.31034 13.1379H14.2414M6.51724 29.6897H10.931M4.31034 16.4483H21.9655M4.31034 19.7586H21.9655M17.5517 13.1379H21.9655M15.3448 29.6897H19.7586M17.5517 23.069H21.9655M1 23.6207V24.1724"
            :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: "VisardLinkTwo",
    props: {
        stroke: '#232323'
    }
}
</script>

<style scoped lang="sass">

</style>