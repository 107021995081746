<template>
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="4.24597" r="3.67212" fill="#000000"/>
        <circle cx="2.62294" cy="10.8032" r="2.62294" fill="#000000"/>
        <path d="M2.75415 11.0656L5.90168 6.8689" stroke="#000000"/>
        <path d="M13.2458 11.0656L10.0983 6.8689" stroke="#000000"/>
        <circle cx="13.3771" cy="10.8032" r="2.62294" fill="#000000"/>
    </svg>
</template>

<script>
export default {
    name: "Graph"
}
</script>
