<template>
    <div class="icon-pack">
        <div v-for="icon in icons">
            <component :is="icon" />
            <span>{{icon}}</span>
        </div>
    </div>
</template>

<script>
import Case from "@/components/icons/sidebar/Case.vue";
import Dollars from "@/components/icons/sidebar/Dollars.vue";
import Graph from "@/components/icons/sidebar/Graph.vue";
import Passport from "@/components/icons/sidebar/Passport.vue";
import Person from "@/components/icons/sidebar/Person.vue";
import Picture from "@/components/icons/sidebar/Picture.vue";
import Suitcase from "@/components/icons/sidebar/Suitcase.vue";

export default {
    name: "icon-pack",
    components: {
        Case,
        Dollars,
        Graph,
        Passport,
        Person,
        Picture,
        Suitcase
    },
    data() {
        return {
            icons: ['Case', 'Dollars', 'Graph', 'Passport', 'Person', 'Picture', 'Suitcase']
        }
    }
}
</script>

<style scoped lang="sass">
.icon-pack
    max-width: 1000px
    width: 100%
    margin: 0 auto
    display: flex
    div
        display: flex
        flex-direction: column
        align-items: center
        justify-content: space-between
        height: 100px
        padding: 12px
        svg
            width: 50px
            max-height: 50px
            height: 100%
        span
            margin-top: 12px


</style>